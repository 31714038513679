// import React, { useEffect, useState } from "react";
// import DashboardLayout from "../../../../layouts/DashboardLayout";
// import { Box, Button, Container, Grid, TextField } from "@mui/material";
// import useStyles from "./styles";
// import { useNavigate, useParams } from "react-router-dom";
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import CeroButton from "../../../../components/CeroButton";
// import Status from "./Status";
// import {
//   getInitiativeDetails,
//   getTasksList,
//   resetGoals,
//   saveTaskTracking,
// } from "../../../../redux/actions";
// import { useDispatch, useSelector } from "react-redux";
// import CeroTable from "../../../../components/CeroTable";
// import { STATUS } from "../../../../redux/constants";
// import { useSnackbar } from "notistack";
// import CreateIcon from "@mui/icons-material/CreateOutlined";
// import { getCookie } from "../../../../services/cookie";
// import FileUpload from "../../../AddEmissions/FileUpload";
// import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
// import CeroUploadedFile from "../../../../components/CeroUploadedFile";

// export const taskTableColumns = [
//   {
//     columnKey: "name",
//     columnId: "name",
//     columnHeader: "Task",
//     width: "10vw",
//   },
//   {
//     columnKey: "year",
//     columnId: "year",
//     columnHeader: "Year",
//     width: "8vw",
//   },
//   {
//     columnKey: "period",
//     columnId: "period",
//     columnHeader: "Period",
//     width: "8vw",
//   },
//   {
//     columnKey: "action",
//     columnId: "action",
//     columnHeader: "Progress%",
//     width: "8vw",
//   },
//   {
//     columnKey: "notes",
//     columnId: "notes",
//     columnHeader: "Notes",
//     width: "15vw",
//   },
//   {
//     columnKey: "edit",
//     columnId: "edit",
//     columnHeader: "",
//     width: "3vw",
//   },
// ];

// const InitiativeTrackingDetails = () => {
//   const classes = useStyles();
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const { id } = useParams();
//   const { enqueueSnackbar } = useSnackbar();
//   const [selectedId, setSelectedId] = useState("");
//   const [progressValue, setProgressValue] = useState({});
//   const [notesValue, setNotesValue] = useState({});
//   const [editingTasks, setEditingTasks] = useState({});
//   const [editedTaskIds, setEditedTaskIds] = useState(new Set());
//   const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")
//   const [recordModified, setRecordModified] = useState(false);
//   const [selectedFile, setSelectedFile] = useState(null);

//   const handlePercentageChange = (e, taskId) => {
//     setSelectedId(taskId)
//     var progress = e.target.value;

//     setProgressValue((prevMap) => ({
//       ...prevMap,
//       [taskId]: progress,
//     }));
//     handleEdit(e, taskId)

//   };

//   const handleFileUpload = (file) => {
//     setSelectedFile(file);
//   };
//   const handleFileRemove = () => {
//     setSelectedFile(null);
//   };

//   const handleRemoveFile = (index) => {
//     const updatedFiles = [...selectedFile];
//     updatedFiles.splice(index, 1);
//     setSelectedFile(updatedFiles);
//   };

//   const handleFileChange = (event) => {
//     const files = Array.from(event.target.files);
//     const newFiles = files.map((file) => ({
//       name: file.name,
//       size: `${(file.size / 1024).toFixed(2)} KB`,
//       file,
//     }));
//     setSelectedFile((files));
//   };

//   const handleDetailsChange = (e, taskId) => {
//     setSelectedId(taskId)
//     var note = e.target.value;
//     setNotesValue((prevMap) => ({
//       ...prevMap,
//       [taskId]: note,
//     }));
//     handleEdit(e, taskId)
//   };

//   const initiativeDetails = useSelector(
//     (state) => state.goalSettings.getInitiativeDetails.data
//   );

//   const taskDetails = useSelector(
//     (state) => state.goalSettings.getTasksList.data
//   );

//   const saveTask = useSelector((state) => state.goalSettings.saveTaskTracking);

//   useEffect(() => {
//     dispatch(getInitiativeDetails(id));
//     dispatch(getTasksList(id));
//   }, [dispatch, id]);

//   useEffect(() => {
//     // const result = {}
//     if (taskDetails.length>0){
//       const { result, result1 } = taskDetails?.reduce(
//         ({ result, result1 }, task) => {
//           result[task.id] = task.progress?parseInt(task.progress.split('%')[0]):0;
//           result1[task.id] = task.note;
//           return { result, result1};
//         },
//         { result: {}, result1: {}}
//       );
//     setProgressValue(result)
//     setNotesValue(result1);
//     }
//   }, [taskDetails]);

//   useEffect(() => {
//     const hasProgressChanges = Array.isArray(taskDetails) && taskDetails?.some(item => {
//       const progressInValue = progressValue[item.id];
//       const itemProgress = item.progress;
//       if (!!!itemProgress && !!!progressInValue)
//         return false
//       else if (!!itemProgress && !!!progressInValue)
//         return true
//       else if (!!!itemProgress && !!progressInValue)
//         return true
//       // Otherwise, perform the usual comparison
//       return parseFloat(progressInValue) !== parseFloat(itemProgress.split('%')[0]);
//     });

//     const hasNoteChanges = Array.isArray(taskDetails) && taskDetails?.some(item => {
//       const noteInValue = notesValue[item.id];
//       const itemNote = item.note;
//       // Check if targetInValue is "" and itemTarget is null, and treat them as equivalent
//       if ((noteInValue === "" && itemNote === null) || (noteInValue === null && itemNote === "")) {
//         return false;
//       }
//       else if (!itemNote && noteInValue)
//         return true
//       else if (itemNote && !noteInValue)
//         return true
//       // Otherwise, perform the usual comparison
//       return noteInValue !== itemNote;
//     });

//     if (hasProgressChanges || hasNoteChanges)
//       setRecordModified(true);
//     else
//     setRecordModified(false)
//     // ()
//   }, [progressValue, notesValue]);

//   const handleEdit = (e, taskId) => {
//     e.preventDefault();
//     e.stopPropagation();
//     // setTaskId(taskId);
//     setEditedTaskIds((prevSet) => new Set(prevSet.add(taskId)));
//       setEditingTasks(prevState => ({
//         ...prevState,
//         [taskId]: true,
//       }));
//   };

//   const onSaveTaskDetails = () => {
//     const updatedTasks = taskDetails.map((task) => {
//     let progress = task.progress;
//     let note = task.note;
//     setSelectedId("")

//     if (editedTaskIds.has(task.id)) {
//       progress = progressValue[task.id]?progressValue[task.id]:0;
//       note = notesValue[task.id];
//     }
//     else if (task.id === selectedId) {
//       progress = progressValue[selectedId] || task.progress;
//       note = notesValue[selectedId] || task.note;
//     }
//         return {
//           ...task,
//           progress,
//           note,
//         };
//     });
//     dispatch(saveTaskTracking(id, updatedTasks));
//   };

//   useEffect(() => {
//     if (saveTask.status === STATUS.SUCCESS) {
//       dispatch(resetGoals());
//       dispatch(getTasksList(id));
//       setEditingTasks(prevState => {
//         const newState = {};
//         for (const key in prevState) {
//           newState[key] = false;
//         }
//         return newState;
//       });

//       enqueueSnackbar("Saved successfully", { variant: "success", autoHideDuration: 3000, });
//     } else if (saveTask.status === STATUS.ERROR) {
//       dispatch(resetGoals());
//       enqueueSnackbar(
//         "We couldn't process your request. Please try again later.",
//         { variant: "error", autoHideDuration: 3000, }
//       );
//     }
//   }, [saveTask, dispatch, id]);

//   const getInitiativesList = () => {
//     if (!Array.isArray(taskDetails)) {
//       return [];
//     }
//     return taskDetails?.map((item) => {
//       return {
//         ...item,
//         action: editingTasks[item.id] ? (
//           <TextField
//             className={classes.percentField}
//             variant="outlined"
//             value={progressValue[item.id]}
//             onChange={(e) => handlePercentageChange(e, item.id)}
//             placeholder="Enter %"
//             disabled={readOnlyMode}
//             type="number"
//           />
//         ) : item.progress === null ? (
//           <TextField
//             className={classes.percentField}
//             variant="outlined"
//             value={progressValue[item.id]}
//             onChange={(e) => handlePercentageChange(e, item.id)}
//             placeholder="Enter %"
//             disabled={readOnlyMode}

//           />
//         ) : (
//           item.progress
//         ),
//         notes: editingTasks[item.id] ? (
//           <TextField
//             className={classes.textField}
//             variant="outlined"
//             multiline
//             rows={2}
//             value={notesValue[item.id]}
//             onChange={(e) => handleDetailsChange(e, item.id)}
//             placeholder="Enter details"
//             disabled={readOnlyMode}
//           />
//         ) : item.note === null ? (
//           <TextField
//             className={classes.textField}
//             variant="outlined"
//             multiline
//             rows={2}
//             value={notesValue[item.id]}
//             onChange={(e) => handleDetailsChange(e, item.id)}
//             placeholder="Enter details"
//             disabled={readOnlyMode}
//           />
//         ) : (
//           item.note
//         ),
//         edit:
//           !readOnlyMode && (
//             <CreateIcon
//               onClick={(e) => handleEdit(e, item.id)}
//               className={classes.editIcon}
//             />
//           ),
//       };
//     });
//   };

//   return (
//     <DashboardLayout>
//       <Container className={classes.container}>
//         <Box className={classes.buttonContainer}>
//           <div
//             className={classes.backContainer}
//             onClick={() => {
//               navigate(-1);
//             }}
//           >
//             <ArrowBackIosIcon />
//             Back
//           </div>
//          <div>
//             <CeroButton
//               buttonText="Save"
//               className={classes.buttonPrimary}
//               onClick={onSaveTaskDetails}
//               disabled={readOnlyMode || !recordModified}
//             />
//                   {/* <FileUpload onFileUpload={handleFileUpload} onFileRemove={handleFileRemove} /> */}
//                   {/* <CeroUploadedFile /> */}
//                   <Grid item xs={12}>
//             <Button
//               variant="contained"
//               className={classes.uploadButton}
//               component="label"
//               startIcon={<AttachmentOutlinedIcon />}
//             >
//               Attach Documents
//               <input type="file" hidden multiple onChange={handleFileChange} />
//             </Button>
//             <Box sx={{ display: "flex", gap: "8px" }}>
//               {selectedFile?.map((file, index) => (
//                 <CeroUploadedFile
//                   key={index}
//                   fileName={file.name}
//                   fileSize={file.size}
//                   onRemove={() => handleRemoveFile(index)}
//                 />
//               ))}
//             </Box>
//           </Grid>

//           </div>
//         </Box>
//         <Status details={initiativeDetails} />
//         <CeroTable
//           columns={taskTableColumns}
//           data={getInitiativesList()}
//           hasMore={false}
//           loading={false}
//           classes={{ tableBodyCell: classes.tableBodyCell }}
//         />
//       </Container>
//     </DashboardLayout>
//   );
// };

// export default InitiativeTrackingDetails;

import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import { Box, Button, Container, Grid, TextField } from "@mui/material";
import useStyles from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroButton from "../../../../components/CeroButton";
import Status from "./Status";
import {
  getInitiativeDetails,
  getTasksList,
  initiativeAttachment,
  resetGoals,
  resetInitiativeAttachment,
  saveTaskTracking,
} from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import CeroTable from "../../../../components/CeroTable";
import { STATUS } from "../../../../redux/constants";
import { useSnackbar } from "notistack";
import CreateIcon from "@mui/icons-material/CreateOutlined";
import { getCookie } from "../../../../services/cookie";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import CeroUploadedFile from "../../../../components/CeroUploadedFile";
import axios from "axios";
import FileUpload from "../../../AddEmissions/FileUpload";

export const taskTableColumns = [
  {
    columnKey: "name",
    columnId: "name",
    columnHeader: "Task",
    width: "10vw",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
    width: "8vw",
  },
  {
    columnKey: "period",
    columnId: "period",
    columnHeader: "Period",
    width: "8vw",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "Progress%",
    width: "8vw",
  },
  {
    columnKey: "notes",
    columnId: "notes",
    columnHeader: "Notes",
    width: "15vw",
  },
  {
    columnKey: "edit",
    columnId: "edit",
    columnHeader: "",
    width: "3vw",
  },
];

const InitiativeTrackingDetails = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedId, setSelectedId] = useState("");
  const [progressValue, setProgressValue] = useState({});
  const [notesValue, setNotesValue] = useState({});
  const [editingTasks, setEditingTasks] = useState({});
  const [editedTaskIds, setEditedTaskIds] = useState(new Set());
  const readOnlyMode = getCookie("read_only_mode") === "disable_edit";
  const [recordModified, setRecordModified] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);

  const handlePercentageChange = (e, taskId) => {
    setSelectedId(taskId);
    var progress = e.target.value;

    setProgressValue((prevMap) => ({
      ...prevMap,
      [taskId]: progress,
    }));
    handleEdit(e, taskId);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setFileUploaded(true);
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setFileUploaded(false);
  };

  const handleDetailsChange = (e, taskId) => {
    setSelectedId(taskId);
    var note = e.target.value;
    setNotesValue((prevMap) => ({
      ...prevMap,
      [taskId]: note,
    }));
    handleEdit(e, taskId);
  };

  const initiativeDetails = useSelector(
    (state) => state.goalSettings.getInitiativeDetails.data
  );

  const taskDetails = useSelector(
    (state) => state.goalSettings.getTasksList.data
  );

  const saveTask = useSelector((state) => state.goalSettings.saveTaskTracking);

  const saveAttachment = useSelector(
    (state) => state.goalSettings.initiativeAttachment
  );
  console.log("saveAttachment", saveAttachment.status);

  useEffect(() => {
    dispatch(getInitiativeDetails(id));
    dispatch(getTasksList(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (taskDetails.length > 0) {
      const { result, result1 } = taskDetails?.reduce(
        ({ result, result1 }, task) => {
          result[task.id] = task.progress
            ? parseInt(task.progress.split("%")[0])
            : 0;
          result1[task.id] = task.note;
          return { result, result1 };
        },
        { result: {}, result1: {} }
      );
      setProgressValue(result);
      setNotesValue(result1);
    }
  }, [taskDetails]);

  useEffect(() => {
    const hasProgressChanges =
      Array.isArray(taskDetails) &&
      taskDetails?.some((item) => {
        const progressInValue = progressValue[item.id];
        const itemProgress = item.progress;
        if (!!!itemProgress && !!!progressInValue) return false;
        else if (!!itemProgress && !!!progressInValue) return true;
        else if (!!!itemProgress && !!progressInValue) return true;
        return (
          parseFloat(progressInValue) !== parseFloat(itemProgress.split("%")[0])
        );
      });

    const hasNoteChanges =
      Array.isArray(taskDetails) &&
      taskDetails?.some((item) => {
        const noteInValue = notesValue[item.id];
        const itemNote = item.note;
        if (
          (noteInValue === "" && itemNote === null) ||
          (noteInValue === null && itemNote === "")
        ) {
          return false;
        } else if (!itemNote && noteInValue) return true;
        else if (itemNote && !noteInValue) return true;
        return noteInValue !== itemNote;
      });

    if (hasProgressChanges || hasNoteChanges) setRecordModified(true);
    else setRecordModified(false);
  }, [progressValue, notesValue]);

  const handleEdit = (e, taskId) => {
    e.preventDefault();
    e.stopPropagation();
    setEditedTaskIds((prevSet) => new Set(prevSet.add(taskId)));
    setEditingTasks((prevState) => ({
      ...prevState,
      [taskId]: true,
    }));
  };

  const handleFileUpload = (file) => {
    setSelectedFile(file);
    setFileUploaded(true);
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
    setFileUploaded(false);
  };

  const onSaveTaskDetails = async () => {
    const taskDetailsArray = Array.isArray(taskDetails)
      ? taskDetails
      : Object.values(taskDetails);

    const updatedTasks = taskDetailsArray.map((task) => {
      let progress = task.progress;
      let note = task.note;
      setSelectedId("");

      if (editedTaskIds.has(task.id)) {
        progress = progressValue[task.id] ? progressValue[task.id] : 0;
        note = notesValue[task.id];
      } else if (task.id === selectedId) {
        progress = progressValue[selectedId] || task.progress;
        note = notesValue[selectedId] || task.note;
      }
      return {
        ...task,
        progress,
        note,
      };
    });

    const requestData = {
      id: id,
      file: selectedFile,
    };

    if (updatedTasks.length > 0) {
      await dispatch(saveTaskTracking(id, updatedTasks));
    }

    if (selectedFile) {
      await dispatch(initiativeAttachment(requestData));
    }
  };

  useEffect(() => {
    if (saveTask.status === STATUS.SUCCESS) {
      dispatch(resetGoals());
      dispatch(getTasksList(id));
      setEditingTasks((prevState) => {
        const newState = {};
        for (const key in prevState) {
          newState[key] = false;
        }
        return newState;
      });

      enqueueSnackbar("Saved successfully", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } else if (saveTask.status === STATUS.ERROR) {
      dispatch(resetGoals());
      enqueueSnackbar(
        "We couldn't process your request. Please try again later.",
        { variant: "error", autoHideDuration: 3000 }
      );
    }
  }, [saveTask, dispatch, id]);

  useEffect(() => {
    if (saveAttachment.status === STATUS.SUCCESS) {
      dispatch(resetInitiativeAttachment());
      setSelectedFile(null);
      setFileUploaded(false);
      enqueueSnackbar("Saved successfully", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } else if (saveAttachment.status === STATUS.ERROR) {
      dispatch(resetInitiativeAttachment());
      enqueueSnackbar(saveAttachment.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [saveAttachment, dispatch]);

  const getInitiativesList = () => {
    if (!Array.isArray(taskDetails)) {
      return [];
    }
    return taskDetails?.map((item) => {
      return {
        ...item,
        action: editingTasks[item.id] ? (
          <TextField
            className={classes.percentField}
            variant="outlined"
            value={progressValue[item.id]}
            onChange={(e) => handlePercentageChange(e, item.id)}
            placeholder="Enter %"
            disabled={readOnlyMode}
            type="number"
          />
        ) : item.progress === null ? (
          <TextField
            className={classes.percentField}
            variant="outlined"
            value={progressValue[item.id]}
            onChange={(e) => handlePercentageChange(e, item.id)}
            placeholder="Enter %"
            disabled={readOnlyMode}
          />
        ) : (
          item.progress
        ),
        notes: editingTasks[item.id] ? (
          <TextField
            className={classes.textField}
            variant="outlined"
            multiline
            rows={2}
            value={notesValue[item.id]}
            onChange={(e) => handleDetailsChange(e, item.id)}
            placeholder="Enter details"
            disabled={readOnlyMode}
          />
        ) : item.note === null ? (
          <TextField
            className={classes.textField}
            variant="outlined"
            multiline
            rows={2}
            value={notesValue[item.id]}
            onChange={(e) => handleDetailsChange(e, item.id)}
            placeholder="Enter details"
            disabled={readOnlyMode}
          />
        ) : (
          item.note
        ),
        edit: !readOnlyMode && (
          <CreateIcon
            onClick={(e) => handleEdit(e, item.id)}
            className={classes.editIcon}
          />
        ),
      };
    });
  };

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Box className={classes.buttonContainer}>
          <div
            className={classes.backContainer}
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIosIcon />
            Back
          </div>
          <div className={classes.buttonContainer}>
            <CeroButton
              buttonText="Save"
              className={classes.buttonPrimary}
              onClick={onSaveTaskDetails}
              disabled={readOnlyMode || (!recordModified && !fileUploaded)}
            />
            <Grid item xs={12}>
              {selectedFile ? (
                <CeroUploadedFile
                  fileName={selectedFile.name}
                  fileSize={selectedFile.size}
                  onRemove={handleRemoveFile}
                />
              ) : (
                <FileUpload
                  onFileUpload={handleFileUpload}
                  onFileRemove={handleFileRemove}
                />
              )}
            </Grid>
          </div>
        </Box>
        <Status details={initiativeDetails} />
        <CeroTable
          columns={taskTableColumns}
          data={getInitiativesList()}
          hasMore={false}
          loading={false}
          classes={{ tableBodyCell: classes.tableBodyCell }}
        />
      </Container>
    </DashboardLayout>
  );
};

export default InitiativeTrackingDetails;
