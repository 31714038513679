import * as yup from "yup";

export const updateWasteCombustionValidation = yup.object({
  facility: yup.string("Facility is required").required("Facility is required"),
  month: yup.string("Month is required").required("Month is required"),
  year: yup.string("Year is required").required("Year is required"),
  wasteType: yup
    .string("Waste type is required")
    .required("Waste type is required"),
  wasteDisposalOption: yup
    .string("Waste disposal option is required")
    .required("Waste disposal option is required"),
  unit: yup.string("Fuel unit is required").required("Fuel unit is required"),
  amount: yup
    .number()
    .typeError("Amount of fuel must be a valid number")
    .required("Amount of fuel is required")
    .strict(true),
});
