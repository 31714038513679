import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { months, sampleYear } from "../../../constants";
import { STATUS } from "../../../redux/constants";
import { addMobileCombustionValidation } from "./schema";
import {
  getEmissionFuelList,
  resetAddCombustionStatus,
  updateUseOfSoldProducts,
} from "../../../redux/actions";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroButton from "../../../components/CeroButton";
import CeroSelect from "../../../components/CeroSelect";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import EmissionUploadDrawer from "../../EmissionList/EmissionUploadDrawer";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroItemPair from "../../../components/CeroItemPair";
import FileUpload from "../../AddEmissions/FileUpload";

const EditUseOfSoldProducts = (props) => {
  const {
    onCancel,
    onUpload,
    onValidate,
    uploadDisabled,
    emissionData,
    emissionId,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const isCalculateDone = useSelector(
    (state) => state.emission.updateUseOfSoldProducts.isCalculateDone
  );
  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );

  const editEmissionData = useSelector(
    (state) => state.emission.updateUseOfSoldProducts
  );
  const emissionPerformData = useSelector((state) => state.emission.fuelList);
  const currentYear = new Date().getFullYear().toString();
  const [selectedFile, setSelectedFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      facility: emissionData.facility_id || "",
      year: emissionData.year || currentYear,
      month: emissionData.month || "",
      emissionType: emissionData.type_of_emission_from_solid_product_id || "",
      calculationMethod: emissionData.calculation_method || "",
      fuelSource: emissionData.fuel_source || "",
      totalQuantityOfFuelFeedStock: emissionData.amount_of_fuel || 0,
      fuelFeedUnit: emissionData.fuel_unit || "",
      ghgContained: emissionData.ghg_contained_id || "",
      ghgUnit: emissionData.ghg_unit || "",
      emissionPerUse:
        emissionData.emission_per_use_sold_intermediate_products || 0,
      totalLifeTimeUse: emissionData.total_lifetime_use_of_sold_products || 0,
      totalIntermediateProductSold:
        emissionData.total_intermediate_products || 0,
      amountOfGHGGainedPerProduct: emissionData.amount_of_ghg || 0,
      ghgReleasedPercentage: emissionData.percentage_of_ghg || 0,
      totalNumberOfProductSold: emissionData.total_number_of_product_sold || 0,
      totalLifetimeUsePercentage:
        emissionData.percentage_of_total_lifetime_use || 0,
      amountOfGHGEmittedIndirectly: emissionData.amount_of_ghg || 0,
      productName: emissionData.product_name || "",
      productLifeTime: emissionData.total_lifetime_expected_use || 0,
      soldNumbersInReportingPeriod: emissionData.number_sold || 0,
      fuelUnit: emissionData.fuel_unit,
      amountOfFuel: emissionData.amount_fuel || 0,
      modeOfTransport: emissionData.mode_of_transport_id || "",
      vehicleType: emissionData.vehicle_type_id || "",
      emissionFactorDataset: emissionData.ef_dataset_id || "",
      distanceTravelled: emissionData.distance_travelled || "",
      distanceUnit: emissionData.distance_unit
        ? emissionPerformData?.data?.unit?.find(
            (item) => item.name === emissionData.distance_unit
          )?.id || ""
        : "",
      massofPurchansedGoods: emissionData.mass_purchased_goods || "",
      massUnit: emissionData.mass_unit
        ? emissionPerformData?.data?.mass_unit?.find(
            (item) => item.name === emissionData.mass_unit
          )?.id || ""
        : "",
      totalSpent: emissionData.total_spent || 0,
      currency: emissionData.currency || 0,
      gasType: emissionData.gas_type_id || 0,
      refrigerant: emissionData.refrigerant_id || 0,
      quantity: emissionData.amount_of_refrigerant || 0,
      refrigerantUnit: emissionData.refrigerant_unit
        ? emissionPerformData?.data?.refrigerant_unit?.find(
            (item) => item.name === emissionData.refrigerant_unit
          )?.id || ""
        : "",
      electricityUnit: emissionData.electricity_unit
        ? emissionPerformData?.data?.refrigerant_unit?.find(
            (item) => item.name === emissionData.electricity_unit
          )?.id || ""
        : "",
      amountOfElectricity: emissionData.amount_electricity || 0,
      volumeOfStoredGoods: emissionData.volume_stored || 0,
      averageNumberOfDaysStored: emissionData.average_number_of_days || 0,
    },
    validationSchema: addMobileCombustionValidation,
    onSubmit: () => {},
    enableReinitialize: true,
  });

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  const emissionTypeList =
    emissionPerformData?.data?.type_of_emission_form?.map((item) => {
      return {
        key: item.id,
        value: item.name,
      };
    });

  const calculationNamesMap = {};
  const unitMap = {};
  var electricityId = "";
  const calculationInputList = () =>
    emissionPerformData?.data?.calculation_method
      ?.filter((item) => item.emission_form_id === formik.values.emissionType)
      .map((item) => {
        calculationNamesMap[item.id] = item.name; // Populate namesMap
        return {
          key: item.id,
          value: item.name,
        };
      }) || [];
  const fuelSourceInputList = emissionPerformData?.data?.fuel_sources?.map(
    (item) => {
      if (item.name.toLowerCase() === "electricity") electricityId = item.id;
      return {
        key: item.id,
        value: item.name,
      };
    }
  );

  const fuelUnitInputList = emissionPerformData?.data?.fuel_sources
    ?.find((item) => item.id === formik.values.fuelSource)
    ?.units.map((item) => ({ key: item, value: item }));

  const ghgGainedList = emissionPerformData?.data?.green_house_gas?.map(
    (item) => ({
      key: item.id,
      value: item.name,
    })
  );
  const ghgGainedUnitList = emissionPerformData?.data?.ghg_unit?.map(
    (item) => ({
      key: item.name,
      value: item.name,
    })
  );

  const gasTypeList = emissionPerformData?.data?.gas_types?.map((item) => ({
    key: item.id,
    value: item.name,
  }));
  const refrigerantList = () => {
    return formik.values.gasType
      ? emissionPerformData?.data?.refrigerants
          ?.filter((item) => item.gas_type_id === formik.values.gasType)
          ?.map((item) => ({
            key: item.id,
            value: item.name,
          })) || []
      : [];
  };
  const refrigerantsUnitList = emissionPerformData?.data?.refrigerant_unit?.map(
    (item) => {
      unitMap[item.id] = item.name;
      return {
        key: item.id,
        value: item.name,
      };
    }
  );
  const electricityUnitList = emissionPerformData?.data?.electricity_unit?.map(
    (item) => {
      unitMap[item.id] = item.name;
      return {
        key: item.id,
        value: item.name,
      };
    }
  );

  useEffect(() => {
    dispatch(getEmissionFuelList("use_of_sold_products"));
    return () => {
      dispatch(resetAddCombustionStatus());
    };
  }, [dispatch]);

  useEffect(() => {
    if (editEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Use of sold products data updated successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (editEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        editEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
    }
  }, [editEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onCalculate = () => {
    const requestData = {
      id: emissionId,
      facility_id: formik.values.facility,
      year: formik.values.year,
      month: formik.values.month,
      type_of_emission_from_solid_product_id: formik.values.emissionType,
      product_name: formik.values.productName,
      total_lifetime_expected_use: formik.values.productLifeTime,
      number_sold: formik.values.soldNumbersInReportingPeriod,

      fuel_source_id: formik.values.fuelSource,
      amount_of_fuel: parseFloat(
        formik.values.amountOfFuel || formik.values.totalQuantityOfFuelFeedStock
      ),
      fuel_unit: formik.values.fuelUnit || formik.values.fuelFeedUnit,
      calculation_method: formik.values.calculationMethod,
      amount_of_electricity: formik.values.amountOfElectricity,
      electricity_unit: unitMap[formik.values.electricityUnit],
      gas_type_id: formik.values.gasType,
      refrigerant_id: formik.values.refrigerant,
      refrigerant_unit: unitMap[formik.values.refrigerantUnit],
      amount_of_refrigerant: formik.values.quantity,
      total_intermediate_products: formik.values.totalIntermediateProductSold,
      total_lifetime_use_of_sold_products: formik.values.totalLifeTimeUse,
      emission_per_use_sold_intermediate_products: formik.values.emissionPerUse,
      total_number_of_product_sold: formik.values.totalNumberOfProductSold,
      ghg_contained_id: formik.values.ghgContained,
      ghg_unit: formik.values.ghgUnit,
      amount_of_ghg:
        formik.values.amountOfGHGGainedPerProduct ||
        formik.values.amountOfGHGEmittedIndirectly,
      percentage_of_ghg: formik.values.ghgReleasedPercentage,
      percentage_of_total_lifetime_use:
        formik.values.totalLifetimeUsePercentage,
      save: false,
    };
    dispatch(updateUseOfSoldProducts(requestData));
  };

  const onAddMobileCombustionData = () => {
    const requestData = {
      id: emissionId,
      facility_id: formik.values.facility,
      year: formik.values.year,
      month: formik.values.month,
      type_of_emission_from_solid_product_id: formik.values.emissionType,
      product_name: formik.values.productName,
      total_lifetime_expected_use: formik.values.productLifeTime,
      number_sold: formik.values.soldNumbersInReportingPeriod,

      fuel_source_id: formik.values.fuelSource,
      amount_of_fuel: parseFloat(
        formik.values.amountOfFuel || formik.values.totalQuantityOfFuelFeedStock
      ),
      fuel_unit: formik.values.fuelUnit || formik.values.fuelFeedUnit,
      calculation_method: formik.values.calculationMethod,
      amount_of_electricity: formik.values.amountOfElectricity,
      electricity_unit: unitMap[formik.values.electricityUnit],
      gas_type_id: formik.values.gasType,
      refrigerant_id: formik.values.refrigerant,
      refrigerant_unit: unitMap[formik.values.refrigerantUnit],
      amount_of_refrigerant: formik.values.quantity,
      total_intermediate_products: formik.values.totalIntermediateProductSold,
      total_lifetime_use_of_sold_products: formik.values.totalLifeTimeUse,
      emission_per_use_sold_intermediate_products: formik.values.emissionPerUse,
      total_number_of_product_sold: formik.values.totalNumberOfProductSold,
      ghg_contained_id: formik.values.ghgContained,
      ghg_unit: formik.values.ghgUnit,
      amount_of_ghg:
        formik.values.amountOfGHGGainedPerProduct ||
        formik.values.amountOfGHGEmittedIndirectly,
      percentage_of_ghg: formik.values.ghgReleasedPercentage,
      percentage_of_total_lifetime_use:
        formik.values.totalLifetimeUsePercentage,
      save: true,
      file: selectedFile,
    };
    sessionStorage.setItem("year", requestData.year);
    dispatch(updateUseOfSoldProducts(requestData));
  };

  const [isOpenEmissionTypeUpload, setIsOpenEmissionTypeUpload] =
    useState(false);
  const onCloseDrawerUpload = () => {
    setIsOpenEmissionTypeUpload(false);
  };
  const handleFileUpload = (file) => {
    setSelectedFile(file);
    // dispatch(uploadEmissionAttachement("emissionId", file));
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
  };

  return (
    <>
      <div className={classes.backContainer}>
        <div
          className={classes.backButtonDiv}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon />
          Back
        </div>
        <div className={classes.templateButtonDiv}></div>
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Edit Use of Sold Products
          </Typography>
          <Box className={classes.topContainer}>
            <Grid className={classes.dynamicBoard}>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroAutoComplete
                  id="year"
                  name="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.touched.year && formik.errors.year}
                  options={yearList}
                  value={formik.values.year}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="month"
                  name="month"
                  label="Month"
                  fullWidth
                  options={months}
                  selectedValue={formik.values.month}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.month && formik.errors.month}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="emissionType"
                  name="emissionType"
                  label="Types of emissions form sold products"
                  fullWidth
                  options={emissionTypeList}
                  selectedValue={formik.values.emissionType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.emissionType && formik.errors.emissionType
                  }
                />
              </Grid>
              {formik.values.emissionType !== "" && (
                <Grid item className={classes.individualInput}>
                  <CeroSelect
                    required
                    id="calculationMethod"
                    name="calculationMethod"
                    label="Choose the type of direct use phase emissions"
                    fullWidth
                    options={calculationInputList()}
                    selectedValue={formik.values.calculationMethod}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.month && formik.errors.month}
                    classes={{
                      input: classes.input,
                      container: classes.selectContainer,
                    }}
                  />
                </Grid>
              )}
              {formik.values.calculationMethod &&
                calculationNamesMap[
                  formik.values.calculationMethod
                ]?.toLowerCase() ===
                  "products that directly consume energy (fuels or electricity) during use" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="productName"
                        name="productName"
                        label="Product name"
                        value={formik.values.productName}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.productName &&
                          formik.errors.productName
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="productLifeTime"
                        name="productLifeTime"
                        label="Total lifetime expected uses of product:"
                        value={formik.values.productLifeTime}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.productLifeTime &&
                          formik.errors.productLifeTime
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="soldNumbersInReportingPeriod"
                        name="soldNumbersInReportingPeriod"
                        label="Number sold in reporting period"
                        value={formik.values.soldNumbersInReportingPeriod}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.soldNumbersInReportingPeriod &&
                          formik.errors.soldNumbersInReportingPeriod
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="fuelSource"
                        name="fuelSource"
                        label="Fuel Source"
                        fullWidth
                        options={fuelSourceInputList}
                        selectedValue={formik.values.fuelSource}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.fuelSource && formik.errors.fuelSource
                        }
                        classes={{
                          input: classes.input,
                          container: classes.selectContainer,
                        }}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="amountOfFuel"
                        name="amountOfFuel"
                        label="Total fuel consumed per use"
                        value={formik.values.amountOfFuel}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.amountOfFuel &&
                          formik.errors.amountOfFuel
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="fuelUnit"
                        name="fuelUnit"
                        label="Fuel Unit"
                        fullWidth
                        options={
                          electricityId === formik.values.fuelSource
                            ? electricityUnitList
                            : fuelUnitInputList || [{ name: "select" }]
                        }
                        selectedValue={formik.values.fuelUnit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.fuelUnit && formik.errors.fuelUnit
                        }
                        sortOption={true}
                        classes={{
                          input: classes.input,
                          container: classes.selectContainer,
                          paperRoot: classes.paperRoot,
                        }}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="amountOfElectricity"
                        name="amountOfElectricity"
                        label="Electricity consumed per use (kWh):"
                        value={formik.values.amountOfElectricity}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.amountOfElectricity &&
                          formik.errors.amountOfElectricity
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="electricityUnit"
                        name="electricityUnit"
                        label="Electricity unit"
                        fullWidth
                        options={electricityUnitList}
                        selectedValue={formik.values.electricityUnit || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.electricityUnit &&
                          formik.errors.electricityUnit
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="gasType"
                        name="gasType"
                        label="Gas type"
                        fullWidth
                        options={gasTypeList}
                        selectedValue={formik.values.gasType || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.gasType && formik.errors.gasType}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="refrigerant"
                        name="refrigerant"
                        label="Refrigerant"
                        fullWidth
                        options={refrigerantList()}
                        selectedValue={formik.values.refrigerant || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.refrigerant &&
                          formik.errors.refrigerant
                        }
                        classes={{
                          input: classes.input,
                          container: classes.selectContainer,
                          paperRoot: classes.paperRoot,
                        }}
                      />
                    </Grid>

                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="quantity"
                        name="quantity"
                        label="Refrigerant leakage per use"
                        value={formik.values.quantity}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.quantity && formik.errors.quantity
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="refrigerantUnit"
                        name="refrigerantUnit"
                        label="Unit"
                        fullWidth
                        options={refrigerantsUnitList}
                        selectedValue={formik.values.refrigerantUnit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.refrigerantUnit &&
                          formik.errors.refrigerantUnit
                        }
                      />
                    </Grid>
                  </>
                )}

              {formik.values.calculationMethod &&
                calculationNamesMap[
                  formik.values.calculationMethod
                ]?.toLowerCase() === "fuels and feedstock" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="fuelSource"
                        name="fuelSource"
                        label="Fuel / Feedstock"
                        fullWidth
                        options={fuelSourceInputList}
                        selectedValue={formik.values.fuelSource}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.fuelSource && formik.errors.fuelSource
                        }
                        classes={{
                          input: classes.input,
                          container: classes.selectContainer,
                        }}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="totalQuantityOfFuelFeedStock"
                        name="totalQuantityOfFuelFeedStock"
                        label="Total quantity of fuel/Feedstock sold"
                        value={formik.values.totalQuantityOfFuelFeedStock}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.totalQuantityOfFuelFeedStock &&
                          formik.errors.totalQuantityOfFuelFeedStock
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="fuelFeedUnit"
                        name="fuelFeedUnit"
                        label="Unit"
                        fullWidth
                        options={
                          electricityId === formik.values.fuelSource
                            ? electricityUnitList
                            : fuelUnitInputList || [{ name: "select" }]
                        }
                        selectedValue={formik.values.fuelFeedUnit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.fuelFeedUnit &&
                          formik.errors.fuelFeedUnit
                        }
                      />
                    </Grid>
                  </>
                )}
              {formik.values.calculationMethod &&
                calculationNamesMap[
                  formik.values.calculationMethod
                ]?.toLowerCase() ===
                  "greenhouse gases and products that contain or form greenhouse gases that are emitted during use" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="productName"
                        name="productName"
                        label="Product"
                        value={formik.values.productName}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.productName &&
                          formik.errors.productName
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="totalNumberOfProductSold"
                        name="totalNumberOfProductSold"
                        label="Total number of product sold"
                        value={formik.values.totalNumberOfProductSold}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.totalNumberOfProductSold &&
                          formik.errors.totalNumberOfProductSold
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="ghgContained"
                        name="ghgContained"
                        label="GHG contained"
                        fullWidth
                        options={ghgGainedList}
                        selectedValue={formik.values.ghgContained || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.ghgContained &&
                          formik.errors.ghgContained
                        }
                      />
                    </Grid>

                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="amountOfGHGGainedPerProduct"
                        name="amountOfGHGGainedPerProduct"
                        label="Amount of GHG contained per product"
                        value={formik.values.amountOfGHGGainedPerProduct}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.amountOfGHGGainedPerProduct &&
                          formik.errors.amountOfGHGGainedPerProduct
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="ghgUnit"
                        name="ghgUnit"
                        label="Unit"
                        fullWidth
                        options={ghgGainedUnitList}
                        selectedValue={formik.values.ghgUnit || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.ghgUnit && formik.errors.ghgUnit}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="ghgReleasedPercentage"
                        name="ghgReleasedPercentage"
                        label="% of GHG released during lifetime use of product"
                        value={formik.values.ghgReleasedPercentage}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.ghgReleasedPercentage &&
                          formik.errors.ghgReleasedPercentage
                        }
                        type="number"
                      />
                    </Grid>
                  </>
                )}
              {formik.values.calculationMethod &&
                calculationNamesMap[
                  formik.values.calculationMethod
                ]?.toLowerCase() ===
                  "indirect use-phase emissions from products that indirectly consume energy (fuels or electricity) during use" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="productName"
                        name="productName"
                        label="Product name"
                        value={formik.values.productName}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.productName &&
                          formik.errors.productName
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="productLifeTime"
                        name="productLifeTime"
                        label="Total lifetime expected uses of product:"
                        value={formik.values.productLifeTime}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.productLifeTime &&
                          formik.errors.productLifeTime
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="totalLifetimeUsePercentage"
                        name="totalLifetimeUsePercentage"
                        label="% of total lifetime uses using this scenario:"
                        value={formik.values.totalLifetimeUsePercentage}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.totalLifetimeUsePercentage &&
                          formik.errors.totalLifetimeUsePercentage
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="soldNumbersInReportingPeriod"
                        name="soldNumbersInReportingPeriod"
                        label="Number sold in reporting period"
                        value={formik.values.soldNumbersInReportingPeriod}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.soldNumbersInReportingPeriod &&
                          formik.errors.soldNumbersInReportingPeriod
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="fuelSource"
                        name="fuelSource"
                        label="Fuel Source"
                        fullWidth
                        options={fuelSourceInputList}
                        selectedValue={formik.values.fuelSource}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.fuelSource && formik.errors.fuelSource
                        }
                        classes={{
                          input: classes.input,
                          container: classes.selectContainer,
                        }}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="amountOfFuel"
                        name="amountOfFuel"
                        label="Total fuel consumed per use"
                        value={formik.values.amountOfFuel}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.amountOfFuel &&
                          formik.errors.amountOfFuel
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="fuelUnit"
                        name="fuelUnit"
                        label="Fuel Unit"
                        fullWidth
                        options={
                          electricityId === formik.values.fuelSource
                            ? electricityUnitList
                            : fuelUnitInputList || [{ name: "select" }]
                        }
                        selectedValue={formik.values.fuelUnit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.fuelUnit && formik.errors.fuelUnit
                        }
                        sortOption={true}
                        classes={{
                          input: classes.input,
                          container: classes.selectContainer,
                          paperRoot: classes.paperRoot,
                        }}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="amountOfElectricity"
                        name="amountOfElectricity"
                        label="Electricity consumed per use (kWh):"
                        value={formik.values.amountOfElectricity}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.amountOfElectricity &&
                          formik.errors.amountOfElectricity
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="electricityUnit"
                        name="electricityUnit"
                        label="Electricity unit"
                        fullWidth
                        options={electricityUnitList}
                        selectedValue={formik.values.electricityUnit || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.electricityUnit &&
                          formik.errors.electricityUnit
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="gasType"
                        name="gasType"
                        label="Gas type"
                        fullWidth
                        options={gasTypeList}
                        selectedValue={formik.values.gasType || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.gasType && formik.errors.gasType}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="refrigerant"
                        name="refrigerant"
                        label="Refrigerant"
                        fullWidth
                        options={refrigerantList()}
                        selectedValue={formik.values.refrigerant || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.refrigerant &&
                          formik.errors.refrigerant
                        }
                        classes={{
                          input: classes.input,
                          container: classes.selectContainer,
                          paperRoot: classes.paperRoot,
                        }}
                      />
                    </Grid>

                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="quantity"
                        name="quantity"
                        label="Refrigerant leakage per use"
                        value={formik.values.quantity}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.quantity && formik.errors.quantity
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="refrigerantUnit"
                        name="refrigerantUnit"
                        label="Unit"
                        fullWidth
                        options={refrigerantsUnitList}
                        selectedValue={formik.values.refrigerantUnit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.refrigerantUnit &&
                          formik.errors.refrigerantUnit
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="ghgContained"
                        name="ghgContained"
                        label="GHG contained"
                        fullWidth
                        options={ghgGainedList}
                        selectedValue={formik.values.ghgContained || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.ghgContained &&
                          formik.errors.ghgContained
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="amountOfGHGEmittedIndirectly"
                        name="amountOfGHGEmittedIndirectly"
                        label="Amount of GHG emitted indirectly"
                        value={formik.values.amountOfGHGEmittedIndirectly}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.amountOfGHGEmittedIndirectly &&
                          formik.errors.amountOfGHGEmittedIndirectly
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="ghgUnit"
                        name="ghgUnit"
                        label="Unit"
                        fullWidth
                        options={ghgGainedUnitList}
                        selectedValue={formik.values.ghgUnit || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.ghgUnit && formik.errors.ghgUnit}
                      />
                    </Grid>
                  </>
                )}
              {formik.values.calculationMethod &&
                calculationNamesMap[
                  formik.values.calculationMethod
                ]?.toLowerCase() ===
                  "calculation method for sold intermediate products" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="totalIntermediateProductSold"
                        name="totalIntermediateProductSold"
                        label="Total intermediate products sold"
                        value={formik.values.totalIntermediateProductSold}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.totalIntermediateProductSold &&
                          formik.errors.totalIntermediateProductSold
                        }
                        type="number"
                      />
                    </Grid>

                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="totalLifeTimeUse"
                        name="totalLifeTimeUse"
                        label="Total lifetime uses of final sold product"
                        value={formik.values.totalLifeTimeUse}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.totalLifeTimeUse &&
                          formik.errors.totalLifeTimeUse
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="emissionPerUse"
                        name="emissionPerUse"
                        label="Emissions per use of sold intermediate product (kg CO2 e/use)"
                        value={formik.values.emissionPerUse}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.emissionPerUse &&
                          formik.errors.emissionPerUse
                        }
                        type="number"
                      />
                    </Grid>
                  </>
                )}
            </Grid>
            <CeroButton
              buttonText="Calculate"
              className={classes.buttonPrimary}
              onClick={onCalculate}
              disabled={!formik.dirty || !formik.isValid}
            />
            <CeroButton
              buttonText="Clear"
              variant="outlined"
              className={classes.buttonSecondary}
              onClick={formik.resetForm}
            />
            {isCalculateDone && (
              <Box className={classes.bottomContainer}>
                <Typography
                  variant="h6"
                  component="h6"
                  className={classes.previewTitle}
                >
                  Emission Preview
                </Typography>
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="space-between"
                  spacing={8}
                >
                  <Grid item container direction="column" xs={12} md={6}>
                    <CeroItemPair
                      title={
                        <>
                          CO<sub>2</sub>
                        </>
                      }
                      value={`${editEmissionData.data.co2} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          CH<sub>4</sub>
                        </>
                      }
                      value={`${editEmissionData.data.ch4} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          BioFuel CO<sub>2</sub>
                        </>
                      }
                      value={`${editEmissionData.data.biofuel_co2} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                  </Grid>
                  <Grid
                    className={classes.secondResultContainer}
                    item
                    container
                    direction="column"
                    xs={6}
                  >
                    <CeroItemPair
                      title={
                        <>
                          CO<sub>2</sub>e
                        </>
                      }
                      value={`${editEmissionData.data.co2e} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          N<sub>2</sub>O
                        </>
                      }
                      value={`${editEmissionData.data.n2o} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={<>EF</>}
                      value={
                        <>
                          {editEmissionData.data.ef} kgCO<sub>2</sub>e/unit
                        </>
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
          <FileUpload
            onFileUpload={handleFileUpload}
            onFileRemove={handleFileRemove}
          />
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Update"
            disabled={
              !isCalculateDone || editEmissionData.status === STATUS.RUNNING
            }
            className={classes.buttonPrimary}
            onClick={() => onAddMobileCombustionData(formik.values)}
          />

          {/* <div className={classes.buttonContainer}>
                <CeroButton
                   buttonText="Bulk Data Upload"
                   className={classes.bulkButton}
                   startIcon={<FileUploadIcon />}
                   onClick={() => setIsOpenEmissionTypeUpload(true)}
                />
                <CeroButton
                    buttonText="Download Template"
                    className={classes.bulkButton}
                    startIcon={<DownloadIcon />}
                    onClick={onDownload}
                  />
                </div> */}
        </Box>
        <EmissionUploadDrawer
          isOpen={isOpenEmissionTypeUpload}
          onClose={onCloseDrawerUpload}
          onUpload={onUpload}
          onValidate={onValidate}
          uploadDisabled={uploadDisabled}
        />
      </Container>
    </>
  );
};

export default EditUseOfSoldProducts;
