import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { months, sampleYear } from "../../../constants";
import { STATUS } from "../../../redux/constants";
import { addMobileCombustionValidation } from "./schema";
import {
  addMobileCombustion,
  addUpstreamLeasedAsset,
  getEmissionFuelList,
  resetAddCombustionStatus,
} from "../../../redux/actions";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroButton from "../../../components/CeroButton";
import CeroSelect from "../../../components/CeroSelect";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import DownloadIcon from "@mui/icons-material/Download";
import EmissionUploadDrawer from "../../EmissionList/EmissionUploadDrawer";
import { useNavigate } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CeroItemPair from "../../../components/CeroItemPair";
import FileUpload from "../FileUpload";

const AddUpstreamLeasedAsset = (props) => {
  const { onCancel, onDownload, onUpload, onValidate, uploadDisabled } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const isCalculateDone = useSelector(
    (state) => state.emission.addUpstreamLeasedAsset.isCalculateDone
  );
  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const activityTypesData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.activity_types
  );
  const fuelSourceData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.fuel_sources
  );
  const customFuelSourceData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.custom_fuel_sources
  );
  const vehicleTypeData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.vehicle_types
  );
  const fuelUnitData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.units
  );
  const addEmissionData = useSelector(
    (state) => state.emission.addUpstreamLeasedAsset
  );
  const emissionPerformData = useSelector((state) => state.emission.fuelList);
  const currentYear = new Date().getFullYear().toString();
  const [selectedFile, setSelectedFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      facility: "",
      year: currentYear,
      month: "",
      calculationMethod: "",
      calculationApproach: "",
      averageDataSubMethod: "",
      fuelSource: "",
      fuelUnit: "",
      amountOfFuel: "",
      gasType: "",
      refrigerant: "",
      quantity: "",
      refrigerantUnit: "",
      electricityUnit: "",
      amountOfElectricity: "",
      refrigerantQuantity: "",
      energyQuantity: "",
      processEmission: "",
      energyUnit: "",
      quantityOfLeasedAsset: "",
      totalQuantityOfLeasedAsset: "",
      buildingType: "",
      buildingTypeUnit: "",
      totalFloorSpcace: "",
      emissionFactorForAsset: "",
      numberOfAsset: "",
    },
    validationSchema: addMobileCombustionValidation,
    onSubmit: () => {},
  });

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  const fuelSourceInputList = emissionPerformData?.data?.fuel_sources?.map(
    (item) => ({
      key: item.id,
      value: item.name,
    })
  );
  const fuelUnitInputList = emissionPerformData?.data?.fuel_sources
    ?.find((item) => item.id === formik.values.fuelSource)
    ?.units.map((item) => ({ key: item, value: item }));

  const gasTypeList = emissionPerformData?.data?.gas_types?.map((item) => ({
    key: item.id,
    value: item.name,
  }));
  const refrigerantList = () => {
    return formik.values.gasType
      ? emissionPerformData?.data?.refrigerants
          ?.filter((item) => item.gas_type_id === formik.values.gasType)
          ?.map((item) => ({
            key: item.id,
            value: item.name,
          })) || []
      : [];
  };
  const refrigerantsUnitList = emissionPerformData?.data?.refrigerant_unit?.map(
    (item) => ({
      key: item.name,
      value: item.name,
    })
  );
  const calculationMap = {};
  const calculationMethodInputList =
    emissionPerformData?.data?.calculation_method?.map((item) => {
      calculationMap[item.id] = item.name.toLowerCase();
      return {
        key: item.id,
        value: item.name,
      };
    });
  const calculationApproachInputList =
    emissionPerformData?.data?.calculation_approaches?.map((item) => ({
      key: item.id,
      value: item.name,
    }));
  const averageMethodMap = {};

  const averageMethodList = emissionPerformData?.data?.average_data_types?.map(
    (item) => {
      averageMethodMap[item.id] = item.name.toLowerCase();
      return {
        key: item.id,
        value: item.name,
      };
    }
  );
  const buildingTypeList = emissionPerformData?.data?.building_types?.map(
    (item) => {
      return {
        key: item.id,
        value: item.name,
      };
    }
  );
  const buildingTypeUnitList =
    emissionPerformData?.data?.building_type_unit?.map((item) => {
      return {
        key: item.name,
        value: item.name,
      };
    });

  const electricityUnitList = emissionPerformData?.data?.electricity_unit?.map(
    (item) => ({
      key: item.name,
      value: item.name,
    })
  );

  useEffect(() => {
    dispatch(getEmissionFuelList("upstream_leased_asset"));
    return () => {
      dispatch(resetAddCombustionStatus());
    };
  }, [dispatch]);

  useEffect(() => {
    if (addEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Upstream leased asset added successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (addEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        addEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
    }
  }, [addEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onCalculate = () => {
    const requestData = {
      facility_id: formik.values.facility,
      year: formik.values.year,
      month: formik.values.month,
      fuel_source_id: formik.values.fuelSource,
      calculation_method: formik.values.calculationMethod,
      fuel_unit: formik.values.fuelUnit,
      amount_of_fuel: formik.values.amountOfFuel,
      amount_of_electricity:
        formik.values.amountOfElectricity || formik.values.energyQuantity,
      electricity_unit:
        formik.values.electricityUnit || formik.values.energyUnit,
      gas_type_id: formik.values.gasType,
      refrigerant_id: formik.values.refrigerant,
      refrigerant_unit: formik.values.refrigerantUnit,
      amount_of_refrigerants: formik.values.refrigerantQuantity,
      electricity_calculation_approach: formik.values.calculationApproach,
      process_emission: formik.values.processEmission,
      amount_of_leased_assets: formik.values.quantityOfLeasedAsset,
      total_amount_of_leased_assets: formik.values.totalQuantityOfLeasedAsset,
      type_of_average_data_method: formik.values.averageDataSubMethod,
      total_floor_space: formik.values.totalFloorSpcace,
      building_type_id: formik.values.buildingType,
      building_type_unit: formik.values.buildingTypeUnit,
      number_of_assets: formik.values.numberOfAsset,
      average_emission_factor_of_building_type:
        formik.values.emissionFactorForAsset,
      save: false,
    };
    dispatch(addUpstreamLeasedAsset(requestData));
  };

  const onAddUpstreamLeasedData = () => {
    const requestData = {
      facility_id: formik.values.facility,
      year: formik.values.year,
      month: formik.values.month,
      fuel_source_id: formik.values.fuelSource,
      calculation_method: formik.values.calculationMethod,
      fuel_unit: formik.values.fuelUnit,
      amount_of_fuel: formik.values.amountOfFuel,
      amount_of_electricity:
        formik.values.amountOfElectricity || formik.values.energyQuantity,
      electricity_unit:
        formik.values.electricityUnit || formik.values.energyUnit,
      gas_type_id: formik.values.gasType,
      refrigerant_id: formik.values.refrigerant,
      refrigerant_unit: formik.values.refrigerantUnit,
      amount_of_refrigerants: formik.values.refrigerantQuantity,
      electricity_calculation_approach: formik.values.calculationApproach,
      process_emission: formik.values.processEmission,
      amount_of_leased_assets: formik.values.quantityOfLeasedAsset,
      total_amount_of_leased_assets: formik.values.totalQuantityOfLeasedAsset,
      type_of_average_data_method: formik.values.averageDataSubMethod,
      total_floor_space: formik.values.totalFloorSpcace,
      building_type_id: formik.values.buildingType,
      building_type_unit: formik.values.buildingTypeUnit,
      number_of_assets: formik.values.numberOfAsset,
      average_emission_factor_of_building_type:
        formik.values.emissionFactorForAsset,
      save: true,
      file: selectedFile,
    };
    sessionStorage.setItem("year", requestData.year);
    dispatch(addUpstreamLeasedAsset(requestData));
  };

  const [isOpenEmissionTypeUpload, setIsOpenEmissionTypeUpload] =
    useState(false);
  const onCloseDrawerUpload = () => {
    setIsOpenEmissionTypeUpload(false);
  };
  const handleFileUpload = (file) => {
    setSelectedFile(file);
    // dispatch(uploadEmissionAttachement("emissionId", file));
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
  };
  //   const info = () => {
  //     return (
  //       <div>
  //         <Typography variant="body2">
  //           Fuel use: Mobile combustion by the usage of fuel for mobile operations
  //           such as transport.
  //           <br />
  //           Distance activity: Distance travelled by the usage of fuel
  //         </Typography>
  //       </div>
  //     );
  //   };

  return (
    <>
      <div className={classes.backContainer}>
        <div
          className={classes.backButtonDiv}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon />
          Back
        </div>
        <div className={classes.templateButtonDiv}></div>
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Add Upstream Leased Asset
          </Typography>
          <Box className={classes.topContainer}>
            <Grid className={classes.dynamicBoard}>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroAutoComplete
                  id="year"
                  name="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.touched.year && formik.errors.year}
                  options={yearList}
                  value={formik.values.year}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="month"
                  name="month"
                  label="Month"
                  fullWidth
                  options={months}
                  selectedValue={formik.values.month}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.month && formik.errors.month}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="calculationMethod"
                  name="calculationMethod"
                  label="Calculation Method"
                  fullWidth
                  options={calculationMethodInputList}
                  selectedValue={formik.values.calculationMethod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.calculationMethod &&
                    formik.errors.calculationMethod
                  }
                />
              </Grid>

              {calculationMap[formik.values.calculationMethod] ===
                "asset-specific method" && (
                <>
                  <Grid item className={classes.individualInput}>
                    <CeroSelect
                      required
                      id="fuelSource"
                      name="fuelSource"
                      label="Fuel Source"
                      fullWidth
                      options={fuelSourceInputList}
                      selectedValue={formik.values.fuelSource}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.fuelSource && formik.errors.fuelSource
                      }
                      classes={{
                        input: classes.input,
                        container: classes.selectContainer,
                      }}
                    />
                  </Grid>
                  <Grid item className={classes.individualInput}>
                    <CeroInput
                      required
                      id="amountOfFuel"
                      name="amountOfFuel"
                      label="Amount of fuel consumed"
                      value={formik.values.amountOfFuel}
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.amountOfFuel &&
                        formik.errors.amountOfFuel
                      }
                      type="number"
                    />
                  </Grid>
                  <Grid item className={classes.individualInput}>
                    <CeroSelect
                      required
                      id="fuelUnit"
                      name="fuelUnit"
                      label="Fuel Unit"
                      fullWidth
                      options={fuelUnitInputList || [{ name: "select" }]}
                      selectedValue={formik.values.fuelUnit}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.fuelUnit && formik.errors.fuelUnit}
                      sortOption={true}
                    />
                  </Grid>
                  <Grid item className={classes.individualInput}>
                    <CeroSelect
                      required
                      id="gasType"
                      name="gasType"
                      label="Gas type"
                      fullWidth
                      options={gasTypeList}
                      selectedValue={formik.values.gasType || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.gasType && formik.errors.gasType}
                    />
                  </Grid>
                  <Grid item className={classes.individualInput}>
                    <CeroSelect
                      required
                      id="refrigerant"
                      name="refrigerant"
                      label="Refrigerant"
                      fullWidth
                      options={refrigerantList()}
                      selectedValue={formik.values.refrigerant || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.refrigerant && formik.errors.refrigerant
                      }
                      classes={{
                        input: classes.input,
                        container: classes.selectContainer,
                        paperRoot: classes.paperRoot,
                      }}
                    />
                  </Grid>
                  <Grid item className={classes.individualInput}>
                    <CeroInput
                      required
                      id="refrigerantQuantity"
                      name="refrigerantQuantity"
                      label="Quantity of Refrigerant Leakage"
                      value={formik.values.refrigerantQuantity}
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.refrigerantQuantity &&
                        formik.errors.refrigerantQuantity
                      }
                      type="number"
                    />
                  </Grid>
                  <Grid item className={classes.individualInput}>
                    <CeroSelect
                      required
                      id="refrigerantUnit"
                      name="refrigerantUnit"
                      label="Unit"
                      fullWidth
                      options={refrigerantsUnitList}
                      selectedValue={formik.values.refrigerantUnit || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.refrigerantUnit &&
                        formik.errors.refrigerantUnit
                      }
                    />
                  </Grid>
                  <Grid item className={classes.individualInput}>
                    <CeroSelect
                      required
                      id="calculationApproach"
                      name="calculationApproach"
                      label="Calculation Approach"
                      fullWidth
                      options={calculationApproachInputList}
                      selectedValue={formik.values.calculationApproach}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.month && formik.errors.month}
                    />
                  </Grid>
                  <Grid item className={classes.individualInput}>
                    <CeroInput
                      required
                      id="energyQuantity"
                      name="energyQuantity"
                      label="Quantity of electricity,steam,heating,cooling consumed."
                      value={formik.values.energyQuantity}
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.energyQuantity &&
                        formik.errors.energyQuantity
                      }
                      type="number"
                    />
                  </Grid>
                  <Grid item className={classes.individualInput}>
                    <CeroSelect
                      required
                      id="energyUnit"
                      name="energyUnit"
                      label="Unit"
                      fullWidth
                      options={electricityUnitList}
                      selectedValue={formik.values.energyUnit || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.energyUnit && formik.errors.energyUnit
                      }
                    />
                  </Grid>
                  <Grid item className={classes.individualInput}>
                    <CeroInput
                      required
                      id="processEmission"
                      name="processEmission"
                      label="Process emissions in kg"
                      value={formik.values.processEmission}
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.processEmission &&
                        formik.errors.processEmission
                      }
                      type="number"
                    />
                  </Grid>
                </>
              )}

              {calculationMap[formik.values.calculationMethod] ===
                "lessor-specific method" && (
                <>
                  <Grid item className={classes.individualInput}>
                    <CeroSelect
                      required
                      id="fuelSource"
                      name="fuelSource"
                      label="Fuel Source"
                      fullWidth
                      options={fuelSourceInputList}
                      selectedValue={formik.values.fuelSource}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.fuelSource && formik.errors.fuelSource
                      }
                      classes={{
                        input: classes.input,
                        container: classes.selectContainer,
                      }}
                    />
                  </Grid>
                  <Grid item className={classes.individualInput}>
                    <CeroInput
                      required
                      id="amountOfFuel"
                      name="amountOfFuel"
                      label="Amount of fuel consumed"
                      value={formik.values.amountOfFuel}
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.amountOfFuel &&
                        formik.errors.amountOfFuel
                      }
                      type="number"
                    />
                  </Grid>
                  <Grid item className={classes.individualInput}>
                    <CeroSelect
                      required
                      id="fuelUnit"
                      name="fuelUnit"
                      label="Fuel Unit"
                      fullWidth
                      options={fuelUnitInputList || [{ name: "select" }]}
                      selectedValue={formik.values.fuelUnit}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.fuelUnit && formik.errors.fuelUnit}
                      sortOption={true}
                    />
                  </Grid>
                  <Grid item className={classes.individualInput}>
                    <CeroSelect
                      required
                      id="gasType"
                      name="gasType"
                      label="Gas type"
                      fullWidth
                      options={gasTypeList}
                      selectedValue={formik.values.gasType || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.gasType && formik.errors.gasType}
                    />
                  </Grid>
                  <Grid item className={classes.individualInput}>
                    <CeroSelect
                      required
                      id="refrigerant"
                      name="refrigerant"
                      label="Refrigerant"
                      fullWidth
                      options={refrigerantList()}
                      selectedValue={formik.values.refrigerant || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.refrigerant && formik.errors.refrigerant
                      }
                      classes={{
                        input: classes.input,
                        container: classes.selectContainer,
                        paperRoot: classes.paperRoot,
                      }}
                    />
                  </Grid>
                  <Grid item className={classes.individualInput}>
                    <CeroInput
                      required
                      id="refrigerantQuantity"
                      name="refrigerantQuantity"
                      label="Quantity of Refrigerant Leakage"
                      value={formik.values.refrigerantQuantity}
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.refrigerantQuantity &&
                        formik.errors.refrigerantQuantity
                      }
                      type="number"
                    />
                  </Grid>
                  <Grid item className={classes.individualInput}>
                    <CeroSelect
                      required
                      id="refrigerantUnit"
                      name="refrigerantUnit"
                      label="Unit"
                      fullWidth
                      options={refrigerantsUnitList}
                      selectedValue={formik.values.refrigerantUnit || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.refrigerantUnit &&
                        formik.errors.refrigerantUnit
                      }
                    />
                  </Grid>
                  <Grid item className={classes.individualInput}>
                    <CeroSelect
                      required
                      id="calculationApproach"
                      name="calculationApproach"
                      label="Calculation Approach"
                      fullWidth
                      options={calculationApproachInputList}
                      selectedValue={formik.values.calculationApproach}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.month && formik.errors.month}
                    />
                  </Grid>
                  <Grid item className={classes.individualInput}>
                    <CeroInput
                      required
                      id="energyQuantity"
                      name="energyQuantity"
                      label="Quantity of electricity,steam,heating,cooling consumed."
                      value={formik.values.energyQuantity}
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.energyQuantity &&
                        formik.errors.energyQuantity
                      }
                      type="number"
                    />
                  </Grid>
                  <Grid item className={classes.individualInput}>
                    <CeroSelect
                      required
                      id="energyUnit"
                      name="energyUnit"
                      label="Unit"
                      fullWidth
                      options={electricityUnitList}
                      selectedValue={formik.values.energyUnit || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.energyUnit && formik.errors.energyUnit
                      }
                    />
                  </Grid>
                  <Grid item className={classes.individualInput}>
                    <CeroInput
                      required
                      id="processEmission"
                      name="processEmission"
                      label="Process emissions in kg"
                      value={formik.values.processEmission}
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.processEmission &&
                        formik.errors.processEmission
                      }
                      type="number"
                    />
                  </Grid>
                  <Grid item className={classes.individualInput}>
                    <CeroInput
                      required
                      id="quantityOfLeasedAsset"
                      name="quantityOfLeasedAsset"
                      label="Area,Volume,quantity of the leased asset."
                      value={formik.values.quantityOfLeasedAsset}
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.quantityOfLeasedAsset &&
                        formik.errors.quantityOfLeasedAsset
                      }
                      type="number"
                    />
                  </Grid>
                  <Grid item className={classes.individualInput}>
                    <CeroInput
                      required
                      id="totalQuantityOfLeasedAsset"
                      name="totalQuantityOfLeasedAsset"
                      label="Total area,Volume,quantity of lessor assets"
                      value={formik.values.totalQuantityOfLeasedAsset}
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.totalQuantityOfLeasedAsset &&
                        formik.errors.totalQuantityOfLeasedAsset
                      }
                      type="number"
                    />
                  </Grid>
                </>
              )}

              {calculationMap[formik.values.calculationMethod] ===
                "average-data method" && (
                <Grid item className={classes.individualInput}>
                  <CeroSelect
                    required
                    id="averageDataSubMethod"
                    name="averageDataSubMethod"
                    label="Choose Type of average data method"
                    fullWidth
                    options={averageMethodList}
                    selectedValue={formik.values.averageDataSubMethod}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "averageDataSubMethod",
                        e.target.value
                      )
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.averageDataSubMethod &&
                      formik.errors.averageDataSubMethod
                    }
                  />
                </Grid>
              )}
              {averageMethodMap[formik.values.averageDataSubMethod] ===
                "type1 - floor space data is available" && (
                <>
                  <Grid item className={classes.individualInput}>
                    <CeroSelect
                      required
                      id="buildingType"
                      name="buildingType"
                      label="Building type"
                      fullWidth
                      options={buildingTypeList}
                      selectedValue={formik.values.buildingType || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.buildingType &&
                        formik.errors.buildingType
                      }
                    />
                  </Grid>
                  <Grid item className={classes.individualInput}>
                    <CeroSelect
                      required
                      id="buildingTypeUnit"
                      name="buildingTypeUnit"
                      label="Unit"
                      fullWidth
                      options={buildingTypeUnitList}
                      selectedValue={formik.values.buildingTypeUnit || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.buildingTypeUnit &&
                        formik.errors.buildingTypeUnit
                      }
                    />
                  </Grid>
                  <Grid item className={classes.individualInput}>
                    <CeroInput
                      required
                      id="totalFloorSpcace"
                      name="totalFloorSpcace"
                      label="Total floor space of  building type"
                      value={formik.values.totalFloorSpcace}
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.totalFloorSpcace &&
                        formik.errors.totalFloorSpcace
                      }
                      type="number"
                    />
                  </Grid>
                </>
              )}

              {averageMethodMap[formik.values.averageDataSubMethod] ===
                "type2 - floor space data is unavailable" && (
                <>
                  <Grid item className={classes.individualInput}>
                    <CeroInput
                      required
                      id="numberOfAsset"
                      name="numberOfAsset"
                      label="Number of assets"
                      value={formik.values.numberOfAsset}
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.numberOfAsset &&
                        formik.errors.numberOfAsset
                      }
                      type="number"
                    />
                  </Grid>

                  <Grid item className={classes.individualInput}>
                    <CeroInput
                      required
                      id="emissionFactorForAsset"
                      name="emissionFactorForAsset"
                      label="Average emission factor for building type(kg CO2 e/m2/year)"
                      value={formik.values.emissionFactorForAsset}
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.emissionFactorForAsset &&
                        formik.errors.emissionFactorForAsset
                      }
                      type="number"
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <CeroButton
              buttonText="Calculate"
              className={classes.buttonPrimary}
              onClick={onCalculate}
              disabled={!formik.dirty || !formik.isValid}
            />
            <CeroButton
              buttonText="Clear"
              variant="outlined"
              className={classes.buttonSecondary}
              onClick={formik.resetForm}
            />
            {isCalculateDone && (
              <Box className={classes.bottomContainer}>
                <Typography
                  variant="h6"
                  component="h6"
                  className={classes.previewTitle}
                >
                  Emission Preview
                </Typography>
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="space-between"
                  spacing={8}
                >
                  <Grid item container direction="column" xs={12} md={6}>
                    <CeroItemPair
                      title={
                        <>
                          CO<sub>2</sub>
                        </>
                      }
                      value={`${addEmissionData.data.co2} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          CH<sub>4</sub>
                        </>
                      }
                      value={`${addEmissionData.data.ch4} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          BioFuel CO<sub>2</sub>
                        </>
                      }
                      value={`${addEmissionData.data.biofuel_co2} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                  </Grid>
                  <Grid
                    className={classes.secondResultContainer}
                    item
                    container
                    direction="column"
                    xs={6}
                  >
                    <CeroItemPair
                      title={
                        <>
                          CO<sub>2</sub>e
                        </>
                      }
                      value={`${addEmissionData.data.co2e} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          N<sub>2</sub>O
                        </>
                      }
                      value={`${addEmissionData.data.n2o} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={<>EF</>}
                      value={
                        <>
                          {addEmissionData.data.ef} kgCO<sub>2</sub>e/unit
                        </>
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
          <FileUpload
            onFileUpload={handleFileUpload}
            onFileRemove={handleFileRemove}
          />
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Add Data"
            disabled={
              !isCalculateDone || addEmissionData.status === STATUS.RUNNING
            }
            className={classes.buttonPrimary}
            onClick={() => onAddUpstreamLeasedData(formik.values)}
          />

          {/* <div className={classes.buttonContainer}>
                <CeroButton
                   buttonText="Bulk Data Upload"
                   className={classes.bulkButton}
                   startIcon={<FileUploadIcon />}
                   onClick={() => setIsOpenEmissionTypeUpload(true)}
                />
                <CeroButton
                    buttonText="Download Template"
                    className={classes.bulkButton}
                    startIcon={<DownloadIcon />}
                    onClick={onDownload}
                  />
                </div> */}
        </Box>
        <EmissionUploadDrawer
          isOpen={isOpenEmissionTypeUpload}
          onClose={onCloseDrawerUpload}
          onUpload={onUpload}
          onValidate={onValidate}
          uploadDisabled={uploadDisabled}
        />
      </Container>
    </>
  );
};

export default AddUpstreamLeasedAsset;
