export const months = [
  {
    id: "january",
    value: "January",
    key: 1,
  },
  {
    id: "february",
    value: "February",
    key: 2,
  },
  {
    id: "march",
    value: "March",
    key: 3,
  },
  {
    id: "april",
    value: "April",
    key: 4,
  },
  {
    id: "may",
    value: "May",
    key: 5,
  },
  {
    id: "june",
    value: "June",
    key: 6,
  },
  {
    id: "july",
    value: "July",
    key: 7,
  },
  {
    id: "august",
    value: "August",
    key: 8,
  },
  {
    id: "september",
    value: "September",
    key: 9,
  },
  {
    id: "october",
    value: "October",
    key: 10,
  },
  {
    id: "november",
    value: "November",
    key: 11,
  },
  {
    id: "december",
    value: "December",
    key: 12,
  },
];
export const location = [
  {
    id: "us",
    value: "United States",
    key: "US",
  },
  {
    id: "in",
    value: "India",
    key: "IN",
  },
  {
    id: "gb",
    value: "United Kingdom",
    key: "GB",
  },
  {
    id: "au",
    value: "Australia",
    key: "AU",
  },
  {
    id: "ca",
    value: "Canada",
    key: "CA",
  },
  {
    id: "de",
    value: "Germany",
    key: "DE",
  },
  {
    id: "fr",
    value: "France",
    key: "FR",
  },
  {
    id: "jp",
    value: "Japan",
    key: "JP",
  },
  {
    id: "cn",
    value: "China",
    key: "CN",
  },
  {
    id: "br",
    value: "Brazil",
    key: "BR",
  },
];

export const sampleYear = (insertNonBegin = false) => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 2015; i <= currentYear + 1; i++) {
    years.push({
      key: i,
      value: i.toString(),
    });
  }
  if (insertNonBegin)
    years.push({
      key: null,
      value: "Select Option",
    });
  return years.reverse();
};

export const sampleDuration = (insertNonBegin = false) => {
  const years = [];
  for (let i = 20; i >= 1; i--) {
    years.push({
      key: i,
      value: i,
    });
  }
  if (insertNonBegin)
    years.push({
      key: undefined,
      value: "Select Option",
    });
  return years.reverse();
};

export const sampleYearArray = [
  {
    key: 2024,
    value: "2024",
  },
  {
    key: 2023,
    value: "2023",
  },
  {
    key: 2022,
    value: "2022",
  },
  {
    key: 2021,
    value: "2021",
  },
  {
    key: 2020,
    value: "2020",
  },
  {
    key: 2019,
    value: "2019",
  },
  {
    key: 2018,
    value: "2018",
  },
  {
    key: 2017,
    value: "2017",
  },
  {
    key: 2016,
    value: "2016",
  },
  {
    key: 2015,
    value: "2015",
  },
];

export const sampleFilterType = [
  {
    key: "type1",
    value: "Type 1",
  },
  {
    key: "type2",
    value: "Type 2",
  },
  {
    key: "type3",
    value: "Type 3",
  },
];

export const sampleFacility = [
  {
    key: "Facility1",
    value: "Facility 1",
  },
  {
    key: "Facility2",
    value: "Facility 2",
  },
];

export const sampleCountryList = [
  {
    key: "Afghanistan",
    value: "Afghanistan",
  },
  {
    key: "India",
    value: "india",
  },
];

export const sampleTimeDurations = [
  {
    key: "monthly",
    value: "Monthly",
  },
  {
    key: "quarterly",
    value: "Quarterly",
  },
];

export const emissionTypes = [
  {
    id: "stationary_combustion",
    title: "Stationary combustion",
  },
  {
    id: "mobile_combustion",
    title: "Mobile combustion",
  },
  {
    id: "refrigerants",
    title: "Refrigerants",
  },
  {
    id: "transportation",
    title: "Transportation",
  },
  {
    id: "water",
    title: "Water",
  },
  {
    id: "waste",
    title: "Waste",
  },
  {
    id: "purchased_electricity",
    title: "Purchased Electricity",
  },
  {
    id: "water_consumption",
    title: "Water consumption",
  },
  {
    id: "water_discharge",
    title: "Water discharge",
  },
  {
    id: "employees_hire_and_turnover",
    title: "Employee Hire & Turnover",
  },
  {
    id: "employees_benefit",
    title: "Employees Benefits",
  },
  {
    id: "employee_health_safety_incident_record",
    title: "Employee Health & Safety incident record",
  },
  {
    id: "worker_safety_training_procedures",
    title: "Worker safety training and procedures",
  },
  {
    id: "age_based_statistics",
    title: "Age-based statistics",
  },
  {
    id: "gender_based_statistics",
    title: "Gender-based statistics",
  },
  {
    id: "employees_diversity_and_ratio_of_basic_salary",
    title: "Employee Diversity and Ratio of Basic Salary",
  },
  {
    id: "discrimination_incident_record",
    title: "Discrimination incident record",
  },
  {
    id: "supplier_screening_using_enviormental_social_criteria",
    title: "Supplier screening using Environmental and Social Criteria",
  },
  {
    id: "social_engagement_human_rights_training",
    title: "Human Rights",
  },
  {
    id: "local_communities",
    title: "Local communities",
  },
  {
    id: "political_contributions",
    title: "Political contributions",
  },
  {
    id: "anti_corruption_disclosure",
    title: "Anti-corruption disclosure",
  },
  {
    id: "anti_corruption_training",
    title: "Anti-corruption training",
  },
  {
    id: "anti_competitive_disclosure",
    title: "Anti-competitive disclosure",
  },
  {
    id: "economic_impact",
    title: "Economic Impact",
  },
  {
    id: "subsidies_financial_assistance",
    title: "Subsidies and Financial Assistance",
  },
  {
    id: "tax",
    title: "Tax",
  },
];

export const emissionTypeData = [
  {
    id: "environmental",
    title: "Environmental",
    subItems: [
      {
        id: "energy_and_materials",
        title: "Energy and Materials",
        subItems: [
          {
            id: "stationary_combustion",
            title: "Stationary combustion",
          },
          {
            id: "mobile_combustion",
            title: "Mobile combustion",
          },
          {
            id: "refrigerants",
            title: "Refrigerants",
          },
          {
            id: "transportation",
            title: "Transportation",
          },
          {
            id: "purchased_electricity",
            title: "Purchased electricity",
          },
        ],
      },
      {
        id: "water",
        title: "Water",
        subItems: [
          {
            id: "water_consumption",
            title: "Water consumption",
          },
          {
            id: "water_discharge",
            title: "Water discharge",
          },
        ],
      },
      {
        id: "waste",
        title: "Waste",
        subItems: [
          {
            id: "waste",
            title: "Waste",
          },
        ],
      },
    ],
  },
  {
    id: "social",
    title: "Social",
    subItems: [
      {
        id: "employment",
        title: "Employment",
        subItems: [
          {
            id: "employees_hire_and_turnover",
            title: "Employee Hire & Turnover",
          },
          {
            id: "employees_benefit",
            title: "Employee Benefits",
          },
        ],
      },
      {
        id: "health_and_safety",
        title: "Health & Safety",
        subItems: [
          {
            id: "employee_health_safety_incident_record",
            title: "Employee Health & Safety incident record",
          },
          {
            id: "worker_safety_training_procedures",
            title: "Worker safety training and procedures",
          },
        ],
      },
      {
        id: "diversity",
        title: "Diversity",
        subItems: [
          {
            id: "employees_diversity_and_ratio_of_basic_salary",
            title: "Employee Diversity and Ratio of Basic Salary",
          },
          {
            id: "discrimination_incident_record",
            title: "Discrimination incident record",
          },
        ],
      },
      {
        id: "development_traning",
        title: "Development and Training",
        subItems: [
          {
            id: "training_hours_per_employee",
            title: "Training hours per employee",
          },
          {
            id: "performance_and_career_development_programs",
            title: "Performance and career development programs",
          },
        ],
      },
      {
        id: "supplier_screening",
        title: "Supplier Screening",
        subItems: [
          {
            id: "supplier_screening_using_enviormental_social_criteria",
            title: "Supplier Screening using Environmental and Social Criteria",
          },
          {
            id: "suppliers_human_rights",
            title: "Human Rights",
          },
        ],
      },
      {
        id: "social_engagement",
        title: "Social Engagement",
        subItems: [
          {
            id: "local_communities",
            title: "Local communities",
          },
          {
            id: "social_engagement_human_rights_training",
            title: "Human rights",
          },
          {
            id: "political_contributions",
            title: "Political contributions",
          },
        ],
      },
    ],
  },
  {
    id: "governance",
    title: "Governance",
    subItems: [
      {
        id: "Management",
        title: "Management",
        subItems: [
          {
            id: "board_diversity",
            title: "Board Diversity",
          },
          {
            id: "management_diversity",
            title: "Management Diversity",
          },
        ],
      },
      {
        id: "Ethical Behaviour",
        title: "Ethical Behaviour",
        subItems: [
          {
            id: "anti_corruption_disclosure",
            title: "Anti-corruption disclosure",
          },
          {
            id: "anti_corruption_training",
            title: "Anti-corruption training",
          },
          {
            id: "anti_competitive_disclosure",
            title: "Anti-competitive disclosure",
          },
        ],
      },
      {
        id: "economic_and_financial_disclosure",
        title: "Economic and financial disclosure",
        subItems: [
          {
            id: "economic_impact",
            title: "Economic Impact",
          },
          {
            id: "subsidies_financial_assistance",
            title: "Subsidies and Financial Assistance",
          },
        ],
      },
    ],
  },
];

export const reportEmissionTypeData = [
  {
    id: "environmental",
    title: "Environmental",
    subItems: [
      {
        id: "energy_and_materials",
        title: "Energy and Materials",
        subItems: [
          {
            id: "stationary_combustion",
            title: "Stationary combustion",
          },
          {
            id: "mobile_combustion",
            title: "Mobile combustion",
          },
          {
            id: "refrigerants",
            title: "Refrigerants",
          },
          {
            id: "transportation",
            title: "Transportation",
          },
          {
            id: "purchased_electricity",
            title: "Purchased electricity",
          },
        ],
      },
      {
        id: "waste_water",
        title: "Water & Waste",
        subItems: [
          {
            id: "water_consumption",
            title: "Water consumption",
          },
          {
            id: "water_discharge",
            title: "Water discharge",
          },
          {
            id: "waste",
            title: "Waste",
          },
        ],
      },
    ],
  },
  {
    id: "social",
    title: "Social",
    subItems: [
      {
        id: "employment",
        title: "Employment",
        subItems: [
          {
            id: "employees_hire_and_turnover",
            title: "Employee Hire & Turnover",
          },
          {
            id: "employees_benefit",
            title: "Employee Benefits",
          },
        ],
      },
      {
        id: "health_and_safety",
        title: "Health & Safety",
        subItems: [
          {
            id: "employee_health_safety_incident_record",
            title: "Employee Health & Safety incident record",
          },
          {
            id: "worker_safety_training_procedures",
            title: "Worker safety training and procedures",
          },
        ],
      },
      {
        id: "diversity",
        title: "Diversity",
        subItems: [
          {
            id: "employees_diversity_and_ratio_of_basic_salary",
            title: "Employee Diversity and Ratio of Basic Salary",
          },
          {
            id: "discrimination_incident_record",
            title: "Discrimination incident record",
          },
        ],
      },
      {
        id: "development_traning",
        title: "Development and Training",
        subItems: [
          {
            id: "training_hours_per_employee",
            title: "Training hours per employee",
          },
          {
            id: "performance_and_career_development_programs",
            title: "Performance and career development programs",
          },
        ],
      },
      {
        id: "supplier_screening",
        title: "Supplier Screening",
        subItems: [
          {
            id: "supplier_screening_using_enviormental_social_criteria",
            title: "Supplier Screening using Environmental and Social Criteria",
          },
          {
            id: "suppliers_human_rights",
            title: "Human Rights",
          },
        ],
      },
      {
        id: "social_engagement",
        title: "Social Engagement",
        subItems: [
          {
            id: "local_communities",
            title: "Local communities",
          },
          {
            id: "social_engagement_human_rights_training",
            title: "Human rights",
          },
          {
            id: "political_contributions",
            title: "Political contributions",
          },
        ],
      },
    ],
  },
  {
    id: "governance",
    title: "Governance",
    subItems: [
      {
        id: "Management",
        title: "Management",
        subItems: [
          {
            id: "board_diversity",
            title: "Board Diversity",
          },
          {
            id: "management_diversity",
            title: "Management Diversity",
          },
        ],
      },
      {
        id: "Ethical Behaviour",
        title: "Ethical Behaviour",
        subItems: [
          {
            id: "anti_corruption_disclosure",
            title: "Anti-corruption disclosure",
          },
          {
            id: "anti_corruption_training",
            title: "Anti-corruption training",
          },
          {
            id: "anti_competitive_disclosure",
            title: "Anti-competitive disclosure",
          },
        ],
      },
      {
        id: "economic_and_financial_disclosure",
        title: "Economic and financial disclosure",
        subItems: [
          {
            id: "economic_impact",
            title: "Economic Impact",
          },
          {
            id: "subsidies_financial_assistance",
            title: "Subsidies and Financial Assistance",
          },
        ],
      },
    ],
  },
];

export const selectionPeriod = [
  {
    key: "last_one_month",
    value: "Last one month",
  },
  {
    key: "last_six_months",
    value: "Last six months",
  },
  {
    key: "last_one_year",
    value: "Last one year",
  },
  {
    key: "last_five_years",
    value: "Last five years",
  },
  {
    key: "advanced",
    value: "Advanced",
  },
];

export const userRoles = [
  {
    key: "admin",
    value: "Business Admin",
  },
  {
    key: "sustainability_manager",
    value: "Business Sustainability Manager",
  },
  {
    key: "auditor",
    value: "Auditor",
  },
  {
    key: "business_user",
    value: "Business User",
  },
  {
    key: "community_user",
    value: "External User",
  },
  {
    key: "approver",
    value: "Business Approver",
  },
  {
    key: "facility_manager",
    value: "Business Facility Manager",
  },
  {
    key: "super_admin",
    value: "Super Admin",
  },
  {
    key: "read_only_user",
    value: "Read Only User",
  },
];

export const userRolesForAddUser = [
  {
    key: "admin",
    value: "Business Admin",
  },
  {
    key: "sustainability_manager",
    value: "Business Sustainability Manager",
  },
  {
    key: "business_user",
    value: "Business User",
  },
  {
    key: "approver",
    value: "Business Approver",
  },
  {
    key: "facility_manager",
    value: "Business Facility Manager",
  },
  {
    key: "read_only_user",
    value: "Read Only User",
  },
];

export const glossaryContent = [
  {
    id: "a",
    title: "A",
    subItems: [
      {
        id: "anti_competitive_disclosure",
        title: "Anti-competitive Disclosure",
        value: `Transparent communication about a company's efforts to comply with antitrust laws and prevent anti-competitive practices within its industry.`,
      },
      {
        id: "anti_corruption_disclosure",
        title: "Anti-corruption Disclosure",
        value: `Evidence of the company transparently disclosing information about its efforts to prevent and combat corruption within its operations. This may include policies, procedures, and measures taken to address corruption risks.`,
      },
      {
        id: "anti_corruption_training",
        title: "Anti-corruption Training",
        value: `Educational programs provided to employees and stakeholders to raise awareness about corruption risks, ethical standards, and the company's commitment to preventing corrupt practices.`,
      },
      {
        id: "assessment_cycle",
        title: "Assessment Cycle",
        value: `This is where you can schedule the company’s reports, assessments, audits and their respective deadlines.`,
      },
    ],
  },
  {
    id: "b",
    title: "B",
    subItems: [
      {
        id: "board_diversity",
        title: "Board Diversity",
        value: `Data regarding the representation of diverse individuals, including but not limited to gender, ethnicity, age, and background, within a company's board of directors.`,
      },
      {
        id: "business_travel",
        title: "Business Travel",
        value: `The movement of employees for business purposes.`,
      },
    ],
  },
  {
    id: "d",
    title: "D",
    subItems: [
      {
        id: "direct_economic_impact",
        title: "Direct Economic Impact",
        value: `The measurable economic effects, both positive and negative, that a company has on the immediate local or global economy through its operations, investments, and expenditures.`,
      },
      {
        id: "direct_effects",
        title: "Direct Effects",
        value: `Immediate impact of the released gas on the Earth's energy balance and climate.`,
      },
      {
        id: "discrimination_incident_record",
        title: "Discrimination Incident Record",
        value: `A documented record of incidents related to discrimination within the company, including details of reported discriminatory behavior, investigations, and resolutions.`,
      },
      {
        id: "distance_activity",
        title: "Distance Activity",
        value: `Distance traveled by the usage of fuel.`,
      },
    ],
  },
  {
    id: "e",
    title: "E",
    subItems: [
      {
        id: "economic_impact",
        title: "Economic Impact",
        value: `The economic impact in governance refers to the financial implications and considerations of corporate governance practices.`,
      },
      {
        id: "employee_benefits",
        title: "Employee Benefits",
        value: `The range of non-financial perks and support provided by a company to its employees.`,
      },
      {
        id: "employee_commute",
        title: "Employee Commuting",
        value: `The travel undertaken by employees to and from their workplace.`,
      },
      {
        id: "employee_diversity_and_ratio_of_basic_salary",
        title: "Employee diversity and ratio of basic salary",
        value: `Employee diversity refers to the representation of different demographic groups within a company's workforce, such as gender, race, ethnicity, age, and sexual orientation. The ratio of basic salary, on the other hand, typically refers to the average basic salary of all employees in the organization.`,
      },
      {
        id: "employee_health_and_safety_incident_record",
        title: "Employee Health and Safety Incident Record",
        value: `A record detailing incidents related to the health and safety of employees within the workplace. This includes accidents, injuries, or illnesses that occur during the course of work.`,
      },
      {
        id: "employee_hire_and_turnover",
        title: "Employee Hire and Turnover",
        value: `Employee hire refers to the process of bringing new employees into an organization. Employee turnover refers to the rate at which employees leave an organization over a specific period of time.`,
      },
    ],
  },
  {
    id: "f",
    title: "F",
    subItems: [
      {
        id: "fuel_use",
        title: "Fuel Use",
        value: `Mobile combustion by the usage of fuel for mobile operations such as transport.`,
      },
    ],
  },
  {
    id: "h",
    title: "H",
    subItems: [
      {
        id: "hfc",
        title: "HFC",
        value: `Hydrofluorocarbons`,
      },
      {
        id: "human_rights",
        title: "Human Rights",
        value: `Include the right to equality, right against discrimination, right to freedom of speech and expression, right to work, and the right to constitutional remedies.`,
      },
    ],
  },
  {
    id: "l",
    title: "L",
    subItems: [
      {
        id: "local_communities",
        title: "Local Communities",
        value: `This refers to any form of positive engagement with the local communities in the geographical areas surrounding a company's operations. This includes interacting responsibly, supporting community initiatives, and addressing any potential social impacts associated with the company's presence.`,
      },
      {
        id: "location_based",
        title: "Location Based",
        value: `Refers to the electricity consumed by an entity that is sourced from a specific geographic location.`,
      },
    ],
  },
  {
    id: "m",
    title: "M",
    subItems: [
      {
        id: "management_diversity",
        title: "Management Diversity",
        value: `Data regarding the representation of diverse individuals, including but not limited to gender, ethnicity, age, and background, within a company's leadership and management team.`,
      },
      {
        id: "mobile_combustion",
        title: "Mobile Combustion",
        value: `This consists of emissions released by mobile sources, including vehicles like cars, trucks, airplanes, and ships.`,
      },
    ],
  },
  {
    id: "p",
    title: "P",
    subItems: [
      {
        id: "performance_career_development_programs",
        title: "Performance And Career development programs",
        value: `Performance and career development programs are initiatives designed by organizations to enhance the skills, knowledge, and capabilities of their employees, thereby improving their performance, engagement, and career prospects.`,
      },
      {
        id: "pfc",
        title: "PFC",
        value: `Perfluorocarbons`,
      },
      {
        id: "political_contributions",
        title: "Political Contributions",
        value: `Financial support or contributions made by a company to political parties, candidates, or campaigns, including any form of support given with political implications.`,
      },
      {
        id: "purchased_electricity",
        title: "Purchased Electricity",
        value: `Emissions released by the usage of purchased electricity.`,
      },
    ],
  },
  {
    id: "r",
    title: "R",
    subItems: [
      {
        id: "refrigerants",
        title: "Refrigerants",
        value: `This consists of emissions released by chemical substances used in refrigeration and air conditioning systems.`,
      },
      {
        id: "radio_active_forcing",
        title: "Radiative Forcing",
        value: `Measurement of the imbalance in the Earth's energy budget caused by factors that alter the balance between incoming solar radiation and outgoing infrared radiation.`,
      },
    ],
  },
  {
    id: "s",
    title: "S",
    subItems: [
      {
        id: "scope_1",
        title: "Scope 1",
        value: `Direct Emissions. Scope 1 emissions represent direct CO2 (GHG) emissions that occur from sources that are owned or controlled by your company. Here is a non-exhaustive list of what types of emissions are included in scope 1: on-site fuel combustion, industrial processes, company-owned vehicles, company waste management and processes conducted within the company's operations.`,
      },
      {
        id: "scope_2",
        title: "Scope 2",
        value: `Indirect Emissions. These are emissions resulting from the generation of purchased electricity, heat, or steam consumed by the company and they occur at facilities owned and controlled by another entity. Here is a non-exhaustive list of what types of emissions are included in scope 2: purchased electricity, purchased heat or steam, district heating or cooling systems, imported thermal energy, external energy sources and transmission or distribution losses.`,
      },
      {
        id: "scope_3",
        title: "Scope 3",
        value: `Other indirect emissions - all other indirect emissions that occur in the value chain of the reporting company, including both upstream and downstream activities. This can include emissions from the supply chain, product use, and disposal, beyond what is considered Scope 1 or 2.`,
      },
      {
        id: "stationary_combustion",
        title: "Stationary Combustion",
        value: `This consists of emissions released by fuels burnt within fixed facilities, such as power plants, industrial boilers, or heating systems.`,
      },
      {
        id: "subsidies_financial_assistance",
        title: "Subsidies and financial assistance",
        value: `Monetary support, incentives, or assistance provided by governments or other entities to support the company's operations. This can include grants, subsidies, or tax incentives.`,
      },
      {
        id: "supplier_screening_environmental_social_criteria",
        title: "Supplier screening using environmental and social criteria",
        value: `Supplier screening using environmental and social criteria is a process by which organizations assess the sustainability performance of their suppliers based on environmental and social factors.`,
      },
    ],
  },
  {
    id: "t",
    title: "T",
    subItems: [
      {
        id: "tickets",
        title: "Tickets",
        value: `Tickets are used in two scenarios.
1. When a record is sent back for review, a ticket is generated.
2. When there is a generic requirement (like user creation/modification etc)`,
      },
      {
        id: "total_emissions",
        title: "Total Emissions",
        value: `Total emissions refer to the total amount of greenhouse gases (GHGs) and other pollutants released into the atmosphere as a result of human activities, such as industrial processes, transportation, agriculture, and energy production.`,
      },
      {
        id: "training_hours_per_employee",
        title: "Training Hours Per Employee",
        value: `Training hours per employee is a metric used to measure the amount of training or development activities that an employee receives within a specific period of time, typically a year.`,
      },
      {
        id: "transportation",
        title: "Transportation",
        value: `This consists of emissions released by the usage of modes of transport, such as cars, trucks, planes, trains, and ships.`,
      },
    ],
  },
  {
    id: "u",
    title: "U",
    subItems: [
      {
        id: "uk_defra",
        title: "UK DEFRA",
        value: `Is the Department for Environment, Food and Rural Affairs. It is a government department in the United Kingdom responsible for environmental protection, food production and farming, and rural communities.`,
      },
      {
        id: "upstream_t_and_d",
        title: "Upstream T&D",
        value: `Upstream transportation & distribution in the supply chain, including the movement of raw materials, components, or products from suppliers to manufacturers or distributors.`,
      },
      {
        id: "us_epa",
        title: "US EPA",
        value: `United States Environmental Protection Agency`,
      },
    ],
  },
  {
    id: "w",
    title: "W",
    subItems: [
      {
        id: "waste",
        title: "Waste",
        value: `Total waste products discarded from the company's operations.`,
      },
      {
        id: "water_consumption",
        title: "Water Consumption",
        value: `Upstream transportation & distribution in the supply chain, including the movement of raw materials, components, or products from suppliers to manufacturers or distributors.`,
      },
      {
        id: "water_discharge",
        title: "Water Discharge",
        value: `Total volume of water disposed of or discharged from the company's operations, including treated or untreated water.`,
      },
      {
        id: "weight_distance",
        title: "Weight Distance",
        value: `Weight distance refers to the total weight of the vehicle, which is the weight of the vehicle alone plus the weight of its load.`,
      },
      {
        id: "worker_safety_training_and_procedures",
        title: "Worker Safety Training and Procedures",
        value: `Programs and protocols designed to educate employees on safety measures in the workplace and prevent accidents.`,
      },
    ],
  },
];

export const carbonCreditContent = [
  {
    id: "a",
    title: "A",
    subItems: [
      {
        id: "anti_competitive_disclosure",
        title: "Welcome to Our Carbon Credit Pool",
        value: `Thank you for taking a step towards a greener future! By clicking the link below, you will enter our carbon credit pool,
        where positive environmental impact begins.`,
      },
      {
        id: "anti_corruption_disclosure",
        title: "What is Carbon Credit Pool?",
        value: `A carbon credit pool is a collective effort to mitigate carbon emissions. Through your participation, you contribute to
        offsetting carbon footprints and supporting sustainability projects worldwide.`,
      },
    ],
  },
];

export const designationWorkFlow = [
  {
    name: "Flow 1",
    designationOrder: [
      "Business User",
      "Facility Manager",
      "Business Approver",
      "Sustainability Manager",
    ],
    value: [
      "business_user",
      "facility_manager",
      "approver",
      "sustainability_manager",
    ],
  },
  {
    name: "Flow 2",
    designationOrder: [
      "Business User",
      "Facility Manager",
      "Sustainability Manager",
    ],
    value: ["business_user", "facility_manager", "sustainability_manager"],
  },
  {
    name: "Flow 3",
    designationOrder: [
      "Business User",
      "Business Approver",
      "Sustainability Manager",
    ],
    value: ["business_user", "approver", "sustainability_manager"],
  },
  {
    name: "Flow 4",
    designationOrder: ["Business User", "Sustainability Manager"],
    value: ["business_user", "sustainability_manager"],
  },
  // {
  //   name: "Flow 5",
  //   designationOrder: [ "Sustainability Manager"],
  //   value: ["sustainability_manager"]

  // },
];

export const auditStatus = {
  audited: "Audited",
  pending: "Audit pending",
  added: "Added",
  approved: "Approved",
};

export const yearRange = (startYear, duration) => {
  const years = [];
  for (let i = startYear; i <= startYear + duration; i++) {
    years.push({
      key: i,
      value: i.toString(),
    });
  }
  return years;
};

export const emissionTypeDataForMenu = [
  {
    id: "environmental",
    title: "Environmental",
    subItems: [
      {
        id: "scope1",
        title: "Scope 1",
        subItems: [
          {
            id: "stationary_combustion",
            title: "Stationary combustion",
            tooltip: "Fuel Emissions from equipments",
          },
          {
            id: "mobile_combustion",
            title: "Mobile combustion",
            tooltip: "Emissions from company vehicles",
          },
          {
            id: "refrigerants",
            title: "Refrigerants",
            tooltip: "Emissions from cooling systems",
          },
        ],
      },
      {
        id: "scope2",
        title: "Scope 2",
        subItems: [
          {
            id: "purchased_electricity",
            title: "Purchased electricity",
            tooltip: "Emissions from purchased electircity",
          },
        ],
      },
      {
        id: "scope3",
        title: "Scope 3",
        subItems: [
          // {
          //   id: "transportation",
          //   title: "Transportation",
          //   tooltip: "Emissions from third-party transport",
          // },
          // {
          //   id: "water_consumption",
          //   title: "Water consumption",
          //   tooltip: "Emissions from water use",
          // },
          // {
          //   id: "water_discharge",
          //   title: "Water discharge",
          //   tooltip: "Emissions from water release",
          // },

          {
            id: "purchased_goods_and_services",
            title: "Purchased goods and services",
            tooltip:
              "Emissions from the production of goods and services bought by the company",
          },
          {
            id: "fuel_and_energy_related_activities",
            title: "Fuel and energy related activities",
            tooltip:
              "Emissions from fuel and electricity generation not included in Scope 1 or 2, such as extraction, refining, and transportation",
          },
          {
            id: "waste",
            title: "Waste",
            tooltip: "Emissions from waste disposal",
          },
          {
            id: "employee_commute",
            title: "Employee commuting",
            tooltip:
              "Emissions from employees traveling between home and the workplace.",
          },
          {
            id: "downstream_T&D",
            title: "Downstream T&D",
            tooltip:
              "Emissions from the transport and distribution of sold products after they leave the company.",
          },
          {
            id: "use_of_sold_products",
            title: "Use of Sold Products",
            tooltip:
              "Emissions from the energy consumption or impact of using a company's products.",
          },
          {
            id: "franchises",
            title: "Franchises",
            tooltip:
              "Emissions from franchise operations not owned by the company but using its brand.",
          },
          {
            id: "end_of_life_treatment_of_sold_products",
            title: "End-of-life treatment of sold products",
            tooltip:
              "Emissions from the disposal, recycling, or destruction of products sold by the company.",
          },
          {
            id: "capital_goods",
            title: "Capital goods",
            tooltip:
              "Emissions from the production of assets such as machinery, equipment, and buildings used by the company.",
          },
          {
            id: "upstream_T&D",
            title: "Upstream T&D",
            tooltip:
              "Emissions from the transport and distribution of goods purchased by the company before use.",
          },
          {
            id: "business_travel",
            title: "Business travel",
            tooltip:
              "Emissions from employee travel for work-related purposes, including flights, hotels, and car rentals.",
          },
          {
            id: "upstream_leased_asset",
            title: "Upstream leased assets",
            tooltip:
              "Emissions from assets leased by the company from another entity, such as rented office spaces or machinery.",
          },
          {
            id: "processing_of_sold_products",
            title: "Processing of sold products",
            tooltip:
              "Emissions from customers processing intermediate products before final use.",
          },
          {
            id: "downstream_leased_asset",
            title: "Downstream leased assets",
            tooltip:
              "Emissions from assets leased out by the company to other entities.",
          },
          {
            id: "investments",
            title: "Investments",
            tooltip:
              "Emissions associated with financial investments, such as equity, bonds, and project financing.",
          },
        ],
      },
    ],
  },
  {
    id: "social",
    title: "Social",
    subItems: [
      {
        id: "employment",
        title: "Employment",
        subItems: [
          {
            id: "employees_hire_and_turnover",
            title: "Employee Hire & Turnover",
          },
          {
            id: "employees_benefit",
            title: "Employee Benefits",
          },
        ],
      },
      {
        id: "health_and_safety",
        title: "Health & Safety",
        subItems: [
          {
            id: "employee_health_safety_incident_record",
            title: "Employee Health & Safety incident record",
          },
          {
            id: "worker_safety_training_procedures",
            title: "Worker safety training and procedures",
          },
        ],
      },
      {
        id: "diversity",
        title: "Diversity",
        subItems: [
          {
            id: "employees_diversity_and_ratio_of_basic_salary",
            title: "Employee Diversity and Ratio of Basic Salary",
          },
          {
            id: "discrimination_incident_record",
            title: "Discrimination incident record",
          },
        ],
      },
      {
        id: "development_traning",
        title: "Development and Training",
        subItems: [
          {
            id: "training_hours_per_employee",
            title: "Training hours per employee",
          },
          {
            id: "performance_and_career_development_programs",
            title: "Performance and career development programs",
          },
        ],
      },
      {
        id: "supplier_screening",
        title: "Supplier Screening",
        subItems: [
          {
            id: "supplier_screening_using_enviormental_social_criteria",
            title: "Supplier Screening using Environmental and Social Criteria",
          },
          {
            id: "suppliers_human_rights",
            title: "Human Rights",
          },
        ],
      },
      {
        id: "social_engagement",
        title: "Social Engagement",
        subItems: [
          {
            id: "local_communities",
            title: "Local communities",
          },
          {
            id: "social_engagement_human_rights_training",
            title: "Human rights",
          },
          {
            id: "political_contributions",
            title: "Political contributions",
          },
        ],
      },
    ],
  },
  {
    id: "governance",
    title: "Governance",
    subItems: [
      {
        id: "Management",
        title: "Management",
        subItems: [
          {
            id: "board_diversity",
            title: "Board Diversity",
          },
          {
            id: "management_diversity",
            title: "Management Diversity",
          },
        ],
      },
      {
        id: "Ethical Behaviour",
        title: "Ethical Behaviour",
        subItems: [
          {
            id: "anti_corruption_disclosure",
            title: "Anti-corruption disclosure",
          },
          {
            id: "anti_corruption_training",
            title: "Anti-corruption training",
          },
          {
            id: "anti_competitive_disclosure",
            title: "Anti-competitive disclosure",
          },
        ],
      },
      {
        id: "economic_and_financial_disclosure",
        title: "Economic and financial disclosure",
        subItems: [
          {
            id: "economic_impact",
            title: "Economic Impact",
          },
          {
            id: "subsidies_financial_assistance",
            title: "Subsidies and Financial Assistance",
          },
        ],
      },
    ],
  },
];

export const reportEmissionTypeWIthScopeData = [
  {
    id: "environmental",
    title: "Environmental",
    subItems: [
      {
        id: "Scope 1",
        title: "Scope 1",
        subItems: [
          {
            id: "stationary_combustion",
            title: "Stationary combustion",
          },
          {
            id: "mobile_combustion",
            title: "Mobile combustion",
          },
          {
            id: "refrigerants",
            title: "Refrigerants",
          },
          {
            id: "transportation",
            title: "Transportation",
          },
          {
            id: "purchased_electricity",
            title: "Purchased electricity",
          },
        ],
      },
      {
        id: "Scope 2",
        title: "Scope 2",
        subItems: [
          {
            id: "purchased_electricity",
            title: "Purchased electricity",
          },
        ],
      },
      {
        id: "Scope 3",
        title: "Scope 3",
        subItems: [
          {
            id: "upstream_t_and_d",
            title: "upstream T and D",
          },
          {
            id: "downstream_t_and_d",
            title: "downstream T and D",
          },
          {
            id: "business_travel",
            title: "Business Travel",
          },
          {
            id: "employee_commute",
            title: "Employee Commuting",
          },
          {
            id: "capital_goods",
            title: "Capital goods",
          },
          {
            id: "use_of_sold_products",
            title: "Use of sold products",
          },
          {
            id: "end_of_life_treatment",
            title: "End of life treatment",
          },
          {
            id: "processing_of_sold_products",
            title: "Processing of sold products",
          },

          {
            id: "upstream_leased_asset",
            title: "Upstream leased assets",
          },
          {
            id: "downstream_leased_asset",
            title: "Downstream leased assets",
          },
          {
            id: "purchased_goods_and_services",
            title: "Purchased goods and services",
          },
          {
            id: "waste",
            title: "Waste",
          },
        ],
      },
    ],
  },
  {
    id: "social",
    title: "Social",
    subItems: [
      {
        id: "employment",
        title: "Employment",
        subItems: [
          {
            id: "employees_hire_and_turnover",
            title: "Employee Hire & Turnover",
          },
          {
            id: "employees_benefit",
            title: "Employee Benefits",
          },
        ],
      },
      {
        id: "health_and_safety",
        title: "Health & Safety",
        subItems: [
          {
            id: "employee_health_safety_incident_record",
            title: "Employee Health & Safety incident record",
          },
          {
            id: "worker_safety_training_procedures",
            title: "Worker safety training and procedures",
          },
        ],
      },
      {
        id: "diversity",
        title: "Diversity",
        subItems: [
          {
            id: "employees_diversity_and_ratio_of_basic_salary",
            title: "Employee Diversity and Ratio of Basic Salary",
          },
          {
            id: "discrimination_incident_record",
            title: "Discrimination incident record",
          },
        ],
      },
      {
        id: "development_traning",
        title: "Development and Training",
        subItems: [
          {
            id: "training_hours_per_employee",
            title: "Training hours per employee",
          },
          {
            id: "performance_and_career_development_programs",
            title: "Performance and career development programs",
          },
        ],
      },
      {
        id: "supplier_screening",
        title: "Supplier Screening",
        subItems: [
          {
            id: "supplier_screening_using_enviormental_social_criteria",
            title: "Supplier Screening using Environmental and Social Criteria",
          },
          {
            id: "suppliers_human_rights",
            title: "Human Rights",
          },
        ],
      },
      {
        id: "social_engagement",
        title: "Social Engagement",
        subItems: [
          {
            id: "local_communities",
            title: "Local communities",
          },
          {
            id: "social_engagement_human_rights_training",
            title: "Human rights",
          },
          {
            id: "political_contributions",
            title: "Political contributions",
          },
        ],
      },
    ],
  },
  {
    id: "governance",
    title: "Governance",
    subItems: [
      {
        id: "Management",
        title: "Management",
        subItems: [
          {
            id: "board_diversity",
            title: "Board Diversity",
          },
          {
            id: "management_diversity",
            title: "Management Diversity",
          },
        ],
      },
      {
        id: "Ethical Behaviour",
        title: "Ethical Behaviour",
        subItems: [
          {
            id: "anti_corruption_disclosure",
            title: "Anti-corruption disclosure",
          },
          {
            id: "anti_corruption_training",
            title: "Anti-corruption training",
          },
          {
            id: "anti_competitive_disclosure",
            title: "Anti-competitive disclosure",
          },
        ],
      },
      {
        id: "economic_and_financial_disclosure",
        title: "Economic and financial disclosure",
        subItems: [
          {
            id: "economic_impact",
            title: "Economic Impact",
          },
          {
            id: "subsidies_financial_assistance",
            title: "Subsidies and Financial Assistance",
          },
        ],
      },
    ],
  },
];
