import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getEmission } from "../../redux/actions";

import DashboardLayout from "../../layouts/DashboardLayout";
import PurchasedElectricityDetails from "./PurchasedElectricityDetails";
import MobileCombustionDetails from "./MobileCombustionDetails";
// import EmployeeCommutingDetails from "./EmployeeCommutingDetails";
import StationaryCombustionDetails from "./StationaryCombustionDetails";
import RefrigerantsDetails from "./RefrigerantsDetails";
import WaterConsumptionDetails from "./WaterConsumptionDetails";
import WaterDischargeDetails from "./WaterDischargeDetails";
import WasteCombustionDetails from "./WasteCombustionDetails";
import TransportationDetails from "./Transportation";
import DevelopmentAndTraining from "./DevelopmentAndTraining";
import EmployeeHealthSafety from "./EmployeeHealthSafety";
import WorkerSafetyTraining from "./WorkerSafetyTraining";
import EmployeeTurnover from "./EmployeeTurnover";
import AgeBasedStatistics from "./AgeBasedStatistics";
import GenderBasedStatistics from "./GenderBasedStatistics";
import DiscriminationIncident from "./DiscriminationIncident";
import SupplierScreening from "./SupplierScreening";
import OperationalHumanRightsTraining from "./OperationalHumanRightsTraining";
import SocialEngagementHumanRightsTraining from "./SocialEngagementHumanRightsTraining";
import LocalCommunities from "./LocalCommunities";
import PoliticalContributions from "./PoliticalContributions";
import AntiCorruptionDisclosure from "./AntiCorruptionDisclosure";
import AntiCorruptionTraining from "./AntiCorruptionTraining";
import AntiCompetitiveDisclosure from "./AntiCompetitiveDisclosure";
import SubsidiesFinancialAssistance from "./SubsidiesFinancialAssistance";
import { rolesEnum } from "../../layouts/DashboardLayout/pages";
import CreateTicketDrawer from "../common/CreateTicketDrawer";
import useStyles from "./styles";
import BoardDiversity from "./BoardDiversity";
import ManagementDiversity from "./ManagementDiversity";
import EconomicImpact from "./EconomicImpact";
import { getCookie } from "../../services/cookie";
import EmployeeBenefit from "./EmployeeBenefit";
import EmployeeHireDetails from "../EmissionList/EmployeeHireTable/EmployeeHireDetails";
import EmployeeTrainingHours from "./EmployeeTrainingHour";
import PerformanceAndCareerDevelopmentPrograms from "./PerformanceAndCareerDevelopmentPrograms";
import EmployeeDiversityDetails from "../EmissionList/EmployeesDiversityTable/EmployeeDiversityDetails";
import UpstreamTAndD from "./UpstreamTAndD";
import BusinessTravel from "./BusinessTravel";
import EmployeeCommutingDetails from "./EmployeeCommutingDetails";
import PurchasedGoodsAndServices from "./PurchasedGoodsAndServices";
import DownstreamTAndD from "./DownstreamTAndD";
import CapitalGoods from "./CapitalGoods";
import UseOfSoldProductsDetails from "./UseOfSoldProductsDetails";
import FuelAndEnergyRelatedActivities from "./FuelAndEnergyRelatedActivities";
import EndOfLifeTreatment from "./EndOfLifeTreatment";
import UpstreamLeasedAsset from "./UpstreamLeasedAsset";
import DownstreamLeasedAsset from "./DownstreamLeasedAsset";
import Franchise from "./FranchiseDetails";
import ProcessingOfSoldProducts from "./ProcessingOfSoldProducts";

const EmissionsDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const company = queryParams.get("company");

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const emissionData = useSelector(
    (state) => state.emission.emissionDetails.data
  );
  const emissionDataStatus = useSelector(
    (state) => state.emission.emissionDetails.status
  );

  const userRole = useSelector((state) => state.auth.userInfo.role);
  const isDeleteEnable = [
    rolesEnum.FACILITY_MANAGER,
    rolesEnum.BUSINESS_USER,
    rolesEnum.APPROVER,
  ].includes(userRole);
  const isAuditor = userRole.includes(rolesEnum.AUDITOR);
  const isOnlyAdmin =
    userRole.includes(rolesEnum.ADMIN) && userRole.length === 1;
  const susManager =
    getCookie("role") === "sustainability_manager" ||
    getCookie("role") === "facility_manager" ||
    getCookie("role") === "proxy_sustainability_manager";
  const { type, id, subType, approvalId } = useParams();
  const distribution = queryParams.get("distribution");
  const classification = queryParams.get("classification");
  console.log("nikj", type, id, subType, approvalId);

  const onCancel = () => {
    navigate(-1);
    // navigate(`/emissions/${emissionType}/?year=${filteredEmissionData[0].year}`,{ state: { filteredEmissionData }, replace: true })
  };
  const typeMap = {
    "upstream_T&D": "upstream_t_and_d",
    "downstream_T&D": "downstream_t_and_d",
    end_of_life_treatment_of_sold_products: "end_of_life_treatment",
  };
  useEffect(() => {
    dispatch(
      getEmission({
        emissionType: typeMap[type] || type,
        emissionId: id,
        isAuditor,
        company,
        distribution: distribution,
        classification: classification,
      })
    );
  }, [type, id, dispatch, isAuditor, company, distribution, classification]);
  return (
    <DashboardLayout>
      <Container className={classes.container}>
        {emissionDataStatus === "running" ? (
          <div>Loading</div>
        ) : emissionDataStatus === "error" ? (
          <div>We couldn't process your request. Please try again later.</div>
        ) : (
          <>
            {type === "purchased_electricity" && (
              <PurchasedElectricityDetails
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "mobile_combustion" && (
              <MobileCombustionDetails
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "stationary_combustion" && (
              <StationaryCombustionDetails
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "refrigerants" && (
              <RefrigerantsDetails
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "transportation" && (
              <TransportationDetails
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "water_consumption" && (
              <WaterConsumptionDetails
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "water_discharge" && (
              <WaterDischargeDetails
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "waste" && (
              <WasteCombustionDetails
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "development_training" && (
              <DevelopmentAndTraining
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "employee_health_safety_incident_record" && (
              <EmployeeHealthSafety
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "worker_safety_training_procedures" && (
              <WorkerSafetyTraining
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "employees_turnover" && (
              <EmployeeTurnover
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "age_based_statistics" && (
              <AgeBasedStatistics
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "gender_based_statistics" && (
              <GenderBasedStatistics
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "discrimination_incident_record" && (
              <DiscriminationIncident
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type ===
              "supplier_screening_using_enviormental_social_criteria" && (
              <SupplierScreening
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "suppliers_human_rights" && (
              <OperationalHumanRightsTraining
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "social_engagement_human_rights_training" && (
              <SocialEngagementHumanRightsTraining
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "local_communities" && (
              <LocalCommunities
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "political_contributions" && (
              <PoliticalContributions
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "anti_corruption_disclosure" && (
              <AntiCorruptionDisclosure
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "anti_corruption_training" && (
              <AntiCorruptionTraining
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "anti_competitive_disclosure" && (
              <AntiCompetitiveDisclosure
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "subsidies_financial_assistance" && (
              <SubsidiesFinancialAssistance
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "board_diversity" && (
              <BoardDiversity
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "management_diversity" && (
              <ManagementDiversity
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "economic_impact" && (
              <EconomicImpact
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "employees_benefit" && (
              <EmployeeBenefit
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "training_hours_per_employee" && (
              <EmployeeTrainingHours
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "performance_and_career_development_programs" && (
              <PerformanceAndCareerDevelopmentPrograms
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "employees_hire_and_turnover" && (
              <EmployeeHireDetails
                emissionData={emissionData}
                emissionId={id}
                isAuditor={isAuditor}
                company={company}
                setIsDrawerOpen={setIsDrawerOpen}
              />
            )}
            {type === "employees_diversity_and_ratio_of_basic_salary" && (
              <EmployeeDiversityDetails
                emissionData={emissionData}
                emissionId={id}
                isAuditor={isAuditor}
                company={company}
                setIsDrawerOpen={setIsDrawerOpen}
              />
            )}
            {type === "upstream_T&D" && (
              <UpstreamTAndD
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
                subType={subType}
                approvalId={approvalId}
              />
            )}
            {type === "business_travel" && (
              <BusinessTravel
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}

            {type === "employee_commute" && (
              <EmployeeCommutingDetails
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
              />
            )}
            {type === "use_of_sold_products" && (
              <UseOfSoldProductsDetails
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
                subType={subType}
                approvalId={approvalId}
              />
            )}
            {type === "purchased_goods_and_services" && (
              <PurchasedGoodsAndServices
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
                subType={subType}
                approvalId={approvalId}
              />
            )}
            {type === "downstream_T&D" && (
              <DownstreamTAndD
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
                subType={subType}
                approvalId={approvalId}
              />
            )}
            {type === "capital_goods" && (
              <CapitalGoods
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
                subType={subType}
                approvalId={approvalId}
              />
            )}
            {type === "fuel_and_energy_related_activities" && (
              <FuelAndEnergyRelatedActivities
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
                subType={subType}
                approvalId={approvalId}
              />
            )}
            {type === "end_of_life_treatment_of_sold_products" && (
              <EndOfLifeTreatment
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
                subType={subType}
                approvalId={approvalId}
              />
            )}
            {type === "upstream_leased_asset" && (
              <UpstreamLeasedAsset
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
                subType={subType}
                approvalId={approvalId}
              />
            )}
            {type === "downstream_leased_asset" && (
              <DownstreamLeasedAsset
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
                subType={subType}
                approvalId={approvalId}
              />
            )}
            {type === "franchises" && (
              <Franchise
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
                subType={subType}
                approvalId={approvalId}
              />
            )}
            {type === "processing_of_sold_products" && (
              <ProcessingOfSoldProducts
                onCancel={onCancel}
                emissionId={id}
                emissionData={emissionData}
                isDeleteEnable={isDeleteEnable}
                setIsDrawerOpen={setIsDrawerOpen}
                isAuditor={isAuditor}
                company={company}
                isAdmin={!isOnlyAdmin}
                isSusManager={susManager}
                subType={subType}
                approvalId={approvalId}
              />
            )}
          </>
        )}
      </Container>
      <CreateTicketDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        scope="emission"
        scopeId={id}
        companyId={company}
      />
    </DashboardLayout>
  );
};

export default EmissionsDetails;
