import { Box, Container, Grid, Link, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import CeroInfoPair from "../../../components/CeroInfoPair";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../../services/cookie";
import CeroPopUp from "../../../components/CeroPopUp";
import { useState } from "react";
import CeroButton from "../../../components/CeroButton";
import CeroInput from "../../../components/CeroInput";
import { rolesEnum } from "../../../layouts/DashboardLayout/pages";

const Status = ({ ticketDetails, userInfo, companyId }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const role = getCookie("role");
  const readOnlyMode = getCookie("read_only_mode") === "disable_edit";
  const isSupportTicket =
    window.location.pathname.includes("/support-tickets") ||
    role === "super_admin";
  const isAuditor = role === rolesEnum.AUDITOR;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const isRportNft = ticketDetails.title.includes("Report NFT Request");
  // const onUpdate = () => {
  //   navigate(
  //     `/emissions/${ticketDetails.topic_type}/details/${ticketDetails.ticket_scope_id}/?company=${companyId}`
  //   );
  // };
  const typeMap = {
    upstream_t_and_d: "upstream_T&D",
    downstream_t_and_d: "downstream_T&D",
    end_of_life_treatment: "end_of_life_treatment_of_sold_products",
  };
  const onUpdate = () => {
    ticketDetails?.ticket_scope === "offset"
      ? navigate(`/carbon-offset/${ticketDetails.ticket_scope_id}?action=edit`)
      : navigate(
          `/emissions/${
            typeMap[ticketDetails.topic_type] || ticketDetails.topic_type
          }/details/${ticketDetails.ticket_scope_id}/?company=${companyId}`
        );
  };

  const status = ticketDetails.status.toLowerCase();
  const getStatusColorClass = () => {
    if (status === "closed") {
      return classes.closed;
    } else {
      return classes.open;
    }
  };

  const getMintNFT = () => {
    return (
      <Box className={classes.popUp}>
        <Grid container spacing={2} display={"flex"}>
          <Grid item xs={6}>
            <CeroInput
              classes={{ container: classes.textArea }}
              rows={1}
              id="company"
              name="company"
              label="Company Name"
              placeholder="Please type your comment"
              value={ticketDetails.company}
              // fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <CeroInput
              classes={{ container: classes.textArea }}
              rows={1}
              id="company"
              name="company"
              label="Audit Reference Number"
              placeholder="Please type your comment"
              value={ticketDetails?.audit_number?.toUpperCase()}
              // fullWidth
            />
          </Grid>
        </Grid>
        <div className={classes.buttonContainer}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={() => {
              setConfirm(true);
              setIsPopupOpen(false);
            }}
            buttonText="Proceed"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() => setIsPopupOpen(false)}
            buttonText="Cancel"
          />
        </div>
      </Box>
    );
  };

  const getConfirmation = () => {
    return (
      <Box className={classes.popUpConfirm}>
        <Typography variant="body1" style={{ fontWeight: 500 }}>
          Are you sure you want to proceed ?
        </Typography>
        <div className={classes.buttonContainerReview}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={() =>
              // call here nft
              {
                userInfo(ticketDetails);
                setConfirm(false);
              }
            }
            buttonText="Confirm"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() => setConfirm(false)}
            buttonText="Cancel"
          />
        </div>
      </Box>
    );
  };

  return (
    <Container className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {isSupportTicket ? (
            <>
              <CeroInfoPair
                title="Status"
                value={ticketDetails.status}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                  value: getStatusColorClass(),
                }}
              />
              <CeroInfoPair
                title="Ticket type"
                value={ticketDetails.ticket_type}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
              />
              <CeroInfoPair
                title="Severity"
                value={ticketDetails.severity}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
              />
              <CeroInfoPair
                title="Ticket Raised By"
                value={ticketDetails.ticket_owner_name}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
                ticketDetails={ticketDetails}
              />
            </>
          ) : (
            <>
              <CeroInfoPair
                title="Status"
                value={ticketDetails.status}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                  value: getStatusColorClass(),
                }}
              />
              <CeroInfoPair
                title="Facility"
                value={ticketDetails.facility_name}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
              />
              <CeroInfoPair
                title="Assignee"
                value={ticketDetails.assignee_name}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
              />
              <CeroInfoPair
                title="Scope"
                value={ticketDetails.ticket_scope}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
              />
            </>
          )}
        </Grid>
        <Grid item xs={6}>
          {isSupportTicket ? (
            <>
              <CeroInfoPair
                title="Company"
                value={ticketDetails.company ? ticketDetails.company : "NA"}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                  value: classes.companyValue,
                }}
              />
              <CeroInfoPair
                title="Assigned on"
                value={
                  ticketDetails.assigned_on &&
                  dayjs(ticketDetails.assigned_on).format("DD MMM YYYY HH:mm")
                }
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
              />
              <CeroInfoPair
                title="Last updated on"
                value={
                  ticketDetails.last_updated &&
                  dayjs(ticketDetails.last_updated).format("DD MMM YYYY HH:mm")
                }
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
              />
            </>
          ) : (
            <>
              <CeroInfoPair
                title="Created By"
                value={
                  ticketDetails.ticket_owner_name
                    ? ticketDetails.ticket_owner_name
                    : "NA"
                }
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
                ticketDetails={ticketDetails}
              />
              <CeroInfoPair
                title="Created on"
                value={
                  ticketDetails.assigned_on &&
                  dayjs(ticketDetails.assigned_on).format("DD MMM YYYY HH:mm")
                }
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
              />
              <CeroInfoPair
                title="Last updated on"
                value={
                  ticketDetails.last_updated &&
                  dayjs(ticketDetails.last_updated).format("DD MMM YYYY HH:mm")
                }
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                }}
              />
            </>
          )}
        </Grid>
      </Grid>
      {ticketDetails.ticket_type === "request nft" && status !== "closed" && (
        <Stack sx={{ marginTop: "1rem" }}>
          <Typography variant="h6" component="span">
            {`Description`}
          </Typography>
          <Typography
            sx={{
              marginTop: "0.5rem",
              marginBottom: "0.7rem",
              fontWeight: 500,
            }}
            variant="body2"
            component="span"
          >
            {isRportNft
              ? `${ticketDetails.description}`
              : `A token generation request has been generated following the approval
            of audit ${ticketDetails.audit_number?.toUpperCase()}.`}
          </Typography>
          <Typography
            sx={{
              marginTop: "0.5rem",
              marginBottom: "0.7rem",
              fontWeight: 500,
            }}
            variant="body2"
            component="span"
          >
            {`Initiate the minting process `}
            <span>
              <Link
                onClick={() => setIsPopupOpen(true)}
                style={{ cursor: "pointer" }}
                className={classes.LinkStyle}
              >
                Click here
              </Link>
            </span>{" "}
          </Typography>
          <Typography
            sx={{ marginTop: "0.5rem", marginBottom: "1rem", fontWeight: 500 }}
            variant="body2"
            component="span"
          >
            Steps to Follow:
          </Typography>
          <Box className={classes.stepsContainer}>
            <Typography>1. Initiate the minting process</Typography>
            <Typography>
              2. The system will handle a transaction and you will be notified
              of the status
            </Typography>
            <Typography>
              3. Once the transaction is successful, a token will be generated
              and the Sustainability Manager will be notified to download the
              certificate
            </Typography>
            <Typography mt={6}>
              Note: This is a manual process,and all actions need to be
              completed carefully.
            </Typography>
          </Box>
        </Stack>
      )}
      {ticketDetails?.ticket_scope === "emission" ||
      (ticketDetails?.ticket_scope === "offset" &&
        !readOnlyMode &&
        !isSupportTicket &&
        !isAuditor) ? (
        <Typography sx={{ fontWeight: 500 }} variant="body2">
          <span>
            <Link
              className={classes.LinkStyle}
              onClick={onUpdate}
              style={{ cursor: "pointer" }}
            >
              Click here
            </Link>
          </span>{" "}
          to update
        </Typography>
      ) : (
        <div style={{ display: "grid" }}>
          {/* <Typography variant="h6" component="span">
            {`Description`}
          </Typography> */}
          <Typography
            sx={{
              marginTop: "0.5rem",
              marginBottom: "0.7rem",
              fontWeight: 500,
            }}
            variant="body2"
            component="span"
          >
            {ticketDetails.description}
          </Typography>
        </div>
      )}
      <CeroPopUp
        primaryPopUp={{
          open: isPopupOpen,
          onClose: () => setIsPopupOpen(false),
          content: getMintNFT(),
          header: { title: "Generate Token" },
        }}
      />
      <CeroPopUp
        primaryPopUp={{
          open: confirm,
          onClose: () => setConfirm(false),
          content: getConfirmation(),
          header: { title: "Confirm!" },
        }}
        classes={{ dialog: classes.dialog }}
        showCloseButton={true}
      />
    </Container>
  );
};

export default Status;
