import { all, put, call, takeLatest } from "redux-saga/effects";

import { request } from "../../services/client";
import { ActionTypes } from "../constants/actions";
import { APIEndpoints } from "../constants";

export function* addVision(action) {
  try {
    const response = yield call(request, APIEndpoints.ADD_VISION, {
      method: "POST",
      payload: { vision: action.payload },
    });
    yield put({
      type: ActionTypes.ADD_VISION_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_VISION_FAILURE,
      payload: err.message,
    });
  }
}

export function* getVision(action) {
  try {
    const response = yield call(request, APIEndpoints.GET_VISION, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_VISION_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_VISION_FAILURE,
      payload: err,
    });
  }
}

export function* addMission(action) {
  try {
    const { mission, country } = action.payload;
    const response = yield call(request, APIEndpoints.ADD_MISSION, {
      method: "POST",
      payload: { mission: mission, country: country },
    });
    yield put({
      type: ActionTypes.ADD_MISSION_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_MISSION_FAILURE,
      payload: err.message,
    });
  }
}

export function* editMission(action) {
  try {
    const { missionId, mission, country } = action.payload;
    const response = yield call(request, APIEndpoints.EDIT_MISSION(missionId), {
      method: "PUT",
      payload: { mission: mission, country: country },
    });
    yield put({
      type: ActionTypes.EDIT_MISSION_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.EDIT_MISSION_FAILURE,
      payload: err,
    });
  }
}

export function* getMission(action) {
  try {
    const { country } = action.payload;
    let endpoint = APIEndpoints.GET_MISSION;
    if (country !== undefined || null) {
      endpoint += `?country=${country}`;
    }
    const response = yield call(request, endpoint, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_MISSION_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_MISSION_FAILURE,
      payload: err,
    });
  }
}

export function* getInitiativeDetails(action) {
  const { initiativeId } = action.payload;
  try {
    const response = yield call(
      request,
      APIEndpoints.GET_INITIATIVE_DETAILS(initiativeId),
      {
        method: "GET",
      }
    );

    yield put({
      type: ActionTypes.GET_INITIATIVE_DETAILS_SUCCESS,
      payload: response.initiative_response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_INITIATIVE_DETAILS_FAILURE,
      payload: err,
    });
  }
}

export function* addInitiative(action) {
  const requestData = action.payload;
  try {
    const response = yield call(request, APIEndpoints.ADD_INITIATIVE, {
      method: "POST",
      payload: requestData,
    });
    yield put({
      type: ActionTypes.ADD_INITIATIVE_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_INITIATIVE_FAILURE,
      payload: err,
    });
  }
}

export function* listInitiative(action) {
  try {
    const { year, country, facility } = action.payload;
    let endpoint = APIEndpoints.LIST_INITIATIVE;
    if (year !== undefined || null) {
      endpoint += `year=${year}&`;
    }
    if (country !== undefined || null) {
      endpoint += `country=${country}&`;
    }
    if (facility !== undefined || null) {
      endpoint += `facility_id=${facility}`;
    }
    const response = yield call(request, endpoint, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.LIST_INITIATIVE_SUCCESS,
      payload: response.initiative_list,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LIST_INITIATIVE_FAILURE,
      payload: err,
    });
  }
}

export function* addTasks(action) {
  const requestData = action.payload;
  try {
    const response = yield call(request, APIEndpoints.ADD_TASKS, {
      method: "POST",
      payload: requestData,
    });
    yield put({
      type: ActionTypes.ADD_TASKS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_TASKS_FAILURE,
      payload: err,
    });
  }
}

export function* getTasksList(action) {
  const { initiativeId } = action.payload;

  try {
    const response = yield call(
      request,
      APIEndpoints.GET_TASKS_LIST(initiativeId),
      {
        method: "GET",
      }
    );

    yield put({
      type: ActionTypes.GET_TASKS_LIST_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_TASKS_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* saveTaskTracking(action) {
  try {
    const { Id, taskDetails } = action.payload;
    const response = yield call(request, APIEndpoints.SAVE_TASK_TRACKING(Id), {
      method: "POST",
      payload: {
        task_track: taskDetails,
      },
    });
    yield put({
      type: ActionTypes.SAVE_TASK_TRACKING_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.SAVE_TASK_TRACKING_FAILURE,
      payload: err,
    });
  }
}

export function* addTargetActual(action) {
  try {
    const { values } = action.payload;
    const response = yield call(request, APIEndpoints.ADD_TARGET_ACTUAL, {
      method: "POST",
      payload: {
        target_actual_list: values,
      },
    });
    yield put({
      type: ActionTypes.ADD_TARGET_ACTUAL_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_TARGET_ACTUAL_FAILURE,
      payload: err,
    });
  }
}

export function* getTrackingCycleAssessmentPeriod(action) {
  console.log("action.payload", action.payload);
  const { values } = action.payload;
  try {
    const response = yield call(
      request,
      APIEndpoints.GET_TRACKING_CYCLE_ASSESSMENT_PERIOD(values),
      {
        method: "GET",
      }
    );

    yield put({
      type: ActionTypes.GET_TRACKING_CYCLE_ASSESSMENT_PERIOD_SUCCESS,
      payload: response.assessment_period,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_TRACKING_CYCLE_ASSESSMENT_PERIOD_FAILURE,
      payload: err,
    });
  }
}

export function* editTask(action) {
  try {
    const { Id, name, period, year } = action.payload;
    const response = yield call(request, APIEndpoints.EDIT_TASK(Id), {
      method: "PUT",
      payload: { name: name, 
                 period: period,
                year: year},
    });
    yield put({
      type: ActionTypes.EDIT_TASK_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.EDIT_TASK_FAILURE,
      payload: err,
    });
  }
}


export function* initiativeAttachment(action) {
  try {
    const formData = new FormData();
    const { requestData } = action.payload;

    for (let key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        if (key === "file") {
          if (requestData[key] instanceof File) {
            formData.append("file", requestData[key]);
          }
        } else {
          formData.append(key, requestData[key]);
        }
      }
    }
    const response = yield call(
      request,
      APIEndpoints.INITIATIVE_ATTACHMENT(requestData.id),
      {
        // method: 'POST',
        // payload: requestData
        method: "POST",
        isFormData: true,
        payload: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    yield put({
      type: ActionTypes.INITIATIVE_ATTACHMENT_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.INITIATIVE_ATTACHMENT_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.ADD_VISION, addVision),
    takeLatest(ActionTypes.GET_VISION, getVision),
    takeLatest(ActionTypes.ADD_MISSION, addMission),
    takeLatest(ActionTypes.EDIT_MISSION, editMission),
    takeLatest(ActionTypes.GET_MISSION, getMission),
    takeLatest(ActionTypes.GET_INITIATIVE_DETAILS, getInitiativeDetails),
    takeLatest(ActionTypes.ADD_INITIATIVE, addInitiative),
    takeLatest(ActionTypes.LIST_INITIATIVE, listInitiative),
    takeLatest(ActionTypes.ADD_TASKS, addTasks),
    takeLatest(ActionTypes.GET_TASKS_LIST, getTasksList),
    takeLatest(ActionTypes.SAVE_TASK_TRACKING, saveTaskTracking),
    takeLatest(ActionTypes.ADD_TARGET_ACTUAL, addTargetActual),
    takeLatest(
      ActionTypes.GET_TRACKING_CYCLE_ASSESSMENT_PERIOD,
      getTrackingCycleAssessmentPeriod
    ),
    takeLatest(ActionTypes.EDIT_TASK, editTask),
    takeLatest(ActionTypes.INITIATIVE_ATTACHMENT, initiativeAttachment),
  ]);
}
