import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { months, sampleYear } from "../../../constants";
import { STATUS } from "../../../redux/constants";
import { addMobileCombustionValidation } from "./schema";
import {
  addMobileCombustion,
  getEmissionFuelList,
  resetAddCombustionStatus,
  updateProcessingOfSoldProducts,
} from "../../../redux/actions";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroButton from "../../../components/CeroButton";
import CeroSelect from "../../../components/CeroSelect";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import DownloadIcon from "@mui/icons-material/Download";
import EmissionUploadDrawer from "../../EmissionList/EmissionUploadDrawer";
import { useNavigate } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CeroItemPair from "../../../components/CeroItemPair";
import FileUpload from "../../AddEmissions/FileUpload";

const EditProcessingOfSoldProducts = (props) => {
  const { onCancel, onDownload, onUpload, onValidate, uploadDisabled,emissionData,emissionId, } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const isCalculateDone = useSelector(
    (state) => state.emission.updateProcessingOfSoldProducts.isCalculateDone
  );
  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const fuelSourceData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.fuel_sources
  );
  const fuelUnitData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.units
  );
  const editEmissionData = useSelector(
    (state) => state.emission.updateProcessingOfSoldProducts
  );
  const emissionPerformData = useSelector((state) => state.emission.fuelList);
  const currentYear = new Date().getFullYear().toString();
  const [selectedFile, setSelectedFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      facility: emissionData.facility_id || "",
      year: emissionData.year || currentYear,
      month: emissionData.month || "",
      calculationMethod: emissionData.calculation_method || "",
      fuelSource: emissionData.fuel_source_id || "",
      fuelUnit: emissionData.fuel_unit || "",
      amountOfFuel: emissionData.amount_fuel || 0,
      emissionFactor: emissionData.emission_factor_of_sold_products,
      massOfWaste: emissionData.amount_waste || 0,
      wasteMassUnit:emissionData.waste_unit ||"",
      massOfProduct:emissionData.mass_of_sold_product,
      wasteTreatmentMethod:emissionData.waste_treatment_id || "",
      wasteType:emissionData.waste_type_id || "",
      emissionReleased:emissionData.amount_of_emission_released,
      refrigerantUnit:emissionData.refrigerant_unit || "",
      quantity:emissionData.amount_refrigerants || 0,
      refrigerant:emissionData.refrigerant_id || "",
      gasType:emissionData.gas_type_id || "",
      electricityUnit:emissionData.electricity_unit || "",
      amountOfElectricity:emissionData.amount_electricity || 0,
      finalProductFuelUnit:"",
      massUnit: emissionData.mass_unit || "",
    },
    validationSchema: addMobileCombustionValidation,
    onSubmit: () => {},
    enableReinitialize:true,
  });

  
  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));
  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));
  const calculationNamesMap = {};
  const calculationInputList =
    emissionPerformData?.data?.calculation_method?.map((item) => {
      calculationNamesMap[item.id] = item.name; // Populate namesMap
      return {
        key: item.id,
        value: item.name,
      };
    }) || [];
  const fuelSourceInputList = emissionPerformData?.data?.fuel_sources?.map(
    (item) => ({
      key: item.id,
      value: item.name,
    })
  );
  const fuelUnitInputList = emissionPerformData?.data?.fuel_sources
  ?.find((item) => item.id === formik.values.fuelSource)
  ?.units.map((item) => ({ key: item, value: item }));

  const gasTypeList = emissionPerformData?.data?.gas_types?.map((item) => ({
    key: item.id,
    value: item.name,
  }));
  const refrigerantList = emissionPerformData?.data?.refrigerants?.map(
    (item) => ({
      key: item.id,
      value: item.name,
    })
  );
  const refrigerantsUnitList = emissionPerformData?.data?.refrigerant_unit?.map(
    (item) => {
      return {
        key: item.name,
        value: item.name,
      };
    }
  );
  const electricityUnitList = emissionPerformData?.data?.electricity_unit?.map(
    (item) => {
      return {
        key: item.name,
        value: item.name,
      };
    }
  );

  const wasteUnit = emissionPerformData?.data?.waste_unit?.map((item) => ({
    key: item.name,
    value: item.name,
  }));
  useEffect(() => {
    dispatch(getEmissionFuelList("processing_of_sold_products"));
    return () => {
      dispatch(resetAddCombustionStatus());
    };
  }, [dispatch]);

  useEffect(() => {
    if (editEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Processing of sold products updated successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (editEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        editEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
    }
  }, [editEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onCalculate = () => {
    const requestData = {
      id:emissionId,
      facility_id: formik.values.facility,
      year: formik.values.year,
      month: formik.values.month,
      calculation_method_id: formik.values.calculationMethod,
      fuel_source_id: formik.values.fuelSource,
      amount_fuel: formik.values.amountOfFuel,
      fuel_unit: formik.values.fuelUnit,
      waste_type_id: formik.values.wasteType,
      waste_treatment_id: formik.values.wasteTreatmentMethod,
      waste_unit: formik.values.wasteMassUnit,
      amount_waste: formik.values.massOfWaste,
      amount_electricity: formik.values.amountOfElectricity,
      electricity_unit: formik.values.electricityUnit,
      gas_type_id: formik.values.gasType,
      refrigerant_id: formik.values.refrigerant,
      refrigerant_unit: formik.values.refrigerantUnit,
      amount_refrigerants: formik.values.quantity,
      amount_of_emission_released: formik.values.emissionReleased,
      mass_of_sold_product: formik.values.massOfProduct,
      emission_factor_of_sold_products: formik.values.emissionFactor,
      mass_unit: formik.values.massUnit,
      save: false,
    };
     dispatch(updateProcessingOfSoldProducts(requestData));
  };

  const onUpdateProcessingOfSoldProductsData = () => {
    const requestData = {
      id:emissionId,
      facility_id: formik.values.facility,
      year: formik.values.year,
      month: formik.values.month,
      calculation_method_id: formik.values.calculationMethod,
      fuel_source_id: formik.values.fuelSource,
      amount_of_fuel: parseFloat(formik.values.amountOfFuel),
      fuel_unit: formik.values.fuelUnit,
      waste_type_id: formik.values.wasteType,
      waste_treatment_id: formik.values.wasteTreatmentMethod,
      waste_unit: formik.values.wasteMassUnit,
      mass_of_waste: formik.values.massOfWaste,
      amount_of_electricity: formik.values.amountOfElectricity,
      electricity_unit: formik.values.electricityUnit,
      gas_type_id: formik.values.gasType,
      refrigerant_id: formik.values.refrigerant,
      refrigerant_unit: formik.values.refrigerantUnit,
      amount_of_refrigerants: formik.values.quantity,
      amount_of_emission_released: formik.values.emissionReleased,
      mass_of_sold_product: formik.values.massOfProduct,
      emission_factor_of_sold_products: formik.values.emissionFactor,
      mass_unit: formik.values.massUnit,
      save: true,
      file: selectedFile,
    };
    sessionStorage.setItem("year", requestData.year);
    dispatch(updateProcessingOfSoldProducts(requestData));
  };
  const wasteTypes =
    emissionPerformData?.data &&
    (emissionPerformData?.data?.waste_types_collection || []).map((item) => ({
      key: item.id,
      value: item.name,
    }));

  const wasteDisposalOption =
    emissionPerformData?.data &&
    (emissionPerformData?.data?.waste_disposal_options || []).map((item) => ({
      key: item.id,
      value: item.name,
    }));

  const [isOpenEmissionTypeUpload, setIsOpenEmissionTypeUpload] =
    useState(false);
  const onCloseDrawerUpload = () => {
    setIsOpenEmissionTypeUpload(false);
  };
  const handleFileUpload = (file) => {
    setSelectedFile(file);
    // dispatch(uploadEmissionAttachement("emissionId", file));
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
  };
  const info = () => {
    return (
      <div>
        <Typography variant="body2">
          Fuel use: Mobile combustion by the usage of fuel for mobile operations
          such as transport.
          <br />
          Distance activity: Distance travelled by the usage of fuel
        </Typography>
      </div>
    );
  };

  return (
    <>
      <div className={classes.backContainer}>
        <div
          className={classes.backButtonDiv}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon />
          Back
        </div>
        <div className={classes.templateButtonDiv}>
          {/* <CeroButton
            buttonText="Bulk Data Upload"
            className={classes.bulkButton}
            startIcon={<FileUploadIcon />}
            onClick={() => setIsOpenEmissionTypeUpload(true)}
          />
          <CeroButton
            buttonText="Download Template"
            className={classes.bulkButton}
            startIcon={<DownloadIcon />}
            onClick={onDownload}
          /> */}
        </div>
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Edit processing of solid products
          </Typography>
          <Box className={classes.topContainer}>
            <Grid className={classes.dynamicBoard}>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroAutoComplete
                  id="year"
                  name="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.touched.year && formik.errors.year}
                  options={yearList}
                  value={formik.values.year}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="month"
                  name="month"
                  label="Month"
                  fullWidth
                  options={months}
                  selectedValue={formik.values.month}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.month && formik.errors.month}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="calculationMethod"
                  name="calculationMethod"
                  label="Calculation Method"
                  fullWidth
                  options={calculationInputList}
                  selectedValue={formik.values.calculationMethod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.calculationMethod &&
                    formik.errors.calculationMethod
                  }
                />
              </Grid>

              {formik.values.calculationMethod &&
                calculationNamesMap[
                  formik.values.calculationMethod
                ]?.toLowerCase() === "site-specific method" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="fuelSource"
                        name="fuelSource"
                        label="Fuel Source"
                        fullWidth
                        options={fuelSourceInputList}
                        selectedValue={formik.values.fuelSource}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.fuelSource && formik.errors.fuelSource
                        }
                        classes={{
                          input: classes.input,
                          container: classes.selectContainer,
                        }}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="amountOfFuel"
                        name="amountOfFuel"
                        label="Amount of Fuel"
                        value={formik.values.amountOfFuel}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.amountOfFuel &&
                          formik.errors.amountOfFuel
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="fuelUnit"
                        name="fuelUnit"
                        label="Fuel Unit"
                        fullWidth
                        options={fuelUnitInputList || [{ name: "select" }]}
                        selectedValue={formik.values.fuelUnit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.fuelUnit && formik.errors.fuelUnit
                        }
                        sortOption={true}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="amountOfElectricity"
                        name="amountOfElectricity"
                        label="Amount of Elecricity Consumed"
                        value={formik.values.amountOfElectricity}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.amountOfElectricity &&
                          formik.errors.amountOfElectricity
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="electricityUnit"
                        name="electricityUnit"
                        label="Unit"
                        fullWidth
                        options={electricityUnitList || [{ name: "select" }]}
                        selectedValue={formik.values.electricityUnit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.electricityUnit &&
                          formik.errors.electricityUnit
                        }
                        sortOption={true}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="gasType"
                        name="gasType"
                        label="Gas type"
                        fullWidth
                        options={gasTypeList}
                        selectedValue={formik.values.gasType || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.gasType && formik.errors.gasType}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="refrigerant"
                        name="refrigerant"
                        label="Refrigerant"
                        fullWidth
                        options={refrigerantList}
                        selectedValue={formik.values.refrigerant || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.refrigerant &&
                          formik.errors.refrigerant
                        }
                        classes={{
                          input: classes.input,
                          container: classes.selectContainer,
                          paperRoot: classes.paperRoot,
                        }}
                      />
                    </Grid>

                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="quantity"
                        name="quantity"
                        label="Amount of refrigerant"
                        value={formik.values.quantity}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.quantity && formik.errors.quantity
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="unit"
                        name="unit"
                        label="Unit"
                        fullWidth
                        options={refrigerantsUnitList}
                        selectedValue={formik.values.refrigerantUnit || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.refrigerantUnit &&
                          formik.errors.refrigerantUnit
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="emissionReleased"
                        name="emissionReleased"
                        label="Process emissions released in the processing of sold intermediate products"
                        value={formik.values.emissionReleased}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.emissionReleased &&
                          formik.errors.emissionReleased
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="wasteType"
                        name="wasteType"
                        label="Waste type"
                        fullWidth
                        options={wasteTypes}
                        selectedValue={formik.values.wasteType}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.wasteType && formik.errors.wasteType
                        }
                        classes={{
                          input: classes.input,
                          container: classes.selectContainer,
                          paperRoot: classes.wastePaper,
                        }}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="wasteTreatmentMethod"
                        name="wasteTreatmentMethod"
                        label="Waste treatment method "
                        fullWidth
                        options={wasteDisposalOption}
                        selectedValue={formik.values.wasteTreatmentMethod}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.wasteTreatmentMethod &&
                          formik.errors.wasteTreatmentMethod
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="massOfWaste"
                        name="massOfWaste"
                        label="Mass of waste output"
                        value={formik.values.massOfWaste}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.massOfWaste &&
                          formik.errors.massOfWaste
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="wasteMassUnit"
                        name="wasteMassUnit"
                        label="Unit"
                        fullWidth
                        options={wasteUnit}
                        selectedValue={formik.values.wasteMassUnit || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.wasteMassUnit &&
                          formik.errors.wasteMassUnit
                        }
                      />
                    </Grid>
                  </>
                )}
              {formik.values.calculationMethod &&
                calculationNamesMap[
                  formik.values.calculationMethod
                ]?.toLowerCase() === "average-data method" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="massOfProduct"
                        name="massOfProduct"
                        label="Mass of sold intermediate product"
                        value={formik.values.massOfProduct}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.massOfProduct &&
                          formik.errors.massOfProduct
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="massUnit"
                        name="massUnit"
                        label="Fuel Unit"
                        fullWidth
                        options={wasteUnit}
                        selectedValue={formik.values.massUnit || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.massUnit && formik.errors.massUnit
                        }
                        sortOption={true}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="emissionFactor"
                        name="emissionFactor"
                        label="Emission factor of processing of sold products"
                        value={formik.values.emissionFactor}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.emissionFactor &&
                          formik.errors.emissionFactor
                        }
                        type="number"
                      />
                    </Grid>
                    {/* <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="finalProductFuelUnit"
                        name="finalProductFuelUnit"
                        label="Fuel Unit for final product"
                        fullWidth
                        options={averageUnitInputList || [{ name: "select" }]}
                        selectedValue={formik.values.finalProductFuelUnit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.finalProductFuelUnit &&
                          formik.errors.finalProductFuelUnit
                        }
                        sortOption={true}
                      /> */}
                    {/* </Grid> */}
                  </>
                )}
            </Grid>
            <CeroButton
              buttonText="Calculate"
              className={classes.buttonPrimary}
              onClick={onCalculate}
              disabled={!formik.dirty || !formik.isValid}
            />
            <CeroButton
              buttonText="Clear"
              variant="outlined"
              className={classes.buttonSecondary}
              onClick={formik.resetForm}
            />
            {isCalculateDone && (
              <Box className={classes.bottomContainer}>
                <Typography
                  variant="h6"
                  component="h6"
                  className={classes.previewTitle}
                >
                  Emission Preview
                </Typography>
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="space-between"
                  spacing={8}
                >
                  <Grid item container direction="column" xs={12} md={6}>
                    <CeroItemPair
                      title={
                        <>
                          CO<sub>2</sub>
                        </>
                      }
                      value={`${editEmissionData.data.co2} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          CH<sub>4</sub>
                        </>
                      }
                      value={`${editEmissionData.data.ch4} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          BioFuel CO<sub>2</sub>
                        </>
                      }
                      value={`${editEmissionData.data.biofuel_co2} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                  </Grid>
                  <Grid
                    className={classes.secondResultContainer}
                    item
                    container
                    direction="column"
                    xs={6}
                  >
                    <CeroItemPair
                      title={
                        <>
                          CO<sub>2</sub>e
                        </>
                      }
                      value={`${editEmissionData.data.co2e} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          N<sub>2</sub>O
                        </>
                      }
                      value={`${editEmissionData.data.n2o} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={<>EF</>}
                      value={
                        <>
                          {editEmissionData.data.ef} kgCO<sub>2</sub>e/unit
                        </>
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
          <FileUpload
            onFileUpload={handleFileUpload}
            onFileRemove={handleFileRemove}
          />
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Update"
            disabled={
              !isCalculateDone || editEmissionData.status === STATUS.RUNNING
            }
            className={classes.buttonPrimary}
            onClick={() => onUpdateProcessingOfSoldProductsData(formik.values)}
          />

          {/* <div className={classes.buttonContainer}>
                <CeroButton
                   buttonText="Bulk Data Upload"
                   className={classes.bulkButton}
                   startIcon={<FileUploadIcon />}
                   onClick={() => setIsOpenEmissionTypeUpload(true)}
                />
                <CeroButton
                    buttonText="Download Template"
                    className={classes.bulkButton}
                    startIcon={<DownloadIcon />}
                    onClick={onDownload}
                  />
                </div> */}
        </Box>
        <EmissionUploadDrawer
          isOpen={isOpenEmissionTypeUpload}
          onClose={onCloseDrawerUpload}
          onUpload={onUpload}
          onValidate={onValidate}
          uploadDisabled={uploadDisabled}
        />
      </Container>
    </>
  );
};

export default EditProcessingOfSoldProducts;
