import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Typography } from "@mui/material";
import {
  averageScoreSupplierIndustry,
  getOffsetOverYears,
} from "../../../redux/actions";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import SupplierOverviewChart from "./StackedColumnChart";


const AverageScoreSupplierIndustry = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const scopeByYear = useSelector(
    (state) => state.dashboard.averageScoreSupplierIndustry.data
  );
console.log("scope by year",scopeByYear);
  useEffect(() => {
    !props.refreshContent && dispatch(averageScoreSupplierIndustry(props.filter));
  }, [dispatch, props.filter]);

  const handleClick = () => {
    navigate('/dashboard/details/risk-assessment', { state: { filter: props.filter} })
  }

  return (
    <Paper className={classes.container} onClick={handleClick}>
      <>
        {Object.keys(scopeByYear)?.length > 0 ? (
          <div>
            <SupplierOverviewChart
              scopeByYear={scopeByYear}
              filter={props.filter}
            />
          </div>
        ) : (
          <div style={{ minHeight: 250 }}>No Data Available</div>
        )}
      </>
    </Paper>
  );
};

export default AverageScoreSupplierIndustry;
