import { Typography } from "@mui/material";
import React from "react";

const ForestLandTable = ({ forestLandTable, setForestLandTable }) => {
  const handleInputChange = (rowIndex, field, value) => {
    const updatedData = [...forestLandTable];
    updatedData[rowIndex][field] = value;
    setForestLandTable(updatedData);
  };

  // const addRow = () => {
  //   setForestLandTable([
  //     ...forestLandTable,
  //     {
  //       areaType: "",
  //       areaHectares: "",
  //       managementStatus: "",
  //       certifiedStatus: "",
  //       certificationType: "",
  //     },
  //   ]);
  // };

  // const removeRow = () => {
  //   if (forestLandTable.length > 2) {
  //     setForestLandTable(forestLandTable.slice(0, -1));
  //   }
  // };

  return (
    <div>
      {/* Table Container */}
      <div style={{ marginTop: "20px", border: "1px solid #000", borderRadius: "8px", overflow: "hidden", width: "100%" }}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr style={{ backgroundColor: "#D3C9CE", color: "#000", fontWeight: "bold", textAlign: "left" }}>
              <th style={tableHeaderStyle}>Area Type</th>
              <th style={tableHeaderStyle}>Area (hectares)</th>
              <th style={tableHeaderStyle}>Management Status (Managed/Unmanaged)</th>
              <th style={tableHeaderStyle}>Certified Status (Yes/No)</th>
              <th style={tableHeaderStyle}>Certification Type</th>
            </tr>
          </thead>
          <tbody>
            {forestLandTable.map((row, index) => (
              <tr key={index}>
                <td style={tableCellStyle}>
                  <Typography
                  >
                    {row.areaType}
                  </Typography>
                </td>
                {/* {["areaType", "areaHectares", "managementStatus", "certifiedStatus", "certificationType"].map((field) => ( */}
                {[ "areaHectares", "managementStatus", "certifiedStatus", "certificationType"].map((field) => (
                  <td key={field} style={tableCellStyle}>
                    <input
                      type="text"
                      value={row[field]}
                      onChange={(e) => handleInputChange(index, field, e.target.value)}
                      style={inputFieldStyle}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      
      {/* <div style={{ display: "flex", justifyContent: "center", marginTop: "10px", gap: "10px" }}>
        <button onClick={removeRow} style={removeButtonStyle}>- Remove</button>
        <button onClick={addRow} style={addButtonStyle}>+ Add More</button>
      </div> */}
    </div>
  );
};

const tableHeaderStyle = {
  padding: "10px",
  border: "1px solid #000",
  textAlign: "center",
};

const tableCellStyle = {
  padding: "10px",
  border: "1px solid #000",
  textAlign: "center",
};

const inputFieldStyle = {
  width: "100%",
  padding: "6px",
  borderRadius: "4px",
  border: "1px solid grey",
  textAlign: "center",
  fontSize: "14px",
  backgroundColor: "white",
  outline: "none",
};

const addButtonStyle = {
  backgroundColor: "white",
  border: "1px solid lightgrey", // Thin light grey border
  color: "grey", // Grey text color
  borderRadius: "4px",
  padding: "8px 12px",
  cursor: "pointer",
};

const removeButtonStyle = {
  backgroundColor: "white",
  border: "1px solid lightgrey", // Thin light grey border
  color: "grey", // Grey text color
  borderRadius: "4px",
  padding: "8px 12px",
  cursor: "pointer",
};

export default ForestLandTable;
