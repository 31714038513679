import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import useStyles from "./styles";

const SupplierOverview = ({ scopeByYear, filter }) => {
  const classes = useStyles();

  console.log("API Response:", scopeByYear, filter);

  const averageScore = scopeByYear?.data?.average_score || 0;
  const totalSuppliers = scopeByYear?.data?.total_suppliers || 0;
  const performanceBreakdown = scopeByYear?.data?.performance_breakdown || [];

  const riskLevels = [
    { label: "E", range: "0-20", count: 0, color: "#D32F2F" },
    { label: "D", range: "21-40", count: 0, color: "#FF9800" },
    { label: "C", range: "41-60", count: 0, color: "#F4C145" },
    { label: "B", range: "61-80", count: 0, color: "#81C784" },
    { label: "A", range: "81-100", count: 0, color: "#4CAF50" },
  ];


  const updatedRiskLevels = riskLevels.map((risk) => {
    const match = performanceBreakdown.find((item) => item.category === risk.label);
    return match ? { ...risk, count: match.count } : risk;
  });

  return (
    <>
      {/* Title */}
      <Typography className={classes.graphTitle}>Supplier Overview</Typography>

      {/* Content Box with Border */}
      <Box
        sx={{
          border: "1px solid #E0E0E0",
          borderRadius: "12px",
          padding: "16px",
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Grid container spacing={0} alignItems="center">
          {/* Left Section: Supplier Rating */}
          <Grid item xs={5} sx={{ paddingRight: "16px" }}>
            <Typography sx={{ fontSize: "16px", fontWeight: 600, marginTop: "-17px" }}>
              Overall supplier average rating
            </Typography>

            <Box sx={{ display: "flex", alignItems: "center", marginTop: "4px", marginBottom: "12px" }}>
              {/* Circular Rating Badge */}
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "32px",
                  height: "32px",
                  borderRadius: "50%",
                  border: "2px solid #F4C145",
                  color: "#F4C145",
                  fontSize: "20px",
                  fontWeight: "800",
                  textAlign: "center",
                  marginRight: "6px",
                }}
              >
                C
              </Typography>
              {/* Average Score */}
              <Typography sx={{ fontSize: "31px", fontWeight: 700, color: "#F4C145" }}>
                {Math.round(averageScore)}
              </Typography>
              <Typography sx={{ fontSize: "18px", color: "#A1A1A1", marginLeft: "12px", marginTop: "9px" }}>
                /100
              </Typography>
            </Box>

            {/* Number of Monitored Suppliers */}
            <Typography sx={{ fontSize: "16px", fontWeight: 600, color: "#3A3A3A", marginTop: "30px" }}>
              Number of monitored suppliers
            </Typography>
            <Typography sx={{ fontSize: "30px", fontWeight: 700, color: "black" }}>
              {totalSuppliers}
            </Typography>
          </Grid>

          {/* Vertical Divider */}
          <Box
            sx={{
              position: "absolute",
              left: "42%",
              top: "0%",
              bottom: "0%",
              width: "1px",
              backgroundColor: "#E0E0E0",
            }}
          ></Box>

          {/* Right Section: Supplier Performance Breakdown */}
          <Grid item xs={6} sx={{ paddingLeft: "16px", marginLeft: "auto" }}>
            <Typography sx={{ fontSize: "16px", fontWeight: 600, marginBottom: "8px" }}>
              Supplier Performance Breakdown
            </Typography>

            <Grid container spacing={1} alignItems="center">
              {/* Column for "High Risk" and "Low Risk" Labels */}
              <Grid item xs={2} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Typography sx={{ fontSize: "12px", color: "black", marginBottom: "40px", marginLeft: "-85px" }}>
                  High Risk
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "black",
                    transform: "rotate(-90deg)",
                    paddingTop: "30px",
                    whiteSpace: "nowrap",
                    margin: "auto",
                    marginLeft: "-65px",
                  }}
                >
                  Risk Levels
                </Typography>
                <Typography sx={{ fontSize: "12px", color: "black", marginTop: "40px", whiteSpace: "nowrap", marginLeft: "-85px" }}>
                  Low Risk
                </Typography>
              </Grid>

              {/* Column for Progress Bars */}
              <Grid item xs={10}>
                {updatedRiskLevels.map((risk) => (
                  <Box key={risk.label} sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                    {/* Risk Label & Range */}
                    <Box sx={{ display: "flex", alignItems: "center", minWidth: "100px" }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          color: risk.color,
                          border: `1px solid ${risk.color}`,
                          padding: "2px 6px",
                          borderRadius: "50%",
                          textAlign: "center",
                          minWidth: "24px",
                        }}
                      >
                        {risk.label}
                      </Typography>
                      <Typography sx={{ fontSize: "14px", marginLeft: "8px", color: risk.color }}>
                        {risk.range}
                      </Typography>
                    </Box>

                    {/* Horizontal Progress Bar */}
                    <Box
                      sx={{
                        flex: 1,
                        backgroundColor: "#E0E0E0",
                        height: "12px",
                        borderRadius: "2px",
                        marginX: "12px",
                        position: "relative",
                        overflow: "hidden",
                        minWidth: "150px",
                        marginLeft: "-13px",
                      }}
                    >
                      <Box
                        sx={{
                          width: `${risk.count * 25}%`,
                          backgroundColor: risk.color,
                          height: "12px",
                          borderRadius: "2px",
                          transition: "width 0.3s ease",
                        }}
                      />
                    </Box>

                    {/* Supplier Count */}
                    <Typography sx={{ fontSize: "14px", fontWeight: 600, minWidth: "20px", textAlign: "right", marginLeft: "-7px" }}>
                      {risk.count}
                    </Typography>
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* Suppliers Label at Bottom Right */} 
      <Box
        sx={{
          textAlign: "right",
          fontSize: "12px",
          color: "black",
          fontWeight: 300,
          marginTop: "-31px",
          marginRight: "10px",
        }}
      >
        Suppliers
      </Box>
    </>
  );
};

export default SupplierOverview;
