export const StationaryColumns = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "fuel",
    columnId: "fuel",
    columnHeader: "Fuel",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "CO2\n(Ton)",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "CH4\n(Ton)",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "N2O\n(Ton)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e\n(Ton)",
  },
  {
    columnKey: "biofuel_co2",
    columnId: "biofuel_co2",
    columnHeader: "BioFuel CO2\n(Ton)",
  },
  {
    columnKey: "ef",
    columnId: "ef",
    columnHeader: "EF \n(kgCO2e/unit)",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Action From",
  },
];

export const StationaryApprovalsColumns = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "fuel",
    columnId: "fuel",
    columnHeader: "Fuel",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "CO2\n(Ton)",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "CH4\n(Ton)",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "N2O\n(Ton)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e\n(Ton)",
  },
  {
    columnKey: "biofuel_co2",
    columnId: "biofuel_co2",
    columnHeader: "BioFuel CO2\n(Ton)",
  },
  {
    columnKey: "ef",
    columnId: "ef",
    columnHeader: "EF \n(kgCO2e/unit)",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Approval by",
  },
];

export const TransportationColumns = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "category_name",
    columnId: "category_name",
    columnHeader: "Category",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "mode_of_transport_name",
    columnId: "mode_of_transport_name",
    columnHeader: "Mode of Transport",
  },
  {
    columnKey: "vehicle_type_name",
    columnId: "vehicle_type_name",
    columnHeader: "Vehicle type",
  },
  {
    columnKey: "activity_type_name",
    columnId: "activity_type_name",
    columnHeader: "Unit",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "CO2\n(Ton)",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "CH4\n(Ton)",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "N2O\n(Ton)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e\n(Ton)",
  },
  {
    columnKey: "biofuel_co2",
    columnId: "biofuel_co2",
    columnHeader: "BioFuel CO2\n(Ton)",
  },
  {
    columnKey: "ef",
    columnId: "ef",
    columnHeader: "EF \n(kgCO2e/unit)",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Action From",
  },
];

export const TransportationApprovalColumns = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "category_name",
    columnId: "category_name",
    columnHeader: "Category",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "mode_of_transport_name",
    columnId: "mode_of_transport_name",
    columnHeader: "Mode of Transport",
  },
  {
    columnKey: "vehicle_type_name",
    columnId: "vehicle_type_name",
    columnHeader: "Vehicle type",
  },
  {
    columnKey: "activity_type_name",
    columnId: "activity_type_name",
    columnHeader: "Unit",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "CO2\n(Ton)",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "CH4\n(Ton)",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "N2O\n(Ton)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e\n(Ton)",
  },
  {
    columnKey: "biofuel_co2",
    columnId: "biofuel_co2",
    columnHeader: "BioFuel CO2\n(Ton)",
  },
  {
    columnKey: "ef",
    columnId: "ef",
    columnHeader: "EF \n(kgCO2e/unit)",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Action From",
  },
];

export const MobileColumns = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "fuel_source",
    columnId: "fuel_source",
    columnHeader: "Fuel Source",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "activity_type",
    columnId: "activity_type",
    columnHeader: "Activity Type",
  },
  {
    columnKey: "vehicle_type",
    columnId: "vehicle_type",
    columnHeader: "Vehicle Type",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "CO2\n(Ton)",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "CH4\n(Ton)",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "N2O\n(Ton)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e\n(Ton)",
  },
  {
    columnKey: "biofuel_co2",
    columnId: "biofuel_co2",
    columnHeader: "BioFuel CO2\n(Ton)",
  },
  {
    columnKey: "ef",
    columnId: "ef",
    columnHeader: "EF \n(kgCO2e/unit)",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Action From",
  },
];

export const MobileApprovalColumns = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "fuel_source",
    columnId: "fuel_source",
    columnHeader: "Fuel Source",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "activity_type",
    columnId: "activity_type",
    columnHeader: "Activity Type",
  },
  {
    columnKey: "vehicle_type",
    columnId: "vehicle_type",
    columnHeader: "Vehicle Type",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "CO2\n(Ton)",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "CH4\n(Ton)",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "N2O\n(Ton)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e\n(Ton)",
  },
  {
    columnKey: "biofuel_co2",
    columnId: "biofuel_co2",
    columnHeader: "BioFuel CO2\n(Ton)",
  },
  {
    columnKey: "ef",
    columnId: "ef",
    columnHeader: "EF \n(kgCO2e/unit)",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Action From",
  },
];

export const PurchasedElectricityColumns = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "calculation_approach",
    columnId: "calculation_approach",
    columnHeader: "Calculation Approach",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "CO2\n(Ton)",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "CH4\n(Ton)",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "N2O\n(Ton)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e\n(Ton)",
  },
  {
    columnKey: "ef",
    columnId: "ef",
    columnHeader: "EF \n(kgCO2e/unit)",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Action From",
  },
];

export const PurchasedElectricityApprovalColumns = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "calculation_approach",
    columnId: "calculation_approach",
    columnHeader: "Calculation Approach",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "CO2\n(Ton)",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "CH4\n(Ton)",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "N2O\n(Ton)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e\n(Ton)",
  },
  {
    columnKey: "ef",
    columnId: "ef",
    columnHeader: "EF \n(kgCO2e/unit)",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Action From",
  },
];

export const WaterDischargeColumns = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "treatment_level",
    columnId: "treatment_level",
    columnHeader: "Treatment Level",
  },
  {
    columnKey: "treatment_required",
    columnId: "treatment_required",
    columnHeader: "Treatment Required",
  },
  {
    columnKey: "water_destination_name",
    columnId: "water_destination_name",
    columnHeader: "Destination Name",
  },
  {
    columnKey: "water_destination_stress_type_name",
    columnId: "water_destination_stress_type_name",
    columnHeader: "Stress Type",
  },
  {
    columnKey: "water_destination_type_name",
    columnId: "water_destination_type_name",
    columnHeader: "Destination type",
  },
  {
    columnKey: "usage",
    columnId: "usage",
    columnHeader: "Usage",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e (Ton)",
  },
  {
    columnKey: "month",
    columnId: "month",
    columnHeader: "Month",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Action From",
  },
];

export const WaterDischargeApprovalColumns = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "treatment_level",
    columnId: "treatment_level",
    columnHeader: "Treatment Level",
  },
  {
    columnKey: "treatment_required",
    columnId: "treatment_required",
    columnHeader: "Treatment Required",
  },
  {
    columnKey: "water_destination_name",
    columnId: "water_destination_name",
    columnHeader: "Destination Name",
  },
  {
    columnKey: "water_destination_stress_type_name",
    columnId: "water_destination_stress_type_name",
    columnHeader: "Stress Type",
  },
  {
    columnKey: "water_destination_type_name",
    columnId: "water_destination_type_name",
    columnHeader: "Destination type",
  },
  {
    columnKey: "usage",
    columnId: "usage",
    columnHeader: "Usage",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e (Ton)",
  },
  {
    columnKey: "month",
    columnId: "month",
    columnHeader: "Month",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Action From",
  },
];

export const WaterConsumptionColumns = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "water_source_stress_type_name",
    columnId: "water_source_stress_type_name",
    columnHeader: "Stress type",
  },
  {
    columnKey: "water_source_type_name",
    columnId: "water_source_type_name",
    columnHeader: "Source type",
  },
  {
    columnKey: "usage",
    columnId: "usage",
    columnHeader: "Usage",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e (Ton)",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Action From",
  },
];

export const WaterConsumptionApprovalColumns = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "water_source_stress_type_name",
    columnId: "water_source_stress_type_name",
    columnHeader: "Stress type",
  },
  {
    columnKey: "water_source_type_name",
    columnId: "water_source_type_name",
    columnHeader: "Source type",
  },
  {
    columnKey: "usage",
    columnId: "usage",
    columnHeader: "Usage",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e (Ton)",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Action From",
  },
];

export const RefrigerantsColumns = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "gas_type_name",
    columnId: "gas_type_name",
    columnHeader: "Gas Type",
  },
  {
    columnKey: "refrigerant_name",
    columnId: "refrigerant_name",
    columnHeader: "Refrigerant",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e (Ton)",
  },
  {
    columnKey: "month",
    columnId: "month",
    columnHeader: "Month",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Action From",
  },
];

export const RefrigerantsApprovalColumns = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "gas_type_name",
    columnId: "gas_type_name",
    columnHeader: "Gas Type",
  },
  {
    columnKey: "refrigerant_name",
    columnId: "refrigerant_name",
    columnHeader: "Refrigerant",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e (Ton)",
  },
  {
    columnKey: "month",
    columnId: "month",
    columnHeader: "Month",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Action From",
  },
];

export const WasteCombustionColumns = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "waste_type_name",
    columnId: "waste_type_name",
    columnHeader: "Waste Type",
  },

  {
    columnKey: "waste_disposal_option_name",
    columnId: "waste_disposal_option_name",
    columnHeader: "Waste disposal option",
  },

  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "usage",
    columnId: "usage",
    columnHeader: "Usage",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e (Ton)",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Action From",
  },
];

export const WasteCombustionApprovalColumns = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "waste_type_name",
    columnId: "waste_type_name",
    columnHeader: "Waste Type",
  },

  {
    columnKey: "waste_disposal_option_name",
    columnId: "waste_disposal_option_name",
    columnHeader: "Waste disposal option",
  },

  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "usage",
    columnId: "usage",
    columnHeader: "Usage",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e (Ton)",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Action From",
  },
];

export const EndOfLifeTreatmentColumns = [
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "product_name",
    columnId: "product_name",
    columnHeader: "Product",
  },
  {
    columnKey: "total_mass",
    columnId: "total_mass",
    columnHeader: "Total Mass of Sold Products & Packaging",
  },
  {
    columnKey: "percentage_treated",
    columnId: "percentage_treated",
    columnHeader: "% of total waste treated by method",
  },
  {
    columnKey: "material_name",
    columnId: "material_name",
    columnHeader: "Material",
  },
  {
    columnKey: "waste_treatment_method_name",
    columnId: "waste_treatment_method_name",
    columnHeader: "Waste treatment method",
  },
  {
    columnKey: "pending_action_role",
    columnId: "pending_action_role",
    columnHeader: "Pending Action From",
  },
];
