import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: "calc(100vh - 300px)",
    margin: theme.spacing(5, 0, 5, 0),
  },
  actionContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    backgroundColor: theme.palette.Secondary.main,
  },
  editIcon: {
    marginLeft: theme.spacing(3),
    color: theme.palette.Primary.background,
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  audited: {
    color: theme.palette.status.audited,
    fontWeight: 500,
    fontSize: 14,
  },
  approved: {
    color: theme.palette.status.approved,
    fontWeight: 500,
    fontSize: 14,
  },
  pending: {
    color: theme.palette.status.pendingApproval,
    fontWeight: 500,
    fontSize: 14,
  },
  added: {
    color: theme.palette.status.added,
    fontWeight: 500,
    fontSize: 14,
  },
  review: {
    color: theme.palette.status.review,
    fontWeight: 500,
    fontSize: 14,
  },
  awaiting: {
    color: theme.palette.status.pendingApproval,
    fontWeight: 500,
    fontSize: 14,
    maxWidth: 55,
  },
  verified: {
    color: theme.palette.status.verified,
    fontWeight: 500,
    fontSize: 14,
    maxWidth: 55,
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.contrastText,
    },
    marginRight: theme.spacing(5),
    marginLeft: theme.spacing(5),
  },
  tableTopic: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableTitle: {
    fontWeight: 700,
    fontSize: 18,
    textTransform: "capitalize",
  },
  tableSubTitle: {
    fontWeight: 500,
    fontSize: 16,
    textTransform: "capitalize",
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  signalAdded: {
    backgroundColor: theme.palette.status.added,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1),
  },
  signalApproved: {
    backgroundColor: theme.palette.status.approved,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1),
  },
  signalVerified: {
    backgroundColor: theme.palette.status.verified,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1),
  },
  signalReview: {
    backgroundColor: theme.palette.status.review,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1),
  },
  signalPending: {
    backgroundColor: theme.palette.status.pendingApproval,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1),
  },
  signalAudited: {
    backgroundColor: theme.palette.status.audited,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1),
  },
  pagination: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
    alignItems: "center",
    "& .MuiPagination-root": {
      backgroundColor: theme.palette.background.white,
      padding: theme.spacing(1),
    },
    "& .MuiButtonBase-root": {
      fontWeight: 600,
    },
    "& .MuiButtonBase-root.Mui-selected": {
      backgroundColor: theme.palette.Primary.background,
      borderRadius: 0,
    },
  },
  subHeads: {
    marginTop: "10px",
    marginBottom: "10px",
  },
}));

export default useStyles;
