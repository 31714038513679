import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    width: "100%",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
    background: theme.palette.background.white,
    fontSize: 13,
    fontWeight: 500,
    marginBottom: 20,
    borderRadius: 8,
    marginLeft: 0,
    marginRight: 0,
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  statusContainer: {
    padding: theme.spacing(5),
    width: "100%",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
    background: theme.palette.background.white,
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 20,
    position: "relative",
    borderRadius: 8,
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  infoContainer: {
    maxWidth: 400,
    display: "flex !important",
    justifyContent: "flex-start",
    padding: theme.spacing(2, 0),
  },
  title: {
    width: "200px",
  },
  value: {
    fontWeight: 500,
    whiteSpace: "nowrap",
  },
  modalContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    outline: "none",
    width: "23%",
  },
  closeButton: {
    marginTop: theme.spacing(2),
    marginLeft: "auto",
    display: "block",
  },
  fileView: {
    "& svg": {
      fill: "rgb(140 33 72)",
    },
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "center",
    // position: "absolute",
    // bottom: "5px",
    // right: "5px",
  },
  fileName: {
    color: "#1D1D1D",
    padding: "4px 0px",
    fontSize: "14px",
    fontWeight: "500",
    textTransform: "capitalize",
  },
}));

export default useStyles;
