import React from "react";
import { Container, Typography, Box } from "@mui/material";

import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const ProgressTemplate = (props) => {
  const { onCancel } = props;
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <>
      <div className={classes.backContainer}>
        <div
          className={classes.backButtonDiv}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon />
          Back
        </div>
        <div className={classes.templateButtonDiv}></div>
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            We are working on this feature. Will update soon!!!
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default ProgressTemplate;
