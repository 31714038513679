import { createActions } from "redux-actions";

import { ActionTypes } from "../constants/actions";

export const {
  getEmissionList,
  clearEmissionList,
  getEmission,
  addStationaryCombustion,
  addMobileCombustion,
  addEmployeeCommuting,
  resetEmployeeCommutingStatus,
  addTransportationCombustion,
  editTransportationCombustion,
  addWaterDischargeCombustion,
  addWaterConsumptionCombustion,
  addWasteCombustion,
  resetAddCombustionStatus,
  getEmissionFuelList,
  getMobileCombustionInputs,
  getEmissionInputFormat,
  addPurchasedElectricity,
  updatePurchasedElectricity,
  updateStationaryCombustion,
  updateMobileCombustion,
  updateWaterDischargeCombustion,
  updateWaterConsumptionCombustion,
  updateWasteCombustion,
  deleteEmissions,
  addRefrigerants,
  updateUpstreamLeasedAsset,
  updateFranchise,
  updateDownstreamLeasedAsset,
  updateRefrigerants,
  listEmissionAuditTrails,
  clearListEmissionAuditTrails,
  listEmissionFiles,
  clearListEmissionFiles,
  uploadEmissionAttachement,
  clearUploadEmissionAttachement,
  deleteEmissionAttachement,
  clearDeleteEmissionAttachement,
  addDevelopmentTrainingDetails,
  addEmployeeHealthDetails,
  addWorkerSafetyTraining,
  addDescriminationIncidentRecord,
  addSupplierHumanRightsTraining,
  addSocialHumanRightsTraining,
  addSupplierScreening,
  addLocalCommunities,
  addPoliticalContribution,
  addAntiCorruptionDisclosure,
  addAntiCorruptionTraining,
  addAntiCompetitiveDisclosure,
  addSubsidiesFinancialAssistance,
  addBoardDiversity,
  addManagementDiversity,
  addUploadFileEmission,
  getNonEmissionDetails,
  clearGetNonEmissionDetails,
  updateNonEmissionDetails,
  updateDevelopmentTrainingDetails,
  updateWorkerSafetyTraining,
  updateEmployeeTurnover,
  updateAgeBasedStatistics,
  updateGenderBasedStatistics,
  updateDiscriminationIncidentRecord,
  updateSupplierScreening,
  updateSupplierHumanRightsTraining,
  updateSocialHumanRightsTraining,
  updateLocalCommunities,
  updatePoliticalContributions,
  updateEmployeeHealthDetails,
  updateAntiCorruptionDisclosure,
  updateAntiCorruptionTraining,
  updateAntiCompetitiveDisclosure,
  updateSubsidiesFinancialAssistance,
  getAllEmissions,
  resetGetAllEmissionStatus,
  getUploadHistory,
  getUploadHistoryDetails,
  addEmployeeHire,
  addEmployeeBenefit,
  addEmployeeTrainingHours,
  updateEmployeeTrainingHours,
  addPerformanceAndCareerDevelopmentPrograms,
  updatePerformanceAndCareerDevelopmentPrograms,
  addEmployeeDiversity,
  resetGetEmissionInputFormatStatus,
  addEconomicImpact,
  updateEconomicImpact,
  updateEmployeeDiversity,
  updateBoardDiversity,
  updateManagementDiversity,
  resetAddBoardDiversityStatus,
  resetAddManagementDiversityStatus,
  clearUpdateBoardDiversityStatus,
  clearUpdateManagementDiversityStatus,
  resetEmployeeTrainingHours,
  resetPerformanceAndCareerDevelopmentPrograms,
  updateEmployeeBenefits,
  updateEmployeeHire,
  resetEmissionDelete,
  getStandardsQuestionnaire,
  addUpstreamTAndD,
  addDownstreamTAndD,
  addBusinessTravel,
  addEmployeeCommute,
  updateUpstreamTAndD,
  updateUseOfSoldProducts,
  updateBusinessTravel,
  updateEmployeeCommute,
  addPurchasedGoodsAndServices,
  updatePurchasedGoodsAndServices,
  updateDownstreamTAndD,
  addFuelAndEnergyRelatedActivities,
  addCapitalGoods,
  addFranchise,
  updateCapitalGoods,
  updateFuelAndEnergyRelatedActivities,
  addUseOfSoldProducts,
  addUpstreamLeasedAsset,
  addDownstreamLeasedAsset,
  addEndOfLifeTreatmentOfSoldProducts,
  updateEndOfLifeTreatmentOfSoldProducts,
  addProcessingOfSoldProducts,
  updateProcessingOfSoldProducts,
} = createActions({
  [ActionTypes.GET_EMISSION_LIST]: (
    emissionType,
    filter,
    isAuditor,
    company,
    facilities,
    period,
    country,
    isStakeholder
  ) => ({
    emissionType,
    filter,
    isAuditor,
    isStakeholder,
    company,
    facilities,
    period,
    country,
  }),
  [ActionTypes.CLEAR_EMISSION_LIST]: () => ({}),
  [ActionTypes.GET_EMISSION]: (params) => params,
  [ActionTypes.ADD_STATIONARY_COMBUSTION]: (requestData) => ({ requestData }),
  [ActionTypes.ADD_MOBILE_COMBUSTION]: (requestData) => ({ requestData }),
  [ActionTypes.ADD_EMPLOYEE_COMMUTING]: (requestData) => ({ requestData }),
  [ActionTypes.ADD_TRANSPORTATION_COMBUSTION]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.EDIT_TRANSPORTATION_COMBUSTION]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.ADD_WATER_DISCHARGE_COMBUSTION]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.ADD_WATER_CONSUMPTION_COMBUSTION]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.ADD_WASTE_COMBUSTION]: (requestData) => ({ requestData }),
  [ActionTypes.RESET_ADD_COMBUSTION_STATUS]: () => ({}),
  [ActionTypes.GET_EMISSION_FUEL_LIST]: (emissionType) => ({ emissionType }),
  [ActionTypes.GET_MOBILE_COMBUSTION_INPUTS]: (emissionType) => ({
    emissionType,
  }),
  [ActionTypes.GET_EMISSION_INPUT_FORMAT]: (
    emissionType,
    assessment,
    year,
    facility
  ) => ({ emissionType, assessment, year, facility }),
  [ActionTypes.ADD_PURCHASED_ELECTRICITY]: (requestData) => ({ requestData }),
  [ActionTypes.UPDATE_PURCHASED_ELECTRICITY]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.UPDATE_STATIONARY_COMBUSTION]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.UPDATE_MOBILE_COMBUSTION]: (requestData) => ({ requestData }),
  [ActionTypes.UPDATE_WATER_DISCHARGE_COMBUSTION]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.UPDATE_WATER_CONSUMPTION_COMBUSTION]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.UPDATE_WASTE_COMBUSTION]: (requestData) => ({ requestData }),
  [ActionTypes.DELETE_EMISSIONS]: (requestData) => ({ requestData }),
  [ActionTypes.ADD_REFRIGERANTS]: (requestData) => ({ requestData }),
  [ActionTypes.UPDATE_REFRIGERANTS]: (requestData) => ({ requestData }),
  [ActionTypes.UPDATE_UPSTREAM_LEASED_ASSET]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.UPDATE_DOWNSTREAM_LEASED_ASSET]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.UPDATE_FRANCHISE]: (requestData) => ({
    requestData,
  }), 
  [ActionTypes.LIST_EMISSION_AUDIT_TRAILS]: (payload) => payload,
  [ActionTypes.CLEAR_LIST_EMISSION_AUDIT_TRAILS]: () => ({}),
  [ActionTypes.LIST_EMISSION_FILES]: (payload) => payload,
  [ActionTypes.CLEAR_LIST_EMISSION_FILES]: () => ({}),
  [ActionTypes.UPLOAD_EMISSION_ATTACHEMENT]: (emissionId, file) => ({
    emissionId,
    file,
  }),
  [ActionTypes.CLEAR_UPLOAD_EMISSION_ATTACHEMENT]: () => ({}),
  [ActionTypes.DELETE_EMISSION_ATTACHEMENT]: (emissionId, attachementId) => ({
    emissionId,
    attachementId,
  }),
  [ActionTypes.CLEAR_DELETE_EMISSION_ATTACHEMENT]: () => ({}),
  [ActionTypes.ADD_DEVELOPMENT_TRAINING_DETAILS]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.ADD_EMPLOYEE_HEALTH_DETAILS]: (requestData) => ({ requestData }),
  [ActionTypes.ADD_WORKER_SAFETY_TRAINING]: (requestData) => ({ requestData }),
  [ActionTypes.ADD_DESCRIMINATION_INCIDENT_RECORD]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.ADD_SUPPLIER_HUMAN_RIGHTS_TRAINING]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.ADD_SOCIAL_HUMAN_RIGHTS_TRAINING]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.ADD_SUPPLIER_SCREENING]: (requestData) => ({ requestData }),
  [ActionTypes.ADD_LOCAL_COMMUNITIES]: (requestData) => ({ requestData }),
  [ActionTypes.ADD_POLITICAL_CONTRIBUTION]: (requestData) => ({ requestData }),
  [ActionTypes.ADD_ANTI_CORRUPTION_DISCLOSURE]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.ADD_ANTI_CORRUPTION_TRAINING]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.ADD_ANTI_COMPETITIVE_DISCLOSURE]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.ADD_SUBSIDIES_FINANCIAL_ASSISTANCE]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.ADD_BOARD_DIVERSITY]: (requestData) => ({ requestData }),
  [ActionTypes.ADD_MANAGEMENT_DIVERSITY]: (requestData) => ({ requestData }),
  [ActionTypes.ADD_UPLOAD_FILE_EMISSION]: (requestData) => ({ requestData }),
  [ActionTypes.GET_NON_EMISSION_DETAILS]: (requestData) => ({ requestData }),
  [ActionTypes.CLEAR_GET_NON_EMISSION_DETAILS]: () => ({}),
  [ActionTypes.UPDATE_NON_EMISSION_DETAILS]: (requestData) => ({ requestData }),
  [ActionTypes.UPDATE_DEVELOPMENT_TRAINING_DETAILS]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.UPDATE_WORKER_SAFETY_TRAINING]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.UPDATE_EMPLOYEE_TURNOVER]: (requestData) => ({ requestData }),
  [ActionTypes.UPDATE_AGE_BASED_STATISTICS]: (requestData) => ({ requestData }),
  [ActionTypes.UPDATE_GENDER_BASED_STATISTICS]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.UPDATE_DISCRIMINATION_INCIDENT_RECORD]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.UPDATE_SUPPLIER_SCREENING]: (requestData) => ({ requestData }),
  [ActionTypes.UPDATE_SUPPLIER_HUMAN_RIGHTS_TRAINING]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.UPDATE_SOCIAL_HUMAN_RIGHTS_TRAINING]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.UPDATE_LOCAL_COMMUNITIES]: (requestData) => ({ requestData }),
  [ActionTypes.UPDATE_POLITICAL_CONTRIBUTIONS]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.UPDATE_EMPLOYEE_HEALTH_DETAILS]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.UPDATE_ANTI_CORRUPTION_DISCLOSURE]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.UPDATE_ANTI_CORRUPTION_TRAINING]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.UPDATE_ANTI_COMPETITIVE_DISCLOSURE]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.UPDATE_SUBSIDIES_FINANCIAL_ASSISTANCE]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.UPLOAD_EMISSION]: (requestData) => ({ requestData }),
  [ActionTypes.GET_ALL_EMISSIONS]: (requestData) => ({ requestData }),
  [ActionTypes.RESET_GET_ALL_EMISSION_STATUS]: () => ({}),
  [ActionTypes.GET_UPLOAD_HISTORY]: (emissionType, filter) => ({
    emissionType,
    filter,
  }),
  [ActionTypes.GET_UPLOAD_HISTORY_DETAILS]: (emissionType, blob_url) => ({
    emissionType,
    blob_url,
  }),
  [ActionTypes.ADD_EMPLOYEE_HIRE]: (requestData) => ({ requestData }),
  [ActionTypes.ADD_EMPLOYEE_BENEFIT]: (requestData) => ({ requestData }),
  [ActionTypes.ADD_EMPLOYEE_TRAINING_HOURS]: (requestData) => ({ requestData }),
  [ActionTypes.UPDATE_EMPLOYEE_TRAINING_HOURS]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.ADD_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS]: (
    requestData
  ) => ({ requestData }),
  [ActionTypes.UPDATE_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS]: (
    requestData
  ) => ({ requestData }),
  [ActionTypes.ADD_EMPLOYEE_DIVERSITY]: (requestData) => ({ requestData }),
  [ActionTypes.RESET_GET_EMISSION_INPUT_FORMAT_STATUS]: () => ({}),
  [ActionTypes.RESET_EMPLOYEE_COMMUTING_STATUS]: () => ({}),
  [ActionTypes.ADD_ECONOMIC_IMPACT]: (requestData) => ({ requestData }),
  [ActionTypes.UPDATE_ECONOMIC_IMPACT]: (requestData) => ({ requestData }),
  [ActionTypes.UPDATE_EMPLOYEE_DIVERSITY]: (requestData) => ({ requestData }),
  [ActionTypes.UPDATE_BOARD_DIVERSITY]: (requestData) => ({ requestData }),
  [ActionTypes.UPDATE_MANAGEMENT_DIVERSITY]: (requestData) => ({ requestData }),
  [ActionTypes.RESET_ADD_BOARD_DIVERSITY_STATUS]: () => ({}),
  [ActionTypes.RESET_ADD_MANAGEMENT_DIVERSITY_STATUS]: () => ({}),
  [ActionTypes.CLEAR_UPDATE_BOARD_DIVERSITY_STATUS]: () => ({}),
  [ActionTypes.CLEAR_UPDATE_MANAGEMENT_DIVERSITY_STATUS]: () => ({}),
  [ActionTypes.RESET_EMPLOYEE_TRAINING_HOURS]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.RESET_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS]: (
    requestData
  ) => ({ requestData }),
  [ActionTypes.UPDATE_EMPLOYEE_BENEFITS]: (requestData) => ({ requestData }),
  [ActionTypes.UPDATE_EMPLOYEE_HIRE]: (requestData) => ({ requestData }),
  [ActionTypes.RESET_EMISSION_DELETE]: () => ({}),
  [ActionTypes.GET_STANDARDS_QUESTIONNAIRE]: (id, assessment_id) => ({
    id,
    assessment_id,
  }),
  [ActionTypes.ADD_UPSTREAM_T_AND_D]: (requestData) => ({ requestData }),
  [ActionTypes.ADD_DOWNSTREAM_T_AND_D]: (requestData) => ({ requestData }),
  [ActionTypes.ADD_BUSINESS_TRAVEL]: (requestData) => ({ requestData }),
  [ActionTypes.ADD_EMPLOYEE_COMMUTE]: (requestData) => ({ requestData }),
  [ActionTypes.UPDATE_UPSTREAM_T_AND_D]: (requestData) => ({ requestData }),
  [ActionTypes.UPDATE_USE_OF_SOLD_PRODUCTS]: (requestData) => ({ requestData }),
  [ActionTypes.UPDATE_BUSINESS_TRAVEL]: (requestData) => ({ requestData }),
  [ActionTypes.UPDATE_EMPLOYEE_COMMUTE]: (requestData) => ({ requestData }),
  [ActionTypes.UPDATE_BUSINESS_TRAVEL]: (requestData) => ({ requestData }),
  [ActionTypes.ADD_PURCHASED_GOODS_AND_SERVICES]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.UPDATE_PURCHASED_GOODS_AND_SERVICES]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.UPDATE_BUSINESS_TRAVEL]: (requestData) => ({ requestData }),
  [ActionTypes.UPDATE_DOWNSTREAM_T_AND_D]: (requestData) => ({ requestData }),
  [ActionTypes.ADD_FUEL_AND_ENERGY_RELATED_ACTIVITIES]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.ADD_CAPITAL_GOODS]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.UPDATE_CAPITAL_GOODS]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.ADD_FRANCHISE]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.UPDATE_FUEL_AND_ENERGY_RELATED_ACTIVITIES]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.ADD_USE_OF_SOLD_PRODUCTS]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.ADD_UPSTREAM_LEASED_ASSET]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.UPDATE_UPSTREAM_LEASED_ASSET]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.ADD_DOWNSTREAM_LEASED_ASSET]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.ADD_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.UPDATE_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS]: (
    requestData
  ) => ({
    requestData,
  }),
  [ActionTypes.ADD_PROCESSING_OF_SOLD_PRODUCTS]: (requestData) => ({
    requestData,
  }),
  [ActionTypes.UPDATE_PROCESSING_OF_SOLD_PRODUCTS]: (requestData) => ({
    requestData,
  }),
});
