import React, { useEffect } from "react";
import useStyles from "./styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Select,
} from "@mui/material";
import { useSelector } from "react-redux";
import { getCookie } from "../../../../services/cookie";
import CeroPhoneInput from "../../../../components/CeroPhoneInput";
import StrikesTable from "../RiskTable/StrikesTable";
import FuelEconomyTable from "../RiskTable/FuelEconomyTables";
import CorruptionTable from "../RiskTable/CorruptionTable";
import ForestLandTable from "../RiskTable/forestLandTable";
import WorkforceTable from "../RiskTable/workforceTable";
// import EngineeringAndConstructionTable from "../RiskTable/EngineeringAndConstruction";


function SasbQuestionnaire({
  handleCheckboxChange,
  handleSingleSelect,
  handleTextInputChange,
  textAnswers,
  selectedValues,
  SetSelectedValues,
  forestLandTable,
  setForestLandTable,
  // engineeringAndConstructionTable,
  // setEngineeringAndConstructionTable,
  corruptionTable,
  setCorruptionTable,
  strikesTable,
  setStrikesTable,
  workforceTable,
  setWorkforceTable,
  lowEmissionTable,
  setLowEmissionTable,
  fuelEconomyTable,
  setFuelEconomyTable,
  handleDateInputChange,
  dateAnswers,
}) {
  const classes = useStyles();
  const role = getCookie("role");

  const sasbQuestions = useSelector((state) => {
    const data = state.emission.getStandardsQuestionnaire.data.questions_list;
    return Array.isArray(data) ? data : [];
  });

  const questionDict = {};
  let questionNumber = 1; // Global question counter across sections

  sasbQuestions.forEach(
    ({
      main_section,
      section,
      question,
      question_type,
      options,
      id,
      condition,
      question_id,
    }) => {
      if (!questionDict[main_section]) {
        questionDict[main_section] = {};
      }
      if (!questionDict[main_section][section]) {
        questionDict[main_section][section] = [];
      }
      questionDict[main_section][section].push({
        question,
        question_type,
        options,
        id,
        condition,
        question_id,
      });
    }
  );

  return (
    <div>
      {sasbQuestions.length > 0 && (
        <Grid mb={20}>
          <div className={classes.questionList}>
            {Object.keys(questionDict).map((section, sectionIndex) => (
              <React.Fragment key={sectionIndex}>
                <div className={classes.sectionTitle}>
                  <Typography
                    fontSize={20}
                    fontWeight={550}
                    variant="h3"
                    paddingTop="10px"
                  >
                    {section.toUpperCase()}
                  </Typography>
                </div>
                {Object.keys(questionDict[section]).map(
                  (subSection, subIndex) => (
                    <div key={subIndex}>
                      <Typography
                        fontSize={18}
                        fontWeight={500}
                        variant="h4"
                        paddingTop="10px"
                      >
                        {subSection}
                      </Typography>
                      {questionDict[section][subSection].map(
                        (question, qIndex) => {
                          // Identify if it's a sub-question
                          const isSubQuestion = question.question_id !== "None";

                          // Get the parent's answer if this is a sub-question
                          const parentAnswer = isSubQuestion
                            ? selectedValues[question.question_id]
                            : null;

                          // Check if the question should be displayed
                          const shouldRender =
                            !isSubQuestion || parentAnswer === "Yes";

                          if (!shouldRender) return null; // Skip rendering if condition is not met

                          let displayIndex = !isSubQuestion
                            ? questionNumber++
                            : ""; // Continuous numbering across sections

                          // Check if "Other (Please specify):" is selected
                          const isOtherSelected =
                            question.question_type ===
                              "multiselect_check_box" &&
                            selectedValues[question.id]?.includes(
                              "Other (Please specify):"
                            );

                          return (
                            <Grid key={qIndex} className={classes.gridItem}>
                              {/* Display question number for main questions only */}
                              {question?.question !== "" && (
                                <Typography
                                  fontSize={18}
                                  fontWeight={500}
                                  variant="h3"
                                  paddingLeft={isSubQuestion ? 6 : 4} // Indent sub-questions
                                  paddingBottom={2}
                                >
                                  {displayIndex && `${displayIndex}. `}
                                  {question?.question}
                                </Typography>
                              )}

                              {/* Answer Inputs */}
                              <Box
                                display="flex"
                                paddingLeft={6}
                                flexWrap="wrap"
                              >
                                {/* Text Fields */}
                                {[
                                  "text",
                                  "Text Field",
                                  "short_text",
                                  "input",
                                  "string",
                                  "textarea",
                                ].includes(question.question_type) && (
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Enter your answer"
                                    value={textAnswers[question.id] || ""}
                                    onChange={(e) =>
                                      handleTextInputChange(
                                        question.id,
                                        e.target.value
                                      )
                                    }
                                    className={classes.input}
                                    multiline
                                    rows={3}
                                  />
                                )}

                                {["number_text"].includes(
                                  question.question_type
                                ) && (
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Enter your answer"
                                    value={textAnswers[question.id] || ""}
                                    onChange={(e) => {
                                      const value = e.target.value.replace(
                                        /\D/g,
                                        ""
                                      );
                                      handleTextInputChange(question.id, value);
                                    }}
                                    inputProps={{
                                      inputMode: "numeric",
                                      pattern: "[0-9]*",
                                    }}
                                    className={classes.input}
                                  />
                                )}

                                {/* Dropdown Select Inputs */}
                                {/* {question?.question_type ===
                                  "choose_from_option" &&
                                  question.options.length > 0 && (
                                    <Select
                                      fullWidth
                                      value={selectedValues[question.id] || ""}
                                      onChange={(e) =>
                                        handleSingleSelect(
                                          question.id,
                                          e.target.value
                                        )
                                      }
                                      displayEmpty
                                    >
                                      <MenuItem value="" disabled>
                                        Select an option
                                      </MenuItem>
                                      {question.options.map((option, idx) => (
                                        <MenuItem key={idx} value={option}>
                                          {option}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  )} */}
                                {(question?.question_type ===
                                  "choose_from_option" ||
                                  question?.question_type ===
                                    "Choose from Options") &&
                                  question?.options?.map((option, idx) => (
                                    <>
                                      <FormControlLabel
                                        key={idx}
                                        control={
                                          <Checkbox
                                            className={classes.selectCheckbox}
                                            checked={
                                              selectedValues &&
                                              selectedValues[question?.id] ===
                                                option
                                            }
                                            onChange={() =>
                                              handleSingleSelect(
                                                question.id,
                                                option
                                              )
                                            }
                                            value={option}
                                            sx={{
                                              color: "#5A404E !important",
                                              borderWidth: "none",
                                              "&.Mui-checked": {
                                                color: "#8C2148 !important",
                                              },
                                            }}
                                          />
                                        }
                                        label={option}
                                      />
                                      {selectedValues[question.id] ===
                                        "Other (Please specify)" &&
                                        option === "Other (Please specify)" && (
                                          <TextField
                                            variant="outlined"
                                            placeholder="Please type your Answer"
                                            multiline
                                            rows={3}
                                            value={
                                              textAnswers[
                                                `other-${question.id}`
                                              ] || ""
                                            }
                                            onChange={(e) =>
                                              handleTextInputChange(
                                                `other-${question.id}`,
                                                e.target.value
                                              )
                                            }
                                            className={classes.input}
                                          />
                                        )}
                                    </>
                                  ))}

                                {/* Multiselect checkboxes */}
                                {question.question_type ===
                                  "multiselect_check_box" && (
                                  <div>
                                    {/* Render options in a grid */}
                                    <div
                                      style={{
                                        display: "grid",
                                        gridTemplateColumns: `repeat(${
                                          question.options.some(
                                            (option) =>
                                              option.split(" ").length > 6
                                          )
                                            ? 2
                                            : 4
                                        }, 1fr)`,
                                        gap: "16px",
                                        alignItems: "left",
                                        position: "relative",
                                        textAlign: "left",
                                      }}
                                    >
                                      {question.options.map((option, idx) => (
                                        <FormControlLabel
                                          key={idx}
                                          control={
                                            <Checkbox
                                              className={classes.selectCheckbox}
                                              checked={
                                                selectedValues[
                                                  question.id
                                                ]?.includes(option) || false
                                              }
                                              onChange={(e) =>
                                                handleCheckboxChange(
                                                  e,
                                                  question.id
                                                )
                                              }
                                              value={option}
                                              sx={{
                                                color: "#5A404E !important",
                                                "&.Mui-checked": {
                                                  color: "#8C2148 !important",
                                                },
                                                marginRight: "8px",
                                              }}
                                            />
                                          }
                                          label={option}
                                        />
                                      ))}
                                    </div>

                                    {/* Render "Other (Please specify):" text field in a new row */}
                                    {question.options.includes(
                                      "Other (Please specify):"
                                    ) &&
                                      selectedValues[question.id]?.includes(
                                        "Other (Please specify):"
                                      ) && (
                                        <div
                                          style={{
                                            marginTop: "16px",
                                            width: "100%",
                                          }}
                                        >
                                          <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Please specify"
                                            value={
                                              textAnswers[
                                                `other-${question.id}`
                                              ] || ""
                                            }
                                            onChange={(e) =>
                                              handleTextInputChange(
                                                `other-${question.id}`,
                                                e.target.value
                                              )
                                            }
                                            className={classes.input}
                                            multiline
                                            rows={3}
                                          />
                                        </div>
                                      )}
                                  </div>
                                )}
                                {/* Conditional Table Rendering */}
                                {question.question_type === "table" && (
                                  <Grid width={"100%"} key={question.id}>
                                    {question.instructions?.map(
                                      (instruction, index) => (
                                        <Typography
                                          paddingLeft={6}
                                          paddingY={1}
                                          key={index}
                                        >
                                          {index + 1}. {instruction}
                                        </Typography>
                                      )
                                    )}

                                    {/* Table Rendering */}
                                    {question.condition ===
                                    "table_sasb_aerospace_&_defence_1" ? (
                                      <CorruptionTable
                                        corruptionTable={corruptionTable}
                                        setCorruptionTable={setCorruptionTable}
                                      />
                                    ) : question.condition ===
                                      "table_sasb_forestry_management_1" ? (
                                      <ForestLandTable
                                        forestLandTable={forestLandTable}
                                        setForestLandTable={setForestLandTable}
                                      />
                                    ) : question.condition ===
                                      "table_sasb_airlines_1" ? (
                                      <StrikesTable
                                        strikesTable={strikesTable}
                                        setStrikesTable={setStrikesTable}
                                      />
                                    ) : question.condition ===
                                    "table_sasb_engineering_&_construction_service_1" ? (
                                    <WorkforceTable
                                    workforceTable={workforceTable}
                                    setWorkforceTable={setWorkforceTable}
                                    />
                                    // ) : question.condition ===
                                    // "table_sasb_engineering_&_construction_service_1" ? (
                                    // <EngineeringAndConstructionTable
                                    // engineeringAndConstructionTable={engineeringAndConstructionTable}
                                    // setEngineeringAndConstructionTable={setEngineeringAndConstructionTable}
                                    // />
                                    ) : (
                                      <Typography color="error">
                                        No Matching Table Found
                                      </Typography>
                                    )}
                                  </Grid>
                                )}
                              </Box>
                            </Grid>
                          );
                        }
                      )}
                    </div>
                  )
                )}
              </React.Fragment>
            ))}
          </div>
        </Grid>
      )}
    </div>
  );
}

export default SasbQuestionnaire;
