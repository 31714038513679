import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Chip,
  Typography,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  BusinessTravelDistance,
  BusinessTravelFuel,
  BusinessTravelSpend,
  EmployeeCommuteFuel,
  EmployeeCommuteAverage,
  EmployeeCommuteDistance,
  PurchasedGoodsAverageData,
  PurchasedGoodsSpend,
  PurchasedGoodsSupplierSpecific,
  CapitalGoodsAverage,
  CapitalGoodsSpend,
  CapitalGoodsSupplier,
  UpstreamDistributionAverage,
  UpstreamDistributionSite,
  UpstreamTransportationDistance,
  UpstreamTransportationFuel,
  UpstreamTransportationRefrigerant,
  UpstreamTransportationSpend,
  DirectUsephaseFuels,
  DirectUsephaseGreenhouse,
  DirectUsephaseProducts,
  IndirectUsephaseCalculation,
  IndirectUseOfEmission,
  FuelEnergyPurchasedFules,
  FuelEnergyPurchasedElectricity,
  FuelEnergyTandDLosses,
  FuelEnergyPurchasedAndSold,
  UpstreamLeasedAsset,
  UpstreamLeasedAverage1,
  UpstreamLeasedAverage2,
  Franchisespecific,
  FranchiseAverage1,
  FranchiseAverage2,
  ProcessingOfSoldProductsAverage,
  ProcessingOfSoldProductsSite,
} from "../EmissionTable/TableColumns";
import CeroTable from "../../../components/CeroTable";
import useStyles from "./styles";
import { STATUS } from "../../../redux/constants";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { deleteEmissions, resetEmissionDelete } from "../../../redux/actions";
import EmissionDeletePrompt from "../../EmissionDeletePrompt";
import { getCookie } from "../../../services/cookie";

const Scope3Table = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    emissionType,
    emissionData,
    onLoadMore,
    dataStatus,
    isAuditor,
    company,
    editOff,
    reportTable,
    isOnlyAdmin,
    isDeleteActionRequired,
  } = props;
  const currentPath = window.location.pathname;
  const approvalTable = currentPath.includes("approval");
  const role = useSelector((state) => state.auth.role);
  const readOnlyMode = getCookie("read_only_mode") === "disable_edit";
  const deleteEmissionData = useSelector(
    (state) => state.emission.deleteEmissions
  );
  const [displayWarning, setDisplayWarning] = useState(false);
  const [emissionId, setEmissionId] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const onConfirmDelete = () => {
    const requestData = {
      id: emissionId,
    };
    dispatch(deleteEmissions(requestData));
  };
  useEffect(() => {
    if (deleteEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Topic deleted successfully", {
        variant: "success",
      });
      dispatch(resetEmissionDelete());
    } else if (deleteEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetEmissionDelete());
    }
    setDisplayWarning(false);
  }, [deleteEmissionData, enqueueSnackbar, dispatch]);

  const onSelectEmissionData = (rowData) => {
    isAuditor
      ? navigate(
          `/emissions/${emissionType}/details/${rowData.id}/?company=${company}`
        )
      : navigate(`/emissions/${emissionType}/details/${rowData.id}`);
  };
  const typeMap = {
    "upstream_T&D": "upstream_t_and_d",
    "downstream_T&D": "downstream_t_and_d",
  };
  const getStatus = (status) => {
    if (status === "approved") {
      return reportTable ? (
        <Tooltip title="Approved" arrow>
          <Chip className={classes.signalApproved} />
        </Tooltip>
      ) : (
        <Typography className={classes.approved}>Approved</Typography>
      );
    } else if (status === "submitted") {
      return reportTable ? (
        <Tooltip title="Submitted" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      ) : (
        <Typography className={classes.pending}>Pending Approval</Typography>
      );
    } else if (status === "audited") {
      return reportTable ? (
        <Tooltip title="Audited" arrow>
          <Chip className={classes.signalAudited} />
        </Tooltip>
      ) : (
        <Typography className={classes.audited}>Audited</Typography>
      );
    } else if (status === "pending") {
      return reportTable ? (
        <Tooltip title="Pending Approval" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      ) : (
        <Typography className={classes.pending}>Pending Approval</Typography>
      );
    } else if (status === "added") {
      return reportTable ? (
        <Tooltip title="Added" arrow>
          <Chip className={classes.signalAdded} />
        </Tooltip>
      ) : (
        <Typography className={classes.added}>Added</Typography>
      );
    } else if (status === "assigned") {
      return reportTable ? (
        <Tooltip title="Assigned" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      ) : (
        <Typography className={classes.pending}>Pending Approval</Typography>
      );
    } else if (status === "review") {
      return reportTable ? (
        <Tooltip title="Review" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      ) : (
        <Typography className={classes.review}>Review</Typography>
      );
    } else if (status === "verified") {
      return reportTable ? (
        <Tooltip title="Verified" arrow>
          <Chip className={classes.signalVerified} />
        </Tooltip>
      ) : (
        <Typography className={classes.verified}>Verified</Typography>
      );
    } else if (status === "Awaiting approval") {
      return reportTable ? (
        <Tooltip title="Awaiting approval" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      ) : (
        <Typography className={classes.pending}>Pending Approval</Typography>
      );
    } else if (status === "pending_approval") {
      return reportTable ? (
        <Tooltip title="Pending approval" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      ) : (
        <Typography className={classes.pending}>Pending approval</Typography>
      );
    } else {
      return reportTable ? (
        <Tooltip title="" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      ) : (
        <Typography className={classes.pending}>{status}</Typography>
      );
    }
  };
  const onEditEmissionData = (e, emission) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/emissions/edit/${emissionType}/${emission}`);
  };
  const getData = (data) => {
    const isProcessingSoldProducts =
      emissionType === "processing_of_sold_products";
    return data.map((item) => {
      const mass_of_sold_product = isProcessingSoldProducts
        ? `${item.mass_of_sold_product}  ${
            item.mass_unit ? item.mass_unit : ""
          }`
        : item.mass_of_sold_product;
      const amount_electricity = isProcessingSoldProducts
        ? `${item.amount_electricity}  ${
            item.electricity_unit ? item.electricity_unit : ""
          }`
        : item.amount_electricity;
      const amount_fuel = isProcessingSoldProducts
        ? `${item.amount_fuel}  ${item.fuel_unit ? item.fuel_unit : ""}`
        : item.amount_fuel;
      const amount_refrigerants = isProcessingSoldProducts
        ? `${item.amount_refrigerants}  ${
            item.refrigerant_unit ? item.refrigerant_unit : ""
          }`
        : item.amount_refrigerants;
      return {
        ...item,
        mass_of_sold_product: mass_of_sold_product,
        amount_electricity: amount_electricity,
        amount_fuel: amount_fuel,
        amount_refrigerants: amount_refrigerants,
        status: getStatus(item.status),
        total_spent: item.total_spent + " " + item.currency,
        action: !readOnlyMode && (
          <Box className={classes.actionContainer}>
            {item.is_able_to_update === true && !editOff && isOnlyAdmin && (
              <IconButton
                className={classes.editIcon}
                onClick={(e) => onEditEmissionData(e, item.id)}
              >
                <CreateIcon />
              </IconButton>
            )}
            {isDeleteActionRequired &&
              ((item.status === "added" && role.includes("facility_manager")) ||
                (!["audited", "reviewed"].includes(item.status) &&
                  (role.includes("sustainability_manager") ||
                    role.includes("proxy_sustainability_manager")))) && (
                // ((item.status === "added" && role.includes('facility_manager')) || (!["audited", "reviewed"].includes(item.status) && (role.includes('sustainability_manager')) || role.includes('proxy_sustainability_manager'))) &&
                <IconButton
                  className={classes.editIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setEmissionId(item.id);
                    setDisplayWarning(true);
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              )}
          </Box>
        ),
      };
    });
  };
  const scope3TopicDataMapper = {
    upstream_t_and_d: {
      transportation: {
        distance_based: {
          categoryTitle: "Upstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Distance Based",
          tableColumns: UpstreamTransportationDistance,
        },
        fuel_based: {
          categoryTitle: "Upstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Fuel Based",
          tableColumns: UpstreamTransportationFuel,
        },
        refrigerant_leakage: {
          categoryTitle: "Upstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Refrigerant Leakage Based",
          tableColumns: UpstreamTransportationRefrigerant,
        },
        spend_based: {
          categoryTitle: "Upstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Spend Based",
          tableColumns: UpstreamTransportationSpend,
        },
      },
      distribution: {
        site_specific: {
          categoryTitle: "Upstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Distribution",
          subCategory: "Site Specific Based",
          tableColumns: UpstreamDistributionSite,
        },
        average_method: {
          categoryTitle: "Upstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Distribution",
          subCategory: "Average Method Based",
          tableColumns: UpstreamDistributionAverage,
        },
      },
    },
    business_travel: {
      spend_based: {
        categoryTitle: "Calculation Method:",
        category: "Spend Based",
        tableColumns: BusinessTravelSpend,
      },
      fuel_based: {
        categoryTitle: "Calculation Method: ",
        category: "Fuel Based",
        tableColumns: BusinessTravelFuel,
      },
      distance_based: {
        categoryTitle: "Calculation Method: ",
        category: "Distance Based",
        tableColumns: BusinessTravelDistance,
      },
    },

    upstream_leased_asset: {
      asset_specific: {
        categoryTitle: "Calculation Method:",
        category: "Asset specific method",
        tableColumns: UpstreamLeasedAsset,
      },
      lessor_specific: {
        categoryTitle: "Calculation Method:",
        category: "Lessor specific method",
        tableColumns: UpstreamLeasedAsset,
      },
      average_method_type_1: {
        categoryTitle: "Calculation Method:",
        category: "Average data method type 1",
        tableColumns: UpstreamLeasedAverage1,
      },
      average_method_type_2: {
        categoryTitle: "Calculation Method:",
        category: "Average data method type 2",
        tableColumns: UpstreamLeasedAverage2,
      },
    },
    downstream_leased_asset: {
      asset_specific: {
        categoryTitle: "Calculation Method:",
        category: "Asset specific method",
        tableColumns: UpstreamLeasedAsset,
      },
      lessor_specific: {
        categoryTitle: "Calculation Method:",
        category: "Lessor specific method",
        tableColumns: UpstreamLeasedAsset,
      },
      average_method_type_1: {
        categoryTitle: "Calculation Method:",
        category: "Average data method type 1",
        tableColumns: UpstreamLeasedAverage1,
      },
      average_method_type_2: {
        categoryTitle: "Calculation Method:",
        category: "Average data method type 2",
        tableColumns: UpstreamLeasedAverage2,
      },
    },

    employee_commute: {
      average_method: {
        categoryTitle: "Calculation Method: ",
        category: "average data method",
        tableColumns: EmployeeCommuteAverage,
      },
      distance_based: {
        categoryTitle: "Calculation Method: ",
        category: "distance based",
        tableColumns: EmployeeCommuteDistance,
      },
      fuel_based: {
        categoryTitle: "Calculation Method: ",
        category: "fuel based",
        tableColumns: EmployeeCommuteFuel,
      },
    },
    purchased_goods_and_services: {
      spend_based: {
        categoryTitle: "Calculation Method: ",
        category: "Spend Based",
        tableColumns: PurchasedGoodsSpend,
      },
      average_method: {
        categoryTitle: "Calculation Method: ",
        category: "Average Data Method",
        tableColumns: PurchasedGoodsAverageData,
      },
      supplier_specific: {
        categoryTitle: "Calculation Method: ",
        category: "Supplier-specific Method",
        tableColumns: PurchasedGoodsSupplierSpecific,
      },
    },
    downstream_t_and_d: {
      transportation: {
        distance_based: {
          categoryTitle: "Downstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Distance Based",
          tableColumns: UpstreamTransportationDistance,
        },
        fuel_based: {
          categoryTitle: "Downstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Fuel Based",
          tableColumns: UpstreamTransportationFuel,
        },
        refrigerant_leakage: {
          categoryTitle: "Downstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Refrigerant Leakage Based",
          tableColumns: UpstreamTransportationRefrigerant,
        },
        spend_based: {
          categoryTitle: "Downstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Spend Based",
          tableColumns: UpstreamTransportationSpend,
        },
      },
      distribution: {
        site_specific: {
          categoryTitle: "Downstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Distribution",
          subCategory: "Site Specific Based",
          tableColumns: UpstreamDistributionSite,
        },
        average_method: {
          categoryTitle: "Downstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Distribution",
          subCategory: "Average Method Based",
          tableColumns: UpstreamDistributionAverage,
        },
      },
    },
    capital_goods: {
      spend_based: {
        categoryTitle: "Calculation Method: ",
        category: "Spend Based",
        tableColumns: CapitalGoodsSpend,
      },
      supplier_specific: {
        categoryTitle: "Calculation Method: ",
        category: "Supplier specific",
        tableColumns: CapitalGoodsSupplier,
      },
      average_method: {
        categoryTitle: "Calculation Method: ",
        category: "Average method",
        tableColumns: CapitalGoodsAverage,
      },
    },

    use_of_sold_products: {
      direct_use_phase: {
        fuel_feedstock: {
          categoryTitle: "Types of emissions from sold products: ",
          subCategoryTitle: "Choose the type of direct use phase emission : ",
          category: "Direct use phase emissions",
          subCategory: "Fuels and Feedstock",
          tableColumns: DirectUsephaseFuels,
        },
        greenhouse_gas_emissions: {
          categoryTitle: "Types of emissions from sold products: ",
          subCategoryTitle: "Choose the type of direct use phase emission : ",
          category: "Direct use phase emissions",
          subCategory:
            "Greenhouse gases and products that contain or form greenhouse gases that are emitted during use",
          tableColumns: DirectUsephaseGreenhouse,
        },
        products_consume_energy: {
          categoryTitle: "Types of emissions from sold products: ",
          subCategoryTitle: "Choose the type of direct use phase emission : ",
          category: "Direct use phase emissions",
          subCategory: "Products that directly consume energy during use",
          tableColumns: DirectUsephaseProducts,
        },
      },
      indirect_use_phase: {
        sold_intermediate_products: {
          categoryTitle: "Types of emissions from sold products: ",
          subCategoryTitle: "Choose the type of indirect use phase emission : ",
          category: "Indirect use phase emissions",
          subCategory: "Calculation method for sold intermediate products",
          tableColumns: IndirectUsephaseCalculation,
        },
        indirect_use_of_emission_from_products: {
          categoryTitle: "Types of emissions from sold products: ",
          subCategoryTitle: "Choose the type of indirect use phase emission : ",
          category: "Indirect use phase emissions",
          subCategory:
            "Indirect use phase emissions from products that indirectly consume energy during use",
          tableColumns: IndirectUseOfEmission,
        },
      },
    },

    franchises: {
      franchise_specific: {
        categoryTitle: "Calculation Method:",
        category: "Franchise specific method",
        tableColumns: Franchisespecific,
      },
      average_method_type_1: {
        categoryTitle: "Calculation Method:",
        category: "Average data method type 1",
        tableColumns: FranchiseAverage1,
      },
      average_method_type_2: {
        categoryTitle: "Calculation Method:",
        category: "Average data method type 2",
        tableColumns: FranchiseAverage2,
      },
    },

    fuel_and_energy_related_activities: {
      purchased_fuel: {
        categoryTitle: "Calculation Method: ",
        category: "Upstream emissions of purchased fuels",
        tableColumns: FuelEnergyPurchasedFules,
      },
      purchased_electricity: {
        categoryTitle: "Calculation Method: ",
        category: "Upstream emissions of purchased electricity",
        tableColumns: FuelEnergyPurchasedElectricity,
      },
      transmission_distribution_loss: {
        categoryTitle: "Calculation Method: ",
        category: "Emissions from transmission and distribution losses",
        tableColumns: FuelEnergyTandDLosses,
      },
      life_cycle_emission: {
        categoryTitle: "Calculation Method: ",
        category: "Life cycle emissions from power that is purchased and sold",
        tableColumns: FuelEnergyPurchasedAndSold,
      },
    },
    processing_of_sold_products: {
      average_data: {
        categoryTitle: "Calculation Method:",
        category: "Average data method",
        tableColumns: ProcessingOfSoldProductsAverage,
      },
      site_specific: {
        categoryTitle: "Calculation Method: ",
        category: "Site Specific Method",
        tableColumns: ProcessingOfSoldProductsSite,
      },
    },
  };
  const twoTyreTopics = [
    "upstream_T&D",
    "downstream_T&D",
    "use_of_sold_products",
  ];
  const tempType = typeMap[emissionType] || emissionType;
  const hasValue = (obj) => {
    if (typeof obj !== "object" || obj === null) return false; // Ensure obj is valid

    return Object.values(obj).some((value) => {
      if (typeof value === "object" && value !== null) {
        return hasValue(value); // Recursively check nested objects
      }
      return value !== null && value !== undefined && value !== ""; // Check primitive values
    });
  };
  const NoDataFlag = hasValue(emissionData);
  console.log(NoDataFlag, scope3TopicDataMapper[tempType], "no data");

  const renderTable = (key, subKey = null) => {
    const categoryTitle = subKey
      ? scope3TopicDataMapper[tempType][key][subKey]?.categoryTitle
      : scope3TopicDataMapper[tempType][key]?.categoryTitle;

    const category = subKey
      ? scope3TopicDataMapper[tempType][key][subKey]?.category
      : scope3TopicDataMapper[tempType][key]?.category;

    const subCategoryTitle = subKey
      ? scope3TopicDataMapper[tempType][key][subKey]?.subCategoryTitle
      : null;

    const subCategory = subKey
      ? scope3TopicDataMapper[tempType][key][subKey]?.subCategory
      : null;

    return (
      <div key={subKey ? `${key}-${subKey}` : key}>
        <div className={classes.subHeads}>
          <Typography variant="h7" component="span">
            <span
              className={classes.tableSubTitle}
            >{`${categoryTitle} ${category}`}</span>
          </Typography>
          <br />
        </div>
        {subCategoryTitle && (
          <div className={classes.subHeads}>
            <Typography variant="h7" component="span">
              <span
                className={classes.tableSubTitle}
              >{`${subCategoryTitle} ${subCategory}`}</span>
            </Typography>
          </div>
        )}
        <CeroTable
          columns={
            subKey
              ? scope3TopicDataMapper[tempType][key][subKey]?.tableColumns || []
              : scope3TopicDataMapper[tempType][key]?.tableColumns || []
          }
          data={getData(
            subKey ? emissionData[key]?.[subKey] : emissionData[key]
          )}
          hasMore={false}
          loading={false}
          loadMore={onLoadMore}
          onSelectRow={approvalTable ? "#" : onSelectEmissionData}
          classes={{ tableContainer: classes.tableContainer }}
        />
      </div>
    );
  };

  const renderContent = () => {
    if (!NoDataFlag) {
      return (
        <Typography variant="h7" component="span">
          <span className={classes.tableSubTitle}>No Data</span>
        </Typography>
      );
    }
    console.log("some data exist");

    return twoTyreTopics.includes(emissionType)
      ? Object.keys(scope3TopicDataMapper[tempType]).map((key) =>
          Object.keys(scope3TopicDataMapper[tempType][key]).map((subKey) => {
            if (emissionData[key]?.[subKey]?.length > 0) {
              return renderTable(key, subKey);
            }
            return null;
          })
        )
      : Object.keys(scope3TopicDataMapper[tempType]).map((key) => {
          if (emissionData[key]?.length > 0) {
            return renderTable(key);
          }
          return null;
        });
  };

  return (
    <>
      {dataStatus === STATUS.SUCCESS ? (
        <Box>{renderContent()}</Box>
      ) : (
        <Box className={classes.loader}>
          <Typography variant="h7" component="span">
            {dataStatus === STATUS.RUNNING ? (
              <CircularProgress />
            ) : dataStatus === STATUS.ERROR ? (
              "Something went wrong. Please try again later"
            ) : (
              ""
            )}
          </Typography>
        </Box>
      )}
      {displayWarning && (
        <EmissionDeletePrompt
          isOpen={displayWarning}
          onClose={() => setDisplayWarning(false)}
          onConfirm={onConfirmDelete}
          setOpenDeletePrompt={displayWarning}
        />
      )}
    </>
  );
};

export default Scope3Table;
