import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { months, sampleYear } from "../../../constants";
import { STATUS } from "../../../redux/constants";
import { addMobileCombustionValidation } from "./schema";
import {
  addCapitalGoods,
  addMobileCombustion,
  getEmissionFuelList,
  resetAddCombustionStatus,
} from "../../../redux/actions";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroButton from "../../../components/CeroButton";
import CeroSelect from "../../../components/CeroSelect";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import DownloadIcon from "@mui/icons-material/Download";
import EmissionUploadDrawer from "../../EmissionList/EmissionUploadDrawer";
import { useNavigate } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CeroItemPair from "../../../components/CeroItemPair";
import FileUpload from "../FileUpload";

const AddCapitalGoods = (props) => {
  const { onCancel, onDownload, onUpload, onValidate, uploadDisabled } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const isCalculateDone = useSelector(
    (state) => state.emission.addCapitalGoods.isCalculateDone
  );
  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const activityTypesData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.activity_types
  );
  const fuelSourceData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.fuel_sources
  );
  const customFuelSourceData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.custom_fuel_sources
  );
  const vehicleTypeData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.vehicle_types
  );
  const fuelUnitData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.units
  );
  const addEmissionData = useSelector(
    (state) => state.emission.addCapitalGoods
  );
  const emissionPerformData = useSelector((state) => state.emission.fuelList);
  const currentYear = new Date().getFullYear().toString();
  const [selectedFile, setSelectedFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      facility: "",
      year: currentYear,
      month: "",
      calculationMethod: "",
      fuelSource: "",
      fuelUnit: "",
      amountOfFuel: "",
      modeOfTransport: "",
      vehicleType: "",
      emissionFactorDataset: "",
      distanceTravelled: "",
      distanceUnit: "",
      totalSpent: "",
      currency: "",
      numberOfEmployees: "",

      emissionFactor: "",
      quantityOfGoodsPurchased: "",
      massOfPurchasedGoods: "",
      valueOfPurchase: "",
      scope1Emission: "",
      scope2Emission: "",
      quantityOfMaterial: "",
      cradleGateEmissionfactor: "",
      distanceTransport: "",
      massofMaterial: "",
      massUnit: "",
      massOfWaste: "",
      wasteMassUnit: "",
      wasteTreatmentMethod: "",
      sector: "",
      industry: "",
      industrySubCategory: "",
      companyName: "",
    },
    validationSchema: addMobileCombustionValidation,
    onSubmit: () => {},
  });

  const selectedUnitType = activityTypesData.find(
    (item) => item.id === formik.values.activityType
  );

  // const selectedActivity = activityTypesData.find(item => item. === formik.values.activityType)
  const emittedVehicles = [
    "gasoline agricultural equipment",
    "gasoline ships and boats",
    "other gasoline non-road vehicles",
    "diesel agricultural equipment",
    "diesel ships and boats",
    "other diesel non-road vehicles",
  ];

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));
  const activityType = activityTypesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  const calculationNamesMap = {};
  const calculationInputList =
    emissionPerformData?.data?.calculation_method?.map((item) => {
      calculationNamesMap[item.id] = item.name; // Populate namesMap
      return {
        key: item.id,
        value: item.name,
      };
    }) || [];
  const fuelSourceInputList = emissionPerformData?.data?.fuel_sources?.map(
    (item) => ({
      key: item.id,
      value: item.name,
    })
  );
  const fuelUnitInputList = emissionPerformData?.data?.fuel_unit?.map(
    (item) => ({
      key: item.id,
      value: item.name,
    })
  );
  const transportModeList = emissionPerformData?.data?.transport_modes?.map(
    (item) => ({
      key: item.id,
      value: item.name,
    })
  );
  const vehicleTypeList = emissionPerformData?.data?.vehicle_types?.map(
    (item) => ({
      key: item.id,
      value: item.name,
    })
  );
  const emissionFactorDataset = (
    emissionPerformData?.data?.ef_dataset || []
  ).map((item) => ({ key: item?.id, value: item?.name }));
  const distanceUnitList = emissionPerformData?.data?.unit?.map((item) => ({
    key: item.id,
    value: item.name,
  }));
  const currencyList = emissionPerformData?.data?.currency?.map((item) => {
    return {
      key: item.name,
      value: item.name,
    };
  });
  const sectorList = emissionPerformData?.data?.sectors?.map((item) => {
    return {
      key: item.id,
      value: item.name,
    };
  });
  const industryList = formik.values.sector
    ? emissionPerformData?.data?.industries
        ?.filter((item) => item.sector_id === formik.values.sector)
        .map((item) => ({
          key: item.id,
          value: item.name,
        }))
    : [];

  const industrySubCategoryList = formik.values.industry
    ? emissionPerformData?.data?.industry_sub_sector
        ?.filter((item) => item.industry_id === formik.values.industry)
        .map((item) => ({
          key: item.id,
          value: item.name,
        }))
    : [];

  useEffect(() => {
    dispatch(getEmissionFuelList("capital_goods"));
    return () => {
      dispatch(resetAddCombustionStatus());
    };
  }, [dispatch]);

  useEffect(() => {
    if (addEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Capital goods added successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (addEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        addEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
    }
  }, [addEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onCalculate = () => {
    const requestData = {
      facility_id: formik.values.facility,
      year: formik.values.year,
      month: formik.values.month,
      fuel_source_id: formik.values.fuelSource,
      vehicle_type_id: formik.values.vehicleType,
      amount: parseFloat(formik.values.amountOfFuel),
      unit: formik.values.fuelUnit,
      calculation_method: formik.values.calculationMethod,
      quantity_of_purchased_goods: formik.values.quantityOfGoodsPurchased,
      supplier_specific_product_ef: formik.values.emissionFactor,
      sector: formik.values.sector,
      industry: formik.values.industry,
      industry_sub_category: formik.values.industrySubCategory,
      value_of_purchased_goods: formik.values.valueOfPurchase,
      vendor_company_name: formik.values.companyName,
      currency: formik.values.currency,
      mass_unit: formik.values.fuelUnit,
      mass_of_purchased_goods: formik.values.massOfPurchasedGoods,
      ef_of_purchased_goods_per_mass: formik.values.emissionFactor,
      save: false,
    };
    dispatch(addCapitalGoods(requestData));
  };

  const onAddMobileCombustionData = () => {
    const requestData = {
      facility_id: formik.values.facility,
      year: formik.values.year,
      month: formik.values.month,
      fuel_source_id: formik.values.fuelSource,
      vehicle_type_id: formik.values.vehicleType,
      amount: parseFloat(formik.values.amountOfFuel),
      unit: formik.values.fuelUnit,
      calculation_method: formik.values.calculationMethod,
      quantity_of_purchased_goods: formik.values.quantityOfGoodsPurchased,
      supplier_specific_product_ef: formik.values.emissionFactor,
      sector: formik.values.sector,
      industry: formik.values.industry,
      industry_sub_category: formik.values.industrySubCategory,
      value_of_purchased_goods: formik.values.valueOfPurchase,
      vendor_company_name: formik.values.companyName,
      currency: formik.values.currency,
      mass_unit: formik.values.fuelUnit,
      mass_of_purchased_goods: formik.values.massOfPurchasedGoods,
      ef_of_purchased_goods_per_mass: formik.values.emissionFactor,
      save: true,
      file: selectedFile,
    };
    sessionStorage.setItem("year", requestData.year);
    dispatch(addCapitalGoods(requestData));
  };
  const averageUnitInputList = emissionPerformData?.data?.mass_unit?.map(
    (item) => ({
      key: item.name,
      value: item.name,
    })
  );

  const [isOpenEmissionTypeUpload, setIsOpenEmissionTypeUpload] =
    useState(false);
  const onCloseDrawerUpload = () => {
    setIsOpenEmissionTypeUpload(false);
  };
  const handleFileUpload = (file) => {
    setSelectedFile(file);
    // dispatch(uploadEmissionAttachement("emissionId", file));
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
  };
  const info = () => {
    return (
      <div>
        <Typography variant="body2">
          Fuel use: Mobile combustion by the usage of fuel for mobile operations
          such as transport.
          <br />
          Distance activity: Distance travelled by the usage of fuel
        </Typography>
      </div>
    );
  };

  return (
    <>
      <div className={classes.backContainer}>
        <div
          className={classes.backButtonDiv}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon />
          Back
        </div>
        <div className={classes.templateButtonDiv}>
          {/* <CeroButton
            buttonText="Bulk Data Upload"
            className={classes.bulkButton}
            startIcon={<FileUploadIcon />}
            onClick={() => setIsOpenEmissionTypeUpload(true)}
          />
          <CeroButton
            buttonText="Download Template"
            className={classes.bulkButton}
            startIcon={<DownloadIcon />}
            onClick={onDownload}
          /> */}
        </div>
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Add Capital goods
          </Typography>
          <Box className={classes.topContainer}>
            <Grid className={classes.dynamicBoard}>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroAutoComplete
                  id="year"
                  name="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.touched.year && formik.errors.year}
                  options={yearList}
                  value={formik.values.year}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="month"
                  name="month"
                  label="Month"
                  fullWidth
                  options={months}
                  selectedValue={formik.values.month}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.month && formik.errors.month}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="calculationMethod"
                  name="calculationMethod"
                  label="Calculation Method"
                  fullWidth
                  options={calculationInputList}
                  selectedValue={formik.values.calculationMethod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.calculationMethod &&
                    formik.errors.calculationMethod
                  }
                />
              </Grid>

              {formik.values.calculationMethod &&
                calculationNamesMap[
                  formik.values.calculationMethod
                ]?.toLowerCase() === "supplier-specific method" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="quantityOfGoodsPurchased"
                        name="quantityOfGoodsPurchased"
                        label="Quantity of goods purchased in kg"
                        value={formik.values.quantityOfGoodsPurchased}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.quantityOfGoodsPurchased &&
                          formik.errors.quantityOfGoodsPurchased
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="emissionFactor"
                        name="emissionFactor"
                        label="Supplier specific emission factor of capital goods"
                        value={formik.values.emissionFactor}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.emissionFactor &&
                          formik.errors.emissionFactor
                        }
                        type="number"
                      />
                    </Grid>
                  </>
                )}
              {formik.values.calculationMethod &&
                calculationNamesMap[
                  formik.values.calculationMethod
                ]?.toLowerCase() === "average-data method" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="massOfPurchasedGoods"
                        name="massOfPurchasedGoods"
                        label="Mass of purchased goods or service"
                        value={formik.values.massOfPurchasedGoods}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.massOfPurchasedGoods &&
                          formik.errors.massOfPurchasedGoods
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="fuelUnit"
                        name="fuelUnit"
                        label="Unit"
                        fullWidth
                        options={averageUnitInputList || [{ name: "select" }]}
                        selectedValue={formik.values.fuelUnit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.fuelUnit && formik.errors.fuelUnit
                        }
                        sortOption={true}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="emissionFactor"
                        name="emissionFactor"
                        label="Emission factor for goods or service per unit of mass"
                        value={formik.values.emissionFactor}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.emissionFactor &&
                          formik.errors.emissionFactor
                        }
                        type="number"
                      />
                    </Grid>
                  </>
                )}
              {formik.values.calculationMethod &&
                calculationNamesMap[
                  formik.values.calculationMethod
                ]?.toLowerCase() === "spend-based" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="companyName"
                        name="companyName"
                        label="Vendor company name"
                        value={formik.values.companyName}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.companyName &&
                          formik.errors.companyName
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="sector"
                        name="sector"
                        label="Sector"
                        fullWidth
                        options={sectorList}
                        selectedValue={formik.values.sector || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.sector && formik.errors.sector}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="industry"
                        name="industry"
                        label="Industry"
                        fullWidth
                        options={industryList}
                        selectedValue={formik.values.industry || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.industry && formik.errors.industry
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="industrySubCategory"
                        name="industrySubCategory"
                        label="industry Sub Category"
                        fullWidth
                        options={industrySubCategoryList}
                        selectedValue={formik.values.industrySubCategory || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.industrySubCategory &&
                          formik.errors.industrySubCategory
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="valueOfPurchase"
                        name="valueOfPurchase"
                        label="Value of purchased good or service"
                        value={formik.values.valueOfPurchase}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.valueOfPurchase &&
                          formik.errors.valueOfPurchase
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="currency"
                        name="currency"
                        label="Currency"
                        fullWidth
                        options={currencyList}
                        selectedValue={formik.values.currency || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.currency && formik.errors.currency
                        }
                      />
                    </Grid>
                  </>
                )}
              {formik.values.calculationMethod &&
                calculationNamesMap[
                  formik.values.calculationMethod
                ]?.toLowerCase() === "hybrid" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="scope1Emission"
                        name="scope1Emission"
                        label="Scope 1 emissions of Tier 1 supplier relating to purchased good or service"
                        value={formik.values.scope1Emission}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.scope1Emission &&
                          formik.errors.scope1Emission
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="scope2Emission"
                        name="scope2Emission"
                        label="Scope 2 emissions of Tier 1 supplier relating to purchased good or service"
                        value={formik.values.scope2Emission}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.scope2Emission &&
                          formik.errors.scope2Emission
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="quantityOfMaterial"
                        name="quantityOfMaterial"
                        label="Quantity of material inputs used by tier 1 supplier relating to purchased good or service"
                        value={formik.values.quantityOfMaterial}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.quantityOfMaterial &&
                          formik.errors.quantityOfMaterial
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="cradleGateEmissionfactor"
                        name="cradleGateEmissionfactor"
                        label="Cradle-to-gate emission factor for the material"
                        value={formik.values.cradleGateEmissionfactor}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.cradleGateEmissionfactor &&
                          formik.errors.cradleGateEmissionfactor
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="distanceTransport"
                        name="distanceTransport"
                        label="Distance of transport of material inputs to tier 1 supplier"
                        value={formik.values.distanceTransport}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.distanceTransport &&
                          formik.errors.distanceTransport
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="distanceUnit"
                        name="distanceUnit"
                        label="Unit"
                        fullWidth
                        options={distanceUnitList}
                        selectedValue={formik.values.distanceUnit || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.distanceUnit &&
                          formik.errors.distanceUnit
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="massofMaterial"
                        name="massofMaterial"
                        label="Mass of material input"
                        value={formik.values.massofMaterial}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.massofMaterial &&
                          formik.errors.massofMaterial
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="massUnit"
                        name="massUnit"
                        label="Unit"
                        fullWidth
                        options={distanceUnitList}
                        selectedValue={formik.values.massUnit || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.massUnit && formik.errors.massUnit
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="modeOfTransport"
                        name="modeOfTransport"
                        label="Mode of Transport"
                        fullWidth
                        options={transportModeList}
                        selectedValue={formik.values.modeOfTransport || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.modeOfTransport &&
                          formik.errors.modeOfTransport
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="vehicleType"
                        name="vehicleType"
                        label="Vehicle Type"
                        fullWidth
                        options={vehicleTypeList}
                        selectedValue={formik.values.vehicleType || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.vehicleType &&
                          formik.errors.vehicleType
                        }
                        classes={{
                          input: classes.input,
                          container: classes.selectContainer,
                          paperRoot: classes.paperRoot,
                        }}
                      />
                    </Grid>

                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="massOfWaste"
                        name="massOfWaste"
                        label="Mass of waste from tier 1 supplier relating to the purchased good or service"
                        value={formik.values.massOfWaste}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.massOfWaste &&
                          formik.errors.massOfWaste
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="wasteMassUnit"
                        name="wasteMassUnit"
                        label="Unit"
                        fullWidth
                        options={distanceUnitList}
                        selectedValue={formik.values.wasteMassUnit || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.wasteMassUnit &&
                          formik.errors.wasteMassUnit
                        }
                      />
                    </Grid>

                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="wasteTreatmentMethod"
                        name="wasteTreatmentMethod"
                        label="Waste treatment method"
                        fullWidth
                        options={distanceUnitList}
                        selectedValue={formik.values.wasteTreatmentMethod || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.wasteTreatmentMethod &&
                          formik.errors.wasteTreatmentMethod
                        }
                      />
                    </Grid>
                  </>
                )}
            </Grid>
            <CeroButton
              buttonText="Calculate"
              className={classes.buttonPrimary}
              onClick={onCalculate}
              disabled={!formik.dirty || !formik.isValid}
            />
            <CeroButton
              buttonText="Clear"
              variant="outlined"
              className={classes.buttonSecondary}
              onClick={formik.resetForm}
            />
            {isCalculateDone && (
              <Box className={classes.bottomContainer}>
                <Typography
                  variant="h6"
                  component="h6"
                  className={classes.previewTitle}
                >
                  Emission Preview
                </Typography>
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="space-between"
                  spacing={8}
                >
                  <Grid item container direction="column" xs={12} md={6}>
                    <CeroItemPair
                      title={
                        <>
                          CO<sub>2</sub>
                        </>
                      }
                      value={`${addEmissionData.data.co2} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          CH<sub>4</sub>
                        </>
                      }
                      value={`${addEmissionData.data.ch4} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          BioFuel CO<sub>2</sub>
                        </>
                      }
                      value={`${addEmissionData.data.biofuel_co2} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                  </Grid>
                  <Grid
                    className={classes.secondResultContainer}
                    item
                    container
                    direction="column"
                    xs={6}
                  >
                    <CeroItemPair
                      title={
                        <>
                          CO<sub>2</sub>e
                        </>
                      }
                      value={`${addEmissionData.data.co2e} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          N<sub>2</sub>O
                        </>
                      }
                      value={`${addEmissionData.data.n2o} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={<>EF</>}
                      value={
                        <>
                          {addEmissionData.data.ef} kgCO<sub>2</sub>e/unit
                        </>
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
          <FileUpload
            onFileUpload={handleFileUpload}
            onFileRemove={handleFileRemove}
          />
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Add Data"
            disabled={
              !isCalculateDone || addEmissionData.status === STATUS.RUNNING
            }
            className={classes.buttonPrimary}
            onClick={() => onAddMobileCombustionData(formik.values)}
          />

          {/* <div className={classes.buttonContainer}>
                <CeroButton
                   buttonText="Bulk Data Upload"
                   className={classes.bulkButton}
                   startIcon={<FileUploadIcon />}
                   onClick={() => setIsOpenEmissionTypeUpload(true)}
                />
                <CeroButton
                    buttonText="Download Template"
                    className={classes.bulkButton}
                    startIcon={<DownloadIcon />}
                    onClick={onDownload}
                  />
                </div> */}
        </Box>
        <EmissionUploadDrawer
          isOpen={isOpenEmissionTypeUpload}
          onClose={onCloseDrawerUpload}
          onUpload={onUpload}
          onValidate={onValidate}
          uploadDisabled={uploadDisabled}
        />
      </Container>
    </>
  );
};

export default AddCapitalGoods;
