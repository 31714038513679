import { useEffect, useState } from "react";
import {
  Container,
  Tabs,
  Tab,
  ListItem,
  ListItemText,
  List,
  Paper,
  Grid,
  Tooltip,
} from "@mui/material";
import {
  emissionTypeData,
  emissionTypeDataForMenu,
  months,
  sampleYear,
} from "../../../constants";
import useStyles from "./styles";
import CeroDropdown from "../../../components/CeroDropdown";
import { useDispatch, useSelector } from "react-redux";
import CeroButton from "../../../components/CeroButton";
import EnvironmentalIcon from "../../../assets/icons/EnvironmentalIcon.png";
import SocialIcon from "../../../assets/icons/SocialIcon.png";
import GovernanceIcon from "../../../assets/icons/GovernanceIcon.png";
import activeEnvironmentalIcon from "../../../assets/icons/active-environmental.png";
import activeSocialIcon from "../../../assets/icons/active-social.png";
import activeGovernanceIcon from "../../../assets/icons/active-governance.png";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";
import { getCookie } from "../../../services/cookie";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { listAssessmentCycle } from "../../../redux/actions";

const ESGTable = (props) => {
  const { onAddData, onApplyFilter, isDisabled, setEmissionType } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const emissionTypePath = pathname.substring(pathname.lastIndexOf("/") + 1);

  const [selectedTopic, setSelectedTopic] = useState(0);
  const readOnlyMode = getCookie("read_only_mode") === "disable_edit";

  const handleTabChange = (event, newValue) => {
    setSelectedTopic(newValue);
    if (newValue === 0) {
      setSelectEmissionType("Environmental");
      navigate(`/emissions/emission-esg/Environmental`);
    } else if (newValue === 1) {
      setSelectEmissionType("Social");
      navigate(`/emissions/emission-esg/Social`);
    } else if (newValue === 2) {
      setSelectEmissionType("Governance");
      navigate(`/emissions/emission-esg/Governance`);
    }
  };
  useEffect(() => {
    if (emissionTypePath === "Environmental") {
      setSelectedTopic(0);
    } else if (emissionTypePath === "Social") {
      setSelectedTopic(1);
    } else if (emissionTypePath === "Governance") {
      setSelectedTopic(2);
    }
  }, []);

  const [emissionType, setEmission] = useState("");
  // const [headingTitle, setHeadingTitle] = useState();
  const headingTitleHandler = (title, id) => {
    // setHeadingTitle(title);
    setEmissionType(id);
    setEmission(id);
  };
  const [searchText, setSearchText] = useState("");
  const [filterYear, setYear] = useState(null);
  const [filterMonth, setMonth] = useState(null);
  const [filterType, setFilterType] = useState("");
  const [facility, setFacility] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [selectEmissionType, setSelectEmissionType] = useState("Environmental");
  const [lastClickedSubtopic, setLastClickedSubtopic] = useState(null);
  const [currentlyClickedSubtopic, setCurrentlyClickedSubtopic] =
    useState(null);

  const isSocialOrGovernanceAndNotFacilityMgr =
    (window.location.pathname.includes("/Social") &&
      getCookie("role") !== "facility_manager" &&
      getCookie("role") !== "sustainability_manager" &&
      getCookie("role") !== "proxy_sustainability_manager") ||
    (window.location.pathname.includes("/Governance") &&
      getCookie("role") !== "sustainability_manager" &&
      getCookie("role") !== "proxy_sustainability_manager");

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));
  const monthOptions = [...months];
  monthOptions.splice(0, 0, { key: "all", value: "All" });
  facilitiesList.splice(0, 0, { key: "all", value: "All" });
  const onApply = () => {
    if (!filterYear) {
      enqueueSnackbar("Please choose a year", {
        variant: "error",
      });
    } else {
      const filterValue = {
        year: filterYear,
        tableShow: true,
      };
      if (searchText) {
        filterValue.search = searchText;
      }
      if (filterMonth !== "all") {
        filterValue.month = filterMonth;
      }
      if (filterType) {
        filterValue.filterType = filterType;
      }
      if (facility !== "all") {
        filterValue.facility_id = facility;
      }
      onApplyFilter(filterValue);
    }
  };

  const iconMapping = {
    Environmental: (
      <img
        src={selectedTopic === 0 ? activeEnvironmentalIcon : EnvironmentalIcon}
        alt="Environmental"
      />
    ),
    Social: (
      <img
        src={selectedTopic === 1 ? activeSocialIcon : SocialIcon}
        alt="Social"
      />
    ),
    Governance: (
      <img
        src={selectedTopic === 2 ? activeGovernanceIcon : GovernanceIcon}
        alt="Governance"
      />
    ),
  };

  const onAddDataClick = () => {
    if (emissionType) {
      onAddData();
    } else {
      enqueueSnackbar("Please select a topic before adding data.", {
        variant: "error",
      });
    }
  };

  const selectEmissionTypeData = (title) => {
    setSelectEmissionType(title);
    navigate(`/emissions/emission-esg/${title}`);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const handleFacilityChange = (event) => {
    setFacility(event.target.value);
  };
  const handleSubtopicClick = (subtopicId, subtopicTitle) => {
    if (currentlyClickedSubtopic === subtopicId) {
      setCurrentlyClickedSubtopic(null);
      setLastClickedSubtopic(null);
      setEmission("");
    } else if (currentlyClickedSubtopic === null) {
      setLastClickedSubtopic(subtopicId);
      setCurrentlyClickedSubtopic(subtopicId);
      headingTitleHandler(subtopicTitle, subtopicId);
    } else {
      setCurrentlyClickedSubtopic(subtopicId);
      setLastClickedSubtopic(null);
      headingTitleHandler(subtopicTitle, subtopicId);
    }
  };
  const firstHalf = emissionTypeDataForMenu[0].subItems[2].subItems.slice(
    0,
    Math.ceil(emissionTypeDataForMenu[0].subItems[2].subItems.length / 2)
  );
  const secondHalf = emissionTypeDataForMenu[0].subItems[2].subItems.slice(
    Math.ceil(emissionTypeDataForMenu[0].subItems[2].subItems.length / 2)
  );

  return (
    <Container className={classes.container}>
      <div className={classes.topBar}>
        <h4 className={classes.mainTitle}>Select ESG Topic</h4>
        <div>
          <CeroButton
            buttonText="Standards Questionnaire"
            className={classes.bulkButton}
            onClick={() => {
              navigate("/emissions/standards-questionnaire");
            }}
          />
          <CeroButton
            buttonText="Production Data"
            className={classes.bulkButton}
            onClick={() => navigate("/production-data-list")}
          />
        </div>
      </div>
      <Paper
        className={classes.containerBox}
        style={{
          backgroundColor: (() => {
            switch (selectedTopic) {
              case 0:
                return "#F8F7F9";
              case 1:
                return "#F8F7F9";
              case 2:
                return "#F8F7F9";
              default:
                return "#E0E0E0";
            }
          })(),
        }}
      >
        <div className={classes.tabsContainer}>
          <Tabs
            value={selectedTopic}
            className={classes.tabs}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            {emissionTypeDataForMenu?.map((topic, index) => (
              <Tab
                className={classes.tabOption}
                key={index}
                style={{
                  backgroundColor:
                    selectedTopic === index ? "#F8F7F9" : "#E0E0E0",
                  color: selectedTopic === index ? "white" : "inherit",
                  flexGrow: 1,
                  border:
                    selectedTopic === index ? "3px solid #5A404E" : "none",
                  borderBottom:
                    selectedTopic === index ? "none" : "3px solid #5A404E",
                }}
                label={
                  <div
                    className={classes.tabLabel}
                    onClick={() => selectEmissionTypeData(topic.title)}
                  >
                    {iconMapping[topic.title]}
                    <span className={classes.tabTitle}>{topic.title}</span>
                  </div>
                }
              />
            ))}
          </Tabs>
          <div className={classes.tabBorder}></div>
        </div>
        {selectedTopic === 0 ? (
          <div className={classes.bottomBox}>
            <List className={classes.topicsEnv}>
              <div key={1}>
                <ListItem>
                  <ListItemText
                    disableTypography
                    sx={{ lineHeight: "5px !important" }}
                    className={classes.headings}
                    primary={
                      <span className={classes.headingText}>
                        {`Scope 1`}
                        <span className={classes.underline}></span>
                      </span>
                    }
                  />
                </ListItem>
                <List className={classes.tabContentBox}>
                  {emissionTypeDataForMenu[0].subItems[0].subItems.map(
                    (heading, index) => (
                      <ListItem
                        key={index}
                        onClick={() =>
                          handleSubtopicClick(heading.id, heading.title)
                        }
                        className={`${
                          currentlyClickedSubtopic === heading.id
                            ? classes.subtopicLabel + " clicked"
                            : classes.subtopicLabel
                        }`}
                      >
                        <span className={classes.subtopicTitle}>
                          {heading.title}
                          {heading.tooltip && (
                            <Tooltip
                              title={heading.tooltip}
                              classes={{ popper: classes.tooltip }}
                              arrow
                              placement="right-start"
                            >
                              <InfoOutlinedIcon className={classes.infoIcon} />
                            </Tooltip>
                          )}
                        </span>
                        {/* <ListItemText
                        sx={{
                          cursor: "pointer",
                          lineHeight: "20px !important",
                        }}
                        disableTypography
                        className="subtopicTitle"
                        primary={heading.title}
                      /> */}
                      </ListItem>
                    )
                  )}
                </List>
                <ListItem>
                  <ListItemText
                    disableTypography
                    sx={{ lineHeight: "5px !important" }}
                    className={classes.headings}
                    primary={
                      <span className={classes.headingText}>
                        {`Scope 2`}
                        <span className={classes.underline}></span>
                      </span>
                    }
                  />
                </ListItem>
                <List className={classes.tabContentBox}>
                  {emissionTypeDataForMenu[0].subItems[1].subItems.map(
                    (heading, index) => (
                      <ListItem
                        key={index}
                        onClick={() =>
                          handleSubtopicClick(heading.id, heading.title)
                        }
                        className={`${
                          currentlyClickedSubtopic === heading.id
                            ? classes.subtopicLabel + " clicked"
                            : classes.subtopicLabel
                        }`}
                      >
                        <span className={classes.subtopicTitle}>
                          {heading.title}
                          {heading.tooltip && (
                            <Tooltip
                              title={heading.tooltip}
                              classes={{ popper: classes.tooltip }}
                              arrow
                              placement="right-start"
                            >
                              <InfoOutlinedIcon className={classes.infoIcon} />
                            </Tooltip>
                          )}
                        </span>
                        {/* <ListItemText
                        sx={{
                          cursor: "pointer",
                          lineHeight: "20px !important",
                        }}
                        disableTypography
                        className="subtopicTitle"
                        primary={heading.title}
                      /> */}
                      </ListItem>
                    )
                  )}
                </List>
              </div>
              <div key={2}>
                <ListItem>
                  <ListItemText
                    disableTypography
                    sx={{ lineHeight: "5px !important" }}
                    className={classes.headings}
                    primary={
                      <span className={classes.headingText}>
                        {`Scope 3`}
                        <span className={classes.underline}></span>
                      </span>
                    }
                  />
                </ListItem>
                <List className={classes.tabContentBox}>
                  {
                    <div style={{ display: "flex", gap: "20px" }}>
                      {/* First Column */}
                      <div>
                        {firstHalf.map((heading, index) => (
                          <ListItem
                            key={index}
                            onClick={() =>
                              handleSubtopicClick(heading.id, heading.title)
                            }
                            className={`${
                              currentlyClickedSubtopic === heading.id
                                ? classes.subtopicLabel + " clicked"
                                : classes.subtopicLabel
                            }`}
                          >
                            <span className={classes.subtopicTitle}>
                              {heading.title}
                              {heading.tooltip && (
                                <Tooltip
                                  title={heading.tooltip}
                                  classes={{ popper: classes.tooltip }}
                                  arrow
                                  placement="right-start"
                                >
                                  <InfoOutlinedIcon
                                    className={classes.infoIcon}
                                  />
                                </Tooltip>
                              )}
                            </span>
                          </ListItem>
                        ))}
                      </div>

                      {/* Second Column */}
                      <div>
                        {secondHalf.map((heading, index) => (
                          <ListItem
                            key={index}
                            onClick={() =>
                              handleSubtopicClick(heading.id, heading.title)
                            }
                            className={`${
                              currentlyClickedSubtopic === heading.id
                                ? classes.subtopicLabel + " clicked"
                                : classes.subtopicLabel
                            }`}
                          >
                            <span className={classes.subtopicTitle}>
                              {heading.title}
                              {heading.tooltip && (
                                <Tooltip
                                  title={heading.tooltip}
                                  classes={{ popper: classes.tooltip }}
                                  arrow
                                  placement="right-start"
                                >
                                  <InfoOutlinedIcon
                                    className={classes.infoIcon}
                                  />
                                </Tooltip>
                              )}
                            </span>
                          </ListItem>
                        ))}
                      </div>
                    </div>
                  }
                </List>
              </div>
            </List>
          </div>
        ) : (
          <div className={classes.bottomBox}>
            <List className={classes.topics}>
              {emissionTypeDataForMenu[selectedTopic].subItems.map(
                (subtopic, index) => (
                  <div key={index}>
                    <ListItem>
                      <ListItemText
                        disableTypography
                        sx={{ lineHeight: "5px !important" }}
                        className={classes.headings}
                        primary={
                          <span className={classes.headingText}>
                            {subtopic.title}
                            <span className={classes.underline}></span>
                          </span>
                        }
                      />
                    </ListItem>
                    <List className={classes.tabContentBox}>
                      {subtopic.subItems.map((heading, index) => (
                        <ListItem
                          key={index}
                          onClick={() =>
                            handleSubtopicClick(heading.id, heading.title)
                          }
                          className={`${
                            currentlyClickedSubtopic === heading.id
                              ? classes.subtopicLabel + " clicked"
                              : classes.subtopicLabel
                          }`}
                        >
                          <span className={classes.subtopicTitle}>
                            {heading.title}
                            {heading.tooltip && (
                              <Tooltip
                                title={heading.tooltip}
                                classes={{ popper: classes.tooltip }}
                                arrow
                                placement="right-start"
                              >
                                <InfoOutlinedIcon
                                  className={classes.infoIcon}
                                />
                              </Tooltip>
                            )}
                          </span>
                          {/* <ListItemText
                        sx={{
                          cursor: "pointer",
                          lineHeight: "20px !important",
                        }}
                        disableTypography
                        className="subtopicTitle"
                        primary={heading.title}
                      /> */}
                        </ListItem>
                      ))}
                    </List>
                  </div>
                )
              )}
            </List>
          </div>
        )}
      </Paper>
      {!showFilter && (
        <Grid
          className={classes.filterContainer}
          container
          columnSpacing={2}
          alignItems="center"
          gap={4}
          justifyContent="center"
          wrap="nowrap"
        >
          {!isDisabled && !isSocialOrGovernanceAndNotFacilityMgr && (
            <Grid item xs={1.5}>
              <CeroButton
                buttonText="Add Data"
                className={classes.button}
                onClick={onAddDataClick}
                disabled={readOnlyMode}
              />
            </Grid>
          )}
          {!isDisabled && (
            <Grid item xs={1.5}>
              <CeroButton
                buttonText="View Data"
                className={classes.button}
                // onClick={onApply}
                onClick={() => setShowFilter(true)}
              />
            </Grid>
          )}
        </Grid>
      )}
      {showFilter && (
        <>
          <Grid
            className={classes.filterViewContainer}
            container
            columnSpacing={2}
            alignItems="center"
            gap={4}
            justifyContent="center"
            wrap="nowrap"
          >
            <Grid
              className={classes.filterShowContainer}
              container
              columnSpacing={2}
              gap={5}
            >
              <CeroDropdown
                id="year"
                label="Year"
                fullWidth
                options={sampleYear()}
                onChange={handleYearChange}
                selectedValue={filterYear}
                disabled={isDisabled}
              />
              <CeroDropdown
                id="month"
                label="Month"
                fullWidth
                options={monthOptions}
                onChange={handleMonthChange}
                selectedValue={filterMonth}
                disabled={isDisabled}
              />
              <CeroDropdown
                id="facility"
                label="Facility"
                fullWidth
                options={facilitiesList}
                onChange={handleFacilityChange}
                selectedValue={facility}
                disabled={isDisabled}
              />
            </Grid>
            {!isDisabled && (
              <Grid item xs={1.5}>
                <CeroButton
                  buttonText="View Data"
                  className={classes.button}
                  onClick={onApply}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Container>
  );
};

export default ESGTable;
