import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.white,
    height: "fit-content",
    width: "95%",
    borderRadius: 14,
    padding: theme.spacing(5, 7),
    paddingBottom: 0,
    position: "relative",
  },
  innerContainer: {
    height: "100%",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "none",
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.background.darkGray,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: theme.palette.background.darkGray,
    },
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  buttonContainer: {
    padding: theme.spacing(4, 0),
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    width: 140,
    height: 42,
    marginRight: 15,
    fontWeight: 400,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  buttonSecondary: {
    color: "#9B2244",
    border: "1px solid #9B2244",
    backgroundColor: "transparent",
    textTransform: "capitalize",
    width: 120,
    height: 42,
    borderRadius: "4px",
    fontWeight: 500,
    "&:hover": {
      backgroundColor: "#F8E7EB",
    },
  },
  bottomContainer: {
    maxWidth: 700,
    marginTop: theme.spacing(5),
  },
  previewTitle: {
    marginBottom: theme.spacing(3),
  },
  previewItem: {
    margin: theme.spacing(1, 0),
    fontSize: 15,
  },
  secondResultContainer: {
    paddingLeft: `${theme.spacing(4)} !important`,
    marginLeft: theme.spacing(4),
    borderLeft: `solid 1px ${theme.palette.Other.stroke}`,
  },
  backContainer: {
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    paddingBottom: 35,
    paddingTop: 10,
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
  },
  bulkButton: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    marginRight: 15,
    fontWeight: 400,
    width: 170,
    height: 42,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  info: {
    paddingTop: theme.spacing(8),
  },
  infoIcon: {
    fontSize: "larger",
    color: theme.palette.Primary.background,
    cursor: "pointer",
  },
  tooltip: {
    cursor: "pointer",
    "& .MuiTooltip-tooltip": {
      backgroundColor: ` ${theme.palette.Primary.light} !important`,
      border: "1px solid #8C2148",
      color: "black",
    },
  },
  inputWithInfo: {
    display: "flex",
    justifyContent: "space-between",
  },
  infoContainer: {
    justifyContent: "space-between",
    fontWeight: 600,
  },
  value: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.primary,
    textTransform: "capitalize",
  },
  emissionTitle: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.primary,
    textTransform: "capitalize",
  },
  input: {
    maxWidth: "352px !important",
    "& .MuiFormControl-root-MuiTextField-root": {
      maxWidth: "352px",
    },
  },
  selectContainer: {
    maxWidth: "352px !important",
  },
  paperRoot: {
    left: "46% !important",
  },
  backButtonDiv: {
    alignItems: "center",
    display: "flex",
    // paddingLeft: '28px'
  },
  templateButtonDiv: {
    paddingRight: "20px",
  },
  individualInput: {
    width: "100%",
    // textAlign: "center",
  },
  dynamicBoard: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "10px",
  },
}));

export default useStyles;
