
import React from "react";


const StrikesTable = ({ strikesTable, setStrikesTable }) => {
 const handleInputChange = (rowIndex, field, value) => {
    const updatedData = [...strikesTable];
    updatedData[rowIndex][field] = value;
    setStrikesTable(updatedData);
  };

  const addRow = () => {
    setStrikesTable([
      ...strikesTable,
      {
        strikeLockout: "",
        numberOfWorkers: "",
        durationDays: "",
        causeOfWorkStoppage: "",
        impactOnProduction: "",
        correctiveActions: "",
      },
    ]);
  };

  const removeRow = () => {
    if (strikesTable.length > 1) {
      setStrikesTable(strikesTable.slice(0, -1));
    }
  };

  return (
    <div>
      {/* Table Container */}
      <div style={{ marginTop: "20px", border: "1px solid #000", borderRadius: "8px", overflow: "hidden", width: "100%" }}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr style={{ backgroundColor: "#cfcfcf", color: "#000" }}>
              <th style={tableHeaderStyle}>Strike/Lockout</th>
              <th style={tableHeaderStyle}>Number of Workers Involved</th>
              <th style={tableHeaderStyle}>Duration (Days Idle)</th>
              <th style={tableHeaderStyle}>Cause of Work Stoppage</th>
              <th style={tableHeaderStyle}>Impact on Production</th>
              <th style={tableHeaderStyle}>Corrective Actions</th>
             
            </tr>
          </thead>
          <tbody>
            {strikesTable.map((row, index) => (
              <tr key={index}>
                {[
                  "strikeLockout",
                  "numberOfWorkers",
                  "durationDays",
                  "causeOfWorkStoppage",
                  "impactOnProduction",
                  "correctiveActions",
                ].map((field) => (
                    <td key={field} style={tableCellStyle}>
                    <input
                      type="text"
                      value={row[field]}
                      onChange={(e) => handleInputChange(index, field, e.target.value)}
                      style={inputFieldStyle}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Buttons (Now Outside the Table) */}
      <div style={{ display: "flex", justifyContent: "center", marginTop: "10px", gap: "10px" }}>
        <button onClick={removeRow} style={removeButtonStyle}>- Remove</button>
        <button onClick={addRow} style={addButtonStyle}>+ Add More</button>
      </div>
    </div>
  );
};

const tableHeaderStyle = {
  padding: "10px",
  border: "1px solid #000",
  textAlign: "center",
};

const tableCellStyle = {
  padding: "10px",
  border: "1px solid #000",
  textAlign: "center",
};

const inputFieldStyle = {
  width: "100%",
  padding: "6px",
  borderRadius: "4px",
  border: "1px solid grey",
  textAlign: "center",
  fontSize: "14px",
  backgroundColor: "white",
  outline: "none",
};

const addButtonStyle = {
    backgroundColor: "white",
    border: "1px solid lightgrey",  // Thin light grey border
    color: "grey",  // Grey text color
    borderRadius: "4px",
    padding: "8px 12px",
    cursor: "pointer",
    // fontWeight: "bold",
};

const removeButtonStyle = {
  backgroundColor: "white",
    border: "1px solid lightgrey",  // Thin light grey border
    color: "grey",  // Grey text color
    borderRadius: "4px",
    padding: "8px 12px",
    cursor: "pointer",
    // fontWeight: "bold",
};

export default StrikesTable;