import { getRole } from "../../services/rolemanager";

export const STATUS = {
  IDLE: "idle",
  RUNNING: "running",
  READY: "ready",
  SUCCESS: "success",
  ERROR: "error",
};
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
  ? process.env.REACT_APP_API_BASE_URL.trim()
  : "";
// const API_BASE_URL = "http://ceroed-demo-api-cd.azurewebsites.net";

// const getPrefix = () => {
//   return getCookie("role") && getCookie("role") === "auditor"
//     ? "auditor"
//     : "business";
// };

const getPrefix = () => {
  const role = getRole();
  return role && role === "auditor" ? "auditor" : "business";
};

export const APIEndpoints = {
  LOGIN: `${API_BASE_URL}/business/token`,
  USER_SIGN_UP: `${API_BASE_URL}/business/signup`,
  AUDITOR_SIGN_UP: `${API_BASE_URL}/auditor/signup`,
  LOGOUT: (email) => `${API_BASE_URL}/business/general-logout?user_id=${email}`,
  CHANGE_PASSWORD: () => `${API_BASE_URL}/${getPrefix()}/change_password`,
  SET_EMAIL_CONFIRMED: (userId, code) =>
    `${API_BASE_URL}/business/confirm-email/${userId}/${code}`,
  USER_INVITE_LOGIN: `${API_BASE_URL}/business/set-password`,
  SUPER_ADMIN_TWOFA_VALIDATION: (userId) =>
    `${API_BASE_URL}/business/verify-otp?user_id=${userId}`,
  PROXY_SUSTAINABILITY_MANAGER_LOGIN: (companyId) =>
    `${API_BASE_URL}/admin/proxy-login/${companyId}`,
  PROXY_LOGIN_ACCESS_SUPERADMIN: (userId, status) =>
    `${API_BASE_URL}/business/users/give-access-for_super-admin-proxy-login?user_id=${userId}&login_status=${status}`,
  SWITCH_BACK_TO_SUPER_ADMIN: (userId) =>
    `${API_BASE_URL}/business/switch-to-super-admin/${userId}`,
  TRADE_LOGIN_ARRO_USER: `${API_BASE_URL}/business/trade-token`,

  GET_ACCOUNT_DETAILS: (role) =>
    `${API_BASE_URL}/${role}/account/account-details`,
  UPDATE_ACCOUNT_DETAILS: (role) => `${API_BASE_URL}/${role}/account/update`,
  GET_USER_COMPANY_DETAILS: (role) =>
    `${API_BASE_URL}/${role}/company/company-details`,
  UPDATE_COMPANY_DETAILS: `${API_BASE_URL}/business/company/company-details`,

  FORGOT_PASSWORD: `${API_BASE_URL}/business/forgot_password`,
  RESET_PASSWORD: `${API_BASE_URL}/business/reset_password`,

  GET_EMISSION_LIST: (emissionType) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/list-emission/${emissionType}`,
  GET_AUDITOR_EMISSION_LIST: (emissionType, company) =>
    `${API_BASE_URL}/auditor/company/${company}/emissions/${emissionType}`,
  GET_EMISSION: (emissionType, emissionId, distribution, classification) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/${emissionType}/${emissionId}?distribution=${distribution}&classification=${classification}`,
  GET_AUDITOR_EMISSION: (
    emissionType,
    emissionId,
    company,
    distribution,
    classification
  ) =>
    `${API_BASE_URL}/auditor/company/${company}/emissions/${emissionType}/${emissionId}?distribution=${distribution}&classification=${classification}`,
  GET_EMISSION_FUEL_LIST: (emissionType) =>
    `${API_BASE_URL}/${getPrefix()}/emission-input-format/${emissionType}`,
  GET_EMISSION_INPUT_FORMAT: (emissionType, assessment, year, facility) =>
    `${API_BASE_URL}/${getPrefix()}/emission-input-format/${emissionType}?assessment_period=${assessment}&year=${year}&facility_id=${facility}`,

  LIST_FACILITIES: `${API_BASE_URL}/facilities/`,
  ADD_FACILITY: `${API_BASE_URL}/facilities/`,
  GET_FACILITY: (facilityId) => `${API_BASE_URL}/facilities/${facilityId}`,
  EDIT_FACILITY: (facilityId) => `${API_BASE_URL}/facilities/${facilityId}`,
  DELETE_FACILITY: (facilityId) => `${API_BASE_URL}/facilities/${facilityId}`,

  ADD_STATIONARY_COMBUSTION: `${API_BASE_URL}/${getPrefix()}/emissions/stationary_combustion`,
  ADD_PURCHASED_ELECTRICITY: `${API_BASE_URL}/${getPrefix()}/emissions/purchased_electricity`,
  ADD_REFRIGERANTS: `${API_BASE_URL}/${getPrefix()}/emissions/refrigerants`,
  UPDATE_REFRIGERANTS: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/refrigerants/${emissionId}`,
  ADD_MOBILE_COMBUSTION: `${API_BASE_URL}/${getPrefix()}/emissions/mobile_combustion`,
  ADD_EMPLOYEE_COMMUTE: `${API_BASE_URL}/${getPrefix()}/emissions/employee_commuting`,
  ADD_TRANSPORTATION_COMBUSTION: `${API_BASE_URL}/${getPrefix()}/emissions/transportation`,
  ADD_WATER_DISCHARGE: `${API_BASE_URL}/${getPrefix()}/emissions/water_discharge`,
  ADD_WATER_CONSUMPTION: `${API_BASE_URL}/${getPrefix()}/emissions/water_consumption`,
  ADD_WASTE_COMBUSTION: `${API_BASE_URL}/${getPrefix()}/emissions/waste`,
  ADD_DEVELOPMET_TRAINING_DETAILS: `${API_BASE_URL}/${getPrefix()}/emissions/development-training`,
  ADD_EMPLOYEE_HEALTH_DETAILS: `${API_BASE_URL}/${getPrefix()}/emissions/employee_health_safety_incident_record`,
  ADD_WORKER_SAFETY_TRAINING: `${API_BASE_URL}/${getPrefix()}/emissions/worker_safety_training_procedures`,
  ADD_DESCRIMINATION_INCIDENT_RECORD: `${API_BASE_URL}/${getPrefix()}/emissions/discrimination-incident-record`,
  ADD_SUPPLIER_HUMAN_RIGHTS_TRAINING: `${API_BASE_URL}/${getPrefix()}/emissions/supplier-human-rights`,
  ADD_SOCIAL_HUMAN_RIGHTS_TRAINING: `${API_BASE_URL}/${getPrefix()}/emissions/social-engagement-human-rights-training`,
  ADD_SUPPLIER_SCREENING: `${API_BASE_URL}/${getPrefix()}/emissions/supplier-screening-using-env-social-criteria`,
  ADD_LOCAL_COMMUNITIES: `${API_BASE_URL}/${getPrefix()}/emissions/local-communities`,
  ADD_POLITICAL_CONTRIBUTION: `${API_BASE_URL}/${getPrefix()}/emissions/political-contributions`,
  ADD_ANTI_CORRUPTION_DISCLOSURE: `${API_BASE_URL}/${getPrefix()}/emissions/anti-corruption-disclosure`,
  ADD_ANTI_CORRUPTION_TRAINING: `${API_BASE_URL}/${getPrefix()}/emissions/anti-corruption-training`,
  ADD_ANTI_COMPETITIVE_DISCLOSURE: `${API_BASE_URL}/${getPrefix()}/emissions/anti-competitive-disclosure`,
  ADD_SUBSIDIES_FINANCIAL_ASSISTANCE: `${API_BASE_URL}/${getPrefix()}/emissions/subsidies-financial-assistance`,
  ADD_EMPLOYEES_TURNOVER: `${API_BASE_URL}/${getPrefix()}/emissions/employees-turnover`,
  ADD_AGE_BASED_STATISTICS: `${API_BASE_URL}/${getPrefix()}/emissions/age-based-statistics`,
  ADD_GENDER_BASED_STATISTICS: `${API_BASE_URL}/${getPrefix()}/emissions/gender-based-statistics`,
  ADD_BOARD_DIVERSITY: `${API_BASE_URL}/${getPrefix()}/emissions/board-diversity`,
  ADD_MANAGEMENT_DIVERSITY: `${API_BASE_URL}/${getPrefix()}/emissions/management-diversity`,
  ADD_TAX: `${API_BASE_URL}/${getPrefix()}/emissions/tax`,
  ADD_DIRECT_ECONOMIC_IMPACT: `${API_BASE_URL}/${getPrefix()}/emissions/direct_economic_impact`,
  ADD_INDIRECT_ECONOMIC_IMPACT: `${API_BASE_URL}/${getPrefix()}/emissions/indirect_economic_impact`,
  GET_NON_EMISSION_DETAILS: (emissionId, emissionType) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/${emissionType}/${emissionId}`,
  UPDATE_NON_EMISSION_DETAILS: (emissionId, emissionType) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/${emissionType}/${emissionId}`,
  EDIT_TRANSPORTATION_COMBUSTION: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/transportation/${emissionId}`,
  UPDATE_PURCHASED_ELECTRICITY: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/purchased_electricity/${emissionId}`,
  UPDATE_STATIONARY_COMBUSTION: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/stationary_combustion/${emissionId}`,
  UPDATE_MOBILE_COMBUSTION: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/mobile_combustion/${emissionId}`,
  UPDATE_WATER_DISCHARGE_COMBUSTION: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/water_discharge/${emissionId}`,
  UPDATE_WATER_CONSUMPTION_COMBUSTION: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/water_consumption/${emissionId}`,
  UPDATE_WASTE_COMBUSTION: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/waste/${emissionId}`,
  UPDATE_BOARD_DIVERSITY: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/board-diversity/${emissionId}`,
  UPDATE_MANAGEMENT_DIVERSITY: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/management-diversity/${emissionId}`,
  DELETE_EMISSIONS: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/${emissionId}`,
  LIST_EMISSION_COMMENTS: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/comments/${emissionId}`,
  LIST_AUDITOR_EMISSION_COMMENTS: (emissionId, company) =>
    `${API_BASE_URL}/auditor/company/${company}/emissions/comments/${emissionId}`,
  ADD_EMISSION_COMMENT: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/comment/${emissionId}`,
  LIST_EMISSION_AUDIT_TRAILS: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/audit-trails/${emissionId}`,
  LIST_AUDITOR_EMISSION_AUDIT_TRAILS: (emissionId, company) =>
    `${API_BASE_URL}/auditor/company/${company}/emissions/audit-trails/${emissionId}`,
  LIST_EMISSION_FILES: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/attachment/${emissionId}`,
  LIST_AUDITOR_EMISSION_FILES: (emissionId, company) =>
    `${API_BASE_URL}/auditor/company/${company}/emissions/attachments/${emissionId}`,
  UPLOAD_EMISSION_ATTACHEMENT: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/attachment/${emissionId}`,
  DELETE_EMISSION_ATTACHEMENT: (emissionId, attachementId) =>
    `${API_BASE_URL}/${getPrefix()}/attachment/${emissionId}/${attachementId}`,
  GET_ALL_EMISSIONS: (selectedYear) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/get_all_emissions?year=${selectedYear}`,

  GET_INDUSTRY_TYPES: `${API_BASE_URL}/${getPrefix()}/listings/industry_types`,
  GET_COUNTRY_LIST: `${API_BASE_URL}/${getPrefix()}/listings/countries`,
  LIST_GRID_REGIONS: (countryId) =>
    `${API_BASE_URL}/${getPrefix()}/listings/countries/${countryId}/grid_regions`,
  GET_MANAGER_LIST: (role) =>
    `${API_BASE_URL}/${getPrefix()}/users/get-managers/${role}`,

  LIST_ASSIGNEE: (role) => `${API_BASE_URL}/${role}/tickets/assignee-list`,
  LIST_FRAMEWORK: `${API_BASE_URL}/${getPrefix()}/reports/list-frameworks`,
  LIST_TOPIC: `${API_BASE_URL}/${getPrefix()}/reports/list-topics`,

  GET_EMISSION_YEAR: `${API_BASE_URL}/${getPrefix()}/graph/emission-year`,
  GET_EMISSION_TYPES: `${API_BASE_URL}/${getPrefix()}/graph/emission-type`,
  GET_EMISSION_REGION: `${API_BASE_URL}/${getPrefix()}/graph/emission_region`,
  GET_EMISSIONS_BY_MONTH: `${API_BASE_URL}/${getPrefix()}/graph/emission-month`,
  GET_EMISSIONS_BY_SCOPE: `${API_BASE_URL}/${getPrefix()}/graph/co2-emission-by-scope`,
  GET_EMISSIONS_BY_SCOPE_BY_YEAR: `${API_BASE_URL}/${getPrefix()}/graph/co2-emission-by-scope-by-year`,
  GET_ESG_OVERVIEW: `${API_BASE_URL}/${getPrefix()}/graph/get_esg_overview_graph`,
  GET_ENERGY_MATERIAL: `${API_BASE_URL}/${getPrefix()}/graph/energy-material-co2e`,
  GET_FUEL_SOURCES_TARGET: `${API_BASE_URL}/${getPrefix()}/graph/fuel-sources-target-sankey`,

  LIST_USERS: `${API_BASE_URL}/${getPrefix()}/users/`,
  ADD_USER: `${API_BASE_URL}/${getPrefix()}/users/`,
  GET_USER: (userId) => `${API_BASE_URL}/${getPrefix()}/users/${userId}`,
  EDIT_USER: (userId) => `${API_BASE_URL}/${getPrefix()}/users/${userId}`,
  EDIT_USER_STAKEHOLDER: (userId) =>
    `${API_BASE_URL}/${getPrefix()}/users/update-stakeholder/${userId}`,
  DELETE_USER: (userId) => `${API_BASE_URL}/${getPrefix()}/users/${userId}`,

  AUDITOR_LOGIN: `${API_BASE_URL}/auditor/token`,
  FORGOT_AUDITOR_PASSWORD: `${API_BASE_URL}/auditor/forgot_password`,
  RESET_AUDITOR_PASSWORD: `${API_BASE_URL}/auditor/reset_password`,

  LIST_COMPANIES: `${API_BASE_URL}/auditor/companies`,
  GET_COMPANY_DETAILS: (companyId) =>
    `${API_BASE_URL}/auditor/company/${companyId}/company-details`,
  GET_COMPANY_AUDIT_HISTORY: (companyId) =>
    `${API_BASE_URL}/auditor/company/${companyId}/company-audit-history`,
  GET_COMPANY_AUDIT_DETAILS: (companyId) =>
    `${API_BASE_URL}/auditor/company/${companyId}/company-audit-summary`,
  APPROVE_COMPANY_AUDIT: (companyId, auditId) =>
    `${API_BASE_URL}/auditor/company/${companyId}/approve-audit-status/${auditId}`,

  GET_APPROVAL_SUMMARY: (year, facility, auditId) =>
    `${API_BASE_URL}/${getPrefix()}/approval-statuses/${year}/${facility}?audit_id=${auditId}`,
  GET_APPROVAL_DETAILS: (year, facility, auditId) =>
    `${API_BASE_URL}/${getPrefix()}/approval-status-overview/${year}?facility_id=${facility}&audit_id=${auditId}`,
  GET_APPROVAL_MONTHLY_DETAILS: `${API_BASE_URL}/${getPrefix()}/monthly-approval-status-summary`,
  GET_APPROVAL_MONTHLY_SUMMARY: `${API_BASE_URL}/${getPrefix()}/monthly-approval-status-summary-overview`,
  GET_DASHBOARD_STATISTICS: `${API_BASE_URL}/${getPrefix()}/dashboard-statistics`,
  GET_AUDIT_SUMMARY: `${API_BASE_URL}/${getPrefix()}/audit-statuses`,
  GET_FACILITY_TOPIC_EMISSION: `${API_BASE_URL}/${getPrefix()}/graph/facilities-drill-down-emission-details`,
  GET_DASHBOARD_SUMMARY: `${API_BASE_URL}/auditor/dashboard-summary`,
  GET_FUEL_SOURCE_EMISSION: `${API_BASE_URL}/business/graph/fuel-sources-target-sankey`,
  MARK_ALL_READ_AUDITOR_NOTIFICATIONS: `${API_BASE_URL}/${getPrefix()}/notifications/mark-all-read`,
  MARK_ALL_READ: `${API_BASE_URL}/${getPrefix()}/notifications/mark-all-read`,
  MARK_AS_READ_AUDITOR_NOTIFICATIONS: (id) =>
    `${API_BASE_URL}/auditor/notifications/mark-read/${id}`,
  MARK_AS_READ: (id) =>
    `${API_BASE_URL}/${getPrefix()}/notifications/mark-read/${id}`,
  LIST_NOTIFICATIONS: (unread = true, rolePrefix) =>
    `${API_BASE_URL}/${rolePrefix}/notifications?unread=${unread}`,
  LIST_AUDITOR_NOTIFICATIONS: (unread = true) =>
    `${API_BASE_URL}/auditor/notifications?unread=${unread}`,

  GET_YEARLY_AUDIT_SUMMARY: (year) =>
    `${API_BASE_URL}/${getPrefix()}/yearly-audit-status-summary?year=${year}`,
  GET_YEARLY_AUDIT_SUMMARY_OVERVIEW: (year, statusId) =>
    `${API_BASE_URL}/${getPrefix()}/yearly-audit-status-summary-overview?year=${year}&audit_status_id=${statusId}`,
  GET_ALL_QUESTIONS: (payload) =>
    `${API_BASE_URL}/${getPrefix()}/audit-qualitative-questionnaires?audit_status_id=${
      payload.id
    }`,
  GET_ALL_AUDITOR_QUESTIONS: (payload) =>
    `${API_BASE_URL}/auditor/company/${payload.company}/audit-qualitative-questionnaires?audit_status_id=${payload.id}`,
  GET_YEARLY_AUDIT_STATUS_SUMMARY_OVERVIEW: (companyId) =>
    `${API_BASE_URL}/auditor/company/${companyId}/yearly-audit-status-summary-overview`,
  GET_AUDIT_SUMMARY_BY_ID: (id) =>
    `${API_BASE_URL}/${getPrefix()}/yearly-audit-status-summary?audit_status_id=${id}`,

  REQUEST_AUDIT: (audit_status_id) =>
    `${API_BASE_URL}/${getPrefix()}/yearly-audit-summary-assign-to-audit/${audit_status_id}`,

  ANSWER_QUALITATIVE_QUESTION: (audit_status_id) =>
    `${API_BASE_URL}/business/audit-qualitative-qa-data/${audit_status_id}`,

  SUBMIT_APPROVAL: (status_id) =>
    `${API_BASE_URL}/${getPrefix()}/monthly-approval_summary-assign-to-submit/${status_id}`,

  REQUEST_APPROVAL: (status_id) =>
    `${API_BASE_URL}/${getPrefix()}/monthly-approval_summary-assign-to-approve/${status_id}`,

  APPROVE_REQUEST: (status_id) =>
    `${API_BASE_URL}/${getPrefix()}/monthly-approval-summary-approve/${status_id}`,

  CREATE_TICKET: `${API_BASE_URL}/${getPrefix()}/tickets/`,
  LIST_TICKETS: (payload) =>
    `${API_BASE_URL}/${getPrefix()}/tickets/?ticket_type=${
      payload.ticketType
    }&ticket_status=${payload.ticketStatus}&year=${payload.year}&internal=${
      payload.internal
    }`,
  LIST_SCOPE_TICKETS: (scope, scopeId) =>
    `${API_BASE_URL}/${getPrefix()}/tickets/scope/${scope}/${scopeId}`,
  LIST_AUDITOR_SCOPE_TICKETS: (scope, scopeId, company) =>
    `${API_BASE_URL}/auditor/company/${company}/tickets/scope/${scope}/${scopeId}`,
  COMPANY_LIST_TICKETS: (payload) =>
    `${API_BASE_URL}/auditor/company/${payload.company}/tickets/?ticket_type=${payload.ticketType}&ticket_status=${payload.ticketStatus}&year=${payload.year}`,
  COMPANY_CREATE_TICKET: (payload) =>
    `${API_BASE_URL}/auditor/company/${payload.companyId}/tickets/`,
  GET_TICKET_DETAILS: (payload) =>
    `${API_BASE_URL}/${getPrefix()}/tickets/${payload.id}`,
  GET_COMPANY_TICKET_DETAILS: (payload) =>
    `${API_BASE_URL}/auditor/company/${payload.companyId}/tickets/${payload.id}`,
  COMPANY_CLOSE_TICKET: (payload) =>
    `${API_BASE_URL}/auditor/company/${payload.companyId}/tickets/${payload.id}/close`,
  COMPANY_ADD_RESPONSE: (payload) =>
    `${API_BASE_URL}/auditor/company/${payload.companyId}/tickets/response/${payload.id}`,
  ADD_RESPONSE: (payload) =>
    `${API_BASE_URL}/${getPrefix()}/tickets/response/${payload.id}`,
  CLOSE_TICKET: (payload) =>
    `${API_BASE_URL}/${getPrefix()}/tickets/${payload.id}/close`,

  LIST_REPORTS: `${API_BASE_URL}/${getPrefix()}/reports/`,
  GET_REPORT_LIST_TOPICS: (emission_type) =>
    `${API_BASE_URL}/${getPrefix()}/reports/list-topics/${emission_type}`,
  GET_REPORT_EMISSION_DETAILS: (
    emission_type,
    emission_id,
    distribution,
    classification
  ) =>
    `${API_BASE_URL}/${getPrefix()}/reports/${emission_type}/${emission_id}?distribution=${distribution}&classification=${classification}`,
  GET_REPORT_DETAILS: (id) => `${API_BASE_URL}/${getPrefix()}/reports/${id}`,
  CREATE_REPORT: `${API_BASE_URL}/${getPrefix()}/reports/create-report`,
  UPDATE_REPORT: (id) =>
    `${API_BASE_URL}/${getPrefix()}/reports/update-report/${id}`,
  DOWNLOAD_REPORT: `${API_BASE_URL}/${getPrefix()}/reports/download-report`,
  ASSIGN_REPORT: (id) =>
    `${API_BASE_URL}/${getPrefix()}/reports/assign-report?report_id=${id}`,
  GENERATE_AUDITED_REPORT: (payload) =>
    `${API_BASE_URL}/auditor/company/${payload.company}/generate-audited-report?year=${payload.year}&audit_id=${payload.auditId}`,
  DOWNLOAD_AUDITED_REPORT: (payload) =>
    `${API_BASE_URL}/auditor/company/${payload.company}/download-audited-report?audit_id=${payload.auditId}`,
  LIST_ASSESSMENT_CYCLE: `${API_BASE_URL}/${getPrefix()}/list-company-assessment-cycle`,
  GET_ASSESSMENT_DETAILS: (payload) =>
    `${API_BASE_URL}/${getPrefix()}/company_assessment_cycle?year=${
      payload.year
    }&country=${payload.country}&id=${payload.id}`,
  CREATE_ASSESSMENT_CYCLE: `${API_BASE_URL}/${getPrefix()}/add-company-assessment-cycle`,
  UPDATE_ASSESSMENT_CYCLE: (id) =>
    `${API_BASE_URL}/${getPrefix()}/update-company-calender/${id}`,

  UPDATE_DEVELOPMENT_TRAINING_DETAILS: (development_training_id) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/development-training/${development_training_id}`,
  UPDATE_WORKER_SAFETY_TRAINING: (worker_safety_training_procedures_id) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/worker-safety-training-procedures/${worker_safety_training_procedures_id}`,
  UPDATE_DISCRIMINATION_INCIDENT_RECORD: (discrimination_incident_record_id) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/discrimination-incident-record/${discrimination_incident_record_id}`,
  UPDATE_SUPPLIER_SCREENING: (suppliers_screening_id) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/supplier-screening-using-env-social-criteria/${suppliers_screening_id}`,
  UPDATE_SUPPLIER_HUMAN_RIGHTS_TRAINING: (human_rights_training_id) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/supplier-human-rights/${human_rights_training_id}`,
  UPDATE_SOCIAL_HUMAN_RIGHTS_TRAINING: (
    social_engagement_human_rights_training_id
  ) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/social-engagement-human-rights-training/${social_engagement_human_rights_training_id}`,
  UPDATE_LOCAL_COMMUNITIES: (local_communities_id) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/local-communities/${local_communities_id}`,
  UPDATE_POLITICAL_CONTRIBUTIONS: (political_contributions_id) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/political-contributions/${political_contributions_id}`,
  UPDATE_EMPLOYEE_HEALTH_DETAILS: (employee_health_safety_incident_record_id) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/employee_health_safety_incident_record/${employee_health_safety_incident_record_id}`,
  UPDATE_ANTI_CORRUPTION_DISCLOSURE: (anti_corruption_disclosure_id) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/anti-corruption-disclosure/${anti_corruption_disclosure_id}`,
  UPDATE_ANTI_CORRUPTION_TRAINING: (anti_corruption_training_id) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/anti-corruption-training/${anti_corruption_training_id}`,
  UPDATE_ANTI_COMPETITIVE_DISCLOSURE: (anti_competitive_disclosure_id) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/anti-competitive-disclosure/${anti_competitive_disclosure_id}`,
  UPDATE_SUBSIDIES_FINANCIAL_ASSISTANCE: (subsidies_financial_assistance_id) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/subsidies-financial-assistance/${subsidies_financial_assistance_id}`,

  GET_AUDIT_TRAILS: (company_id, audit_status_id) =>
    `${API_BASE_URL}/auditor/company/${company_id}/audit-track/${audit_status_id}`,

  DOWNLOAD_EMISSION: (emission_type) =>
    `${API_BASE_URL}/${getPrefix()}/bulkupload-template/${emission_type}`,
  VALIDATE_UPLOAD_EMISSION: (emission_type, save) =>
    `${API_BASE_URL}/${getPrefix()}/bulk-upload/${emission_type}/validate?save=${save}`,
  UPLOAD_EMISSION: (emission_type, save) =>
    `${API_BASE_URL}/${getPrefix()}/bulk-upload/${emission_type}?save=${save}`,

  GET_UPLOAD_HISTORY: (emissionType, filter) =>
    `${API_BASE_URL}/business/bulk-upload/${emissionType}/history?${filter}`,
  GET_UPLOAD_HISTORY_DETAILS: (emissionType, blob_url) =>
    `${API_BASE_URL}/business/bulk-upload/${emissionType}/history/details?blob_url=${blob_url}`,
  GET_AUDITOR_LIST: `${API_BASE_URL}/${getPrefix()}/list-auditor-assigned-to-company`,
  UPDATE_AUDITOR_STATUS: (auditor_id, auditor_status) =>
    `${API_BASE_URL}/${getPrefix()}/activate-deactivate-auditor?auditor_id=${auditor_id}&auditor_status=${auditor_status}`,
  EMAIL_VALIDATE: () => `${API_BASE_URL}/${getPrefix()}/assign-auditor`,

  SUPERADMIN_LOGIN: `${API_BASE_URL}/admin/token`,
  GET_SUPERADMIN_DASHBOARD: `${API_BASE_URL}/admin/dashboard`,
  GET_TOTAL_COMPANIES: `${API_BASE_URL}/admin/companies`,
  COMPANY_DETAILS: (companyId) =>
    `${API_BASE_URL}/admin/company/${companyId}/company-details`,
  COMPANY_COUNTRY_LIST: (companyId) =>
    `${API_BASE_URL}/admin/company/${companyId}/country-details`,
  COMPANY_FACILITY_LIST: (companyId) =>
    `${API_BASE_URL}/admin/company/${companyId}/facility-details`,
  COMPANY_USERS_LIST: (companyId, isActive) =>
    `${API_BASE_URL}/admin/company/${companyId}/user-details?active=${isActive}`,
  COMPANY_FACILITY_DETAILS: (companyId, countryName) =>
    `${API_BASE_URL}/admin/company/${companyId}/county/${countryName}/facilities`,
  GET_USER_STATISTICS: `${API_BASE_URL}/admin/user/user-statistics`,
  GET_USERS_LIST: (isActive) =>
    `${API_BASE_URL}/admin/user/user-list?active=${isActive}`,
  COMPANY_USER_DETAILS: (companyId, id, userType, isActive) =>
    `${API_BASE_URL}/admin/company/${companyId}/users-details-list/${id}?user_type=${userType}&active=${isActive}`,
  TOTAL_USER_DETAILS: (userType, isActive) =>
    `${API_BASE_URL}/admin/user/users-details-list?user_type=${userType}&active=${isActive}`,
  GET_SUPERADMIN_LIST: `${API_BASE_URL}/admin/super-admin`,
  ADD_SUPER_ADMIN: `${API_BASE_URL}/admin/super-admin`,
  EDIT_SUPER_ADMIN: (userId) => `${API_BASE_URL}/admin/super-admin/${userId}`,
  DELETE_SUPER_ADMIN: (userId) => `${API_BASE_URL}/admin/super-admin/${userId}`,
  GET_SUPERADMIN: (userId) => `${API_BASE_URL}/admin/super-admin/${userId}`,
  GET_ASSIGNED_STAKEHOLDERS: `${API_BASE_URL}/${getPrefix()}/users/get-assigned-stakeholders`,
  ACTIVATE_DEACTIVATE_STAKEHOLDERS: (id, status) =>
    `${API_BASE_URL}/${getPrefix()}/users/deactivate-activate-stakeholders?stakeholder_id=${id}&company_status=${status}`,

  STAKEHOLDER_LOGIN: `${API_BASE_URL}/stakeholder/token`,
  GET_STAKEHOLDER_COMPANY: `${API_BASE_URL}/stakeholder/get-assigned-companies`,
  STAKEHOLDER_COMPANY_DETAILS: (companyId) =>
    `${API_BASE_URL}/stakeholder/get-company-details/${companyId}`,
  STAKEHOLDER_DASHBOARD: (companyId) =>
    `${API_BASE_URL}/stakeholder/dashboard-statistics/${companyId}`,
  GET_STAKEHOLDER_REPORTS_LIST: (companyId, year) =>
    `${API_BASE_URL}/stakeholder/get-reports/${companyId}?${year}`,
  GET_ORGANIZATION_COUNTRY: `${API_BASE_URL}/${getPrefix()}/listings/organization-countries`,
  SET_STAKEHOLDER_EMAIL: (userId, code) =>
    `${API_BASE_URL}/stakeholder/confirm-email/${userId}/${code}`,
  STAKEHOLDER_INVITE_LOGIN: `${API_BASE_URL}/stakeholder/set-password`,
  STAKEHOLDER_FORGOT_PASSWORD: `${API_BASE_URL}/stakeholder/forgot_password`,
  STAKEHOLDER_RESET_PASSWORD: `${API_BASE_URL}/stakeholder/reset_password`,
  GET_STAKEHOLDER_ASSIGNED_REPORTS: (companyId, reportId) =>
    `${API_BASE_URL}/stakeholder/get-report-details/${companyId}/${reportId}`,
  GET_STAKEHOLDER_AUDITED_REPORTS: (companyId, year) =>
    `${API_BASE_URL}/stakeholder/get-audited-report-details/${companyId}?year=${year}`,
  DOWNLOAD_STAKEHOLDER_REPORTS: (companyId, report_type, report_id) =>
    `${API_BASE_URL}/stakeholder/${companyId}/download-report?report_type=${report_type}&report_id=${report_id}`,
  DOWNLOAD_ASSIGNED_STAKEHOLDER_REPORTS: (companyId, report_type, report_id) =>
    `${API_BASE_URL}/stakeholder/${companyId}/download-report?report_type=${report_type}&report_id=${report_id}`,
  GET_STAKEHOLDER_TOPICS_SUMMARY: (companyId, type) =>
    `${API_BASE_URL}/stakeholder/get-topics-summary/${companyId}?report_type=${type}`,
  GET_STAKEHOLDER_TOPICS_DATA: (companyId, topic_type, year) =>
    `${API_BASE_URL}/stakeholder/topics-summary/${companyId}/${topic_type}?year=${year}`,
  GET_READ_ONLY_USER_ASSIGNED_REPORTS: (id) =>
    `${API_BASE_URL}/${getPrefix()}/reports/get-assigned-reports/${id}`,
  REVOKE_GRANT_REPORT_ACCESS: (stakeholder_id, report_id, status) =>
    `${API_BASE_URL}/${getPrefix()}/reports/revoke-grant-report-access/${stakeholder_id}?report_id=${report_id}&report_status=${status}`,
  STAKEHOLDER_ESG_OVERVIEW: (companyId) =>
    `${API_BASE_URL}/stakeholder/graph/get_esg_overview_graph/${companyId}`,
  STAKEHOLDER_EMISSION_BY_SCOPE_BY_YEAR: (companyId) =>
    `${API_BASE_URL}/stakeholder/graph/co2-emission-by-scope-by-year/${companyId}`,
  STAKEHOLDER_EMISSION_TYPE_GRAPH: (companyId) =>
    `${API_BASE_URL}/stakeholder/graph/emission-type/${companyId}`,
  GET_STAKEHOLDER_EMISSION_YEAR_DATA: (companyId) =>
    `${API_BASE_URL}/stakeholder/graph/emission-year/${companyId}`,
  GET_STAKEHOLDER_ENERGY_CONSUMPTION_GRAPH_DATA: (companyId) =>
    `${API_BASE_URL}/stakeholder/graph/energy-material-co2e/${companyId}`,
  GET_STAKEHOLDER_INITIATIVE_TRACKING_GRAPH_DATA: (companyId) =>
    `${API_BASE_URL}/stakeholder/graph/initiative-tracking/${companyId}`,
  GET_STAKEHOLDER_SANKEY_GRAPH_DATA: (companyId) =>
    `${API_BASE_URL}/stakeholder/graph/fuel-sources-target-sankey/${companyId}`,
  GET_STAKEHOLDER_EMISSION_BY_YEAR_GRAPH_DATA: (companyId) =>
    `${API_BASE_URL}/stakeholder/graph/emission-year/${companyId}`,
  GET_STAKEHOLDER_EMISSION_BY_TYPE_GRAPH_DATA: (companyId) =>
    `${API_BASE_URL}/stakeholder/graph/emission-type/${companyId}`,
  GET_STAKEHOLDER_EMISSION_SCOPE_BY_GRAPH_DATA: (companyId) =>
    `${API_BASE_URL}/stakeholder/graph/co2-emission-by-scope-by-year/${companyId}`,
  GET_STAKEHOLDER_DASHBOARD_STATISTICS_DATA: (companyId) =>
    `${API_BASE_URL}/stakeholder/dashboard-statistics/${companyId}`,

  GET_STAKEHOLDER_EMPLOYEE_DIVERSITY_GRAPH_DATA: (companyId) =>
    `${API_BASE_URL}/stakeholder/graph/get-employee-diversity/${companyId}`,
  GET_STAKEHOLDER_DEVELOPMENT_TRAINING_GRAPH_DATA: (companyId) =>
    `${API_BASE_URL}/stakeholder/graph/get-development-training/${companyId}`,
  GET_STAKEHOLDER_EMPLOYEE_HIRE_RESIGN_TREND_GRAPH_DATA: (companyId) =>
    `${API_BASE_URL}/stakeholder/graph/get-employee-hire-resign-trends/${companyId}`,
  GET_STAKEHOLDER_SOCIAL_ENGAGEMENT_GRAPH_DATA: (companyId) =>
    `${API_BASE_URL}/stakeholder/graph/get-social-engagement-graph/${companyId}`,
  GET_STAKEHOLDER_EMPLOYEE_TURNOVER_RATE_GRAPH_DATA: (companyId) =>
    `${API_BASE_URL}/stakeholder/graph/get-employee-turnover-rate/${companyId}`,
  GET_STAKEHOLDER_INITIATIVE_TRACKING_DRILLDOWN_GRAPH_DATA: (companyId) =>
    `${API_BASE_URL}/stakeholder/graph/initiative-tracking-drill-down/${companyId}`,
  GET_STAKEHOLDER_ENERGY_MATERIAL_DRILLDOWN_GRAPH_DATA: (companyId) =>
    `${API_BASE_URL}/stakeholder/graph/energy-material-drill-down/${companyId}`,
  GET_STAKEHOLDER_ETHICAL_BEHAVIOUR_GRAPH_DATA: (companyId) =>
    `${API_BASE_URL}/stakeholder/graph/get-ethical-behaviour-data/${companyId}`,
  GET_STAKEHOLDER_ECONOMIC_IMPACT_GRAPH_DATA: (companyId) =>
    `${API_BASE_URL}/stakeholder/graph/get-economic-impact/${companyId}`,
  GET_STAKEHOLDER_ANTI_CORRUPTION_TRAINING_GRAPH_DATA: (companyId) =>
    `${API_BASE_URL}/stakeholder/graph/get-anti-corruption-training/${companyId}`,
  GET_STAKEHOLDER_ANTI_CORRUPTION_DISCLOSURE_GRAPH_DATA: (companyId) =>
    `${API_BASE_URL}/stakeholder/graph/get-anti-corruption-disclosure/${companyId}`,
  GET_STAKEHOLDER_BOARD_MANAGEMENT_DIVERSITY_GRAPH_DATA: (companyId) =>
    `${API_BASE_URL}/stakeholder/graph/get-board-management-diversity/${companyId}`,

  GET_STAKEHOLDER_ACCOUNT: (role) => `${API_BASE_URL}/${role}/account-details`,
  STAKEHOLDER_CHANGE_PSWD: `${API_BASE_URL}/stakeholder/change_password`,
  GET_NEWS: (timeStamp) =>
    `${API_BASE_URL}/business/graph/news?utc_timestamp=${timeStamp}`,
  GET_AUDITOR_DASHBOARD: `${API_BASE_URL}/auditor/dashboard-statistics`,
  GET_AUDIT_STATUS_GRAPH: (role) =>
    `${API_BASE_URL}/${role}/graph/get_audit_status_graph`,
  GET_AUDIT_TREND_GRAPH: (role) => `${API_BASE_URL}/${role}/graph/audit-trend`,
  GET_ESG_OVERVIEW_GRAPH: (role) =>
    `${API_BASE_URL}/${role}/graph/get_esg_overview_graph`,
  GET_RECORD_STATUS: `${API_BASE_URL}/business/graph/emission-status`,
  GET_TICKET_STATUS: (role) => `${API_BASE_URL}/${role}/graph/ticket-status`,
  FACILITY_DISTRIBUTION: `${API_BASE_URL}/business/graph/facility-distribution-graph`,
  UPDATE_STAKEHOLDER_ACCOUNT_DETAILS: `${API_BASE_URL}/stakeholder/update`,
  UPDATE_AUDITOR_COMPANY: `${API_BASE_URL}/auditor/company/update-company-details`,
  GET_CUMULATIVE_TOPICS: `${API_BASE_URL}/${getPrefix()}/reports/get-cumulative-topic-data`,

  GET_FACILITY_PERIODS: (year, facility) =>
    `${API_BASE_URL}/business/get_facility_audit_periods?year=${year}&facility=${facility}`,
  GET_APPROVAL_TOPIC_STATUS: (status_id) =>
    `${API_BASE_URL}/${getPrefix()}/get-topics-status?monthly_approval_status_id=${status_id}`,
  ROLE_BASED_APPROVAL: (statusId, emissionType, categoryType) =>
    `${API_BASE_URL}/${getPrefix()}/list-topic-based-on-role?monthly_approval_status_id=${statusId}&emission_type=${emissionType}&category_type=${categoryType}`,
  SUBMIT_MONTHLY_APPROVAL: (statusId, categoryType) =>
    `${API_BASE_URL}/${getPrefix()}/monthly-approval_summary-assign-to-submit/${statusId}?category_type=${categoryType}`,
  ASSIGN_MONTHLY_APPROVAL: (statusId) =>
    `${API_BASE_URL}/${getPrefix()}/monthly-approval_summary-assign-to-approve/${statusId}`,
  APPROVE_MONTHLY_APPROVAL: (statusId, categoryType) =>
    `${API_BASE_URL}/${getPrefix()}/monthly-approval-summary-approve/${statusId}?category_type=${categoryType}`,
  PENDING_APPROVALS: (year, auditId, facility) =>
    `${API_BASE_URL}/${getPrefix()}/request-approval-overview?year=${year}&audit_id=${auditId}&facility_id=${facility}`,
  ADD_REVIEW_COMMENTS: (statusId) =>
    `${API_BASE_URL}/${getPrefix()}/reject-topic?monthly_approval_status_id=${statusId}`,

  GET_REFRESH_TOKEN_BUSINESS: `${API_BASE_URL}/business/refresh_token`,
  GET_REFRESH_TOKEN_STAKEHOLDER: `${API_BASE_URL}/stakeholder/refresh_token`,
  GET_REFRESH_TOKEN_AUDITOR: `${API_BASE_URL}/auditor/refresh_token`,
  GET_REFRESH_TOKEN_ADMIN: `${API_BASE_URL}/admin/refresh_token`,

  SET_ACTIVE_ROLE: (role) =>
    `${API_BASE_URL}/business/set_active_role?active_role=${role}`,
  ROLE_DISTRIBUTION: `${API_BASE_URL}/business/graph/role-distribution`,

  GET_ASSESSMENT_PERIOD: (year, facilityId, emissionType, emissionId) => {
    let url = `${API_BASE_URL}/business/get-assessment-period?year=${year}&facility_id=${facilityId}`;
    if (emissionType) {
      url += `&emission_type=${emissionType}`;
    }
    if (emissionId) {
      url += `&emission_id=${emissionId}`;
    }
    return url;
  },
  ADD_EMPLOYEE_HIRE: `${API_BASE_URL}/${getPrefix()}/emissions/employees-hire-turnover`,
  ADD_EMPLOYEE_BENEFIT: `${API_BASE_URL}/${getPrefix()}/emissions/employees-benefit`,
  UPLOAD_ATTACHMENT: `${API_BASE_URL}/${getPrefix()}/upload-attachment`,
  ADD_EMPLOYEE_TRAINING_HOURS: `${API_BASE_URL}/${getPrefix()}/emissions/employees-training-hours`,
  UPDATE_EMPLOYEE_TRAINING_HOURS: (employee_training_id) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/employees-training-hours/${employee_training_id}`,
  ADD_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS: `${API_BASE_URL}/${getPrefix()}/emissions/performance-and-career-development-programs`,
  UPDATE_PERFORMANCE_AND_CAREER_DEVELOPMENT_PROGRAMS: (program_id) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/performance-and-career-development-programs/${program_id}`,
  ADD_EMPLOYEE_DIVERSITY: `${API_BASE_URL}/${getPrefix()}/emissions/employees-diversity-record`,
  ADD_ECONOMIC_IMPACT: `${API_BASE_URL}/${getPrefix()}/emissions/economic_impact`,
  UPDATE_ECONOMIC_IMPACT: (economic_impact_id) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/economic_impact/${economic_impact_id}`,
  UPDATE_EMPLOYEE_DIVERSITY: (employee_diversity_record_id) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/employees-diversity-record/${employee_diversity_record_id}`,
  UPDATE_EMPLOYEE_BENEFITS: (employees_benefit_id) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/employees-benefit/${employees_benefit_id}`,
  UPDATE_EMPLOYEE_HIRE: (employees_hire_id) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/employees-hire-turnover/${employees_hire_id}`,
  APPROVE_AUDIT_BY_SUSMGR: (auditId) =>
    `${API_BASE_URL}/business/approve-audit-status/${auditId}`,
  GET_AUDIT_TYPES: (year, status) =>
    `${API_BASE_URL}/business/get-audit-type?status=${status}&year=${year}`,
  UPDATE_AUDIT_TYPES: (auditId, selfAudit) =>
    `${API_BASE_URL}/business/set-audit-type?audit_id=${auditId}`,
  GENERAL_USER_LOGIN: `${API_BASE_URL}/business/general-token`,
  GET_APPROVAL_EMISSION: (statusId, emissionType, categoryType) =>
    `${API_BASE_URL}/business/approval-emission-overview?monthly_approval_status_id=${statusId}&emission_type=${emissionType}&category_type=${categoryType}`,
  GET_TOPIC_BASED_APPROVAL: (statusId, emissionType, categoryType) =>
    `${API_BASE_URL}/business/list-topic-based-on-role?monthly_approval_status_id=${statusId}&emission_type=${emissionType}&category_type=${categoryType}`,
  ASSIGN_TO_SUBMIT: (statusId, categoryType) =>
    `${API_BASE_URL}/business/monthly-approval_summary-assign-to-submit/${statusId}?category_type=${categoryType}`,
  PENDING_APPROVAL_EMISSION: (statusId, emissionType, categoryType) =>
    `${API_BASE_URL}/business/pending-approval-emission-overview?monthly_approval_status_id=${statusId}&emission_type=${emissionType}&category_type=${categoryType}`,
  GET_APPROVED_DATA: (statusId, categoryType) =>
    `${API_BASE_URL}/business/monthly-approval-summary-approve/${statusId}?category_type=${categoryType}`,
  GET_STAKEHOLDER_CUMULATIVE_DATA: (companyId, reportId, type) =>
    `${API_BASE_URL}/stakeholder/topics-cumulative-details/${companyId}/${reportId}?report_type=${type}`,
  GENERATE_AUDITED_REPORT_BY_SM: (payload) =>
    `${API_BASE_URL}/business/generate-audited-report?year=${payload.year}&audit_id=${payload.auditId}`,
  CLEAR_OTP: (userId) => `${API_BASE_URL}/business/clear-otp?user_id=${userId}`,
  RESEND_OTP: (userId) =>
    `${API_BASE_URL}/business/get-verification-code?user_id=${userId}`,
  UPDATE_TICKET: (ticketID) =>
    `${API_BASE_URL}/${getPrefix()}/tickets/${ticketID}`,

  ADD_VISION: `${API_BASE_URL}/business/add-vision`,
  GET_VISION: `${API_BASE_URL}/business/get-vision`,
  ADD_MISSION: `${API_BASE_URL}/business/add-mission`,
  EDIT_MISSION: (missionId) =>
    `${API_BASE_URL}/business/edit-mission/${missionId}`,
  GET_MISSION: `${API_BASE_URL}/business/get-missions`,
  GET_INITIATIVE_DETAILS: (initiativeId) =>
    `${API_BASE_URL}/business/get-initiative-details/${initiativeId}`,
  ADD_INITIATIVE: `${API_BASE_URL}/business/add-initiative`,
  LIST_INITIATIVE: `${API_BASE_URL}/business/list-initiative?`,
  ADD_TASKS: `${API_BASE_URL}/tasks/`,
  GET_TASKS_LIST: (Id) => `${API_BASE_URL}/tasks/?initiative_id=${Id}`,
  SAVE_TASK_TRACKING: (Id) => `${API_BASE_URL}/tasks/task-tracking/${Id}`,
  LIST_INITIATIVES_DEFAULT: `${API_BASE_URL}/business/list-initiative`,
  ADD_TARGET_ACTUAL: `${API_BASE_URL}/business/add-target-actual-values`,
  GET_INITIATIVE_TRACKING: `${API_BASE_URL}/${getPrefix()}/graph/initiative-tracking`,
  GET_EMPLOYEE_DIVERSITY: `${API_BASE_URL}/${getPrefix()}/graph/get-employee-diversity`,
  GET_DEVELOPMENT_TRAINING: `${API_BASE_URL}/${getPrefix()}/graph/get-development-training`,
  GET_SOCIAL_ENGAGEMENT: `${API_BASE_URL}/${getPrefix()}/graph/get-social-engagement-graph`,
  GET_HIRE_RESIGN_GRAPH: `${API_BASE_URL}/${getPrefix()}/graph/get-employee-hire-resign-trends`,
  GET_BOARD_MANAGEMENT_GRAPH: `${API_BASE_URL}/${getPrefix()}/graph/get-board-management-diversity`,
  GET_EMPLOYEE_TURNOVER_GRAPH: `${API_BASE_URL}/${getPrefix()}/graph/get-employee-turnover-rate`,
  GET_ECONOMIC_GRAPH: `${API_BASE_URL}/${getPrefix()}/graph/get-economic-impact`,
  GET_ETHICAL_BEHAVIOUR_GRAPH: `${API_BASE_URL}/${getPrefix()}/graph/get-ethical-behaviour-data`,
  GET_ANTI_CORRUPTION_TRAINING: `${API_BASE_URL}/${getPrefix()}/graph/get-anti-corruption-training`,
  GET_ANTI_CORRUPTION_DISCLOSURE: `${API_BASE_URL}/${getPrefix()}/graph/get-anti-corruption-disclosure`,
  GET_TRACKING_CYCLE_ASSESSMENT_PERIOD: (cycle) =>
    `${API_BASE_URL}/tasks/get-assessment-period-based-on-tracking-cycle?tracking_cycle=${cycle}`,
  UPDATE_COMPANY_SUBSCRIPTION: (companyId) =>
    `${API_BASE_URL}/admin/company/${companyId}/company-subscription`,
  GET_INITIATIVE_TRACKING_DRILL_DOWN: `${API_BASE_URL}/${getPrefix()}/graph/initiative-tracking-drill-down`,
  GET_ENERGY_MATERIALS_DRILL_DOWN: `${API_BASE_URL}/${getPrefix()}/graph/energy-material-drill-down`,
  EDIT_TASK: (Id) => `${API_BASE_URL}/tasks/${Id}`,
  SET_AUDIT_TOPICS: (Id) =>
    `${API_BASE_URL}/${getPrefix()}/set-audit-topics?auditor_id=${Id}`,
  CREATE_BUSINESS_ADMIN: (companyId) =>
    `${API_BASE_URL}/admin/create_business_admin/${companyId}`,
  UPDATE_BUSINESS_ADMIN_STATUS: (userId) =>
    `${API_BASE_URL}/admin/unlock-user/${userId}`,
  GET_USER_LOGS: (userId) =>
    `${API_BASE_URL}/${getPrefix()}/users/user-logs?user_id=${userId}`,
  LOCK_UNLOCK_USER: (userId, status) =>
    `${API_BASE_URL}/${getPrefix()}/users/${userId}/${status}`,
  GET_RECENT_LOGS: (userId) =>
    `${API_BASE_URL}/${getPrefix()}/users/recent-log?user_id=${userId}`,
  ADMIN_TWOFA_OTP_GENERATE: (email) =>
    `${API_BASE_URL}/business/get-otp?email=${email}`,
  ADMIN_TWOFA_OTP_SUBMIT: () => `${API_BASE_URL}/business/check-otp`,
  ADMIN_PASSWORD_SUBMIT: () => `${API_BASE_URL}/business/set-otp-password`,
  GET_SUBSCRIPTION_DETAILS: `${API_BASE_URL}/${getPrefix()}/get-company-subscription-details`,
  GET_SUBSCRIPTION_DETAILS_STAKEHOLDER: (companyId) =>
    `${API_BASE_URL}/stakeholder/get-company-subscription-details/${companyId}`,
  SUPERADMIN_CHANGE_PASSWORD: `${API_BASE_URL}/admin/change_password`,

  CREATE_SUBSCRIPTION_PLAN: (companyId, subscriptionId, duration) =>
    `${API_BASE_URL}/admin/subscriptions/${companyId}?subscription_id=${subscriptionId}&duration=${duration}`,

  GET_SUBSCRIPTION_DETAILS_SUPERADMIN: `${API_BASE_URL}/admin/subscriptions/`,

  GET_ALL_SUBSCRIPTION_PLANS_SUPERADMIN: (companyId) =>
    `${API_BASE_URL}/admin/subscriptions/${companyId}`,
  GET_STATES: (country) => `${API_BASE_URL}/business/get-states/${country}`,
  GET_SUPERADMIN_ACCOUNT_DETAILS: () => `${API_BASE_URL}/admin/account-details`,
  UPDATE_SUPERADMIN_ACCOUNT_DETAILS: (role) =>
    `${API_BASE_URL}/admin/update-account`,

  GET_APPROVAL_FLOW: (country, year) =>
    `${API_BASE_URL}/business/company_approval_flow?year=${year}&country=${country}`,
  UPDATE_APPROVAL_FLOW: () =>
    `${API_BASE_URL}/business/update_company_approval_flow`,

  GET_REPORT_BLOB: (Id) =>
    `${API_BASE_URL}/${getPrefix()}/reports/is-report-blob-uploaded-or-not?report_id=${Id}`,

  //Trade
  SIGNUP_TRADE: `${API_BASE_URL}/trade/signup`,
  LOGIN_TRADE: `${API_BASE_URL}/trade/token`,
  INDUSTRY_TYPES: `${API_BASE_URL}/trade/industry_types`,
  GET_LOCATIONS: `${API_BASE_URL}/trade/countries`,
  SELL_CARBON_CREDITS: `${API_BASE_URL}/trade/dashboard/sell-carbon-credit`,
  GET_CARBON_CREDITS_ADDED: `${API_BASE_URL}/trade/carbon-credits/added-carbon-credits`,
  BUY_CARBON_CREDIT_LIST: (search) =>
    `${API_BASE_URL}/trade/carbon-credits/?project_name=${search}`,
  GET_PROJECT_DETAILS: (projectId) =>
    `${API_BASE_URL}/trade/carbon-credits/carbon-credit/${projectId}`,
  GET_TRADE_ACCOUNT: `${API_BASE_URL}/trade/account/`,
  UPDATE_TRADE_ACCOUNT: `${API_BASE_URL}/trade/account/`,
  GET_SUPER_ADMIN_REPORT: (companyId) =>
    `${API_BASE_URL}/admin/company/${companyId}/reports`,
  GET_SUPER_ADMIN_ORGANIZATION_COUNTRY: (companyId) =>
    `${API_BASE_URL}/admin/company/${companyId}/organization-countries`,
  UPLOAD_COMPANY_LOGO: `${API_BASE_URL}/business/company-logo`,
  ASSIGN_AUDITOR: `${API_BASE_URL}/${getPrefix()}/assign-auditor`,

  GET_MATERIALITY_INDUSTRIES: `${API_BASE_URL}/business/materiality/materiality-industries`,
  GET_MATERIALITY_QUESTIONS: `${API_BASE_URL}/business/materiality/questions`,
  CREATE_MATERIALITY_QUESTIONS: `${API_BASE_URL}/business/materiality/questions`,
  SET_INDUSTRY_TYPE: (industry) =>
    `${API_BASE_URL}/business/materiality/set-industry-type?industry_type_id=${industry}`,
  GET_SURVEY: `${API_BASE_URL}/business/materiality/survey`,
  SEND_QUESTIONNAIRES: `${API_BASE_URL}/business/materiality/send-questionnaires`,

  AUDITOR_REACTION: (companyId, auditId) =>
    `${API_BASE_URL}/auditor/company/${companyId}/react-topic?audit_status_id=${auditId}`,
  AUDITOR_QUALITATIVE_QUESTION: (companyId, audit_status_id) =>
    `${API_BASE_URL}/auditor/company/${companyId}/audit-qualitative-qa-data/${audit_status_id}`,

  GET_SUPERADMIN_TICKETS: (payload) =>
    `${API_BASE_URL}/admin/tickets?ticket_type=${payload.ticketType}&ticket_status=${payload.ticketStatus}&year=${payload.year}&company=${payload.company}`,
  GET_SUPERADMIN_TICKET_DETAILS: (ticketId) =>
    `${API_BASE_URL}/admin/tickets/${ticketId}`,
  ADD_SUPERADMIN_RESPONSE: (ticketId) =>
    `${API_BASE_URL}/admin/tickets/response/${ticketId}`,
  CLOSE_SUPERADMIN_TICKET: (ticketId) =>
    `${API_BASE_URL}/admin/tickets/${ticketId}/close`,

  LIST_AUDITOR_TICKETS: (payload) =>
    `${API_BASE_URL}/auditor/external/tickets/?ticket_type=${payload.ticketType}&ticket_status=${payload.ticketStatus}&year=${payload.year}`,
  GET_AUDITOR_TICKET_DETAILS: (payload) =>
    `${API_BASE_URL}/auditor/external/tickets/${payload.id}`,
  CREATE_TICKET_AUDITOR: `${API_BASE_URL}/auditor/external/tickets/`,
  CLOSE_TICKET_AUDITOR: (payload) =>
    `${API_BASE_URL}/auditor/external/tickets/${payload.id}/close`,
  ADD_RESPONSE_AUDITOR: (payload) =>
    `${API_BASE_URL}/auditor/external/tickets/response/${payload.id}`,

  LIST_CARBON_OFFSET_PROGRAMS: (location, programType) => {
    let url = `${API_BASE_URL}/business/carbon-offset/?`;
    if (location) {
      url += `&location=${location}`;
    }
    if (programType) {
      url += `&program_type=${programType}`;
    }
    if (location && programType) {
      url += `&location=${location}&program_type=${programType}`;
    }
    return url;
  },
  ADD_CARBON_OFFSET: `${API_BASE_URL}/business/carbon-offset/`,
  GET_PROGRAM_TYPES: `${API_BASE_URL}/${getPrefix()}/carbon-offset/program_types`,
  GET_PROGRAMS: (programId) =>
    `${API_BASE_URL}/business/carbon-offset/programs?program_type_id=${programId}`,
  GET_OFFSET_DETAILS: (programId) =>
    `${API_BASE_URL}/${getPrefix()}/carbon-offset/${programId}`,
  GET_IOT_DATA: (programId, year) =>
    `${API_BASE_URL}/business/carbon-offset/iot-data/${programId}?year=${year}`,
  UPDATE_IOT_DATA: (dataId) =>
    `${API_BASE_URL}/business/carbon-offset/iot-data/${dataId}`,
  REFRESH_IOT_DATA: (programId) =>
    `${API_BASE_URL}/business/carbon-offset/refresh-iot-data/${programId}`,
  GET_OFFSET_COUNTRIES: `${API_BASE_URL}/business/carbon-offset/country-list`,
  GET_SURVEY_QUESTIONS: (answerId) =>
    `${API_BASE_URL}/business/materiality/survey/answer/${answerId}`,
  ANSWER_SURVEY_QUESTIONS: (answerId) =>
    `${API_BASE_URL}/business/materiality/survey/answer/${answerId}`,
  GET_ASSESSMENT_YEAR: (countryCode) =>
    `${API_BASE_URL}/business/assessment-year?country_code=${countryCode}`,

  GET_NFT_URI: `${API_BASE_URL}/admin/block-chain/`,
  GENERATE_BLOCKCHAIN_CERTIFICATE: `${API_BASE_URL}/admin/block-chain/generate-blockchain-certificate`,
  GET_NFT_LIST: `${API_BASE_URL}/admin/block-chain/`,
  GET_NFT_CERTIFICATE: `${API_BASE_URL}/admin/block-chain/get-certificate`,
  VIEW_CERTIFICATE: `${API_BASE_URL}/business/get-certificate`,
  GET_NFT_DETAILS: (id) => `${API_BASE_URL}/admin/block-chain/${id}`,
  GET_SURVEY_LIST: (year) =>
    `${API_BASE_URL}/business/materiality/surveys?year=${year}`,
  TRACK_SURVEY: (surveyId) =>
    `${API_BASE_URL}/business/materiality/track/${surveyId}`,
  SEND_REMINDER: (id) =>
    `${API_BASE_URL}/business/materiality/send-reminder?respondent_id=${id}`,
  SURVEY_RESPONSE: (answerId) =>
    `${API_BASE_URL}/business/materiality/survey-response?answer_id=${answerId}`,
  SURVEY_QUESTIONS: (surveyId) =>
    `${API_BASE_URL}/business/materiality/track/survey-questions?survey_id=${surveyId}`,
  GET_ADMIN_REPORT_DETAILS: (companyId, countryCode, facilityId, year) =>
    `${API_BASE_URL}/admin/company/${companyId}/report-status/${countryCode}/${facilityId}/${year}`,
  GENERATE_ANNOUNCEMENT: `${API_BASE_URL}/admin/notification/`,
  UPDATE_ANNOUNCEMENT: (announcementId) =>
    `${API_BASE_URL}/admin/notification/${announcementId}`,
  GET_ANNOUNCEMENT: (announcementId) =>
    `${API_BASE_URL}/admin/notification/${announcementId}`,
  LIST_ANNOUNCEMENT: `${API_BASE_URL}/admin/notification/`,
  GET_NOTIFICATIONS: (timeStamp) =>
    `${API_BASE_URL}/business/notifications/prompt-based-assessment-cycle?utc_timestamp=${timeStamp}`,
  GET_DASHBOARD_WIDGET: `${API_BASE_URL}/trade/dashboard/widget`,
  GET_CARBON_EMISSIONS: `${API_BASE_URL}/trade/dashboard/carbon-emissions`,
  GET_COUNTRY_FACILITY_AGGREGATE: (companyId, countryCode, facilityId) =>
    `${API_BASE_URL}/admin/company/${companyId}/country-facility-aggregate?country=${countryCode}&facility=${facilityId}`,
  GET_ROLE_BASED_AGGREGATE: (companyId, facilityId) =>
    `${API_BASE_URL}/admin/company/${companyId}/role-based-aggregate/${facilityId}`,
  GET_SUPPLIER_QUESTIONS: (industryId) =>
    `${API_BASE_URL}/business/supplier-assessment/questions/${industryId}`,
  CREATE_SUPPLIER_QUESTIONS: (industryId) =>
    `${API_BASE_URL}/business/supplier-assessment/questions/${industryId}`,
  GET_GENERIC_QUESTIONS: `${API_BASE_URL}/business/supplier-assessment/generic-questions`,
  SEND_SUPPLIER_QUESTIONS: `${API_BASE_URL}/business/supplier-assessment/survey`,
  DOWNLOAD_AUDIT_REPORT: `${API_BASE_URL}/${getPrefix()}/download-audit-report`,
  GET_USER_TYPE: (userType) =>
    `${API_BASE_URL}/trade/dashboard/change-user-type?user_type=${userType}`,
  GET_SUPPLIER_SURVEY_QUESTIONS: (answerId) =>
    `${API_BASE_URL}/business/supplier-assessment/survey-questions/${answerId}`,
  SUPPLIER_SURVEY_RESPONSE: (answerId) =>
    `${API_BASE_URL}/business/supplier-assessment/survey/answer/${answerId}`,
  SURVEY_FILE_UPLOAD: (answerId, questionId) =>
    `${API_BASE_URL}/business/supplier-assessment/survey/answer/upload-blob/${answerId}/${questionId}`,
  GET_USER_GRAPH_LIST: () => `${API_BASE_URL}/business/dashboard-widget-list`,
  SAVE_USER_GRAPH_CONFIG: () => `${API_BASE_URL}/business/configure-dashboard`,
  GET_USER_GRAPH_CONFIG: () =>
    `${API_BASE_URL}/business/dashboard-configurations`,
  GET_SUPPLIER_SURVEYS: (year) =>
    `${API_BASE_URL}/business/supplier-assessment/surveys?year=${year}`,
  GET_SUPPLIER_RESPONDENT_LIST: (id) =>
    `${API_BASE_URL}/business/supplier-assessment/track/${id}`,
  SEND_RESPONDENT_REMINDER: (id) =>
    `${API_BASE_URL}/business/supplier-assessment/send-reminder?respondent_id=${id}`,
  GET_SUPPLIER_SURVEY_TRACK_QUESTIONS: (id) =>
    `${API_BASE_URL}/business/supplier-assessment/track/survey-questions/${id}`,
  GET_SUPPLIER_SURVEY_TRACK_ANSWERS: (id) =>
    `${API_BASE_URL}/business/supplier-assessment/track/answers/${id}`,

  GET_STATES_PUBLIC: (country) =>
    `${API_BASE_URL}/business/get-states-public/${country}`,
  GET_OFFSET_IOT_DATA_DETAILS: (id, year, period) =>
    `${API_BASE_URL}/business/carbon-offset/iot-data/${id}?year=${year}&period=${period}`,
  GET_OVERALL_APPROVAL_STATUS: (facilityId, year, auditCycle) =>
    `${API_BASE_URL}/business/carbon-offset/overall-approval-status/request_approval?assessment_year=${year}&facility_id=${facilityId}&audit_cycle=${auditCycle}`,
  GET_OVERVIEW_APPROVAL_STATUS: (facilityId, year, auditCycle) =>
    `${API_BASE_URL}/business/carbon-offset/overall-approval-status/approval_status?assessment_year=${year}&facility_id=${facilityId}&audit_cycle=${auditCycle}`,
  GET_OVERALL_REQUEST_APPROVAL_STATUS: (facilityId, year, auditCycle) =>
    `${API_BASE_URL}/business/carbon-offset/approval-overview/request_approval?assessment_year=${year}&facility_id=${facilityId}&audit_cycle=${auditCycle}`,
  GET_OFFSET_APPROVAL_STATUS: (facilityId, year, auditCycle) =>
    `${API_BASE_URL}/business/carbon-offset/approval-overview/approval_status?assessment_year=${year}&facility_id=${facilityId}&audit_cycle=${auditCycle}`,
  REVIEW_OFFSET_DATA: (programId, year, period) =>
    `${API_BASE_URL}/business/carbon-offset/approve-iot-data/review?assessment_year=${year}&program_id=${programId}&period=${period}`,
  APPROVE_OFFSET_DATA: (programId, year, period) =>
    `${API_BASE_URL}/business/carbon-offset/approve-iot-data/approve?assessment_year=${year}&program_id=${programId}&period=${period}`,
  SUBMIT_OFFSET_DATA: (programId, year, period) =>
    `${API_BASE_URL}/business/carbon-offset/approve-iot-data/submit?period=${period}&assessment_year=${year}&program_id=${programId}`,
  GET_SUPPLIER_SURVEY_RESULTS: (year, industry) =>
    `${API_BASE_URL}/business/supplier-assessment/survey-results?year=${year}&industry=${industry}`,
  GET_AUDITOR_CARBON_OFFSET_LIST: (companyId, auditId) =>
    `${API_BASE_URL}/auditor/company/${companyId}/audit-offset-overall-status/${auditId}`,
  GET_AUDITOR_CARBON_OFFSET_DETAILS: (companyId, programId) =>
    `${API_BASE_URL}/auditor/company/${companyId}/carbon-offset-program/${programId}`,
  GET_AUDITOR_CARBON_OFFSET_IOT_DATA: (companyId, programId, year, period) =>
    `${API_BASE_URL}/auditor/company/${companyId}/iot-data/${programId}/${year}/${period}`,
  VALIDATE_AUDITOR_CARBON_OFFSET_DATA: (companyId, year, period, programId) =>
    `${API_BASE_URL}/auditor/company/${companyId}/carbon-offset/verify?assessment_year=${year}&period=${period}&program_id=${programId}`,
  REVIEW_AUDITOR_CARBON_OFFSET_DATA: (companyId, year, period, programId) =>
    `${API_BASE_URL}/auditor/company/${companyId}/carbon-offset/review?assessment_year=${year}&period=${period}&program_id=${programId}`,
  GET_CARBON_OFFSET_FORM_DETAILS: (id) =>
    `${API_BASE_URL}/${getPrefix()}/carbon-offset/carbon-offset-approvals/${id}`,
  UPDATE_CARBON_OFFSET_FORM_DETAILS: (id) =>
    `${API_BASE_URL}/business/carbon-offset/update-offset-program/${id}`,
  COMPANY_FUNCTIONALITY_SETUP: (companyId) =>
    `${API_BASE_URL}/admin/company/${companyId}/functionality-setup`,
  FUNCTIONALITY_VIEW: () => `${API_BASE_URL}/business/functionality-view`,
  GET_PRODUCTION_DATA_LIST: (year, country, facility) =>
    `${API_BASE_URL}/business/production-data/?year=${year}&country=${country}&facility=${facility}`,
  ADD_PRODUCTION_DATA: () => `${API_BASE_URL}/business/production-data/`,
  GET_PRODUCTION_EMISSION_GRAPH: `${API_BASE_URL}/${getPrefix()}/graph/total-co2-emission-against-production`,
  GET_PRODUCTION_SCOPE_GRAPH: `${API_BASE_URL}/${getPrefix()}/graph/scope-emission-against-production`,
  GET_FUNCTIONALITY_DATA: (companyId) =>
    `${API_BASE_URL}/admin/company/${companyId}/functionality-setup`,

  GET_SUSTAINABILITY_RISK_QUESTIONS: (industryId) =>
    `${API_BASE_URL}/business/sustainability-risk-assessment/questions/${industryId}`,
  GET_SUSTAINABILITY_RISK_GENERIC_QUESTIONS: `${API_BASE_URL}/business/sustainability-risk-assessment/generic-questions`,
  SEND_SUSTAINABILITY_RISK_QUESTIONS: `${API_BASE_URL}/business/sustainability-risk-assessment/survey`,
  GET_SUSTAINABILITY_RISK_SURVEY_QUESTIONS: (answerId) =>
    `${API_BASE_URL}/business/sustainability-risk-assessment/survey-questions/${answerId}`,
  SUSTAINABILITY_RISK_SURVEY_RESPONSE: (answerId) =>
    `${API_BASE_URL}/business/sustainability-risk-assessment/survey/answer/${answerId}`,
  SUSTAINABILITY_RISK_SURVEY_FILE_UPLOAD: (answerId, questionId) =>
    `${API_BASE_URL}/business/sustainability-risk-assessment/survey/answer/upload-blob/${answerId}/${questionId}`,
  GET_SUSTAINABILITY_RISK_SURVEYS: (year) =>
    `${API_BASE_URL}/business/sustainability-risk-assessment/surveys?year=${year}`,
  GET_SUSTAINABILITY_RISK_RESPONDENT_LIST: (id) =>
    `${API_BASE_URL}/business/sustainability-risk-assessment/track/${id}`,
  SEND_SUSTAINABILITY_RISK_RESPONDENT_REMINDER: (id) =>
    `${API_BASE_URL}/business/sustainability-risk-assessment/send-reminder?respondent_id=${id}`,
  GET_SUSTAINABILITY_RISK_SURVEY_TRACK_QUESTIONS: (id) =>
    `${API_BASE_URL}/business/sustainability-risk-assessment/track/survey-questions/${id}`,
  GET_SUSTAINABILITY_RISK_SURVEY_TRACK_ANSWERS: (id) =>
    `${API_BASE_URL}/business/sustainability-risk-assessment/track/answers/${id}`,
  GET_SUSTAINABILITY_RISK_SURVEY_RESULTS: (year) =>
    `${API_BASE_URL}/business/sustainability-risk-assessment/survey-results?year=${year}`,

  GET_SURVEY_INSIGHTS_LIST: (year) =>
    `${API_BASE_URL}/business/materiality/surveys-insights?year=${year}`,
  GET_SURVEY_INSIGHTS_DETAILS: (id) =>
    `${API_BASE_URL}/business/materiality/surveys-insights-details?id=${id}`,
  // GET_SUSTAINABILITY_RISK_SURVEY_QUESTIONS: (answerId) => `${API_BASE_URL}/business/sustainability-risk-assessment/survey-questions/${answerId}`,
  // SUSTAINABILITY_RISK_SURVEY_RESPONSE: (answerId) => `${API_BASE_URL}/business/sustainability-risk-assessment/survey/answer/${answerId}`,
  // SUSTAINABILITY_RISK_SURVEY_FILE_UPLOAD: (answerId, questionId) => `${API_BASE_URL}/business/sustainability-risk-assessment/survey/answer/upload-blob/${answerId}/${questionId}`,
  // GET_SUSTAINABILITY_RISK_SURVEYS:(year)=> `${API_BASE_URL}/business/sustainability-risk-assessment/surveys?year=${year}`,
  // GET_SUSTAINABILITY_RISK_RESPONDENT_LIST: (id) =>`${API_BASE_URL}/business/sustainability-risk-assessment/track/${id}`,
  // SEND_SUSTAINABILITY_RISK_RESPONDENT_REMINDER:(id) => `${API_BASE_URL}/business/sustainability-risk-assessment/send-reminder?respondent_id=${id}`,
  // GET_SUSTAINABILITY_RISK_SURVEY_TRACK_QUESTIONS: (id) =>`${API_BASE_URL}/business/sustainability-risk-assessment/track/survey-questions/${id}`,
  // GET_SUSTAINABILITY_RISK_SURVEY_TRACK_ANSWERS:(id) =>  `${API_BASE_URL}/business/sustainability-risk-assessment/track/answers/${id}`,
  // GET_SUSTAINABILITY_RISK_SURVEY_RESULTS:(year) => `${API_BASE_URL}/business/sustainability-risk-assessment/survey-results?year=${year}`,
  GET_ADMIN_ANNOUNCEMENT_LIST: (year, notification_type, notification_status) =>
    `${API_BASE_URL}/business/notifications/announcement?year=${year}&notification_type=${notification_type}&notification_status=${notification_status}`,
  ADD_ADMIN_ANNOUNCEMENT: () =>
    `${API_BASE_URL}/business/notifications/announcement`,
  EDIT_ADMIN_ANNOUNCEMENT: (id) =>
    `${API_BASE_URL}/business/notifications/announcement/${id}`,
  GET_ADMIN_ANNOUNCEMENT: (id) =>
    `${API_BASE_URL}/business/notifications/announcement/${id}`,
  GET_ADMIN_ANNOUNCEMENT_CHANGELOG: (id) =>
    `${API_BASE_URL}/business/notifications/announcement/change-log/${id}`,
  GET_STANDARDS_QUESTIONNAIRE: (id, assessment_id) =>
    `${API_BASE_URL}/business/standards/questionnaire/${id}?assessment_id=${assessment_id}`,
  SAVE_STANDARDS_QUESTIONNAIRE: (id) =>
    `${API_BASE_URL}/business/standards/questionnaire/${id}/save`,
  GET_STANDARDS_QUESTIONNAIRE_HISTORY: () =>
    `${API_BASE_URL}/business/standards/history`,
  VIEW_STANDARDS_QUESTIONNAIRE_ANSWER: (framework_id, questionnaire_id) =>
    `${API_BASE_URL}/business/standards/answers/${framework_id}?questionnaire_id=${questionnaire_id}`,
  SUBMIT_STANDARDS_QUESTIONNAIRE: (id) =>
    `${API_BASE_URL}/business/standards/questionnaire/${id}/submit`,
  GET_STANDARDS_QUESTIONNAIRE_ANSWERS: (
    framework_id,
    country,
    year,
    questionnaire_id
  ) =>
    `${API_BASE_URL}/business/standards/answers/${framework_id}?country=${country}&assessment_year=${year}`,
  APPROVE_STANDARDS_QUESTIONNAIRE: (questionnaire_id) =>
    `${API_BASE_URL}/business/standards/approve/${questionnaire_id}`,
  LIST_ASSESSMENT_CYCLE_COUNTRY: (country) =>
    `${API_BASE_URL}/business/list-company-assessment-cycle?country=${country}`,
  GET_DASHBOARD_OFFSET_STATISTICS: `${API_BASE_URL}/business/dashboard-offset-statistics`,
  GET_OFFSET_OVER_YEARS: `${API_BASE_URL}/business/offset-over-years`,
  GET_EMISSION_VS_OFFSET: `${API_BASE_URL}/business/emission-vs-offset`,
  AVERAGE_SCORE_SUPPLIER_INDUSTRY: `${API_BASE_URL}/business/dashboard-supplier-industry-score`,
  GET_SUSTAINABILITY_RISK_RATING: `${API_BASE_URL}/business/dashboard-sustainability-score`,
  DASHBOARD_MATERIALITY_DATA: `${API_BASE_URL}/business/dashboard-materiality-data`,
  DASHBOARD_SUSTAINABILITY_DATA_DETAILS: `${API_BASE_URL}/business/dashboard-sustainability-data-details`,
  DASHBOARD_SUPPLIER_DATA_DETAILS: `${API_BASE_URL}/business/dashboard-supplier-data-details`,
  INITIATIVE_ATTACHMENT: (id) =>
    `${API_BASE_URL}/business/initiative-attachments/${id}`,
  GET_COMPANY_NAME_DETAILS: `${API_BASE_URL}/business/dashboard`,
  REQUEST_REPORT_NFT: (id) =>
    `${API_BASE_URL}/business/reports/request-nft/${id}`,

  ADD_UPSTREAM_T_AND_D: `${API_BASE_URL}/${getPrefix()}/emissions/upstream_t_and_d`,
  ADD_DOWNSTREAM_T_AND_D: `${API_BASE_URL}/${getPrefix()}/emissions/downstream_t_and_d`,
  ADD_BUSINESS_TRAVEL: `${API_BASE_URL}/${getPrefix()}/emissions/business_travel`,
  ADD_PURCHASED_GOODS_AND_SERVICES: `${API_BASE_URL}/${getPrefix()}/emissions/purchased_goods_and_services`,
  UPDATE_UPSTREAM_T_AND_D: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/upstream_t_and_d/${emissionId}`,
  UPDATE_USE_OF_SOLD_PRODUCTS: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/use-of-sold-products/${emissionId}`,
  UPDATE_BUSINESS_TRAVEL: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/business_travel/${emissionId}`,
  UPDATE_EMPLOYEE_COMMUTE: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/employee_commuting/${emissionId}`,
  UPDATE_PURCHASED_GOODS_AND_SERVICES: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/purchased_goods_and_services/${emissionId}`,
  UPDATE_DOWNSTREAM_T_AND_D: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/downstream_t_and_d/${emissionId}`,
  ADD_FUEL_AND_ENERGY_RELATED_ACTIVITIES: `${API_BASE_URL}/${getPrefix()}/emissions/fuel_and_energy_related_activities`,
  ADD_CAPITAL_GOODS: `${API_BASE_URL}/${getPrefix()}/emissions/capital_goods`,
  UPDATE_CAPITAL_GOODS: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/capital_goods/${emissionId}`,
  ADD_FRANCHISE: `${API_BASE_URL}/${getPrefix()}/emissions/franchise`,
  UPDATE_FUEL_AND_ENERGY_RELATED_ACTIVITIES: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/fuel_and_energy_related_activities/${emissionId}`,
  ADD_USE_OF_SOLD_PRODUCTS: `${API_BASE_URL}/${getPrefix()}/emissions/use_of_sold_products`,
  ADD_UPSTREAM_LEASED_ASSET: `${API_BASE_URL}/${getPrefix()}/emissions/upstream_leased_asset`,
  ADD_DOWNSTREAM_LEASED_ASSET: `${API_BASE_URL}/${getPrefix()}/emissions/downstream_leased_asset`,
  ADD_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS: `${API_BASE_URL}/${getPrefix()}/emissions/end_of_life`,
  UPDATE_END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/end_of_life/${emissionId}`,

  ADD_PROCESSING_OF_SOLD_PRODUCTS: `${API_BASE_URL}/${getPrefix()}/emissions/processing_of_sold_products`,
  UPDATE_UPSTREAM_LEASED_ASSET: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/upstream_leased_asset/${emissionId}`,
  UPDATE_DOWNSTREAM_LEASED_ASSET: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/downstream_leased_asset/${emissionId}`,
  UPDATE_FRANCHISE: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/franchise/${emissionId}`,
  UPDATE_PROCESSING_OF_SOLD_PRODUCTS: (emissionId) =>
    `${API_BASE_URL}/${getPrefix()}/emissions/processing_of_sold_products/${emissionId}`,
};
