import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { months, sampleYear } from "../../../constants";
import { STATUS } from "../../../redux/constants";
import { addMobileCombustionValidation } from "./schema";
import {
  addMobileCombustion,
  getEmissionFuelList,
  resetAddCombustionStatus,
  updateFuelAndEnergyRelatedActivities
} from "../../../redux/actions";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroButton from "../../../components/CeroButton";
import CeroSelect from "../../../components/CeroSelect";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import DownloadIcon from "@mui/icons-material/Download";
import EmissionUploadDrawer from "../../EmissionList/EmissionUploadDrawer";
import { useNavigate } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CeroItemPair from "../../../components/CeroItemPair";
import FileUpload from "../../AddEmissions/FileUpload";

const EditFuelAndEnergy = (props) => {
  const { onCancel, onDownload, onUpload, onValidate, uploadDisabled,emissionData,emissionId } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const isCalculateDone = useSelector(
    (state) => state.emission.updateFuelAndEnergyRelatedActivities.isCalculateDone
  );
  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const activityTypesData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.activity_types
  );
  const fuelSourceData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.fuel_sources
  );
  const vehicleTypeData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.vehicle_types
  );
  const fuelUnitData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.units
  );
  const editEmissionData = useSelector(
    (state) => state.emission.updateFuelAndEnergyRelatedActivities
  );
  const emissionPerformData = useSelector((state) => state.emission.fuelList);
  const currentYear = new Date().getFullYear().toString();
  const [selectedFile, setSelectedFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      facility:emissionData.facility_id || "", 
      year: currentYear,
      month: emissionData.month || "",
      activityType: emissionData.activity_id || "",
      fuelType:emissionData.fuel_type_id,
      fuelSource: emissionData.fuel_source_id,
      fuelUnit: emissionData.fuel_unit || "",
      amountOfFuel: emissionData.amount_fuel || 0,
      calculationApproach: emissionData.calculation_approach_id || "",
      quantity:emissionData.amount_electricity || 0,
      electricityUnit:emissionData.electricity_unit
      ? emissionPerformData?.data?.electricity_unit?.find(
        (item) =>item.name ===emissionData.electricity_unit
     )?.id || ""
     :"",
    },
    validationSchema: addMobileCombustionValidation,
    onSubmit: () => {},
    enableReinitialize:true,
  });

  const selectedUnitType = activityTypesData.find(
    (item) => item.id === formik.values.activityType
  );

  // const selectedActivity = activityTypesData.find(item => item. === formik.values.activityType)
  const emittedVehicles = [
    "gasoline agricultural equipment",
    "gasoline ships and boats",
    "other gasoline non-road vehicles",
    "diesel agricultural equipment",
    "diesel ships and boats",
    "other diesel non-road vehicles",
  ];

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));
  const activityType = activityTypesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  const calculationNamesMap = {};
  const unitMap = {};
  const activityTypeList =
    emissionPerformData?.data?.activity?.map((item) => {
      calculationNamesMap[item.id] = item.name; // Populate namesMap
      return {
        key: item.id,
        value: item.name,
      };
    }) || [];
  const fuelTypeInputList = emissionPerformData?.data?.fuel_type?.map(
    (item) => ({
      key: item.id,
      value: item.name,
    })
  );
  const fuelSourceInputList = ()=>{
    return formik.values.fuelType
      ? emissionPerformData?.data?.fuel_sources
        ?.filter((item)=>item.fuel_type===formik.values.fuelType)
        ?.map((item) => ({
          key: item.id,
          value: item.name,
        })) || []
      :[];
  };
  const fuelUnitInputList = formik.values.fuelSource
    ? emissionPerformData?.data?.fuel_sources
        ?.find((item) => item.id === formik.values.fuelSource)
        ?.units?.map((item) => ({
          key: item,
          value: item,
        }))
    : [];
    
  const electricityUnitInputList =
    emissionPerformData?.data?.electricity_unit?.map((item) => {
      unitMap[item.id] = item.name;
      return {
        key: item.id,
        value: item.name,
      };
    });

  const calculationApproachInputList =
    emissionPerformData?.data?.calculation_method?.map((item) => ({
      key: item.id,
      value: item.name,
    }));

  useEffect(() => {
    dispatch(getEmissionFuelList("fuel_and_energy_related_activities"));
    return () => {
      dispatch(resetAddCombustionStatus());
    };
  }, [dispatch]);

  useEffect(() => {
    if (editEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Fuel and energy related activities updated successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (editEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        editEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
    }
  }, [editEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onCalculate = () => {
    const requestData = {
      id:emissionId,
      facility_id: formik.values.facility,
      year: formik.values.year,
      month: formik.values.month,
      activity_id: formik.values.activityType,
      fuel_type_id: formik.values.fuelType,
      fuel_source_id: formik.values.fuelSource,
      amount_fuel: parseFloat(formik.values.amountOfFuel),
      electricity_unit: unitMap[formik.values.electricityUnit],
      amount_electricity: formik.values.quantity,
      calculation_approach_id: formik.values.calculationApproach,
      fuel_unit: formik.values.fuelUnit,
      save: false,
    };
     dispatch(updateFuelAndEnergyRelatedActivities(requestData));
  };

  const onUpdateFuelAndEnergyData = () => {
    const requestData = {
      id:emissionId,
      facility_id: formik.values.facility,
      year: formik.values.year,
      month: formik.values.month,
      activity_id: formik.values.activityType,
      fuel_type_id: formik.values.fuelType,
      fuel_source_id: formik.values.fuelSource,
      amount_fuel: parseFloat(formik.values.amountOfFuel),
      electricity_unit: unitMap[formik.values.electricityUnit],
      amount_electricity: formik.values.quantity,
      calculation_approach_id: formik.values.calculationApproach,
      fuel_unit: formik.values.fuelUnit,
      save: true,
      file: selectedFile,
    };
    sessionStorage.setItem("year", requestData.year);
    dispatch(updateFuelAndEnergyRelatedActivities(requestData));
  };
  

  const [isOpenEmissionTypeUpload, setIsOpenEmissionTypeUpload] =
    useState(false);
  const onCloseDrawerUpload = () => {
    setIsOpenEmissionTypeUpload(false);
  };
  const handleFileUpload = (file) => {
    setSelectedFile(file);
    // dispatch(uploadEmissionAttachement("emissionId", file));
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
  };
  const info = () => {
    return (
      <div>
        <Typography variant="body2">
          Fuel use: Mobile combustion by the usage of fuel for mobile operations
          such as transport.
          <br />
          Distance activity: Distance travelled by the usage of fuel
        </Typography>
      </div>
    );
  };
  return (
    <>
      <div className={classes.backContainer}>
        <div
          className={classes.backButtonDiv}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon />
          Back
        </div>
        <div className={classes.templateButtonDiv}>
          {/* <CeroButton
            buttonText="Bulk Data Upload"
            className={classes.bulkButton}
            startIcon={<FileUploadIcon />}
            onClick={() => setIsOpenEmissionTypeUpload(true)}
          />
          <CeroButton
            buttonText="Download Template"
            className={classes.bulkButton}
            startIcon={<DownloadIcon />}
            onClick={onDownload}
          /> */}
        </div>
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Edit fuel and energy related activities
          </Typography>
          <Box className={classes.topContainer}>
            <Grid className={classes.dynamicBoard}>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroAutoComplete
                  id="year"
                  name="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.touched.year && formik.errors.year}
                  options={yearList}
                  value={formik.values.year}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="month"
                  name="month"
                  label="Month"
                  fullWidth
                  options={months}
                  selectedValue={formik.values.month}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.month && formik.errors.month}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="activityType"
                  name="activityType"
                  label="Activity type"
                  fullWidth
                  options={activityTypeList}
                  selectedValue={formik.values.activityType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.activityType && formik.errors.activityType
                  }
                />
              </Grid>

              {formik.values.activityType &&
                calculationNamesMap[
                  formik.values.activityType
                ]?.toLowerCase() ===
                  "upstream emissions of purchased fuels" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="fuelType"
                        name="fuelType"
                        label="Fuel Type"
                        fullWidth
                        options={fuelTypeInputList}
                        selectedValue={formik.values.fuelType}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.fuelType && formik.errors.fuelType
                        }
                        classes={{
                          input: classes.input,
                          container: classes.selectContainer,
                        }}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="fuelSource"
                        name="fuelSource"
                        label="Fuel Source"
                        fullWidth
                        options={fuelSourceInputList()}
                        selectedValue={formik.values.fuelSource}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.fuelSource && formik.errors.fuelSource
                        }
                        classes={{
                          input: classes.input,
                          container: classes.selectContainer,
                        }}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="amountOfFuel"
                        name="amountOfFuel"
                        label="Amount of Fuel"
                        value={formik.values.amountOfFuel}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.amountOfFuel &&
                          formik.errors.amountOfFuel
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="fuelUnit"
                        name="fuelUnit"
                        label="Fuel Unit"
                        fullWidth
                        options={fuelUnitInputList}
                        selectedValue={formik.values.fuelUnit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.fuelUnit && formik.errors.fuelUnit
                        }
                        sortOption={true}
                      />
                    </Grid>
                  </>
                )}
              {formik.values.activityType &&
                calculationNamesMap[
                  formik.values.activityType
                ]?.toLowerCase() ===
                  "upstream emissions of purchased electricity" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="calculationApproach"
                        name="calculationApproach"
                        label="Calculation Approach"
                        fullWidth
                        options={calculationApproachInputList}
                        selectedValue={formik.values.calculationApproach}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.calculationApproach &&
                          formik.errors.calculationApproach
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="quantity"
                        name="quantity"
                        label="Quantity of electricity, steam, heating, cooling consumed"
                        value={formik.values.quantity}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.quantity && formik.errors.quantity
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="electricityUnit"
                        name="electricityUnit"
                        label="Unit"
                        fullWidth
                        options={electricityUnitInputList || [{ name: "select" }]}
                        selectedValue={formik.values.electricityUnit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.electricityUnit && formik.errors.electricityUnit
                        }
                        sortOption={true}
                      />
                    </Grid>
                  </>
                )}
              {formik.values.activityType &&
                calculationNamesMap[
                  formik.values.activityType
                ]?.toLowerCase() ===
                  "emissions from transmission and distribution losses" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="calculationApproach"
                        name="calculationApproach"
                        label="Calculation Approach"
                        fullWidth
                        options={calculationApproachInputList}
                        selectedValue={formik.values.calculationApproach}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.calculationApproach &&
                          formik.errors.calculationApproach
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="quantity"
                        name="quantity"
                        label="Quantity of electricity, steam, heating, cooling consumed"
                        value={formik.values.quantity}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.quantity && formik.errors.quantity
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="electricityUnit"
                        name="electricityUnit"
                        label="electricityUnit"
                        fullWidth
                        options={electricityUnitInputList || [{ name: "select" }]}
                        selectedValue={formik.values.electricityUnit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.electricityUnit && formik.errors.electricityUnit
                        }
                        sortOption={true}
                      />
                    </Grid>
                  </>
                )}
              {formik.values.activityType &&
                calculationNamesMap[
                  formik.values.activityType
                ]?.toLowerCase() === "life cycle emissions from power that is purchased and sold" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="calculationApproach"
                        name="calculationApproach"
                        label="Calculation Approach"
                        fullWidth
                        options={calculationApproachInputList}
                        selectedValue={formik.values.calculationApproach}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.calculationApproach &&
                          formik.errors.calculationApproach
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="quantity"
                        name="quantity"
                        label="Quantity of electricity, steam, heating, cooling for resale"
                        value={formik.values.quantity}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.quantity && formik.errors.quantity
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="electricityUnit"
                        name="electricityUnit"
                        label="Unit"
                        fullWidth
                        options={electricityUnitInputList || [{ name: "select" }]}
                        selectedValue={formik.values.electricityUnit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.electricityUnit && formik.errors.electricityUnit
                        }
                        sortOption={true}
                      />
                    </Grid>
                  </>
                )}
            </Grid>
            <CeroButton
              buttonText="Calculate"
              className={classes.buttonPrimary}
              onClick={onCalculate}
              disabled={!formik.dirty || !formik.isValid}
            />
            <CeroButton
              buttonText="Clear"
              variant="outlined"
              className={classes.buttonSecondary}
              onClick={formik.resetForm}
            />
            {isCalculateDone && (
              <Box className={classes.bottomContainer}>
                <Typography
                  variant="h6"
                  component="h6"
                  className={classes.previewTitle}
                >
                  Emission Preview
                </Typography>
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="space-between"
                  spacing={8}
                >
                  <Grid item container direction="column" xs={12} md={6}>
                    <CeroItemPair
                      title={
                        <>
                          CO<sub>2</sub>
                        </>
                      }
                      value={`${editEmissionData.data.co2} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          CH<sub>4</sub>
                        </>
                      }
                      value={`${editEmissionData.data.ch4} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          BioFuel CO<sub>2</sub>
                        </>
                      }
                      value={`${editEmissionData.data.biofuel_co2} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                  </Grid>
                  <Grid
                    className={classes.secondResultContainer}
                    item
                    container
                    direction="column"
                    xs={6}
                  >
                    <CeroItemPair
                      title={
                        <>
                          CO<sub>2</sub>e
                        </>
                      }
                      value={`${editEmissionData.data.co2e} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          N<sub>2</sub>O
                        </>
                      }
                      value={`${editEmissionData.data.n2o} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={<>EF</>}
                      value={
                        <>
                          {editEmissionData.data.ef} kgCO<sub>2</sub>e/unit
                        </>
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
          <FileUpload
            onFileUpload={handleFileUpload}
            onFileRemove={handleFileRemove}
          />
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Update"
            disabled={
              !isCalculateDone || editEmissionData.status === STATUS.RUNNING
            }
            className={classes.buttonPrimary}
            onClick={() => onUpdateFuelAndEnergyData(formik.values)}
          />

          {/* <div className={classes.buttonContainer}>
                <CeroButton
                   buttonText="Bulk Data Upload"
                   className={classes.bulkButton}
                   startIcon={<FileUploadIcon />}
                   onClick={() => setIsOpenEmissionTypeUpload(true)}
                />
                <CeroButton
                    buttonText="Download Template"
                    className={classes.bulkButton}
                    startIcon={<DownloadIcon />}
                    onClick={onDownload}
                  />
                </div> */}
        </Box>
        <EmissionUploadDrawer
          isOpen={isOpenEmissionTypeUpload}
          onClose={onCloseDrawerUpload}
          onUpload={onUpload}
          onValidate={onValidate}
          uploadDisabled={uploadDisabled}
        />
      </Container>
    </>
  );
};

export default EditFuelAndEnergy;
