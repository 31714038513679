import React, { useState } from "react";
import { Box, LinearProgress, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import UploadIcon from "@mui/icons-material/Upload";
import useStyles from "./styles";
import fileUploadIcon from "../../assets/icons/fileUploadIcon.png";

const CeroFileUpload = ({ onFileUpload }) => {
  const classes = useStyles();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      onFileUpload(acceptedFiles);
      if (acceptedFiles[0]) {
        handleFileUpload(acceptedFiles[0]);
      }
    },
  });

  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [uploadStarted, setUploadStarted] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);

  const handleFileUpload = (file) => {
    setUploadStarted(true);
    setUploadedFileName(file.name);

    const totalFileSize = file.size;
    let uploadedSize = 0;
    const speed = Math.max(10000, totalFileSize / 50); // Adjust speed dynamically

    const interval = setInterval(() => {
      uploadedSize += speed;
      const progress = (uploadedSize / totalFileSize) * 100;
      setUploadProgress(progress);

      if (progress >= 100) {
        clearInterval(interval);
        setUploadComplete(true);
      }
    }, 100);
  };

  return (
    <Box>
      <div {...getRootProps({ className: "dropzone" })}>
        <input className="input-zone" {...getInputProps()} />
        <div className={classes.uploadBox}>
          <>
            <UploadIcon />
            <p className="dropzone-content">
              Drag & drop or{" "}
              <a
                href="#"
                role="button"
                style={{ color: "#3760CC", cursor: "pointer" }}
              >
                Choose a file
              </a>{" "}
              to upload
            </p>
          </>
        </div>
      </div>

      {uploadStarted && (
        <Box className={classes.progressBox} bgcolor="#F5EFF2">
          <div className={classes.fileBox}>
            <Typography variant="body1" className={classes.fileDetails}>
              <img
                src={fileUploadIcon}
                alt="Upload Icon"
                width="25"
                height="25"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "10px",
                }}
              >
                {uploadedFileName}
                {uploadComplete && (
                  <Typography
                    style={{ fontSize: 12, color: "green", fontWeight: 500 }}
                  >
                    File uploaded successfully
                  </Typography>
                )}
              </div>
            </Typography>
            <Typography
              variant="body1"
              style={{
                float: "right",
                color: "#626161",
                fontSize: 12,
                fontWeight: 500,
              }}
            >
              {Math.min(uploadProgress, 100).toFixed(0)}%
            </Typography>
          </div>
          <LinearProgress
            variant="determinate"
            value={Math.min(uploadProgress, 100)}
            className={classes.linearProgress}
            classes={{
              colorPrimary: classes.colorPrimary,
              barColorPrimary: classes.barColorPrimary,
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default CeroFileUpload;
