import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { STATUS } from "../../../redux/constants";
import { addMobileCombustionValidation } from "./schema";
import {
  addEmployeeCommuting,
  getEmissionFuelList,
  resetAddCombustionStatus,
} from "../../../redux/actions";
import CeroSelect from "../../../components/CeroSelect";
import CeroButton from "../../../components/CeroButton";
import FileUpload from "../FileUpload";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import EmissionUploadDrawer from "../../EmissionList/EmissionUploadDrawer";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import { months, sampleYear } from "../../../constants";
import CeroInput from "../../../components/CeroInput";
import CeroItemPair from "../../../components/CeroItemPair";

const yearList = sampleYear().map((item) => ({
  id: item.key,
  label: item.value,
}));

const AddEmployeeCommuting = (props) => {
  // const { onCancel } = props;
  const { onCancel, onUpload, onValidate, uploadDisabled } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFile, setSelectedFile] = useState(null);
  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );

  const addEmissionData = useSelector(
    (state) => state.emission.addEmployeeCommuting
  );
  const currentYear = new Date().getFullYear().toString();

  const formik = useFormik({
    initialValues: {
      facility: "",
      calculationMethod: "",
      fuelSource: "",
      amountOfFuel: "",
      fuelUnit: "",
      modeOfTransport: "",
      vehicleType: "",
      emissionFactorDataset: "",
      distanceTravelled: "",
      distanceUnit: "",
      numberOfEmployees: "",
      emissionFactor: "",
      activityType: "",
      workingDaysPerMonth: "",
      oneWayDistance: "",
      employeesUsingMode: "",
      totalEmployees: "",
      commutingDaysPerMonth: "",
      DailyoneWayDistance: "",
      year: currentYear,
      month: "",
    },
    validationSchema: addMobileCombustionValidation,
    onSubmit: () => {},
  });
  const emissionPerformData = useSelector((state) => state.emission.fuelList);
  const calculationNamesMap = {};
  const calculationInputList =
    emissionPerformData?.data?.calculation_method?.map((item) => {
      calculationNamesMap[item.id] = item.name; // Populate namesMap
      return {
        key: item.id,
        value: item.name,
      };
    }) || [];

  const fuelUnitInputList = emissionPerformData?.data?.fuel_unit?.map(
    (item) => {
      return {
        key: item.name,
        value: item.name,
      };
    }
  );

  const fuelSourceInputList = emissionPerformData?.data?.fuel_sources?.map(
    (item) => ({
      key: item.id,
      value: item.name,
    })
  );

  const transportModeList = emissionPerformData?.data?.transport_modes?.map(
    (item) => ({
      key: item.id,
      value: item.name,
    })
  );

  const transportAverageModeList =
    emissionPerformData?.data?.transport_modes_average_data_based?.map(
      (item) => ({
        key: item.id,
        value: item.name,
      })
    );
  const vehicleTypeList = emissionPerformData?.data?.vehicle_types
    ?.filter((item) => item.transport_mode_id === formik.values.modeOfTransport)
    .map((item) => ({
      key: item.id,
      value: item.name,
    }));
  const emissionFactorDataset = (
    emissionPerformData?.data?.ef_dataset || []
  ).map((item) => ({ key: item?.id, value: item?.name }));

  const activityTypesData = useSelector(
    (state) => state.emission.fuelList?.data?.activity_types || []
  );

  const activityTypeOptions = activityTypesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const isCalculateDone = useSelector(
    (state) => state.emission.addEmployeeCommuting.isCalculateDone
  );

  const [isOpenEmissionTypeUpload, setIsOpenEmissionTypeUpload] =
    useState(false);
  const onCloseDrawerUpload = () => {
    setIsOpenEmissionTypeUpload(false);
  };

  const onCalculate = () => {
    const requestData = {
      calculation_method: formik.values.calculationMethod,
      facility_id: formik.values.facility,
      commuting_days_per_month: formik.values.commutingDaysPerMonth,
      fuel_source_id: formik.values.fuelSource,
      fuel_unit: formik.values.fuelUnit,
      fuel_amount: formik.values.amountOfFuel,
      mode_of_transport_id: formik.values.modeOfTransport,
      vehicle_type_id: formik.values.vehicleType,
      ef_dataset_id: formik.values.emissionFactorDataset,
      activity_type: formik.values.activityType,
      daily_one_way_distance: formik.values.DailyoneWayDistance,
      total_employees: formik.values.totalEmployees,
      employees_using_mode: formik.values.employeesUsingMode,
      one_way_distance: formik.values.oneWayDistance,
      working_days_per_month: formik.values.workingDaysPerMonth,
      save: false,
      year: formik.values.year,
      month: formik.values.month,
    };

    console.log(" Request Data Sent to Saga:", requestData);

    dispatch(addEmployeeCommuting(requestData));

    console.log(" Dispatched Action: ADD_EMPLOYEE_COMMUTE");

    // Simulating a calculated response (Remove this if using Redux)
  };

  const onAddEmployeeCommutingData = () => {
    const requestData = {
      calculation_method: formik.values.calculationMethod,
      facility_id: formik.values.facility,
      commuting_days_per_month: formik.values.commutingDaysPerMonth,
      fuel_source_id: formik.values.fuelSource,
      fuel_unit: formik.values.fuelUnit,
      fuel_amount: formik.values.amountOfFuel,
      mode_of_transport_id: formik.values.modeOfTransport,
      vehicle_type_id: formik.values.vehicleType,
      ef_dataset_id: formik.values.emissionFactorDataset,
      activity_type: formik.values.activityType,
      daily_one_way_distance: formik.values.DailyoneWayDistance,
      total_employees: formik.values.totalEmployees,
      employees_using_mode: formik.values.employeesUsingMode,
      one_way_distance: formik.values.oneWayDistance,
      working_days_per_month: formik.values.workingDaysPerMonth,
      save: true,
      year: formik.values.year,
      month: formik.values.month,
      file: selectedFile,
    };
    console.log("🔵 Sending API Request Data:", requestData);
    sessionStorage.setItem("year", requestData.year);
    dispatch(addEmployeeCommuting(requestData));
  };

  useEffect(() => {
    dispatch(getEmissionFuelList("employee_commute"));
    return () => {
      dispatch(resetAddCombustionStatus());
    };
  }, [dispatch]);

  useEffect(() => {
    if (addEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Employee Commuting added successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());

      onCancel();
    } else if (addEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        addEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
    }
  }, [addEmissionData, dispatch, enqueueSnackbar, onCancel]);
  const handleFileUpload = (file) => {
    setSelectedFile(file);
    // dispatch(uploadEmissionAttachement("emissionId", file));
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
  };
  return (
    <>
      <div className={classes.backContainer}>
        <div
          className={classes.backButtonDiv}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon />
          Back
        </div>
        <div className={classes.templateButtonDiv}></div>
      </div>

      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Add Employee Commuting
          </Typography>
          <Box className={classes.topContainer}>
            <Grid className={classes.dynamicBoard}>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesData.map((item) => ({
                    key: item.id,
                    value: item.name,
                  }))}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
              </Grid>

              <Grid item className={classes.individualInput}>
                <CeroAutoComplete
                  id="year"
                  name="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.touched.year && formik.errors.year}
                  options={yearList}
                  value={formik.values.year}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="month"
                  name="month"
                  label="Month"
                  fullWidth
                  options={months}
                  selectedValue={formik.values.month}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.month && formik.errors.month}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="calculationMethod"
                  name="calculationMethod"
                  label="Calculation Method"
                  fullWidth
                  options={calculationInputList}
                  selectedValue={formik.values.calculationMethod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.month && formik.errors.month}
                />
              </Grid>

              {formik.values.calculationMethod &&
                calculationNamesMap[
                  formik.values.calculationMethod
                ]?.toLowerCase() === "fuel-based" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="fuel_source_id"
                        name="fuelSource"
                        label="Fuel Source"
                        fullWidth
                        options={fuelSourceInputList}
                        selectedValue={formik.values.fuelSource}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.fuelSource && formik.errors.fuelSource
                        }
                        classes={{
                          input: classes.input,
                          container: classes.selectContainer,
                        }}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="fuel_amount"
                        name="amountOfFuel"
                        label="Amount of Fuel"
                        value={formik.values.amountOfFuel}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.amountOfFuel &&
                          formik.errors.amountOfFuel
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="fuel_unit"
                        name="fuelUnit"
                        label="Fuel Unit"
                        fullWidth
                        options={fuelUnitInputList || [{ name: "select" }]}
                        selectedValue={formik.values.fuelUnit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.fuelUnit && formik.errors.fuelUnit
                        }
                        sortOption={true}
                      />
                    </Grid>
                  </>
                )}
              {formik.values.calculationMethod &&
                calculationNamesMap[
                  formik.values.calculationMethod
                ]?.toLowerCase() === "distance-based" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="mode_of_transport_id"
                        name="modeOfTransport"
                        label="Mode of Transport"
                        fullWidth
                        options={transportModeList}
                        selectedValue={formik.values.modeOfTransport || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.modeOfTransport &&
                          formik.errors.modeOfTransport
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="vehicle_type_id"
                        name="vehicleType"
                        label="Vehicle Type"
                        fullWidth
                        options={vehicleTypeList}
                        selectedValue={formik.values.vehicleType || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.vehicleType &&
                          formik.errors.vehicleType
                        }
                        classes={{
                          input: classes.input,
                          container: classes.selectContainer,
                          paperRoot: classes.paperRoot,
                        }}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="ef_dataset_id"
                        name="emissionFactorDataset"
                        label="Emission Factor Dataset"
                        fullWidth
                        options={emissionFactorDataset}
                        selectedValue={
                          formik.values.emissionFactorDataset || ""
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.emissionFactorDataset &&
                          formik.errors.emissionFactorDataset
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="activity_type"
                        name="activityType"
                        label="Activity Type"
                        fullWidth
                        options={activityTypeOptions} // Use the corrected options
                        selectedValue={formik.values.activityType || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.activityType &&
                          formik.errors.activityType
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="DailyoneWayDistance"
                        name="DailyoneWayDistance"
                        label="Daily one way distance between home and work in km"
                        value={formik.values.DailyoneWayDistance}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.DailyoneWayDistance &&
                          formik.errors.DailyoneWayDistance
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="commuting_days_per_month"
                        name="commutingDaysPerMonth"
                        label="Number of Commuting days per month"
                        value={formik.values.commutingDaysPerMonth}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.commutingDaysPerMonth &&
                          formik.errors.commutingDaysPerMonth
                        }
                        type="number"
                      />
                    </Grid>
                  </>
                )}
              {formik.values.calculationMethod &&
                calculationNamesMap[
                  formik.values.calculationMethod
                ]?.toLowerCase() === "average-data method" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="total_employees"
                        name="totalEmployees"
                        label="Total number of employees"
                        value={formik.values.totalEmployees}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.totalEmployees &&
                          formik.errors.totalEmployees
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="mode_of_transport_id"
                        name="modeOfTransport"
                        label="Mode of Transport"
                        fullWidth
                        options={transportAverageModeList}
                        selectedValue={formik.values.modeOfTransport || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.modeOfTransport &&
                          formik.errors.modeOfTransport
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="employees_using_mode"
                        name="employeesUsingMode"
                        label="% of employees using mode of transport"
                        value={formik.values.employeesUsingMode}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.emissionFactor &&
                          formik.errors.emissionFactor
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="one_way_distance"
                        name="oneWayDistance"
                        label="One way commuting distance in km"
                        value={formik.values.oneWayDistance}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.emissionFactor &&
                          formik.errors.emissionFactor
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="working_days_per_month"
                        name="workingDaysPerMonth"
                        label="Total working days per month"
                        value={formik.values.workingDaysPerMonth}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.workingDaysPerMonth &&
                          formik.errors.workingDaysPerMonth
                        }
                        type="number"
                      />
                    </Grid>
                  </>
                )}
            </Grid>
          </Box>
          {/* <Box className={classes.buttonContainer}>
          <CeroButton
              buttonText="Calculate"
              className={classes.buttonPrimary}
              onClick={onCalculate}
              disabled={!formik.dirty || !formik.isValid}
            />
            <CeroButton
              buttonText="Clear"
              className={classes.buttonSecondary}
              onClick={formik.resetForm}
            />
          </Box> */}

          <CeroButton
            buttonText="Calculate"
            className={classes.buttonPrimary}
            onClick={onCalculate}
            disabled={!formik.dirty || !formik.isValid}
          />

          <CeroButton
            buttonText="Clear"
            variant="outlined"
            className={classes.buttonSecondary}
            onClick={formik.resetForm}
          />
          {isCalculateDone && (
            <Box className={classes.bottomContainer}>
              <Typography
                variant="h6"
                component="h6"
                className={classes.previewTitle}
              >
                Emission Preview
              </Typography>
              <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-between"
                spacing={8}
              >
                <Grid item container direction="column" xs={12} md={6}>
                  <CeroItemPair
                    title={
                      <>
                        CO<sub>2</sub>
                      </>
                    }
                    value={`${addEmissionData.data.co2} tonnes`}
                    classes={{
                      container: classes.infoContainer,
                      title: classes.emissionTitle,
                      value: classes.value,
                    }}
                  />
                  <CeroItemPair
                    title={
                      <>
                        CH<sub>4</sub>
                      </>
                    }
                    value={`${addEmissionData.data.ch4} tonnes`}
                    classes={{
                      container: classes.infoContainer,
                      title: classes.emissionTitle,
                      value: classes.value,
                    }}
                  />
                  <CeroItemPair
                    title={
                      <>
                        BioFuel CO<sub>2</sub>
                      </>
                    }
                    value={`${addEmissionData.data.biofuel_co2} tonnes`}
                    classes={{
                      container: classes.infoContainer,
                      title: classes.emissionTitle,
                      value: classes.value,
                    }}
                  />
                </Grid>
                <Grid
                  className={classes.secondResultContainer}
                  item
                  container
                  direction="column"
                  xs={6}
                >
                  <CeroItemPair
                    title={
                      <>
                        CO<sub>2</sub>e
                      </>
                    }
                    value={`${addEmissionData.data.co2e} tonnes`}
                    classes={{
                      container: classes.infoContainer,
                      title: classes.emissionTitle,
                      value: classes.value,
                    }}
                  />
                  <CeroItemPair
                    title={
                      <>
                        N<sub>2</sub>O
                      </>
                    }
                    value={`${addEmissionData.data.n2o} tonnes`}
                    classes={{
                      container: classes.infoContainer,
                      title: classes.emissionTitle,
                      value: classes.value,
                    }}
                  />
                  <CeroItemPair
                    title={<>EF</>}
                    value={
                      <>
                        {addEmissionData.data.ef} kgCO<sub>2</sub>e/unit
                      </>
                    }
                    classes={{
                      container: classes.infoContainer,
                      title: classes.emissionTitle,
                      value: classes.value,
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
          <FileUpload
            onFileUpload={handleFileUpload}
            onFileRemove={handleFileRemove}
          />
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Add Data"
            disabled={
              !isCalculateDone || addEmissionData.status === STATUS.RUNNING
            }
            className={classes.buttonPrimary}
            onClick={() => onAddEmployeeCommutingData(formik.values)}
          />
        </Box>
        <EmissionUploadDrawer
          isOpen={isOpenEmissionTypeUpload}
          onClose={onCloseDrawerUpload}
          onUpload={onUpload}
          onValidate={onValidate}
          uploadDisabled={uploadDisabled}
        />
      </Container>
    </>
  );
};

export default AddEmployeeCommuting;
