import React, { useState } from "react";
import { Container } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

import DashboardLayout from "../../layouts/DashboardLayout";
import AddStationaryCombustionForm from "./AddStationaryCombustionForm";
import AddTransportationForm from "./AddTransportationForm";
import AddPurchasedElectricityForm from "./AddPurchasedElectricityForm";
import AddMobileCombustionForm from "./AddMobileCombustionForm";
import AddWaterDischargeForm from "./AddWaterDischargeForm";
import AddWaterConsumptionForm from "./AddWaterConsumptionForm";
import AddRefrigerantsForm from "./AddRefrigerantsForm";
import AddWasteCombustion from "./AddWasteCombustion";
import AddDevelopmentTrainingForm from "./AddDevelopmentTrainingForm";
import AddEmployeesHealthForm from "./AddEmployeesHealthForm";
import AddWorkerSafetyTrainingForm from "./AddWorkerSafetyTrainingForm";
import AddDescriminationIncidentForm from "./AddDescriminationIncidentForm";
import AddSupplierHumanRightsTrainingForm from "./AddSupplierHumanRightsTrainingForm";
import AddSocialHumanRightsTrainingForm from "./AddSocialHumanRightsTrainingForm";
import AddSupplierScreeningForm from "./AddSupplierScreeningForm";
import AddLocalCommunitiesForm from "./AddLocalCommunitiesForm";
import AddPoliticalContributionForm from "./AddPoliticalContributionForm";
import AddAntiCorruptionDisclosureForm from "./AddAntiCorruptionDisclosureForm";
import AddAntiCorruptionTrainingForm from "./AddAntiCorruptionTrainingForm";
import AddAntiCompetitiveDisclosureForm from "./AddAntiCompetitiveDisclosureForm";
import useStyles from "./styles";
import AddUploadEmissionForm from "./AddUploadEmissionForm";
import { useSnackbar } from "notistack";
import axios from "axios";
import { APIEndpoints } from "../../redux/constants";
import { getCookie } from "../../services/cookie";
import BulkValidationNotification from "./BulkValidationNotification";
import AddEmployeeAndTurnover from "./AddEmployeeAndTurnover";
import AddEmployeeBenefits from "./AddEmployeeBenefits";
import AddEmployeeTrainingHour from "./AddEmployeeTrainingHours";
import AddPerformanceAndCareerDevelopmentPrograms from "./AddPerformanceAndCareerDevelopmentPrograms";
import AddEmployeeDiversity from "./AddEmployeeDiversity";
import AddSubsidiesAndFinancialAssistance from "./AddSubsidiesAndFinancialAssistance";
import AddEconomicImpact from "./AddEconomicImpact";
import AddManagementDiversityForm from "./AddManagementDiversityForm";
import AddBoardDiversity from "./AddBoardDiversity";
import AddUpstreamTD from "./AddUpstreamT&D";
import AddDownstreamTD from "./AddDownstreamT&D";
import AddBusinessTravel from "./AddBusinessTravel";
import AddPurchasedGoodsAndServices from "./AddPurchasedGoodsAndServices";
import AddCapitalGoods from "./AddCapitalGoods";
import AddFranchise from "./AddFranchise";
import AddFuelAndEnergy from "./AddFuelAndEnergy";
import AddTreatmentOfSoldProducts from "./AddTreatmentOfSoldProducts";
import AddEmployeeCommuting from "./AddEmployeeCommuting";
import AddUpstreamLeasedAsset from "./UpstreamLeasedAssets";
import AddDownstreamLeasedAsset from "./DownstreamLeasedAsset";
import AddUseOfSoldProducts from "./AddUseOfSoldProducts";
import AddProcessingOfSoldProductsForm from "./AddProcessingSoldProduct";
import ProgressTemplate from "./ProgressTemplate";

const uploadFileEmissions = [
  "age_based_statistics",
  "gender_based_statistics",
  "tax",
];

const AddEmissions = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const emissionType = pathname.substring(pathname.lastIndexOf("/") + 1);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState("passed");
  const [notificationResponse, setNotificationResponse] = useState(null);
  const [isValidationSuccess, setIsValidationSuccess] = useState(false);
  const [warningRecord, setwarningRecord] = useState(false);

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };
  const onCancelAdd = () => {
    navigate("/emissions/" + emissionType);
  };

  const authToken = getCookie("auth_token_admin");
  const onValidate = async (file, save, onSuccessCallback) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("filename", file.name);
    try {
      const response = await axios.post(
        APIEndpoints.VALIDATE_UPLOAD_EMISSION(emissionType, true),
        formData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      onSuccessCallback();
      const status_inter =
        response?.data?.status &&
        response?.data?.status.every((statusIns) => statusIns === "passed");
      const statusArr = response?.data?.status;
      const notData = response.data;
      notData.status = status_inter ? "passed" : "failed";

      if (status_inter) {
        setNotificationStatus("passed");
        setNotificationOpen(true);
        setNotificationResponse(notData);
        setIsValidationSuccess(true);
      } else {
        let failedEntries = statusArr.some(
          (statusIns) => statusIns === "failed"
        );
        setNotificationStatus("failed");
        setNotificationOpen(true);
        setNotificationResponse(notData);
        setIsValidationSuccess(failedEntries ? false : true);
        setwarningRecord(failedEntries ? false : true);
      }
    } catch (error) {
      onSuccessCallback();
      enqueueSnackbar(error?.response.data.detail, { variant: "error" });
    }
  };

  const onUpload = async (emissionType, save, onSuccessCallback) => {
    try {
      const response = await axios.post(
        APIEndpoints.UPLOAD_EMISSION(emissionType, true),
        notificationResponse,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      onSuccessCallback();
      enqueueSnackbar("File uploaded successfully", { variant: "success" });
    } catch (error) {
      onSuccessCallback();
      enqueueSnackbar(error?.response.data.detail, { variant: "error" });
    }
  };

  const onDownload = async (year, month, facility) => {
    try {
      const response = await axios.get(
        APIEndpoints.DOWNLOAD_EMISSION(emissionType, year, month, facility),
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const url = response.data.blob_url;
      const link = document.createElement("a");
      link.href = url;
      link.click();
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <BulkValidationNotification
          open={notificationOpen}
          onClose={handleNotificationClose}
          status={notificationStatus}
          response={notificationResponse}
          warningRecord={warningRecord}
        />
        {emissionType === "stationary_combustion" && (
          <AddStationaryCombustionForm
            onCancel={onCancelAdd}
            onDownload={onDownload}
            onUpload={onUpload}
            onValidate={onValidate}
            uploadDisabled={isValidationSuccess}
          />
        )}
        {emissionType === "mobile_combustion" && (
          <AddMobileCombustionForm
            onCancel={onCancelAdd}
            onDownload={onDownload}
            onUpload={onUpload}
            onValidate={onValidate}
            uploadDisabled={isValidationSuccess}
          />
        )}
        {emissionType === "transportation" && (
          <AddTransportationForm
            onCancel={onCancelAdd}
            onDownload={onDownload}
            onUpload={onUpload}
            onValidate={onValidate}
            uploadDisabled={isValidationSuccess}
          />
        )}
        {emissionType === "purchased_electricity" && (
          <AddPurchasedElectricityForm
            onCancel={onCancelAdd}
            onDownload={onDownload}
            onUpload={onUpload}
            onValidate={onValidate}
            uploadDisabled={isValidationSuccess}
          />
        )}
        {emissionType === "water_discharge" && (
          <AddWaterDischargeForm onCancel={onCancelAdd} />
        )}
        {emissionType === "water_consumption" && (
          <AddWaterConsumptionForm onCancel={onCancelAdd} />
        )}
        {emissionType === "refrigerants" && (
          <AddRefrigerantsForm
            onCancel={onCancelAdd}
            onDownload={onDownload}
            onUpload={onUpload}
            onValidate={onValidate}
            uploadDisabled={isValidationSuccess}
          />
        )}
        {emissionType === "waste" && (
          <AddWasteCombustion onCancel={onCancelAdd} />
        )}
        {emissionType === "development_training" && (
          <AddDevelopmentTrainingForm onCancel={onCancelAdd} />
        )}
        {emissionType === "employee_health_safety_incident_record" && (
          <AddEmployeesHealthForm onCancel={onCancelAdd} />
        )}
        {emissionType === "worker_safety_training_procedures" && (
          <AddWorkerSafetyTrainingForm onCancel={onCancelAdd} />
        )}
        {emissionType === "discrimination_incident_record" && (
          <AddDescriminationIncidentForm onCancel={onCancelAdd} />
        )}
        {emissionType ===
          "supplier_screening_using_enviormental_social_criteria" && (
          <AddSupplierScreeningForm onCancel={onCancelAdd} />
        )}
        {emissionType === "suppliers_human_rights" && (
          <AddSupplierHumanRightsTrainingForm onCancel={onCancelAdd} />
        )}
        {emissionType === "social_engagement_human_rights_training" && (
          <AddSocialHumanRightsTrainingForm onCancel={onCancelAdd} />
        )}
        {emissionType === "local_communities" && (
          <AddLocalCommunitiesForm onCancel={onCancelAdd} />
        )}
        {emissionType === "political_contributions" && (
          <AddPoliticalContributionForm onCancel={onCancelAdd} />
        )}
        {emissionType === "anti_corruption_disclosure" && (
          <AddAntiCorruptionDisclosureForm onCancel={onCancelAdd} />
        )}
        {emissionType === "anti_corruption_training" && (
          <AddAntiCorruptionTrainingForm onCancel={onCancelAdd} />
        )}
        {emissionType === "anti_competitive_disclosure" && (
          <AddAntiCompetitiveDisclosureForm onCancel={onCancelAdd} />
        )}
        {/* {emissionType === 'subsidies_financial_assistance' && <AddSubsidiesFinancialForm onCancel={onCancelAdd} />} */}
        {emissionType === "employees_hire_and_turnover" && (
          <AddEmployeeAndTurnover onCancel={onCancelAdd} />
        )}
        {emissionType === "employees_benefit" && (
          <AddEmployeeBenefits onCancel={onCancelAdd} />
        )}
        {emissionType === "training_hours_per_employee" && (
          <AddEmployeeTrainingHour onCancel={onCancelAdd} />
        )}
        {emissionType === "performance_and_career_development_programs" && (
          <AddPerformanceAndCareerDevelopmentPrograms onCancel={onCancelAdd} />
        )}

        {emissionType === "employees_diversity_and_ratio_of_basic_salary" && (
          <AddEmployeeDiversity onCancel={onCancelAdd} />
        )}
        {emissionType === "management_diversity" && (
          <AddManagementDiversityForm onCancel={onCancelAdd} />
        )}
        {emissionType === "board_diversity" && (
          <AddBoardDiversity onCancel={onCancelAdd} />
        )}
        {emissionType === "subsidies_financial_assistance" && (
          <AddSubsidiesAndFinancialAssistance onCancel={onCancelAdd} />
        )}
        {emissionType === "economic_impact" && (
          <AddEconomicImpact onCancel={onCancelAdd} />
        )}
        {emissionType === "upstream_T&D" && (
          <AddUpstreamTD onCancel={onCancelAdd} />
        )}
        {emissionType === "downstream_T&D" && (
          <AddDownstreamTD onCancel={onCancelAdd} />
        )}
        {emissionType === "business_travel" && (
          <AddBusinessTravel onCancel={onCancelAdd} />
        )}
        {emissionType === "employee_commute" && (
          <AddEmployeeCommuting onCancel={onCancelAdd} />
        )}
        {emissionType === "upstream_leased_asset" && (
          <AddUpstreamLeasedAsset onCancel={onCancelAdd} />
        )}
        {emissionType === "downstream_leased_asset" && (
          <AddDownstreamLeasedAsset onCancel={onCancelAdd} />
        )}
        {emissionType === "purchased_goods_and_services" && (
          <AddPurchasedGoodsAndServices onCancel={onCancelAdd} />
        )}
        {emissionType === "capital_goods" && (
          <AddCapitalGoods onCancel={onCancelAdd} />
        )}
        {emissionType === "franchises" && (
          <ProgressTemplate onCancel={onCancelAdd} />
        )}
        {emissionType === "investments" && (
          <ProgressTemplate onCancel={onCancelAdd} />
        )}
        {emissionType === "fuel_and_energy_related_activities" && (
          <AddFuelAndEnergy onCancel={onCancelAdd} />
        )}
        {emissionType === "processing_of_sold_products" && (
          <AddProcessingOfSoldProductsForm onCancel={onCancelAdd} />
        )}
        {emissionType === "end_of_life_treatment_of_sold_products" && (
          <AddTreatmentOfSoldProducts onCancel={onCancelAdd} />
        )}
        {emissionType === "use_of_sold_products" && (
          <AddUseOfSoldProducts onCancel={onCancelAdd} />
        )}

        {uploadFileEmissions.includes(emissionType) && (
          <AddUploadEmissionForm
            emissionType={emissionType}
            onCancel={onCancelAdd}
          />
        )}
      </Container>
    </DashboardLayout>
  );
};

export default AddEmissions;
