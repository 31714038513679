import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Chip,
  CircularProgress,
  Container,
  Tooltip,
  Typography,
} from "@mui/material";
import { sampleYearArray } from "../../../../constants";
import { clearEmissionList, getEmissionList } from "../../../../redux/actions";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import { STATUS } from "../../../../redux/constants";
import useStyles from "./styles";
import { rolesEnum } from "../../../../layouts/DashboardLayout/pages";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CeroTable from "../../../../components/CeroTable";
import {
  StationaryApprovalsColumns,
  MobileApprovalColumns,
  PurchasedElectricityApprovalColumns,
  WaterDischargeApprovalColumns,
  WaterConsumptionApprovalColumns,
  RefrigerantsApprovalColumns,
  TransportationApprovalColumns,
  WasteCombustionApprovalColumns,
  EndOfLifeTreatmentColumns,
} from "./ApprovalTableColumns";
import CeroButton from "../../../../components/CeroButton";

const DEFAULT_ITEMS_PER_PAGE = 20;
const ApprovalStatusTable = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { type } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const selectedMonth = queryParams.get("month");
  const selectedFacility = queryParams.get("facility");
  const selectedYear = queryParams.get("year");
  const company = queryParams.get("company");
  const period = queryParams.get("period");
  const country = queryParams.get("country");
  const emissionData = useSelector(
    (state) => state.emission.emissionList.data.emissions
  );
  const emissionDataStatus = useSelector(
    (state) => state.emission.emissionList.status
  );
  const role = useSelector((state) => state.auth.role);
  const isAuditor = role.includes(rolesEnum.AUDITOR);

  const emissionType = type;
  const [page, setPage] = useState(1);
  var x = sessionStorage.getItem("year");
  const [filter, setFilter] = useState({
    month: selectedMonth,
    year: selectedYear || Number(x) || sampleYearArray[0].key,
    facility_id: selectedFacility,
  });

  const getTableColumn = {
    stationary_combustion: StationaryApprovalsColumns,
    mobile_combustion: MobileApprovalColumns,
    purchased_electricity: PurchasedElectricityApprovalColumns,
    water_discharge: WaterDischargeApprovalColumns,
    water_consumption: WaterConsumptionApprovalColumns,
    refrigerants: RefrigerantsApprovalColumns,
    transportation: TransportationApprovalColumns,
    waste: WasteCombustionApprovalColumns,
    end_of_life_treatment: EndOfLifeTreatmentColumns,
  };
  useEffect(() => {
    !emissionType
      ? navigate("stationary_combustion")
      : onLoadMore(DEFAULT_ITEMS_PER_PAGE, page);
    return () => {
      clearEmissionList();
    };
  }, [emissionType]);

  useEffect(() => {
    onLoadMore(DEFAULT_ITEMS_PER_PAGE, page);
    return () => {
      clearEmissionList();
    };
  }, [filter]);

  const onLoadMore = (limit, pageNumber) => {
    const skip = (pageNumber - 1) * 20;
    const newLimit = DEFAULT_ITEMS_PER_PAGE * pageNumber;
    const filterRequest = {
      limit: newLimit,
      skip,
      month: selectedMonth,
      year: selectedYear || Number(x) || sampleYearArray[0].key,
      facility_id: selectedFacility,
      period: period,
      country: country,
    };
    if (!filterRequest.facility_id) {
      delete filterRequest.facility_id;
    }
    dispatch(
      getEmissionList(
        emissionType,
        filterRequest,
        isAuditor,
        company,
        typeof selectedFacility === "string"
          ? selectedFacility.split(",")
          : selectedFacility,
        period,
        country
      )
    );
  };
  const getStatusColor = (item) => {
    if (item === "approved") {
      return (
        <Tooltip title="Approved" arrow>
          <Chip className={classes.signalApproved} />
        </Tooltip>
      );
    } else if (item === "submitted") {
      return (
        <Tooltip title="Submitted" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "audited") {
      return (
        <Tooltip title="Audited" arrow>
          <Chip className={classes.signalAudited} />
        </Tooltip>
      );
    } else if (item === "verified") {
      return (
        <Tooltip title="Verified" arrow>
          <Chip className={classes.signalVerified} />
        </Tooltip>
      );
    } else if (item === "review") {
      return (
        <Tooltip title="Review" arrow>
          <Chip className={classes.signalReview} />
        </Tooltip>
      );
    } else if (item === "added") {
      return (
        <Tooltip title="Added" arrow>
          <Chip className={classes.signalAdded} />
        </Tooltip>
      );
    } else if (item === "assigned") {
      return (
        <Tooltip title="assigned" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "Awaiting approval") {
      return (
        <Tooltip title="Awaiting approval" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "In Progress") {
      return (
        <Tooltip title="In Progress" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "pending_approval") {
      return (
        <Tooltip title="Pending Approval" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={item} arrow>
          <Chip className={classes.signalAdded} />
          {item}
        </Tooltip>
      );
    }
  };

  const getEmissionData = () =>
    Array.isArray(emissionData)
      ? emissionData?.map((item) => ({
          ...item,
          year: `${item.month}/${item.year}`,
          amount: `${item.amount}  ${item.unit ? item.unit : ""}`,
          status: getStatusColor(item.status),
          treatment_required: item.treatment_required ? "Yes" : "No",
          pending_action_role:
            item.pending_action_role === null ? "NA" : item.pending_action_role,
        }))
      : [];

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Box className={classes.tableTopic}>
          <Typography variant="h7" component="span">
            <span className={classes.tableTitle}>
              {emissionType.replace(/_/g, " ")}
            </span>
          </Typography>
          <Box>
            <CeroButton
              buttonText="Back"
              variant="outlined"
              className={classes.buttonSecondary}
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
            />
          </Box>
        </Box>
        {emissionDataStatus === STATUS.SUCCESS ? (
          <>
            <CeroTable
              columns={getTableColumn[emissionType] || []}
              data={getEmissionData()}
              hasMore={false}
              loading={false}
              loadMore={onLoadMore}
              classes={{ tableContainer: classes.tableContainer }}
            />
          </>
        ) : (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default ApprovalStatusTable;
