import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Grid,
  Typography,
  Box,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { STATUS } from "../../../redux/constants";
import {
  resetAddCombustionStatus,
  deleteEmissions,
} from "../../../redux/actions";
import CeroButton from "../../../components/CeroButton";
import ListComments from "../ListComment";
import ListAuditTrails from "../ListAuditTrails";
import ListEmissionFiles from "../ListEmissionFiles";
import ListTicketDrawer from "../../common/ListTicketsDrawer";
import CeroConfirmDrawer from "../../../components/CeroConfirmDrawer";
import useStyles from "./styles";
import TicketIcon from "../../../assets/icons/TicketIcon.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { getCookie } from "../../../services/cookie";
import CeroItemPair from "../../../components/CeroItemPair";
import CeroInfoPair from "../../../components/CeroInfoPair";
import EmissionDetailsApprovalHeader from "../EmissionApprovalHeader";

const UpstreamTAndD = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const readOnlyMode = getCookie("read_only_mode") === "disable_edit";

  const role = useSelector((state) => state.auth.role);

  const {
    emissionId,
    emissionData,
    onCancel,
    isDeleteEnable,
    setIsDrawerOpen,
    isAuditor,
    company,
    subType,
    approvalId,
  } = props;

  const [value, setValue] = useState(0);
  const [showTickets, setShowTickets] = useState(false);
  const [displayWarning, setDisplayWarning] = useState(false);

  const deleteEmissionData = useSelector(
    (state) => state.emission.deleteEmissions
  );

  useEffect(() => {
    if (deleteEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("upstream T and D record deleted successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (deleteEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        deleteEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetAddCombustionStatus());
    }
  }, [deleteEmissionData, enqueueSnackbar, onCancel, dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onConfirmDelete = () => {
    const requestData = {
      id: emissionId,
    };
    dispatch(deleteEmissions(requestData));
  };

  const onclickShowTickets = () => {
    setShowTickets(true);
  };

  const onCloseTickets = () => {
    setShowTickets(false);
  };

  const onUpdatePurchasedElectricity = () => {
    navigate(`/emissions/edit/upstream_T&D/${emissionId}`);
  };
  const monthDict = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };
  return (
    <>
      <div className={classes.headerContainer}>
        <div
          className={classes.backContainer}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon />
          Back
        </div>
        <Box className={classes.alignButton}>
          <EmissionDetailsApprovalHeader
            emissionId={emissionId}
            can_review_action_do={emissionData.can_review_action_do}
            subType={subType}
            approvalId={approvalId}
          />
          <CeroButton
            buttonText="Raise a ticket"
            className={classes.buttonPrimary}
            onClick={() => setIsDrawerOpen(true)}
            disabled={readOnlyMode}
          />
          <IconButton onClick={onclickShowTickets} disabled={readOnlyMode}>
            <img src={TicketIcon} alt="Logo" width="25" height="25" />
          </IconButton>
        </Box>
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Box className={classes.header}>
            <Typography variant="h6" component="div">
              Upstream T&D
            </Typography>
          </Box>
          <Box className={classes.topContainer}>
            <Grid
              container
              direction={"row"}
              wrap="nowrap"
              justifyContent={"space-between"}
              display={"contents"}
              spacing={12}
            >
              <Grid
                item
                container
                direction={"column"}
                display={"contents"}
                xs={10}
              >
                <Box className={classes.actionBox}>
                  <CeroInfoPair
                    title="Status:"
                    value={
                      emissionData.status
                        ? emissionData.status === "pending_approval"
                          ? "pending approval"
                          : emissionData.status
                        : "NA"
                    }
                    classes={{
                      container: classes.statusContainer,
                      title: classes.statusTitle,
                      value:
                        emissionData.status === "In Progress" ||
                        emissionData.status === "pending_approval"
                          ? classes.Inprogress
                          : classes[emissionData?.status],
                    }}
                  />
                </Box>
                <CeroItemPair
                  title="Facility:"
                  value={
                    emissionData.facility_name
                      ? emissionData.facility_name
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.diversityTitle,
                    value: classes.value,
                  }}
                />

                <CeroItemPair
                  title="Month and Year:"
                  value={
                    emissionData.year && emissionData.month
                      ? monthDict[emissionData.month] + " " + emissionData.year
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.diversityTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Upstream Type:"
                  value={
                    emissionData.calculation_method_name &&
                    [
                      "distance-based",
                      "fuel-based",
                      "refrigerant leakage",
                      "spend-based",
                    ].includes(
                      emissionData.calculation_method_name?.toLowerCase()
                    )
                      ? "Transportation"
                      : "Distribution"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.diversityTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Calculation Method:"
                  value={
                    emissionData.calculation_method_name
                      ? emissionData.calculation_method_name
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.diversityTitle,
                    value: classes.value,
                  }}
                />
                {emissionData?.calculation_method_name?.toLowerCase() ===
                "fuel-based" ? (
                  <>
                    <CeroItemPair
                      title="Fuel Source:"
                      value={
                        emissionData.fuel_source_name
                          ? emissionData.fuel_source_name
                          : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.diversityTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title="Amount of Fuel(unit):"
                      value={
                        emissionData.amount_fuel
                          ? emissionData.amount_fuel
                          : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.diversityTitle,
                        value: classes.value,
                      }}
                    />
                  </>
                ) : emissionData?.calculation_method_name?.toLowerCase() ===
                  "distance-based" ? (
                  <>
                    <CeroItemPair
                      title="Mode of transport:"
                      value={
                        emissionData.mode_of_transport_name
                          ? emissionData.mode_of_transport_name
                          : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.diversityTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title="Vehicle Type:"
                      value={
                        emissionData.vehicle_type_name
                          ? emissionData.vehicle_type_name
                          : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.diversityTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title="Emission Factor Dataset:"
                      value={
                        emissionData.ef_dataset_name
                          ? emissionData.ef_dataset_name
                          : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.diversityTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title="Distance Travelled:"
                      value={
                        emissionData.distance_travelled
                          ? emissionData.distance_travelled
                          : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.diversityTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title="Mass of Purchased Goods:"
                      value={
                        emissionData.mass_purchased_goods
                          ? emissionData.mass_purchased_goods
                          : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.diversityTitle,
                        value: classes.value,
                      }}
                    />
                  </>
                ) : emissionData?.calculation_method_name?.toLowerCase() ===
                  "spend-based" ? (
                  <>
                    <CeroItemPair
                      title="Mode of transport:"
                      value={
                        emissionData.mode_of_transport_name
                          ? emissionData.mode_of_transport_name
                          : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.diversityTitle,
                        value: classes.value,
                      }}
                    />

                    <CeroItemPair
                      title="Total Spend:"
                      value={
                        emissionData.total_spent
                          ? `${emissionData.total_spent} ${emissionData.currency}`
                          : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.diversityTitle,
                        value: classes.value,
                      }}
                    />
                  </>
                ) : emissionData?.calculation_method_name?.toLowerCase() ===
                  "refrigerant leakage" ? (
                  <>
                    <CeroItemPair
                      title="Gas Type:"
                      value={
                        emissionData.gas_type_name
                          ? emissionData.gas_type_name
                          : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.diversityTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title="Refrigerant:"
                      value={
                        emissionData.refrigerant_name
                          ? emissionData.refrigerant_name
                          : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.diversityTitle,
                        value: classes.value,
                      }}
                    />

                    <CeroItemPair
                      title="Quantity of refrigerant leakage(Unit):"
                      value={
                        emissionData.amount_refrigerants
                          ? `${emissionData.amount_refrigerants} ${emissionData.refrigerant_unit}`
                          : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.diversityTitle,
                        value: classes.value,
                      }}
                    />
                  </>
                ) : emissionData?.calculation_method_name?.toLowerCase() ===
                  "site-specific method" ? (
                  <>
                    <CeroItemPair
                      title="Fuel Source:"
                      value={
                        emissionData.fuel_source_name
                          ? emissionData.fuel_source_name
                          : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.diversityTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title="Amount of fuel(unit):"
                      value={
                        emissionData.amount_fuel
                          ? `${emissionData.amount_fuel} ${emissionData.fuel_unit}`
                          : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.diversityTitle,
                        value: classes.value,
                      }}
                    />

                    <CeroItemPair
                      title="Amount of electricity consumed(Unit):"
                      value={
                        emissionData.amount_electricity
                          ? `${emissionData.amount_electricity} ${emissionData.electricity_unit}`
                          : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.diversityTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title="Gas Type:"
                      value={
                        emissionData.gas_type_name
                          ? emissionData.gas_type_name
                          : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.diversityTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title="Refrigerant:"
                      value={
                        emissionData.refrigerant_name
                          ? emissionData.refrigerant_name
                          : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.diversityTitle,
                        value: classes.value,
                      }}
                    />

                    <CeroItemPair
                      title="Quantity of refrigerant leakage(Unit):"
                      value={
                        emissionData.amount_refrigerants
                          ? `${emissionData.amount_refrigerants} ${emissionData.refrigerant_unit}`
                          : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.diversityTitle,
                        value: classes.value,
                      }}
                    />
                  </>
                ) : emissionData?.calculation_method_name?.toLowerCase() ===
                  "average-data method" ? (
                  <>
                    <CeroItemPair
                      title="Volume of stored goods:"
                      value={
                        emissionData.volume_stored
                          ? emissionData.volume_stored
                          : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.diversityTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title="Emission factor:"
                      value={
                        emissionData.emission_factor
                          ? emissionData.emission_factor
                          : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.diversityTitle,
                        value: classes.value,
                      }}
                    />

                    <CeroItemPair
                      title="Average number of goods stored:"
                      value={
                        emissionData.average_number_of_days
                          ? emissionData.average_number_of_days
                          : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.diversityTitle,
                        value: classes.value,
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
                {emissionData?.audited_by && (
                  <>
                    <CeroItemPair
                      title="Audited by:"
                      value={
                        emissionData.audited_by ? emissionData.audited_by : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.diversityTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title="Audited on:"
                      value={
                        emissionData.audited_on
                          ? dayjs(emissionData.audited_on).format("DD/MM/YYYY")
                          : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.diversityTitle,
                        value: classes.value,
                      }}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.bottomContainer}>
            <Typography
              variant="h6"
              component="h6"
              className={classes.previewTitle}
            >
              Emission
            </Typography>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" xs={6}>
                <Typography className={classes.previewItem}>
                  CO<sub>2</sub>: {emissionData?.co2} tonnes
                </Typography>
                <Typography className={classes.previewItem}>
                  CH<sub>4</sub>: {emissionData?.ch4} tonnes
                </Typography>
                <Typography className={classes.previewItem}>
                  BioFuel CO<sub>2</sub>: {emissionData.biofuel_co2} tonnes
                </Typography>
              </Grid>
              <Grid item container direction="column" xs={6}>
                <Typography className={classes.previewItem}>
                  CO<sub>2</sub>e: {emissionData?.co2e} tonnes
                </Typography>
                <Typography className={classes.previewItem}>
                  N<sub>2</sub>O: {emissionData?.n2o} tonnes
                </Typography>
                <Typography className={classes.previewItem}>
                  EF: {emissionData?.ef} kgCO<sub>2</sub>e/unit
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className={classes.buttonContainer}>
          {isDeleteEnable && (
            <CeroButton
              buttonText={<DeleteOutlineIcon />}
              className={clsx(classes.button, classes.deleteButton)}
              onClick={() => setDisplayWarning(true)}
              disabled={readOnlyMode}
            />
          )}
          {emissionData?.is_able_to_update === true && (
            // (emissionData.status === "added" ||
            //   emissionData.status === "review") &&
            //   (isFacMgr || isSusManager) && (
            <CeroButton
              buttonText="Update"
              className={clsx(classes.button, classes.buttonPrimary)}
              onClick={onUpdatePurchasedElectricity}
              disabled={readOnlyMode}
            />
          )}
        </Box>
        <Box
          className={classes.tabContainer}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tabs
            value={value}
            className={classes.tabs}
            onChange={handleChange}
            aria-label="emission tabs"
          >
            <Tab label="Comments" id="comments" />
            <Tab label="Attachment" id="files" />
            <Tab label="Change Logs" id="audit-history" />
          </Tabs>
        </Box>
        {value === 0 && (
          <ListComments
            emissionId={emissionId}
            isAuditor={isAuditor}
            company={company}
          />
        )}
        {value === 2 && (
          <ListAuditTrails
            emissionId={emissionId}
            isAuditor={isAuditor}
            company={company}
          />
        )}
        {value === 1 && (
          <ListEmissionFiles
            emissionId={emissionId}
            isAuditor={isAuditor}
            company={company}
          />
        )}
        {displayWarning && (
          <CeroConfirmDrawer
            isOpen={displayWarning}
            onClose={() => setDisplayWarning(false)}
            onConfirm={onConfirmDelete}
          />
        )}
        {showTickets && (
          <ListTicketDrawer
            isOpen={showTickets}
            scope="emission"
            scopeId={emissionId}
            onClose={onCloseTickets}
          />
        )}
      </Container>
    </>
  );
};

export default UpstreamTAndD;
