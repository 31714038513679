import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Chip,
  CircularProgress,
  Container,
  Tooltip,
  Typography,
} from "@mui/material";
import { sampleYearArray } from "../../../../constants";
import { clearEmissionList, getEmissionList } from "../../../../redux/actions";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import { STATUS } from "../../../../redux/constants";
import useStyles from "./styles";
import { rolesEnum } from "../../../../layouts/DashboardLayout/pages";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import { UpstreamTAndDColumns } from "./ApprovalTableColumns";
import CeroButton from "../../../../components/CeroButton";
import {
  UpstreamDistributionAverage,
  UpstreamDistributionSite,
  UpstreamTransportationDistance,
  UpstreamTransportationFuel,
  UpstreamTransportationRefrigerant,
  UpstreamTransportationSpend,
  BusinessTravelDistance,
  BusinessTravelFuel,
  BusinessTravelSpend,
  EmployeeCommuteFuel,
  EmployeeCommuteDistance,
  EmployeeCommuteAverage,
  PurchasedGoodsSpend,
  PurchasedGoodsAverageData,
  PurchasedGoodsSupplierSpecific,
  CapitalGoodsAverage,
  CapitalGoodsSupplier,
  CapitalGoodsSpend,
  FuelEnergyPurchasedAndSold,
  FuelEnergyTandDLosses,
  FuelEnergyPurchasedElectricity,
  FuelEnergyPurchasedFules,
  UpstreamLeasedAsset,
  UpstreamLeasedAverage1,
  UpstreamLeasedAverage2,
  DirectUsephaseFuels,
  DirectUsephaseGreenhouse,
  DirectUsephaseProducts,
  IndirectUsephaseCalculation,
  IndirectUseOfEmission,
  ProcessingOfSoldProductsAverage,
  ProcessingOfSoldProductsSite,
} from "./ApprovalTableColumns";
import CeroTable from "../../../../components/CeroTable";

const DEFAULT_ITEMS_PER_PAGE = 20;
const ApprovalStatusScope3Table = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { type } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const selectedMonth = queryParams.get("month");
  const selectedFacility = queryParams.get("facility");
  const selectedYear = queryParams.get("year");
  const company = queryParams.get("company");
  const period = queryParams.get("period");
  const country = queryParams.get("country");
  const emissionData = useSelector(
    (state) => state.emission.emissionList.data.emissions
  );
  const emissionDataStatus = useSelector(
    (state) => state.emission.emissionList.status
  );
  const role = useSelector((state) => state.auth.role);
  const isAuditor = role.includes(rolesEnum.AUDITOR);
  const currentPath = window.location.pathname;
  const approvalTable = currentPath.includes("approval");
  const emissionType = type;
  console.log(emissionType, "nik");

  const [page, setPage] = useState(1);
  var x = sessionStorage.getItem("year");
  const [filter, setFilter] = useState({
    month: selectedMonth,
    year: selectedYear || Number(x) || sampleYearArray[0].key,
    facility_id: selectedFacility,
  });
  useEffect(() => {
    !emissionType
      ? navigate("stationary_combustion")
      : onLoadMore(DEFAULT_ITEMS_PER_PAGE, page);
    return () => {
      clearEmissionList();
    };
  }, [emissionType]);

  useEffect(() => {
    onLoadMore(DEFAULT_ITEMS_PER_PAGE, page);
    return () => {
      clearEmissionList();
    };
  }, [filter]);

  const onLoadMore = (limit, pageNumber) => {
    const skip = (pageNumber - 1) * 20;
    const newLimit = DEFAULT_ITEMS_PER_PAGE * pageNumber;
    const filterRequest = {
      limit: newLimit,
      skip,
      month: selectedMonth,
      year: selectedYear || Number(x) || sampleYearArray[0].key,
      facility_id: selectedFacility,
      period: period,
      country: country,
    };
    if (!filterRequest.facility_id) {
      delete filterRequest.facility_id;
    }
    dispatch(
      getEmissionList(
        emissionType,
        filterRequest,
        isAuditor,
        company,
        typeof selectedFacility === "string"
          ? selectedFacility.split(",")
          : selectedFacility,
        period,
        country
      )
    );
  };
  const scope3TopicDataMapper = {
    upstream_t_and_d: {
      transportation: {
        distance_based: {
          categoryTitle: "Upstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Distance Based",
          tableColumns: UpstreamTransportationDistance,
        },
        fuel_based: {
          categoryTitle: "Upstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Fuel Based",
          tableColumns: UpstreamTransportationFuel,
        },
        refrigerant_leakage: {
          categoryTitle: "Upstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Refrigerant Leakage Based",
          tableColumns: UpstreamTransportationRefrigerant,
        },
        spend_based: {
          categoryTitle: "Upstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Spend Based",
          tableColumns: UpstreamTransportationSpend,
        },
      },
      distribution: {
        site_specific: {
          categoryTitle: "Upstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Distribution",
          subCategory: "Site Specific Based",
          tableColumns: UpstreamDistributionSite,
        },
        average_method: {
          categoryTitle: "Upstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Distribution",
          subCategory: "Average Method Based",
          tableColumns: UpstreamDistributionAverage,
        },
      },
    },
    downstream_t_and_d: {
      transportation: {
        distance_based: {
          categoryTitle: "Downstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Distance Based",
          tableColumns: UpstreamTransportationDistance,
        },
        fuel_based: {
          categoryTitle: "Downstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Fuel Based",
          tableColumns: UpstreamTransportationFuel,
        },
        refrigerant_leakage: {
          categoryTitle: "Downstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Refrigerant Leakage Based",
          tableColumns: UpstreamTransportationRefrigerant,
        },
        spend_based: {
          categoryTitle: "Downstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Transportation",
          subCategory: "Spend Based",
          tableColumns: UpstreamTransportationSpend,
        },
      },
      distribution: {
        site_specific: {
          categoryTitle: "Downstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Distribution",
          subCategory: "Site Specific Based",
          tableColumns: UpstreamDistributionSite,
        },
        average_method: {
          categoryTitle: "Downstream Type: ",
          subCategoryTitle: "Calculation Method: ",
          category: "Distribution",
          subCategory: "Average Method Based",
          tableColumns: UpstreamDistributionAverage,
        },
      },
    },
    business_travel: {
      spend_based: {
        categoryTitle: "Calculation Method: ",
        category: "Spend Based",
        tableColumns: BusinessTravelSpend,
      },
      fuel_based: {
        categoryTitle: "Calculation Method: ",
        category: "Fuel Based",
        tableColumns: BusinessTravelFuel,
      },
      distance_based: {
        categoryTitle: "Calculation Method: ",
        category: "Distance Based",
        tableColumns: BusinessTravelDistance,
      },
    },
    employee_commute: {
      average_method: {
        categoryTitle: "Calculation Method: ",
        category: "average data method",
        tableColumns: EmployeeCommuteAverage,
      },
      distance_based: {
        categoryTitle: "Calculation Method: ",
        category: "distance based",
        tableColumns: EmployeeCommuteDistance,
      },
      fuel_based: {
        categoryTitle: "Calculation Method: ",
        category: "fuel based",
        tableColumns: EmployeeCommuteFuel,
      },
    },
    purchased_goods_and_services: {
      spend_based: {
        categoryTitle: "Calculation Method: ",
        category: "Spend Based",
        tableColumns: PurchasedGoodsSpend,
      },
      average_method: {
        categoryTitle: "Calculation Method: ",
        category: "Average Data Method",
        tableColumns: PurchasedGoodsAverageData,
      },
      supplier_specific: {
        categoryTitle: "Calculation Method: ",
        category: "Supplier-specific Method",
        tableColumns: PurchasedGoodsSupplierSpecific,
      },
    },
    capital_goods: {
      spend_based: {
        categoryTitle: "Calculation Method: ",
        category: "Spend Based",
        tableColumns: CapitalGoodsSpend,
      },
      supplier_specific: {
        categoryTitle: "Calculation Method: ",
        category: "Supplier specific",
        tableColumns: CapitalGoodsSupplier,
      },
      average_method: {
        categoryTitle: "Calculation Method: ",
        category: "Average method",
        tableColumns: CapitalGoodsAverage,
      },
    },
    fuel_and_energy_related_activities: {
      purchased_fuel: {
        categoryTitle: "Calculation Method: ",
        category: "Upstream emissions of purchased fuels",
        tableColumns: FuelEnergyPurchasedFules,
      },
      purchased_electricity: {
        categoryTitle: "Calculation Method: ",
        category: "Upstream emissions of purchased electricity",
        tableColumns: FuelEnergyPurchasedElectricity,
      },
      transmission_distribution_loss: {
        categoryTitle: "Calculation Method: ",
        category: "Emissions from transmission and distribution losses",
        tableColumns: FuelEnergyTandDLosses,
      },
      life_cycle_emission: {
        categoryTitle: "Calculation Method: ",
        category: "Life cycle emissions from power that is purchased and sold",
        tableColumns: FuelEnergyPurchasedAndSold,
      },
    },
    upstream_leased_asset: {
      asset_specific: {
        categoryTitle: "Calculation Method:",
        category: "Asset specific method",
        tableColumns: UpstreamLeasedAsset,
      },
      lessor_specific: {
        categoryTitle: "Calculation Method:",
        category: "Lessor specific method",
        tableColumns: UpstreamLeasedAsset,
      },
      average_method_type_1: {
        categoryTitle: "Calculation Method:",
        category: "Average data method type 1",
        tableColumns: UpstreamLeasedAverage1,
      },
      average_method_type_2: {
        categoryTitle: "Calculation Method:",
        category: "Average data method type 2",
        tableColumns: UpstreamLeasedAverage2,
      },
    },
    downstream_leased_asset: {
      asset_specific: {
        categoryTitle: "Calculation Method:",
        category: "Asset specific method",
        tableColumns: UpstreamLeasedAsset,
      },
      lessor_specific: {
        categoryTitle: "Calculation Method:",
        category: "Lessor specific method",
        tableColumns: UpstreamLeasedAsset,
      },
      average_method_type_1: {
        categoryTitle: "Calculation Method:",
        category: "Average data method type 1",
        tableColumns: UpstreamLeasedAverage1,
      },
      average_method_type_2: {
        categoryTitle: "Calculation Method:",
        category: "Average data method type 2",
        tableColumns: UpstreamLeasedAverage2,
      },
    },
    use_of_sold_products: {
      direct_use_phase: {
        fuel_feedstock: {
          categoryTitle: "Types of emissions from sold products: ",
          subCategoryTitle: "Choose the type of direct use phase emission : ",
          category: "Direct use phase emissions",
          subCategory: "Fuels and Feedstock",
          tableColumns: DirectUsephaseFuels,
        },
        greenhouse_gas_emissions: {
          categoryTitle: "Types of emissions from sold products: ",
          subCategoryTitle: "Choose the type of direct use phase emission : ",
          category: "Direct use phase emissions",
          subCategory:
            "Greenhouse gases and products that contain or form greenhouse gases that are emitted during use",
          tableColumns: DirectUsephaseGreenhouse,
        },
        products_consume_energy: {
          categoryTitle: "Types of emissions from sold products: ",
          subCategoryTitle: "Choose the type of direct use phase emission : ",
          category: "Direct use phase emissions",
          subCategory: "Products that directly consume energy during use",
          tableColumns: DirectUsephaseProducts,
        },
      },
      indirect_use_phase: {
        sold_intermediate_products: {
          categoryTitle: "Types of emissions from sold products: ",
          subCategoryTitle: "Choose the type of indirect use phase emission : ",
          category: "Indirect use phase emissions",
          subCategory: "Calculation method for sold intermediate products",
          tableColumns: IndirectUsephaseCalculation,
        },
        indirect_use_of_emission_from_products: {
          categoryTitle: "Types of emissions from sold products: ",
          subCategoryTitle: "Choose the type of indirect use phase emission : ",
          category: "Indirect use phase emissions",
          subCategory:
            "Indirect use phase emissions from products that indirectly consume energy during use",
          tableColumns: IndirectUseOfEmission,
        },
      },
    },
    processing_of_sold_products: {
      average_data: {
        categoryTitle: "Calculation Method:",
        category: "Average data method",
        tableColumns: ProcessingOfSoldProductsAverage,
      },
      site_specific: {
        categoryTitle: "Calculation Method: ",
        category: "Site Specific Method",
        tableColumns: ProcessingOfSoldProductsSite,
      },
    },
  };
  const getStatusColor = (item) => {
    if (item === "approved") {
      return (
        <Tooltip title="Approved" arrow>
          <Chip className={classes.signalApproved} />
        </Tooltip>
      );
    } else if (item === "submitted") {
      return (
        <Tooltip title="Submitted" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "audited") {
      return (
        <Tooltip title="Audited" arrow>
          <Chip className={classes.signalAudited} />
        </Tooltip>
      );
    } else if (item === "verified") {
      return (
        <Tooltip title="Verified" arrow>
          <Chip className={classes.signalVerified} />
        </Tooltip>
      );
    } else if (item === "review") {
      return (
        <Tooltip title="Review" arrow>
          <Chip className={classes.signalReview} />
        </Tooltip>
      );
    } else if (item === "added") {
      return (
        <Tooltip title="Added" arrow>
          <Chip className={classes.signalAdded} />
        </Tooltip>
      );
    } else if (item === "assigned") {
      return (
        <Tooltip title="assigned" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "Awaiting approval") {
      return (
        <Tooltip title="Awaiting approval" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "In Progress") {
      return (
        <Tooltip title="In Progress" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "pending_approval") {
      return (
        <Tooltip title="Pending Approval" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={item} arrow>
          <Chip className={classes.signalAdded} />
          {item}
        </Tooltip>
      );
    }
  };
  const getEmissionData = (tableData) =>
    Array.isArray(tableData)
      ? tableData?.map((item) => ({
          ...item,
          year: `${item.month}/${item.year}`,
          amount: `${item.amount}  ${item.unit ? item.unit : ""}`,
          status: getStatusColor(item.status),
          treatment_required: item.treatment_required ? "Yes" : "No",
          pending_action_role:
            item.pending_action_role === null ? "NA" : item.pending_action_role,
        }))
      : [];
  const onSelectEmissionData = (rowData) => {
    isAuditor
      ? navigate(
          `/emissions/${emissionType}/details/${rowData.id}/?company=${company}`
        )
      : navigate(`/emissions/${emissionType}/details/${rowData.id}`);
  };

  const twoTyreTopics = [
    "upstream_t_and_d",
    "downstream_t_and_d",
    "use_of_sold_products",
  ];

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Box className={classes.tableTopic}>
          <Typography variant="h7" component="span">
            <span className={classes.tableTitle}>
              {emissionType.replace(/_/g, " ")}
            </span>
          </Typography>
          <Box>
            <CeroButton
              buttonText="Back"
              variant="outlined"
              className={classes.buttonSecondary}
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
            />
          </Box>
        </Box>
        {emissionDataStatus === STATUS.SUCCESS ? (
          <>
            {twoTyreTopics.includes(emissionType)
              ? Object.keys(scope3TopicDataMapper[emissionType]).map((key) =>
                  Object.keys(scope3TopicDataMapper[emissionType][key]).map(
                    (subKey) => {
                      if (emissionData[key]?.[subKey]?.length > 0) {
                        return (
                          <div key={`${key}-${subKey}`}>
                            <div className={classes.subHeads}>
                              <Typography variant="h7" component="span">
                                <span className={classes.tableSubTitle}>
                                  {`${scope3TopicDataMapper[emissionType][key][subKey]["categoryTitle"]} 
                ${scope3TopicDataMapper[emissionType][key][subKey]["category"]}`}
                                </span>
                              </Typography>
                              <br />
                            </div>
                            <div className={classes.subHeads}>
                              <Typography variant="h7" component="span">
                                <span className={classes.tableSubTitle}>
                                  {`${scope3TopicDataMapper[emissionType][key][subKey]["subCategoryTitle"]} 
                ${scope3TopicDataMapper[emissionType][key][subKey]["subCategory"]}`}
                                </span>
                              </Typography>
                            </div>
                            <CeroTable
                              columns={
                                scope3TopicDataMapper[emissionType][key][
                                  subKey
                                ]["tableColumns"] || []
                              }
                              data={getEmissionData(emissionData[key][subKey])}
                              hasMore={false}
                              loading={false}
                              loadMore={onLoadMore}
                              classes={{
                                tableContainer: classes.tableContainer,
                              }}
                            />
                          </div>
                        );
                      }
                      return null; // Ensures that `map()` always returns something
                    }
                  )
                )
              : Object.keys(scope3TopicDataMapper[emissionType]).map((key) => {
                  if (emissionData[key]?.length > 0) {
                    return (
                      <div key={`${key}`}>
                        <div className={classes.subHeads}>
                          <Typography variant="h7" component="span">
                            <span className={classes.tableSubTitle}>
                              {`${scope3TopicDataMapper[emissionType][key]["categoryTitle"]} 
                  ${scope3TopicDataMapper[emissionType][key]["category"]}`}
                            </span>
                          </Typography>
                          <br />
                        </div>
                        <CeroTable
                          columns={
                            scope3TopicDataMapper[emissionType][key][
                              "tableColumns"
                            ] || []
                          }
                          data={getEmissionData(emissionData[key])}
                          hasMore={false}
                          loading={false}
                          loadMore={onLoadMore}
                          classes={{ tableContainer: classes.tableContainer }}
                        />
                      </div>
                    );
                  }
                  return null; // Ensures that `map()` always returns something
                })}
          </>
        ) : (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default ApprovalStatusScope3Table;
