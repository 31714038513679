import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 2),
    height: "100%",
    overflowY: "hidden",
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  tableContainer: {
    padding: 0,
    width: "100%",
    maxHeight: "calc(100vh - 120px)",
    background: theme.palette.background.white,
    marginTop: theme.spacing(5),
  },
  cellContainer: {
    boxShadow: "none",
  },
  generalCellContainer: {
    width: "50%",
    boxShadow: "none",
  },
  tableHeaderContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    background: theme.palette.Secondary.background,
    fontWeight: 500,
  },
  audited: {
    color: theme.palette.status.audited,
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    fontSize: 14,
  },
  approved: {
    color: theme.palette.status.approved,
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    fontSize: 14,
  },
  added: {
    color: theme.palette.status.added,
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    marginRight: theme.spacing(1),
  },
  review: {
    color: theme.palette.status.review,
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    marginRight: theme.spacing(1),
  },
  verified: {
    color: theme.palette.status.verified,
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    marginRight: theme.spacing(1),
  },
  pendingApproval: {
    color: theme.palette.status.pendingApproval,
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    fontSize: 14,
  },
  actionContainer: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    backgroundColor: theme.palette.Secondary.main,
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    backgroundColor: theme.palette.background.white,
    textTransform: "capitalize",
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.contrastText,
    },
    marginRight: theme.spacing(5),
    marginLeft: theme.spacing(5),
  },
  tableTopic: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 20,
    alignItems: "center",
  },
  tableTitle: {
    fontWeight: 700,
    fontSize: 18,
    textTransform: "capitalize",
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  icon: {
    marginLeft: theme.spacing(10),
    color: theme.palette.Primary.background,
    cursor: "pointer",
    "&:hover": {
      background: "white",
      borderRadius: "50%",
    },
  },
  textField: {
    backgroundColor: theme.palette.background.white,
  },
  noComments: {
    float: "left",
    fontFamily: "Arial, sans-serif",
    fontSize: 12,
  },
  maxCharacters: {
    float: "right",
    fontSize: 12,
  },
  dialogActions: {
    justifyContent: "flex-start",
    display: "flex",
    paddingLeft: 30,
    paddingBottom: 30,
  },
  textLimit: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 10,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 20,
    paddingBottom: 15,
  },
  topContainer: {
    textTransform: "capitalize",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  popUp: {
    margin: "auto",
  },
  dialog: {
    width: "40% !important",
    textAlign: "center",
  },
  reviewContainer: {
    display: "flex",
    alignItems: "center",
    color: "red",
  },
  editIcon: {
    color: theme.palette.Primary.background,
  },
  reviewButtons: {
    marginRight: theme.spacing(2),
  },
  signalAdded: {
    backgroundColor: theme.palette.status.added,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1),
  },
  signalApproved: {
    backgroundColor: theme.palette.status.approved,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1),
  },
  signalVerified: {
    backgroundColor: theme.palette.status.verified,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1),
  },
  signalReview: {
    backgroundColor: theme.palette.status.review,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1),
  },
  signalPending: {
    backgroundColor: theme.palette.status.pendingApproval,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1),
  },
  signalAudited: {
    backgroundColor: theme.palette.status.audited,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1),
  },
  modalContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    outline: "none",
  },
  closeButton: {
    marginTop: theme.spacing(2),
    marginLeft: "auto",
    display: "block",
  },
  tableSubTitle: {
    fontWeight: 500,
    fontSize: 16,
    textTransform: "capitalize",
  },
  subHeads: {
    marginTop: "10px",
    marginBottom: "10px",
  },
}));

export default useStyles;
