export const StationaryColumns = [
  {
    columnKey: "fuel",
    columnId: "fuel",
    columnHeader: "Fuel",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "CO2\n(Ton)",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "CH4\n(Ton)",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "N2O\n(Ton)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e\n(Ton)",
  },
  {
    columnKey: "biofuel_co2",
    columnId: "biofuel_co2",
    columnHeader: "BioFuel CO2\n(Ton)",
  },
  {
    columnKey: "ef",
    columnId: "ef",
    columnHeader: "EF \n(kgCO2e/unit)",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const TransportationColumns = [
  {
    columnKey: "category_name",
    columnId: "category_name",
    columnHeader: "Category",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "mode_of_transport_name",
    columnId: "mode_of_transport_name",
    columnHeader: "Mode of Transport",
  },
  {
    columnKey: "vehicle_type_name",
    columnId: "vehicle_type_name",
    columnHeader: "Vehicle type",
  },
  {
    columnKey: "activity_type_name",
    columnId: "activity_type_name",
    columnHeader: "Unit",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "CO2\n(Ton)",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "CH4\n(Ton)",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "N2O\n(Ton)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e\n(Ton)",
  },
  {
    columnKey: "biofuel_co2",
    columnId: "biofuel_co2",
    columnHeader: "BioFuel CO2\n(Ton)",
  },
  {
    columnKey: "ef",
    columnId: "ef",
    columnHeader: "EF \n(kgCO2e/unit)",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const MobileColumns = [
  {
    columnKey: "fuel_source",
    columnId: "fuel_source",
    columnHeader: "Fuel Source",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "activity_type",
    columnId: "activity_type",
    columnHeader: "Activity Type",
  },
  {
    columnKey: "vehicle_type",
    columnId: "vehicle_type",
    columnHeader: "Vehicle Type",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "CO2\n(Ton)",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "CH4\n(Ton)",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "N2O\n(Ton)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e\n(Ton)",
  },
  {
    columnKey: "biofuel_co2",
    columnId: "biofuel_co2",
    columnHeader: "BioFuel CO2\n(Ton)",
  },
  {
    columnKey: "ef",
    columnId: "ef",
    columnHeader: "EF \n(kgCO2e/unit)",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const PurchasedElectricityColumns = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "calculation_approach",
    columnId: "calculation_approach",
    columnHeader: "Calculation Approach",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "CO2\n(Ton)",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "CH4\n(Ton)",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "N2O\n(Ton)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e\n(Ton)",
  },
  {
    columnKey: "ef",
    columnId: "ef",
    columnHeader: "EF \n(kgCO2e/unit)",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const WaterDischargeColumns = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "treatment_level",
    columnId: "treatment_level",
    columnHeader: "Treatment Level",
  },
  {
    columnKey: "treatment_required",
    columnId: "treatment_required",
    columnHeader: "Treatment Required",
  },
  {
    columnKey: "water_destination_name",
    columnId: "water_destination_name",
    columnHeader: "Destination Name",
  },
  {
    columnKey: "water_destination_stress_type_name",
    columnId: "water_destination_stress_type_name",
    columnHeader: "Stress Type",
  },
  {
    columnKey: "water_destination_type_name",
    columnId: "water_destination_type_name",
    columnHeader: "Destination type",
  },
  {
    columnKey: "usage",
    columnId: "usage",
    columnHeader: "Usage",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e (Ton)",
  },
  // {
  //   columnKey: "month",
  //   columnId: "month",
  //   columnHeader: "Month",
  // },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const WaterConsumptionColumns = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "water_source_stress_type_name",
    columnId: "water_source_stress_type_name",
    columnHeader: "Stress type",
  },
  {
    columnKey: "water_source_type_name",
    columnId: "water_source_type_name",
    columnHeader: "Source type",
  },
  {
    columnKey: "usage",
    columnId: "usage",
    columnHeader: "Usage",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e (Ton)",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const RefrigerantsColumns = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "gas_type_name",
    columnId: "gas_type_name",
    columnHeader: "Gas Type",
  },
  {
    columnKey: "refrigerant_name",
    columnId: "refrigerant_name",
    columnHeader: "Refrigerant",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e (Ton)",
  },
  // {
  //   columnKey: "month",
  //   columnId: "month",
  //   columnHeader: "Month",
  // },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];
export const EndOfLifeTreatmentColumns = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "product_name",
    columnId: "product_name",
    columnHeader: "Product",
  },
  {
    columnKey: "total_mass",
    columnId: "total_mass",
    columnHeader: "Total Mass of Sold Products & Packaging",
  },
  {
    columnKey: "percentage_treated",
    columnId: "percentage_treated",
    columnHeader: "% of total waste treated by method",
  },
  {
    columnKey: "material_name",
    columnId: "material_name",
    columnHeader: "Material",
  },
  {
    columnKey: "waste_treatment_method_name",
    columnId: "waste_treatment_method_name",
    columnHeader: "Waste treatment method",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];
export const WasteCombustionColumns = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "waste_type_name",
    columnId: "waste_type_name",
    columnHeader: "Waste Type",
  },

  {
    columnKey: "waste_disposal_option_name",
    columnId: "waste_disposal_option_name",
    columnHeader: "Waste disposal option",
  },

  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "usage",
    columnId: "usage",
    columnHeader: "Usage",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e (Ton)",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const DevelopmentTrainingColumns = [
  {
    columnKey: "objective",
    columnId: "objective",
    columnHeader: "Objective",
  },
  {
    columnKey: "attended",
    columnId: "attended",
    columnHeader: "No of Attendee",
  },
  {
    columnKey: "hours",
    columnId: "hours",
    columnHeader: "No of Hours",
  },
  {
    columnKey: "content",
    columnId: "content",
    columnHeader: "Content",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const EmployeeHealthDetailsColumns = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "assessment_period",
    columnId: "assessment_period",
    columnHeader: "Period",
  },
  {
    columnKey: "date",
    columnId: "date",
    columnHeader: "Date",
  },
  {
    columnKey: "type_of_incident",
    columnId: "type_of_incident",
    columnHeader: "Type of incident",
  },
  {
    columnKey: "affected",
    columnId: "affected",
    columnHeader: "Number of Affected",
  },
  {
    columnKey: "severity_of_incident",
    columnId: "severity_of_incident",
    columnHeader: "Severity of Incident",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const WorkerSafetyTrainingColumns = [
  {
    columnKey: "assessment_period",
    columnId: "assessment_period",
    columnHeader: "Period",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "date",
    columnId: "date",
    columnHeader: "Training Date",
  },
  {
    columnKey: "training_method",
    columnId: "training_method",
    columnHeader: "Training Methods",
  },
  {
    columnKey: "attended",
    columnId: "attended",
    columnHeader: "Number of Attendees",
  },
  {
    columnKey: "number_of_hours",
    columnId: "number_of_hours",
    columnHeader: "Number of Hours",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const EmployeeDescriminationIncidentColumns = [
  {
    columnKey: "assessment_period",
    columnId: "assessment_period",
    columnHeader: "Period",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "date",
    columnId: "date",
    columnHeader: "Date",
  },
  {
    columnKey: "status_of_incident",
    columnId: "status_of_incident",
    columnHeader: "Status of Incident",
  },
  {
    columnKey: "types_of_discrimination",
    columnId: "types_of_discrimination",
    columnHeader: "Type of Discrimination",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const SupplierHumanRightsTrainigColumns = [
  {
    columnKey: "assessment_period",
    columnId: "assessment_period",
    columnHeader: "Period",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "supplier_name",
    columnId: "supplier_name",
    columnHeader: "Supplier Name",
  },
  {
    columnKey: "risk_of_child_labor",
    columnId: "risk_of_child_labor",
    columnHeader: "Risk of Child Labour",
  },
  {
    columnKey: "risk_of_compulsory_labor",
    columnId: "risk_of_compulsory_labor",
    columnHeader: "Risk of Forced or Compulsory Labour",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const SocialHumanRightsTrainigColumns = [
  {
    columnKey: "assessment_period",
    columnId: "assessment_period",
    columnHeader: "Period",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "risk_of_child_labor",
    columnId: "risk_of_child_labor",
    columnHeader: "Risk of Child Labour",
  },
  {
    columnKey: "risk_of_compulsory_labor",
    columnId: "risk_of_compulsory_labor",
    columnHeader: "Risk of Forced or Compulsory Labour",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const SupplierScreeningColumns = [
  {
    columnKey: "assessment_period",
    columnId: "assessment_period",
    columnHeader: "Period",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "suppliers_screened_using_env_criteria",
    columnId: "suppliers_screened_using_env_criteria",
    columnHeader: "Suppliers screened using Environmental Criteria",
  },
  {
    columnKey: "suppliers_screened_using_social_criteria",
    columnId: "suppliers_screened_using_social_criteria",
    columnHeader: "Suppliers screened using Social Criteria",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const LocalCommunitiesColumns = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "assessment_period",
    columnId: "assessment_period",
    columnHeader: "Period",
  },
  {
    columnKey: "local_community",
    columnId: "local_community",
    columnHeader: "Name of local community",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Total Contributions",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const PoliticalContributionColumns = [
  {
    columnKey: "assessment_period",
    columnId: "assessment_period",
    columnHeader: "Period",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "any_political_contribution",
    columnId: "any_political_contribution",
    columnHeader: "Political Contributions",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Total Amount",
  },
  {
    columnKey: "beneficiary_of_contribution",
    columnId: "beneficiary_of_contribution",
    columnHeader: "Beneficiary",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const AntiCorruptionDisclosureColumns = [
  {
    columnKey: "assessment_period",
    columnId: "assessment_period",
    columnHeader: "Period",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "is_organisation_accessed_for_corruption",
    columnId: "is_organisation_accessed_for_corruption",
    columnHeader: "Is Organization assessed for risk of corruption",
  },
  {
    columnKey: "number_of_operation",
    columnId: "number_of_operation",
    columnHeader: "Total Number of operations assessed",
  },
  {
    columnKey: "number_of_incident",
    columnId: "number_of_incident",
    columnHeader: "Number of incidents Reported",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const AntiCorruptionTrainigColumns = [
  {
    columnKey: "assessment_period",
    columnId: "assessment_period",
    columnHeader: "Period",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "attended",
    columnId: "attended",
    columnHeader: "Number of Attendees",
  },
  {
    columnKey: "training_date",
    columnId: "training_date",
    columnHeader: "Training date",
  },
  {
    columnKey: "training_method",
    columnId: "training_method",
    columnHeader: "Training Methods",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const AntiCompetitiveDisclosureColumns = [
  {
    columnKey: "assessment_period",
    columnId: "assessment_period",
    columnHeader: "Period",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "number_of_legal_action",
    columnId: "number_of_legal_action",
    columnHeader: "Number of Legal Actions",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const FinancialAssistanceColumns = [
  {
    columnKey: "assessment_period",
    columnId: "assessment_period",
    columnHeader: "Period",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "financial_assistant_received",
    columnId: "financial_assistant_received",
    columnHeader: "Total Financial Assistance received",
  },
  {
    columnKey: "type_financial_assistant",
    columnId: "type_financial_assistant",
    columnHeader: "Type of Assistance",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const UploadFileEmissionColumns = [
  {
    columnKey: "content",
    columnId: "content",
    columnHeader: "Content",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const EmployeeHireAndTurnover = [
  {
    columnKey: "assessment_period",
    columnId: "assessment_period",
    columnHeader: "Period",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "gender",
    columnId: "gender",
    columnHeader: "Gender",
  },
  {
    columnKey: "facility",
    columnId: "facility",
    columnHeader: "Facility",
  },
  {
    columnKey: "employee_classification",
    columnId: "employee_classification",
    columnHeader: "Employee Classification",
  },
  {
    columnKey: "resigned",
    columnId: "resigned",
    columnHeader: "Resigned",
  },
  {
    columnKey: "hired",
    columnId: "hired",
    columnHeader: "Hired",
  },
  {
    columnKey: "turnover_rate",
    columnId: "turnover_rate",
    columnHeader: "Turnover Rate",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const EmployeeHireAndTurnoverByAge = [
  {
    columnKey: "assessment_period",
    columnId: "assessment_period",
    columnHeader: "Period",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "age",
    columnId: "age",
    columnHeader: "Age",
  },
  {
    columnKey: "facility",
    columnId: "facility",
    columnHeader: "Facility",
  },
  {
    columnKey: "employee_classification",
    columnId: "employee_classification",
    columnHeader: "Employee Classification",
  },
  {
    columnKey: "resigned",
    columnId: "resigned",
    columnHeader: "Resigned",
  },
  {
    columnKey: "hired",
    columnId: "hired",
    columnHeader: "Hired",
  },
  {
    columnKey: "turnover_rate",
    columnId: "turnover_rate",
    columnHeader: "Turnover Rate",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
];

export const EmployeeBenefits = [
  {
    columnKey: "assessment_period",
    columnId: "assessment_period",
    columnHeader: "Period",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "employees_classification",
    columnId: "employees_classification",
    columnHeader: "Employee Classification",
  },
  {
    columnKey: "benefit_provided",
    columnId: "benefit_provided",
    columnHeader: "Benefits Provided",
  },
  {
    columnKey: "number_of_employees",
    columnId: "number_of_employees",
    columnHeader: "Number of Employees",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const TrainingHoursPerEmployee = [
  {
    columnKey: "period",
    columnId: "period",
    columnHeader: "Period",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "facility",
    columnId: "facility",
    columnHeader: "Facility",
  },
  {
    columnKey: "date",
    columnId: "date",
    columnHeader: "Date",
  },
  {
    columnKey: "total_employees_training_percentage",
    columnId: "total_employees_training_percentage",
    columnHeader: "Average Training Hours per Employee",
  },
  {
    columnKey: "female_employees_training_percentage",
    columnId: "female_employees_training_percentage",
    columnHeader: "Average Training Hours per Female Employee",
  },
  {
    columnKey: "male_employees_training_percentage",
    columnId: "male_employees_training_percentage",
    columnHeader: "Average Training Hours per Male Employee",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const PerformanceAndCareerDevelopmentProgramsColumns = [
  {
    columnKey: "period",
    columnId: "period",
    columnHeader: "Period",
  },
  {
    columnKey: "facility",
    columnId: "facility",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "date",
    columnId: "date",
    columnHeader: "Date",
  },
  {
    columnKey: "program_type",
    columnId: "program_type",
    columnHeader: "Type of programs",
  },
  {
    columnKey: "employees_participation_percentage",
    columnId: "employees_participation_percentage",
    columnHeader: "Percentage of employees participated",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const EconomicImpactColumns = [
  {
    columnKey: "assessment_period",
    columnId: "assessment_period",
    columnHeader: "Period",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "any_significant_infrastructure",
    columnId: "any_significant_infrastructure",
    columnHeader: "Infrastructure Investment",
  },
  {
    columnKey: "nature_of_investments",
    columnId: "nature_of_investments",
    columnHeader: "Nature of Investment",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const BoardDiversityByGenderColumns = [
  {
    columnKey: "assessment_period",
    columnId: "assessment_period",
    columnHeader: "Period",
    width: "15vw",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
    width: "15vw",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
    width: "15vw",
  },
  {
    columnKey: "male",
    columnId: "male",
    columnHeader: "Male %",
    width: "20vw",
  },
  {
    columnKey: "female",
    columnId: "female",
    columnHeader: "Female %",
    width: "20vw",
  },
  {
    columnKey: "other",
    columnId: "other",
    columnHeader: "Non-Binary %",
    width: "20vw",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
    width: "15vw",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const BoardDiversityByAgeGroupColumns = [
  {
    columnKey: "assessment_period",
    columnId: "assessment_period",
    columnHeader: "Period",
    width: "15vw",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
    width: "15vw",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
    width: "15vw",
  },
  {
    columnKey: "under_30",
    columnId: "under_30",
    columnHeader: "% of Members under 30 years",
    width: "20vw",
  },
  {
    columnKey: "between_30_50",
    columnId: "between_30_50",
    columnHeader: "% of Members under 30-50 years",
    width: "20vw",
  },
  {
    columnKey: "over_50",
    columnId: "over_50",
    columnHeader: "% of Members over 50 years",
    width: "20vw",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
    width: "15vw",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];
export const ManagementDiversityByGenderColumns = [
  {
    columnKey: "assessment_period",
    columnId: "assessment_period",
    columnHeader: "Period",
    width: "15vw",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
    width: "15vw",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
    width: "15vw",
  },
  {
    columnKey: "male",
    columnId: "male",
    columnHeader: "Male %",
    width: "20vw",
  },
  {
    columnKey: "female",
    columnId: "female",
    columnHeader: "Female %",
    width: "20vw",
  },
  {
    columnKey: "other",
    columnId: "other",
    columnHeader: "Non-Binary %",
    width: "20vw",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
    width: "15vw",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
    // width : '5vw',
  },
];

export const ManagementDiversityByAgeGroupColumns = [
  {
    columnKey: "assessment_period",
    columnId: "assessment_period",
    columnHeader: "Period",
    width: "15vw",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
    width: "15vw",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
    width: "15vw",
  },
  {
    columnKey: "under_30",
    columnId: "under_30",
    columnHeader: "% of Members under 30 years",
    width: "20vw",
  },
  {
    columnKey: "between_30_50",
    columnId: "between_30_50",
    columnHeader: "% of Members under 30-50 years",
    width: "20vw",
  },
  {
    columnKey: "over_50",
    columnId: "over_50",
    columnHeader: "% of Members over 50 years",
    width: "20vw",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
    width: "15vw",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
    // width : '5vw',
  },
];

export const sectionConfig = {
  stationary_combustion: {
    title: "Stationary Combustion",
    headers: [
      "Amount",
      "Unit",
      "Fuel",
      "Facility Name",
      "Year",
      "Month",
      "CO2",
      "N2O",
      "CH4",
      "Bio Fuel",
      "EF",
      "Status",
    ],
    keys: [
      "amount",
      "unit",
      "fuel",
      "facility_name",
      "year",
      "month",
      "co2",
      "n2o",
      "ch4",
      "biofuel_co2",
      "ef",
      "status",
    ],
  },
  end_of_life_treatment_of_sold_products: {
    title: "End-of-life treatment of sold products",
    headers: [
      "% of total waste treated by method",
      "Unit",
      "Waste treatment method",
      "Facility Name",
      "Year",
      "Month",
      "Material",
      "Product",
      "Total Mass of Sold Products & Packaging",
      "Status",
    ],
    keys: [
      "percentage_treated",
      "unit",
      "waste_treatment_method_name",
      "facility_name",
      "year",
      "month",
      "material_name",
      "product_name",
      "total_mass",
      "status",
    ],
  },
  mobile_combustion: {
    title: "Mobile Combustion",
    headers: [
      "Amount",
      "Unit",
      "Facility Name",
      "Year",
      "Month",
      "Vehicle Type",
      "Activity",
      "Fuel",
      "CO2",
      "N2O",
      "CH4",
      "Bio Fuel",
      "EF",
      "Status",
    ],
    keys: [
      "amount",
      "unit",
      "facility_name",
      "year",
      "month",
      "vehicle_type",
      "activity_type",
      "fuel_source",
      "co2",
      "n2o",
      "ch4",
      "biofuel_co2",
      "ef",
      "status",
    ],
  },
  refrigerants: {
    title: "Refrigerants",
    headers: [
      "Amount",
      "Unit",
      "Refrigerant",
      "Gas Type",
      "Facility Name",
      "Co2E",
      "Year",
      "Month",
      "Status",
    ],
    keys: [
      "amount",
      "unit",
      "refrigerant_name",
      "gas_type_name",
      "facility_name",
      "co2e",
      "year",
      "month",
      "status",
    ],
  },
  purchased_electricity: {
    title: "Purchased Electricity",
    headers: [
      "Amount",
      "Unit",
      "Calculation Approach",
      "Facility Name",
      "Year",
      "Month",
      "CO2",
      "N2O",
      "CH4",
      "Co2E",
      "Status",
    ],
    keys: [
      "amount",
      "unit",
      "calculation_approach",
      "facility_name",
      "year",
      "month",
      "co2",
      "n2o",
      "ch4",
      "co2e",
      "status",
    ],
  },
  transportation: {
    title: "Transportation",
    headers: [
      "Amount",
      "Unit",
      "Category",
      "EF",
      "Mode of Transport",
      "Vehicle Type",
      "Facility Name",
      "Year",
      "Month",
      "CO2",
      "N2O",
      "CH4",
      "Co2E",
      "Status",
    ],
    keys: [
      "amount",
      "unit",
      "category_name",
      "ef_dataset_name",
      "mode_of_transport_name",
      "vehicle_type_name",
      "facility_name",
      "year",
      "month",
      "co2",
      "n2o",
      "ch4",
      "co2e",
      "status",
    ],
  },
  water_consumption: {
    title: "Water Consumption",
    headers: [
      "Amount",
      "Unit",
      "Water Source",
      "Source Stress",
      "Source Type",
      "Usage",
      "Facility Name",
      "Year",
      "Month",
      "Co2E",
      "Status",
    ],
    keys: [
      "amount",
      "unit",
      "water_source_name",
      "water_source_stress_type_name",
      "water_source_type_name",
      "usage",
      "facility_name",
      "year",
      "month",
      "co2e",
      "status",
    ],
  },
  waste: {
    title: "Waste",
    headers: [
      "Facility Name",
      "Waste Type",
      "Month",
      "Year",
      "Waste Hazard Option",
      "Waste Disposal Option",
      "Waste Disposal Location",
      "Waste disposal operation",
      "Amount",
      "Unit",
      "CO2e",
    ],
    keys: [
      "facility_name",
      "waste_type_name",
      "month",
      "year",
      "waste_hazard_option_name",
      "waste_disposal_option_name",
      "waste_disposal_location_name",
      "waste_disposal_operation_name",
      "amount",
      "unit",
      "co2e",
    ],
  },
  water_discharge: {
    title: "Water Discharge",
    headers: [
      "Amount",
      "Unit",
      "Stress type",
      "Destination",
      "Treatment Reqd",
      "Treatment Level",
      "Destination type",
      "Facility Name",
      "Year",
      "Month",
      "Usage",
      "Co2E",
      "Status",
    ],
    keys: [
      "amount",
      "unit",
      "water_destination_stress_type_name",
      "water_destination_name",
      "treatment_required",
      "treatment_level",
      "water_destination_type_name",
      "facility_name",
      "year",
      "month",
      "usage",
      "co2e",
      "status",
    ],
  },
  employees_turnover: {
    title: "Employees Turnover",
    headers: ["Content", "Date", "Year", "Month", "Status"],
    keys: ["content", "date", "year", "month", "status"],
  },
  employees_hire_and_turnover: {
    title: "Employee Hire & Turnover",
    headers: ["Added by", "Approved by", "Audited by", "Facility", "Status"],
    keys: [
      "added_by_name",
      "approved_by_name",
      "audited_by_name",
      "facility_name",
      "status",
    ],
  },
  employees_benefit: {
    title: "Employee Benefits",
    headers: ["Added by", "Approved by", "Audited by", "Facility", "Status"],
    keys: [
      "added_by_name",
      "approved_by_name",
      "audited_by_name",
      "facility_name",
      "status",
    ],
  },
  employees_diversity_and_ratio_of_basic_salary: {
    title: "Employee Diversity&Ratio of Salary",
    headers: [
      "Period",
      "Type",
      "Basic Salary Ratio(M)",
      "Basic Salary Ratio(F)",
      "Added by",
      "Approved by",
      "Audited by",
      "Benifits Provided",
      "Facility",
      "Salary Paid",
      "Status",
    ],
    keys: [
      "assessment_period",
      "category_type",
      "basic_salary_ratio_for_male",
      "basic_salary_ratio_for_female",
      "added_by_name",
      "approved_by_name",
      "audited_by_name",
      "benefits_provided",
      "facility_name",
      "salary_paid",
      "status",
    ],
  },
  development_training: {
    title: "Development Training",
    headers: [
      "Content",
      "Attended",
      "Hours",
      "Objective",
      "Year",
      "Month",
      "Status",
    ],
    keys: [
      "content",
      "attended",
      "hours",
      "objective",
      "year",
      "month",
      "status",
    ],
  },
  employee_health_safety_incident_record: {
    title: "Employee Health Safety Incident Record",
    headers: [
      "Affected",
      "Corrective Action",
      "Details",
      "Facility Name",
      "Year",
      "Month",
      "Status",
    ],
    keys: [
      "affected",
      "corrective_action",
      "details",
      "facility_name",
      "year",
      "month",
      "status",
    ],
  },
  worker_safety_training_procedures: {
    title: "Worker Safety Training Procedures",
    headers: [
      "Training Hours",
      "Training Method",
      "Attended",
      "Facility",
      "Year",
      "Month",
      "Status",
    ],
    keys: [
      "training_hours",
      "training_method",
      "attended",
      "facility_name",
      "year",
      "month",
      "status",
    ],
  },
  age_based_statistics: {
    title: "Age Based Statistics",
    headers: ["Content", "Date", "Year", "Month", "Status"],
    keys: ["content", "date", "year", "month", "status"],
  },
  gender_based_statistics: {
    title: "Gender Age Based Statistics",
    headers: ["Content", "Date", "Year", "Month", "Status"],
    keys: ["content", "date", "year", "month", "status"],
  },
  discrimination_incident_record: {
    title: "Discrimination Incident Record",
    headers: [
      "Added by",
      "Approved by",
      "Audited by",
      "Corrective Action",
      "Details",
      "Facility",
      "Year",
      "Month",
      "Status",
    ],
    keys: [
      "added_by_name",
      "approved_by_name",
      "audited_by_name",
      "corrective_action",
      "details",
      "facility_name",
      "year",
      "month",
      "status",
    ],
  },
  supplier_screening: {
    title: "Supplier Screening",
    headers: ["Details", "Supplier", "Year", "Month"],
    keys: ["details", "supplier", "year", "month"],
  },
  operational_human_rights_training: {
    title: "Operational Human Rights Training",
    headers: ["Content", "Attended", "Objective", "Year", "Month", "Status"],
    keys: ["content", "attended", "objective", "year", "month", "status"],
  },
  social_engagement_human_rights_training: {
    title: "Social Human Rights Training",
    headers: [
      "Added by",
      "Facility",
      "Year",
      "Compulsory Labor Details",
      "Incident or Action Occured",
      "Status",
    ],
    keys: [
      "added_by_name",
      "facility_name",
      "year",
      "compulsory_labor_details",
      "incident_or_action_occurred",
      "status",
    ],
  },
  local_communities: {
    title: "Local Communities",
    headers: [
      "Period",
      "Objective",
      "Project Undertaken",
      "Facility Name",
      "Year",
      "Month",
      "Status",
    ],
    keys: [
      "assessment_period",
      "objective",
      "project_undertaken",
      "facility_name",
      "year",
      "month",
      "status",
    ],
  },
  political_contributions: {
    title: "Political Contributions",
    headers: [
      "Description of Contribution",
      "Disclosure",
      "Year",
      "Amount",
      "Status",
    ],
    keys: [
      "description_of_contribution",
      "disclosure_explanation",
      "year",
      "amount",
      "status",
    ],
  },
  board_diversity: {
    title: "Board Diversity",
    headers: ["Period", "Added by", "Year", "Facility", "Status"],
    keys: [
      "assessment_period",
      "added_by_name",
      "year",
      "facility_name",
      "status",
    ],
  },
  management_diversity: {
    title: "Management Diversity",
    headers: ["Added by", "Period", "Year", "Facility", "Status"],
    keys: [
      "added_by_name",
      "assessment_period",
      "year",
      "facility_name",
      "status",
    ],
  },
  anti_corruption_disclosure: {
    title: "Anti Corruption Dislosure",
    headers: [
      "Period",
      "Description",
      "Risk Related Corruption",
      "Facility Name",
      "Year",
      "Month",
      "Status",
    ],
    keys: [
      "assessment_period",
      "description_of_corruption",
      "risk_related_corruption",
      "facility_name",
      "year",
      "month",
      "status",
    ],
  },
  anti_corruption_training: {
    title: "Anti Corruption Training",
    headers: [
      "Description of Training",
      "Attended",
      "Training Method",
      "Year",
      "Facility",
      "Status",
    ],
    keys: [
      "description_of_training",
      "attended",
      "training_method",
      "year",
      "facility_name",
      "status",
    ],
  },
  anti_competitive_disclosure: {
    title: "Anti Competitive Dislosure",
    headers: [
      "Added by",
      "Corrective Action",
      "Corrective Action",
      "Facility Name",
      "Year",
      "Month",
      "Status",
    ],
    keys: [
      "added_by_name",
      "corrective_action",
      "corrective_action",
      "facility_name",
      "year",
      "month",
      "status",
    ],
  },
  subsidies_financial_assistance: {
    title: "Subsidies Financial Assistance",
    headers: [
      "Details",
      "Finanicial Assistance Received",
      "Period",
      "Year",
      "Facility",
      "Status",
    ],
    keys: [
      "details",
      "financial_assistant_received",
      "assessment_period",
      "year",
      "facility_name",
      "status",
    ],
  },
  economic_impact: {
    title: "Economic Impact",
    headers: [
      "Period",
      "Facility",
      "Year",
      "Infrastructure Investment",
      "Nature of Investment",
      "Status",
    ],
    keys: [
      "assessment_period",
      "facility_name",
      "year",
      "any_significant_infrastructure",
      "nature_of_investments",
      "status",
    ],
  },
  direct_economic_impact: {
    title: "Direct Economic Impact",
    headers: ["Content", "Date", "Year", "Month", "Status"],
    keys: ["content", "date", "year", "month", "status"],
  },
  indirect_economic_impact: {
    title: "Indirect Economic Impact",
    headers: ["Content", "Date", "Year", "Month", "Status"],
    keys: ["content", "date", "year", "month", "status"],
  },
  performance_and_career_development_programs: {
    title: "Performance & Career Development Programs",
    headers: [
      "Period",
      "Facility",
      "Year",
      "Type of programs",
      "Program Scope",
      "Percentage of employees participated",
      "Status",
    ],
    keys: [
      "assessment_period",
      "facility_name",
      "year",
      "program_type",
      "program_scope",
      "employees_participation_percentage",
      "status",
    ],
  },
  training_hours_per_employee: {
    title: "Training Hours Per Employee",
    headers: [
      "Period",
      "Facility",
      "Year",
      "Training Method",
      "Training Details",
      "Average Training Hours per Employee",
      "Average Training Hours per Female Employee",
      "Average Training Hours per Male Employee",
      "Total Training Hours",
      "Status",
    ],
    keys: [
      "assessment_period",
      "facility_name",
      "year",
      "training_methods",
      "training_details",
      "total_employees_training_percentage",
      "female_employees_training_percentage",
      "male_employees_training_percentage",
      "total_training_hours",
      "status",
    ],
  },
  supplier_screening_using_enviormental_social_criteria: {
    title: "Supplier Screening",
    headers: [
      "Period",
      "Facility",
      "Year",
      "Suppliers screened using Environmental Criteria",
      "Suppliers screened using Social Criteria",
      "Added by",
      "Approved by",
      "Audited by",
      "Status",
    ],
    keys: [
      "assessment_period",
      "facility_name",
      "year",
      "suppliers_screened_using_env_criteria",
      "suppliers_screened_using_social_criteria",
      "added_by_name",
      "approved_by_name",
      "audited_by_name",
      "status",
    ],
  },
  suppliers_human_rights: {
    title: "Supplier Human Rights",
    headers: [
      "Period",
      "Facility",
      "Year",
      "Supplier Name",
      "Added by",
      "Approved by",
      "Audited by",
      "Status",
    ],
    keys: [
      "assessment_period",
      "facility_name",
      "year",
      "supplier_name",
      "added_by_name",
      "approved_by_name",
      "audited_by_name",
      "status",
    ],
  },
  tax: {
    title: "Tax",
    headers: ["Content", "Date", "Year", "Month", "Status"],
    keys: ["content", "date", "year", "month", "status"],
  },
};

export const StationaryColumnsStakeholderReport = [
  {
    columnKey: "statusReport",
    columnId: "statusReport",
    columnHeader: "",
  },
  // {
  //     columnKey: 'status',
  //     columnId: 'status',
  //     columnHeader: 'Status',
  // },
  {
    columnKey: "fuel",
    columnId: "fuel",
    columnHeader: "Fuel",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "facility",
    columnId: "facility",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "CO2\n(Ton)",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "CH4\n(Ton)",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "N2O\n(Ton)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e\n(Ton)",
  },
  {
    columnKey: "biofuel_co2",
    columnId: "biofuel_co2",
    columnHeader: "BioFuel CO2\n(Ton)",
  },
  {
    columnKey: "ef",
    columnId: "ef",
    columnHeader: "EF \n(kgCO2e/unit)",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const TransportationColumnsStakeholderReport = [
  {
    columnKey: "statusReport",
    columnId: "statusReport",
    columnHeader: "",
  },
  // {
  //     columnKey: 'status',
  //     columnId: 'status',
  //     columnHeader: 'Status',
  // },
  {
    columnKey: "category_name",
    columnId: "category_name",
    columnHeader: "Category",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "facility",
    columnId: "facility",
    columnHeader: "Facility",
  },
  {
    columnKey: "vehicle_type_name",
    columnId: "vehicle_type_name",
    columnHeader: "Vehicle type",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "CO2\n(Ton)",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "CH4\n(Ton)",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "N2O\n(Ton)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e\n(Ton)",
  },
  {
    columnKey: "biofuel_co2",
    columnId: "biofuel_co2",
    columnHeader: "BioFuel CO2\n(Ton)",
  },
  {
    columnKey: "ef",
    columnId: "ef",
    columnHeader: "EF \n(kgCO2e/unit)",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const MobileColumnsStakeholderReport = [
  {
    columnKey: "statusReport",
    columnId: "statusReport",
    columnHeader: "",
  },
  // {
  //     columnKey: 'status',
  //     columnId: 'status',
  //     columnHeader: 'Status',
  // },
  {
    columnKey: "fuel_source",
    columnId: "fuel_source",
    columnHeader: "Fuel Source",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "facility",
    columnId: "facility",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "CO2\n(Ton)",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "CH4\n(Ton)",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "N2O\n(Ton)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e\n(Ton)",
  },
  {
    columnKey: "biofuel_co2",
    columnId: "biofuel_co2",
    columnHeader: "BioFuel CO2\n(Ton)",
  },
  {
    columnKey: "ef",
    columnId: "ef",
    columnHeader: "EF \n(kgCO2e/unit)",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const PurchasedElectricityColumnsStakeholderReport = [
  {
    columnKey: "statusReport",
    columnId: "statusReport",
    columnHeader: "",
  },
  // {
  //     columnKey: 'status',
  //     columnId: 'status',
  //     columnHeader: 'Status',
  // },
  {
    columnKey: "facility",
    columnId: "facility",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "calculation_approach",
    columnId: "calculation_approach",
    columnHeader: "Calculation Approach",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "CO2\n(Ton)",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "CH4\n(Ton)",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "N2O\n(Ton)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e\n(Ton)",
  },
  {
    columnKey: "biofuel_co2",
    columnId: "biofuel_co2",
    columnHeader: "BioFuel CO2\n(Ton)",
  },
  {
    columnKey: "ef",
    columnId: "ef",
    columnHeader: "EF \n(kgCO2e/unit)",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const WaterDischargeColumnsStakeholderReport = [
  {
    columnKey: "statusReport",
    columnId: "statusReport",
    columnHeader: "",
  },
  // {
  //     columnKey: 'status',
  //     columnId: 'status',
  //     columnHeader: 'Status',
  // },
  {
    columnKey: "water_destination_name",
    columnId: "water_destination_name",
    columnHeader: "Destination Name",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "facility",
    columnId: "facility",
    columnHeader: "Facility",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e\n(Ton)",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const WaterConsumptionColumnsStakeholderReport = [
  {
    columnKey: "statusReport",
    columnId: "statusReport",
    columnHeader: "",
  },
  // {
  //     columnKey: 'status',
  //     columnId: 'status',
  //     columnHeader: 'Status',
  // },
  {
    columnKey: "water_source_name",
    columnId: "water_source_name",
    columnHeader: "Source Name",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "facility",
    columnId: "facility",
    columnHeader: "Facility",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "CO2e (Ton)",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const RefrigerantsColumnsStakeholderReport = [
  {
    columnKey: "statusReport",
    columnId: "statusReport",
    columnHeader: "",
  },
  // {
  //     columnKey: 'status',
  //     columnId: 'status',
  //     columnHeader: 'Status',
  // },
  {
    columnKey: "facility",
    columnId: "facility",
    columnHeader: "Facility",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "refrigerant_name",
    columnId: "refrigerant_name",
    columnHeader: "Refrigerant",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "Co2E",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const WasteCombustionColumnsStakeholderReport = [
  {
    columnKey: "statusReport",
    columnId: "statusReport",
    columnHeader: "",
  },
  // {
  //     columnKey: 'status',
  //     columnId: 'status',
  //     columnHeader: 'Status',
  // },
  {
    columnKey: "facility",
    columnId: "facility",
    columnHeader: "Facility",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "waste_type_name",
    columnId: "waste_type_name",
    columnHeader: "Waste Type",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "Co2E",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const DevelopmentTrainingColumnsStakeholderReport = [
  {
    columnKey: "statusReport",
    columnId: "statusReport",
    columnHeader: "",
  },
  // {
  //     columnKey: 'status',
  //     columnId: 'status',
  //     columnHeader: 'Status',
  // },
  {
    columnKey: "objective",
    columnId: "objective",
    columnHeader: "Objective",
  },
  {
    columnKey: "attended",
    columnId: "attended",
    columnHeader: "No of Attendee",
  },
  {
    columnKey: "hours",
    columnId: "hours",
    columnHeader: "No of Hours",
  },
  {
    columnKey: "content",
    columnId: "content",
    columnHeader: "Content",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const EmployeeHealthDetailsColumnsStakeholderReport = [
  {
    columnKey: "statusReport",
    columnId: "statusReport",
    columnHeader: "",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "affected",
    columnId: "affected",
    columnHeader: "No of Affected",
  },
  {
    columnKey: "department",
    columnId: "department",
    columnHeader: "Department",
  },
  {
    columnKey: "corrective_action",
    columnId: "corrective_action",
    columnHeader: "Corrective Action",
  },
  {
    columnKey: "details",
    columnId: "details",
    columnHeader: "Details",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const WorkerSafetyTrainingColumnsStakeholderReport = [
  {
    columnKey: "statusReport",
    columnId: "statusReport",
    columnHeader: "",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "objective",
    columnId: "objective",
    columnHeader: "Objective",
  },
  {
    columnKey: "attended",
    columnId: "attended",
    columnHeader: "No of Attendee",
  },
  {
    columnKey: "content",
    columnId: "content",
    columnHeader: "Content",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const EmployeeDescriminationIncidentColumnsStakeholderReport = [
  {
    columnKey: "statusReport",
    columnId: "statusReport",
    columnHeader: "",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "affected",
    columnId: "affected",
    columnHeader: "No of Affected",
  },
  {
    columnKey: "department",
    columnId: "department",
    columnHeader: "Department",
  },
  {
    columnKey: "corrective_action",
    columnId: "corrective_action",
    columnHeader: "Corrective Action",
  },
  {
    columnKey: "details",
    columnId: "details",
    columnHeader: "Details",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const SupplierHumanRightsTrainigColumnsStakeholderReport = [
  {
    columnKey: "statusReport",
    columnId: "statusReport",
    columnHeader: "",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "objective",
    columnId: "objective",
    columnHeader: "Objective",
  },
  {
    columnKey: "attended",
    columnId: "attended",
    columnHeader: "No of Attendee",
  },
  {
    columnKey: "content",
    columnId: "content",
    columnHeader: "Content",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const SocialHumanRightsTrainigColumnsStakeholderReport = [
  {
    columnKey: "statusReport",
    columnId: "statusReport",
    columnHeader: "",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "objective",
    columnId: "objective",
    columnHeader: "Objective",
  },
  {
    columnKey: "attended",
    columnId: "attended",
    columnHeader: "No of Attendee",
  },
  {
    columnKey: "content",
    columnId: "content",
    columnHeader: "Content",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const SupplierScreeningColumnsStakeholderReport = [
  {
    columnKey: "statusReport",
    columnId: "statusReport",
    columnHeader: "",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "supplier",
    columnId: "supplier",
    columnHeader: "No of Suppliers",
  },
  {
    columnKey: "details",
    columnId: "details",
    columnHeader: "Details",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const LocalCommunitiesColumnsStakeholderReport = [
  {
    columnKey: "statusReport",
    columnId: "statusReport",
    columnHeader: "",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "department",
    columnId: "department",
    columnHeader: "Department",
  },
  {
    columnKey: "corrective_action",
    columnId: "corrective_action",
    columnHeader: "Corrective Action",
  },
  {
    columnKey: "details",
    columnId: "details",
    columnHeader: "Details",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const PoliticalContributionColumnsStakeholderReport = [
  {
    columnKey: "statusReport",
    columnId: "statusReport",
    columnHeader: "",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "recipient",
    columnId: "recipient",
    columnHeader: "Recipient",
  },
  {
    columnKey: "details",
    columnId: "details",
    columnHeader: "Details",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const AntiCorruptionDisclosureColumnsStakeholderReport = [
  {
    columnKey: "statusReport",
    columnId: "statusReport",
    columnHeader: "",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "recipient",
    columnId: "recipient",
    columnHeader: "Recipient",
  },
  {
    columnKey: "corrective_action",
    columnId: "corrective_action",
    columnHeader: "Corrective Action",
  },
  {
    columnKey: "details",
    columnId: "details",
    columnHeader: "Details",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const AntiCorruptionTrainigColumnsStakeholderReport = [
  {
    columnKey: "statusReport",
    columnId: "statusReport",
    columnHeader: "",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "objective",
    columnId: "objective",
    columnHeader: "Objective",
  },
  {
    columnKey: "attended",
    columnId: "attended",
    columnHeader: "No of Attendee",
  },
  {
    columnKey: "content",
    columnId: "content",
    columnHeader: "Content",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const AntiCompetitiveDisclosureColumnsStakeholderReport = [
  {
    columnKey: "statusReport",
    columnId: "statusReport",
    columnHeader: "",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "affected_party",
    columnId: "affected_party",
    columnHeader: "Affected Party",
  },
  {
    columnKey: "corrective_action",
    columnId: "corrective_action",
    columnHeader: "Corrective Action",
  },
  {
    columnKey: "details",
    columnId: "details",
    columnHeader: "Details",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const FinancialAssistanceColumnsStakeholderReport = [
  {
    columnKey: "statusReport",
    columnId: "statusReport",
    columnHeader: "",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "amount",
    columnId: "amount",
    columnHeader: "Amount",
  },
  {
    columnKey: "donor",
    columnId: "donor",
    columnHeader: "Donor",
  },
  {
    columnKey: "details",
    columnId: "details",
    columnHeader: "Details",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const UploadFileEmissionColumnsStakeholderReport = [
  {
    columnKey: "statusReport",
    columnId: "statusReport",
    columnHeader: "",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "content",
    columnId: "content",
    columnHeader: "Content",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const UpstreamTransportationFuel = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "fuel_source_name",
    columnId: "fuel_source_name",
    columnHeader: "Fuel Source",
  },
  {
    columnKey: "amount_fuel",
    columnId: "amount_fuel",
    columnHeader: "Amount of Fuel",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "co2",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "ch4",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "n2o",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const UpstreamTransportationDistance = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "mode_of_transport_name",
    columnId: "mode_of_transport_name",
    columnHeader: "Mode of Transport",
  },
  {
    columnKey: "distance_travelled",
    columnId: "distance_travelled",
    columnHeader: "Distance Travelled",
  },
  {
    columnKey: "mass_purchased_goods",
    columnId: "mass_purchased_goods",
    columnHeader: "Mass of Purchased Goods",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "co2",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "ch4",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "n2o",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const UpstreamTransportationSpend = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "mode_of_transport_name",
    columnId: "mode_of_transport_name",
    columnHeader: "Mode of Transport",
  },

  {
    columnKey: "total_spent",
    columnId: "total_spent",
    columnHeader: "Total spend",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "co2",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "ch4",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "n2o",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];
export const UpstreamTransportationRefrigerant = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "gas_type_name",
    columnId: "gas_type_name",
    columnHeader: "Gas type",
  },
  {
    columnKey: "refrigerant_name",
    columnId: "refrigerant_name",
    columnHeader: "refrigerant",
  },
  {
    columnKey: "amount_refrigerants",
    columnId: "amount_refrigerants",
    columnHeader: "Quantity of refrigerant leakage(unit)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const UpstreamDistributionSite = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "fuel_source_name",
    columnId: "fuel_source_name",
    columnHeader: "Fuel Source",
  },
  {
    columnKey: "amount_fuel",
    columnId: "amount_fuel",
    columnHeader: "Amount of Fuel",
  },
  {
    columnKey: "amount_electricity",
    columnId: "amount_electricity",
    columnHeader: "Amount of electiricity consumed(unit)",
  },
  {
    columnKey: "gas_type_name",
    columnId: "gas_type_name",
    columnHeader: "Gas type",
  },
  {
    columnKey: "refrigerant_name",
    columnId: "refrigerant_name",
    columnHeader: "refrigerant",
  },
  {
    columnKey: "amount_refrigerants",
    columnId: "amount_refrigerants",
    columnHeader: "Quantity of refrigerant leakage(unit)",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "co2",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "ch4",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "n2o",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const UpstreamDistributionAverage = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "volume_stored",
    columnId: "volume_stored",
    columnHeader: "Volume of stored goods",
  },
  {
    columnKey: "emission_factor",
    columnId: "emission_factor",
    columnHeader: "Emission Factor",
  },
  {
    columnKey: "average_number_of_days",
    columnId: "average_number_of_days",
    columnHeader: "Average number of goods stored",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "co2",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "ch4",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "n2o",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];
export const BusinessTravelSpend = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "mode_of_transport_name",
    columnId: "mode_of_transport_name",
    columnHeader: "Mode of Transport",
  },
  {
    columnKey: "total_spent",
    columnId: "total_spent",
    columnHeader: "Total spend",
  },
  // {
  //   columnKey: "co2",
  //   columnId: "co2",
  //   columnHeader: "co2",
  // },
  // {
  //   columnKey: "ch4",
  //   columnId: "ch4",
  //   columnHeader: "ch4",
  // },
  // {
  //   columnKey: "n2o",
  //   columnId: "n2o",
  //   columnHeader: "n2o",
  // },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];
export const BusinessTravelFuel = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "fuel_source_name",
    columnId: "fuel_source_name",
    columnHeader: "Fuel Source",
  },
  {
    columnKey: "amount_fuel",
    columnId: "amount_fuel",
    columnHeader: "Amount of Fuel",
  },
  // {
  //   columnKey: "co2",
  //   columnId: "co2",
  //   columnHeader: "co2",
  // },
  // {
  //   columnKey: "ch4",
  //   columnId: "ch4",
  //   columnHeader: "ch4",
  // },
  // {
  //   columnKey: "n2o",
  //   columnId: "n2o",
  //   columnHeader: "n2o",
  // },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const BusinessTravelDistance = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "mode_of_transport_name",
    columnId: "mode_of_transport_name",
    columnHeader: "Mode of Transport",
  },
  {
    columnKey: "distance_travelled",
    columnId: "distance_travelled",
    columnHeader: "Distance Travelled",
  },
  {
    columnKey: "number_of_employees",
    columnId: "number_of_employees",
    columnHeader: "Number of employees",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "co2",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "ch4",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "n2o",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const DirectUsephaseFuels = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "fuel_source",
    columnId: "fuel_source",
    columnHeader: "Fuel / Feedstock",
  },
  {
    columnKey: "amount_fuel",
    columnId: "amount_fuel",
    columnHeader: "Quantity of fuel/feedstock",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "Action",
  },
];

export const DirectUsephaseGreenhouse = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "product_name",
    columnId: "product_name",
    columnHeader: "Product Name",
  },
  {
    columnKey: "total_number_of_product_sold",
    columnId: "total_number_of_product_sold",
    columnHeader: "Total number of solid products",
  },
  {
    columnKey: "ghg_contained_name",
    columnId: "ghg_contained_name",
    columnHeader: "GHG Contained",
  },
  {
    columnKey: "amount_of_ghg",
    columnId: "amount_of_ghg",
    columnHeader: "Amount of GHG per product",
  },
  {
    columnKey: "percentage_of_ghg",
    columnId: "percentage_of_ghg",
    columnHeader: "% GHG released",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "action",
  },
];

export const DirectUsephaseProducts = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "product_name",
    columnId: "product_name",
    columnHeader: "Product Name",
  },
  {
    columnKey: "total_lifetime_expected_use",
    columnId: "total_lifetime_expected_use",
    columnHeader: "Product lifetime usage",
  },
  {
    columnKey: "number_sold",
    columnId: "number_sold",
    columnHeader: "Number of sold",
  },
  {
    columnKey: "fuel_source",
    columnId: "fuel_source",
    columnHeader: "Fuel source",
  },
  {
    columnKey: "amount_fuel",
    columnId: "amount_fuel",
    columnHeader: "Fuel Consumed",
  },
  {
    columnKey: "amount_electricity",
    columnId: "amount_electricity",
    columnHeader: "Electricity Consumed",
  },
  {
    columnKey: "refrigerant_unit",
    columnId: "refrigerant_unit",
    columnHeader: "Refrigerant Leakage ",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "action",
  },
];

export const IndirectUsephaseCalculation = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "total_intermediate_products",
    columnId: "total_intermediate_products",
    columnHeader: "Total Intermediate sold products",
  },
  {
    columnKey: "total_lifetime_use_of_sold_products",
    columnId: "total_lifetime_use_of_sold_products",
    columnHeader: "Product lifetime usage",
  },
  {
    columnKey: "emission_per_use_sold_intermediate_products",
    columnId: "emission_per_use_sold_intermediate_products",
    columnHeader: "Emission per use of sold intermediate product kg CO2 e/use",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "action",
  },
];

export const IndirectUseOfEmission = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "product_name",
    columnId: "product_name",
    columnHeader: "Product Name",
  },
  {
    columnKey: "total_lifetime_use_of_sold_products",
    columnId: "total_lifetime_use_of_sold_products",
    columnHeader: "Product lifetime usage",
  },
  {
    columnKey: "number_sold",
    columnId: "number_sold",
    columnHeader: "Number of sold",
  },
  {
    columnKey: "fuel_source",
    columnId: "fuel_source",
    columnHeader: "Fuel source",
  },
  {
    columnKey: "amount_fuel",
    columnId: "amount_fuel",
    columnHeader: "Fuel Consumed",
  },
  {
    columnKey: "amount_electricity",
    columnId: "amount_electricity",
    columnHeader: "Electricity Consumed",
  },
  {
    columnKey: "refrigerant_unit",
    columnId: "refrigerant_unit",
    columnHeader: "Refrigerant Leakage ",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "action",
  },
];

export const PurchasedGoodsSpend = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "value_of_purchased_goods",
    columnId: "value_of_purchased_goods",
    columnHeader: "Value of purchased goods or services",
  },
  {
    columnKey: "currency",
    columnId: "currency",
    columnHeader: "Currency",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];
export const PurchasedGoodsAverageData = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "mass_of_purchased_goods",
    columnId: "mass_of_purchased_goods",
    columnHeader: "Mass of purchased goods or services(unit)",
  },
  {
    columnKey: "ef_of_purchased_goods_per_mass",
    columnId: "ef_of_purchased_goods_per_mass",
    columnHeader: "Emission factor",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const PurchasedGoodsSupplierSpecific = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "quantity_of_purchased_goods",
    columnId: "quantity_of_purchased_goods",
    columnHeader: "Quantity of goods(kg)",
  },
  {
    columnKey: "supplier_specific_product_ef",
    columnId: "supplier_specific_product_ef",
    columnHeader: "Supplier-specific product Emission factor",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const CapitalGoodsSupplier = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "quantity_of_purchased_goods",
    columnId: "quantity_of_purchased_goods",
    columnHeader: "Quantity of goods(unit)",
  },
  {
    columnKey: "supplier_specific_product_ef",
    columnId: "supplier_specific_product_ef",
    columnHeader: "Supplier specific emission factor",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "co2",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "ch4",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "n2o",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const CapitalGoodsSpend = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "vendor_company_name",
    columnId: "vendor_company_name",
    columnHeader: "Company",
  },
  {
    columnKey: "sector_name",
    columnId: "sector_name",
    columnHeader: "Sector",
  },
  {
    columnKey: "industry_name",
    columnId: "industry_name",
    columnHeader: "Industry",
  },
  {
    columnKey: "value_of_purchased_goods",
    columnId: "value_of_purchased_goods",
    columnHeader: "Value of capital goods",
  },
  {
    columnKey: "currency",
    columnId: "currency",
    columnHeader: "Currency",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "co2",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "ch4",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "n2o",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const CapitalGoodsAverage = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "mass_of_purchased_goods",
    columnId: "mass_of_purchased_goods",
    columnHeader: "Mass of capital goods(unit)",
  },
  {
    columnKey: "ef_of_purchased_goods_per_mass",
    columnId: "ef_of_purchased_goods_per_mass",
    columnHeader: "Emission factor",
  },
  {
    columnKey: "co2",
    columnId: "co2",
    columnHeader: "co2",
  },
  {
    columnKey: "ch4",
    columnId: "ch4",
    columnHeader: "ch4",
  },
  {
    columnKey: "n2o",
    columnId: "n2o",
    columnHeader: "n2o",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];
export const FuelEnergyPurchasedFules = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "fuel_type_name",
    columnId: "fuel_type_name",
    columnHeader: "Fuel Source",
  },
  {
    columnKey: "amount_fuel",
    columnId: "amount_fuel",
    columnHeader: "Amount of fuel consumed(Unit)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const UpstreamLeasedAsset = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "fuel_source",
    columnId: "fuel_source",
    columnHeader: "Fuel Source",
  },
  {
    columnKey: "fuel_amount",
    columnId: "fuel_amount",
    columnHeader: "Amount of Fuel",
  },
  {
    columnKey: "amount_refrigerants_leakage",
    columnId: "amount_refrigerants_leakage",
    columnHeader: "Quantity of refrigerant leakage(unit)",
  },
  {
    columnKey: "amount_electricity",
    columnId: "amount_electricity",
    columnHeader:
      "Quantity of electricity,steam,heating,cooling consumed(unit)",
  },
  {
    columnKey: "process_emission",
    columnId: "process_emission",
    columnHeader: "Process Emissions",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const UpstreamLeasedAverage1 = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },

  {
    columnKey: "total_floor_space",
    columnId: "total_floor_space",
    columnHeader: "Total floor space of building type(unit)",
  },
  {
    columnKey: "building_type_name",
    columnId: "building_type_name",
    columnHeader: "Building type",
  },

  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];
export const UpstreamLeasedAverage2 = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },

  {
    columnKey: "number_of_assets",
    columnId: "number_of_assets",
    columnHeader: "Number of assets",
  },
  {
    columnKey: "average_emission_factor_of_building_type",
    columnId: "average_emission_factor_of_building_type",
    columnHeader: "Average emission factor for asset type(kg co2e/m2/year)",
  },

  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const Franchisespecific = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "franchise_name",
    columnId: "franchise_name",
    columnHeader: "Franchise Name",
  },
  {
    columnKey: "country_name",
    columnId: "country_name",
    columnHeader: "Country",
  },
  {
    columnKey: "scope_1_emissions",
    columnId: "scope_1_emissions",
    columnHeader: "Scope 1 emissions",
  },
  {
    columnKey: "scope_2_emissions",
    columnId: "scope_2_emissions",
    columnHeader: "Scope 2 emissions",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const FranchiseAverage1 = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },

  {
    columnKey: "total_floor_space",
    columnId: "total_floor_space",
    columnHeader: "Total floor space of building type(unit)",
  },
  {
    columnKey: "building_type_name",
    columnId: "building_type_name",
    columnHeader: "Building type",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];
export const FranchiseAverage2 = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },

  {
    columnKey: "number_of_assets",
    columnId: "number_of_assets",
    columnHeader: "Number of Buildings",
  },
  {
    columnKey: "average_emission_factor_of_building_type",
    columnId: "average_emission_factor_of_building_type",
    columnHeader: "Average emission factor for asset type(kg co2e/m2/year)",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const FuelEnergyPurchasedElectricity = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "calculation_approach_name",
    columnId: "calculation_approach_name",
    columnHeader: "Calculation Approach",
  },
  {
    columnKey: "amount_electricity",
    columnId: "amount_electricity",
    columnHeader:
      "Quantity of electricity,steam,heating,cooling consumed(unit)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const FuelEnergyTandDLosses = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "calculation_approach_name",
    columnId: "calculation_approach_name",
    columnHeader: "calculation_approach_name",
  },
  {
    columnKey: "amount_electricity",
    columnId: "amount_electricity",
    columnHeader:
      "Quantity of electricity,steam,heating,cooling consumed(unit)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];
export const FuelEnergyPurchasedAndSold = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "calculation_approach_name",
    columnId: "calculation_approach_name",
    columnHeader: "Calculation Approach",
  },
  {
    columnKey: "amount_electricity",
    columnId: "amount_electricity",
    columnHeader:
      "Amount of electricity,steam,heating,cooling purchased for resale(unit)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const EmployeeCommuteDistance = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "mode_of_transport_name",
    columnId: "mode_of_transport_name",
    columnHeader: "Mode of Transport",
  },

  {
    columnKey: "activity_type_name",
    columnId: "activity_type_name",
    columnHeader: "Activity Type",
  },
  {
    columnKey: "daily_one_way_distance",
    columnId: "daily_one-way_distance",
    columnHeader: "Distance Travelled",
  },
  {
    columnKey: "commuting_days_per_month",
    columnId: "commuting_days_per_month",
    columnHeader: "Number of commuting days per month",
  },

  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const EmployeeCommuteAverage = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "total_employees",
    columnId: "total_employees",
    columnHeader: "Total employees",
  },
  {
    columnKey: "mode_of_transport_name",
    columnId: "mode_of_transport_name",
    columnHeader: "Mode of Transport",
  },

  {
    columnKey: "number_of_employees_using_mode",
    columnId: "number_of_employees_using_mode",
    columnHeader: "Number of employees using this transport",
  },

  {
    columnKey: "one_way_distance",
    columnId: "one_way_distance",
    columnHeader: "One way distance Travelled",
  },

  {
    columnKey: "working_days_per_month",
    columnId: "working_days_per_month",
    columnHeader: "Working days per month",
  },

  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const EmployeeCommuteFuel = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "fuel_source",
    columnId: "fuel_source",
    columnHeader: "Fuel Source",
  },
  {
    columnKey: "fuel_amount",
    columnId: "fuel_amount",
    columnHeader: "Amount of Fuel",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];
export const ProcessingOfSoldProductsSite = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "fuel_source_name",
    columnId: "fuel_source_name",
    columnHeader: "Fuel Source",
  },
  {
    columnKey: "amount_fuel",
    columnId: "amount_fuel",
    columnHeader: "Amount of Fuel",
  },
  {
    columnKey: "amount_electricity",
    columnId: "amount_electricity",
    columnHeader: "Amount of electiricity consumed(unit)",
  },
  {
    columnKey: "amount_refrigerants",
    columnId: "amount_refrigerants",
    columnHeader: "Quantity of refrigerant leakage(unit)",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const ProcessingOfSoldProductsAverage = [
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
  },
  {
    columnKey: "mass_of_sold_product",
    columnId: "mass_of_sold_product",
    columnHeader: "Mass of solid intermediate product(unit)",
  },
  {
    columnKey: "emission_factor_of_sold_products",
    columnId: "emission_factor_of_sold_products",
    columnHeader: "Emission factor for processing of solid products",
  },
  {
    columnKey: "co2e",
    columnId: "co2e",
    columnHeader: "co2e",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
];

export const topicNames = [
  "stationary_combustion",
  "mobile_combustion",
  "refrigerants",
  "purchased_electricity",
  "transportation",
  "water_consumption",
  "water_discharge",
  "waste",
  "employees_turnover",
  "employees_hire_and_turnover",
  "employees_benefit",
  "development_training",
  "employee_health_safety_incident_record",
  "worker_safety_training_procedures",
  "age_based_statistics",
  "gender_based_statistics",
  "employees_diversity_and_ratio_of_basic_salary",
  "discrimination_incident_record",
  "supplier_screening_using_enviormental_social_criteria",
  "supplier_screening",
  "operational_human_rights_training",
  "suppliers_human_rights",
  "social_engagement_human_rights_training",
  "local_communities",
  "political_contributions",
  "board_diversity",
  "management_diversity",
  "anti_corruption_disclosure",
  "anti_corruption_training",
  "anti_competitive_disclosure",
  "subsidies_financial_assistance",
  "direct_economic_impact",
  "indirect_economic_impact",
  "tax",
  "end_of_life_treatment_of_sold_products",
];
