import * as yup from 'yup';

export const addMobileCombustionValidation = yup.object({
    facility: yup
        .string('Facility is required')
        .required('Facility is required'),
    month: yup
        .string('Month is required')
        .required('Month is required'),
    year: yup
        .string('Year is required')
        .required('Year is required'),
    
});
