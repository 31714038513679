import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Header from "../../components/Header";
import SideMenu from "../../components/SideMenu";
import { getOrganizationCountry, listFacilities } from "../../redux/actions";
import { rolesEnum, sideMenuItems } from "./pages";
import useStyles from "./styles";
import clsx from "clsx";
import { getCookie } from "../../services/cookie";

const DashboardLayout = ({ children, classes: propClasses }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const role = useSelector((state) => state.auth.role);
  const modules = getCookie("availableModules");
  const modulesTrade = getCookie("availableModules_trade");
  const role_trade = getCookie("role_trade");
  const isTradePlatform = window.location.pathname.includes("trade");
  const [menuOptions, setMenuOptions] = useState([]);
  useEffect(() => {
    if (
      !role.includes(rolesEnum.AUDITOR) &&
      !role.includes(rolesEnum.STAKEHOLDER) &&
      !role.includes(rolesEnum.SUPER_ADMIN) &&
      !role.includes(rolesEnum.TRADE_ADMIN) &&
      !window.location.pathname.includes("trade")
    ) {
      dispatch(listFacilities());
      dispatch(getOrganizationCountry());
    }
  }, [dispatch, role]);

  useEffect(() => {
    const menu = sideMenuItems?.filter(
      (item) =>
        item.roles.includes(role[0]||role_trade) &&
        (isTradePlatform
          ? modulesTrade?.includes(item.text)
          : modules?.includes(item.text))
    );
    setMenuOptions(menu);
  }, [role, modules,modulesTrade,isTradePlatform,role_trade]);

  return (
    <Box className={classes.dashboardContainer}>
      <SideMenu options={menuOptions} />
      <Box className={classes.rightContainer}>
        <Header />
        <Box
          className={clsx(classes.childContainer, propClasses?.childContainer)}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardLayout;

DashboardLayout.propTypes = {
  children: PropTypes.any,
};
