import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { months, sampleYear } from "../../../constants";
import { STATUS } from "../../../redux/constants";
import { addMobileCombustionValidation } from "./schema";
import {
  addDownstreamTAndD,
  addUpstreamTAndD,
  getEmissionFuelList,
  resetAddCombustionStatus,
} from "../../../redux/actions";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroButton from "../../../components/CeroButton";
import CeroSelect from "../../../components/CeroSelect";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import EmissionUploadDrawer from "../../EmissionList/EmissionUploadDrawer";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroItemPair from "../../../components/CeroItemPair";
import FileUpload from "../FileUpload";

const AddDownstreamTD = (props) => {
  const { onCancel, onDownload, onUpload, onValidate, uploadDisabled } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const isCalculateDone = useSelector(
    (state) => state.emission.addDownstreamTAndD.isCalculateDone
  );
  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const activityTypesData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.activity_types
  );
  const vehicleTypeData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.vehicle_types
  );
  const fuelUnitData = useSelector(
    (state) => state.emission.mobileCombustionInputs.data.units
  );
  const addEmissionData = useSelector(
    (state) => state.emission.addDownstreamTAndD
  );

  const emissionPerformData = useSelector((state) => state.emission.fuelList);
  const currentYear = new Date().getFullYear().toString();
  const [selectedFile, setSelectedFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      facility: "",
      year: currentYear,
      month: "",
      downstreamType: "",
      calculationMethod: "",
      fuelSource: "",
      fuelUnit: "",
      amountOfFuel: "",
      modeOfTransport: "",
      vehicleType: "",
      emissionFactorDataset: "",
      distanceTravelled: "",
      distanceUnit: "",
      massofPurchansedGoods: "",
      massUnit: "",
      totalSpent: "",
      currency: "",
      gasType: "",
      refrigerant: "",
      quantity: "",
      refrigerantUnit: "",
      electricityUnit: "",
      amountOfElectricity: "",
      volumeOfStoredGoods: "",
      averageNumberOfDaysStored: "",
      emissionFactorValue: 0,
    },
    validationSchema: addMobileCombustionValidation,
    onSubmit: () => {},
  });

  const selectedUnitType = activityTypesData.find(
    (item) => item.id === formik.values.activityType
  );

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  const downstreamTypeList = ["Transportation", "Distribution"].map((item) => ({
    key: item,
    value: item,
  }));

  const calculationNamesMap = {};
  var electricityId = "";
  const calculationInputList = () =>
    emissionPerformData?.data?.calculation_method?.[
      formik.values.downstreamType.toLocaleLowerCase()
    ]?.map((item) => {
      calculationNamesMap[item.id] = item.name; // Populate namesMap
      return {
        key: item.id,
        value: item.name,
      };
    }) || [];
  const fuelSourceInputList = emissionPerformData?.data?.fuel_sources?.map(
    (item) => {
      if (item.name.toLowerCase() === "electricity") electricityId = item.id;
      return {
        key: item.id,
        value: item.name,
      };
    }
  );
  const fuelSourceSiteInputList =
    emissionPerformData?.data?.fuel_sources_site_specific_method?.map(
      (item) => {
        return {
          key: item.id,
          value: item.name,
        };
      }
    );
  const fuelUnitInputList = emissionPerformData?.data?.fuel_unit?.map(
    (item) => {
      return {
        key: item.name,
        value: item.name,
      };
    }
  );

  const fuelSiteUnitInputList =
    emissionPerformData?.data?.fuel_sources_site_specific_method
      ?.find((item) => item.id === formik.values.fuelSource)
      ?.units.map((item) => ({ key: item, value: item }));
  const transportModeList = emissionPerformData?.data?.transport_modes?.map(
    (item) => ({
      key: item.id,
      value: item.name,
    })
  );
  const transportModeSpendList =
    emissionPerformData?.data?.transport_modes_spend_based?.map((item) => ({
      key: item.id,
      value: item.name,
    }));

  const vehicleTypeList = (emissionPerformData?.data?.vehicle_types || [])
    .filter(
      (item) =>
        !formik.values.modeOfTransport ||
        item.transport_mode_id === formik.values.modeOfTransport
    )
    .map((item) => ({ key: item?.id, value: item?.name }));
  const emissionFactorDataset = (
    emissionPerformData?.data?.ef_dataset || []
  ).map((item) => ({ key: item?.id, value: item?.name }));
  const distanceUnitList = emissionPerformData?.data?.unit?.map((item) => {
    return {
      key: item.name,
      value: item.name,
    };
  });
  const currencyMap = {};
  const currencyList = emissionPerformData?.data?.currency?.map((item) => {
    currencyMap[item.id] = item.name;
    return {
      key: item.id,
      value: item.name,
    };
  });

  const gasTypeList = emissionPerformData?.data?.gas_types?.map((item) => ({
    key: item.id,
    value: item.name,
  }));
  const refrigerantList = () => {
    return formik.values.gasType
      ? emissionPerformData?.data?.refrigerants
          ?.filter((item) => item.gas_type_id === formik.values.gasType)
          ?.map((item) => ({
            key: item.id,
            value: item.name,
          })) || []
      : [];
  };
  const refrigerantsUnitList = emissionPerformData?.data?.refrigerant_unit?.map(
    (item) => {
      return {
        key: item.name,
        value: item.name,
      };
    }
  );
  const electricityUnitList = emissionPerformData?.data?.electricity_unit?.map(
    (item) => {
      return {
        key: item.name,
        value: item.name,
      };
    }
  );
  const averageUnitInputList =
    emissionPerformData?.data?.average_data_unit?.map((item) => {
      return {
        key: item.name,
        value: item.name,
      };
    });

  const massUnitList = emissionPerformData?.data?.mass_unit?.map((item) => {
    return {
      key: item.name,
      value: item.name,
    };
  });

  useEffect(() => {
    dispatch(getEmissionFuelList("downstream_t_and_d"));
    return () => {
      dispatch(resetAddCombustionStatus());
    };
  }, [dispatch]);

  useEffect(() => {
    if (addEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Downstream T and D added successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (addEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        addEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
    }
  }, [addEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onCalculate = () => {
    const requestData = {
      facility_id: formik.values.facility,
      year: formik.values.year,
      month: formik.values.month,
      fuel_source_id: formik.values.fuelSource,
      vehicle_type_id: formik.values.vehicleType,
      amount_fuel: parseFloat(formik.values.amountOfFuel),
      unit: formik.values.fuelUnit,
      calculation_method: formik.values.calculationMethod,
      upstream_type: formik.values.downstreamType.toLowerCase(),
      ef_dataset_id: formik.values.emissionFactorDataset,
      mode_of_transport_id: formik.values.modeOfTransport,
      gas_type_id: formik.values.gasType,
      refrigerant_id: formik.values.refrigerant,
      amount_electricity: formik.values.amountOfElectricity,
      distance_travelled: formik.values.distanceTravelled,
      mass_purchased_goods: formik.values.massofPurchansedGoods,
      total_spent: formik.values.totalSpent,
      average_number_of_days: formik.values.averageNumberOfDaysStored,
      volume_stored: formik.values.volumeOfStoredGoods,
      currency: currencyMap[formik.values.currency],
      amount_refrigerants: formik.values.quantity,
      emission_factor: formik.values.emissionFactorValue,
      fuel_unit: formik.values.fuelUnit,
      distance_unit: formik.values.distanceUnit,
      electricity_unit: formik.values.electricityUnit,
      refrigerant_unit: formik.values.refrigerantUnit,
      mass_unit: formik.values.massUnit,
      volume_unit: formik.values.fuelUnit,
      save: false,
    };
    dispatch(addDownstreamTAndD(requestData));
  };

  const onAddUpstreamData = () => {
    const requestData = {
      facility_id: formik.values.facility,
      year: formik.values.year,
      month: formik.values.month,
      fuel_source_id: formik.values.fuelSource,
      vehicle_type_id: formik.values.vehicleType,
      amount_fuel: parseFloat(formik.values.amountOfFuel),
      unit: formik.values.fuelUnit,
      calculation_method: formik.values.calculationMethod,
      upstream_type: formik.values.downstreamType.toLowerCase(),
      ef_dataset_id: formik.values.emissionFactorDataset,
      mode_of_transport_id: formik.values.modeOfTransport,
      gas_type_id: formik.values.gasType,
      refrigerant_id: formik.values.refrigerant,
      amount_electricity: formik.values.amountOfElectricity,
      distance_travelled: formik.values.distanceTravelled,
      mass_purchased_goods: formik.values.massofPurchansedGoods,
      total_spent: formik.values.totalSpent,
      emission_factor: formik.values.emissionFactorValue,
      average_number_of_days: formik.values.averageNumberOfDaysStored,
      volume_stored: formik.values.volumeOfStoredGoods,
      currency: currencyMap[formik.values.currency],
      amount_refrigerants: formik.values.quantity,
      fuel_unit: formik.values.fuelUnit,
      distance_unit: formik.values.distanceUnit,
      electricity_unit: formik.values.electricityUnit,
      refrigerant_unit: formik.values.refrigerantUnit,
      mass_unit: formik.values.massUnit,
      volume_unit: formik.values.fuelUnit,
      save: true,
      file: selectedFile,
    };
    sessionStorage.setItem("year", requestData.year);
    dispatch(addDownstreamTAndD(requestData));
  };

  const [isOpenEmissionTypeUpload, setIsOpenEmissionTypeUpload] =
    useState(false);
  const onCloseDrawerUpload = () => {
    setIsOpenEmissionTypeUpload(false);
  };
  const handleFileUpload = (file) => {
    setSelectedFile(file);
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
  };
  const info = () => {
    return (
      <div>
        <Typography variant="body2">
          Fuel use: Mobile combustion by the usage of fuel for mobile operations
          such as transport.
          <br />
          Distance activity: Distance travelled by the usage of fuel
        </Typography>
      </div>
    );
  };

  return (
    <>
      <div className={classes.backContainer}>
        <div
          className={classes.backButtonDiv}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon />
          Back
        </div>
        <div className={classes.templateButtonDiv}>
          {/* <CeroButton
            buttonText="Bulk Data Upload"
            className={classes.bulkButton}
            startIcon={<FileUploadIcon />}
            onClick={() => setIsOpenEmissionTypeUpload(true)}
          />
          <CeroButton
            buttonText="Download Template"
            className={classes.bulkButton}
            startIcon={<DownloadIcon />}
            onClick={onDownload}
          /> */}
        </div>
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Add Downstream T & D
          </Typography>
          <Box className={classes.topContainer}>
            <Grid className={classes.dynamicBoard}>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroAutoComplete
                  id="year"
                  name="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.touched.year && formik.errors.year}
                  options={yearList}
                  value={formik.values.year}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="month"
                  name="month"
                  label="Month"
                  fullWidth
                  options={months}
                  selectedValue={formik.values.month}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.month && formik.errors.month}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="downstreamType"
                  name="downstreamType"
                  label="Downstream type"
                  fullWidth
                  options={downstreamTypeList}
                  selectedValue={formik.values.downstreamType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.month && formik.errors.month}
                />
              </Grid>
              {formik.values.downstreamType !== "" && (
                <Grid item className={classes.individualInput}>
                  <CeroSelect
                    required
                    id="calculationMethod"
                    name="calculationMethod"
                    label="Calculation Method"
                    fullWidth
                    options={calculationInputList()}
                    selectedValue={formik.values.calculationMethod}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.month && formik.errors.month}
                  />
                </Grid>
              )}
              {formik.values.calculationMethod &&
                calculationNamesMap[
                  formik.values.calculationMethod
                ]?.toLowerCase() === "fuel-based" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="fuelSource"
                        name="fuelSource"
                        label="Fuel Source"
                        fullWidth
                        options={fuelSourceInputList}
                        selectedValue={formik.values.fuelSource}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.fuelSource && formik.errors.fuelSource
                        }
                        classes={{
                          input: classes.input,
                          container: classes.selectContainer,
                        }}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="amountOfFuel"
                        name="amountOfFuel"
                        label={
                          electricityId == formik.values.fuelSource
                            ? "Amount of electiricity used"
                            : "Amount of Fuel"
                        }
                        value={formik.values.amountOfFuel}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.amountOfFuel &&
                          formik.errors.amountOfFuel
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="fuelUnit"
                        name="fuelUnit"
                        label="Fuel Unit"
                        fullWidth
                        options={
                          electricityId == formik.values.fuelSource
                            ? electricityUnitList
                            : fuelUnitInputList || [{ name: "select" }]
                        }
                        selectedValue={formik.values.fuelUnit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.fuelUnit && formik.errors.fuelUnit
                        }
                        sortOption={true}
                      />
                    </Grid>
                  </>
                )}

              {formik.values.calculationMethod &&
                calculationNamesMap[
                  formik.values.calculationMethod
                ]?.toLowerCase() === "distance-based" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="modeOfTransport"
                        name="modeOfTransport"
                        label="Mode of Transport"
                        fullWidth
                        options={transportModeList}
                        selectedValue={formik.values.modeOfTransport || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.modeOfTransport &&
                          formik.errors.modeOfTransport
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="vehicleType"
                        name="vehicleType"
                        label="Vehicle Type"
                        fullWidth
                        options={vehicleTypeList}
                        selectedValue={formik.values.vehicleType || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.vehicleType &&
                          formik.errors.vehicleType
                        }
                        classes={{
                          input: classes.input,
                          container: classes.selectContainer,
                          paperRoot: classes.paperRoot,
                        }}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="emissionFactorDataset"
                        name="emissionFactorDataset"
                        label="Emission Factor Dataset"
                        fullWidth
                        options={emissionFactorDataset}
                        selectedValue={
                          formik.values.emissionFactorDataset || ""
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.emissionFactorDataset &&
                          formik.errors.emissionFactorDataset
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="distanceTravelled"
                        name="distanceTravelled"
                        label="Distance Travelled"
                        value={formik.values.distanceTravelled}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.distanceTravelled &&
                          formik.errors.distanceTravelled
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="distanceUnit"
                        name="distanceUnit"
                        label="Unit"
                        fullWidth
                        options={distanceUnitList}
                        selectedValue={formik.values.distanceUnit || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.distanceUnit &&
                          formik.errors.distanceUnit
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="massofPurchansedGoods"
                        name="massofPurchansedGoods"
                        label="Mass of purchansed goods"
                        value={formik.values.massofPurchansedGoods}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.massofPurchansedGoods &&
                          formik.errors.massofPurchansedGoods
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="massUnit"
                        name="massUnit"
                        label="Unit"
                        fullWidth
                        options={massUnitList}
                        selectedValue={formik.values.massUnit || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.massUnit && formik.errors.massUnit
                        }
                      />
                    </Grid>
                  </>
                )}
              {formik.values.calculationMethod &&
                calculationNamesMap[
                  formik.values.calculationMethod
                ]?.toLowerCase() === "spend-based" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="modeOfTransport"
                        name="modeOfTransport"
                        label="Mode of Transport"
                        fullWidth
                        options={transportModeSpendList}
                        selectedValue={formik.values.modeOfTransport || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.modeOfTransport &&
                          formik.errors.modeOfTransport
                        }
                      />
                    </Grid>
                    {/* <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="vehicleType"
                        name="vehicleType"
                        label="Vehicle Type"
                        fullWidth
                        options={vehicleTypeList}
                        selectedValue={formik.values.vehicleType || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.vehicleType &&
                          formik.errors.vehicleType
                        }
                        classes={{
                          input: classes.input,
                          container: classes.selectContainer,
                          paperRoot: classes.paperRoot,
                        }}
                      />
                    </Grid> */}

                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="totalSpent"
                        name="totalSpent"
                        label="Total Spent"
                        value={formik.values.totalSpent}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.totalSpent && formik.errors.totalSpent
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="currency"
                        name="currency"
                        label="Currency"
                        fullWidth
                        options={currencyList}
                        selectedValue={formik.values.currency || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.currency && formik.errors.currency
                        }
                      />
                    </Grid>
                  </>
                )}
              {formik.values.calculationMethod &&
                calculationNamesMap[
                  formik.values.calculationMethod
                ]?.toLowerCase() === "refrigerant leakage" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="gasType"
                        name="gasType"
                        label="Gas type"
                        fullWidth
                        options={gasTypeList}
                        selectedValue={formik.values.gasType || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.gasType && formik.errors.gasType}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="refrigerant"
                        name="refrigerant"
                        label="Refrigerant"
                        fullWidth
                        options={refrigerantList()}
                        selectedValue={formik.values.refrigerant || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.refrigerant &&
                          formik.errors.refrigerant
                        }
                        classes={{
                          input: classes.input,
                          container: classes.selectContainer,
                          paperRoot: classes.paperRoot,
                        }}
                      />
                    </Grid>

                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="quantity"
                        name="quantity"
                        label="Amount of refrigerant"
                        value={formik.values.quantity}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.quantity && formik.errors.quantity
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="refrigerantUnit"
                        name="refrigerantUnit"
                        label="Unit"
                        fullWidth
                        options={refrigerantsUnitList}
                        selectedValue={formik.values.refrigerantUnit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.refrigerantUnit &&
                          formik.errors.refrigerantUnit
                        }
                      />
                    </Grid>
                  </>
                )}
              {formik.values.calculationMethod &&
                calculationNamesMap[
                  formik.values.calculationMethod
                ]?.toLowerCase() === "site-specific method" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="fuelSource"
                        name="fuelSource"
                        label="Fuel Source"
                        fullWidth
                        options={fuelSourceSiteInputList}
                        selectedValue={formik.values.fuelSource}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.fuelSource && formik.errors.fuelSource
                        }
                        classes={{
                          input: classes.input,
                          container: classes.selectContainer,
                        }}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="amountOfFuel"
                        name="amountOfFuel"
                        label="Amount of Fuel"
                        value={formik.values.amountOfFuel}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.amountOfFuel &&
                          formik.errors.amountOfFuel
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="fuelUnit"
                        name="fuelUnit"
                        label="Fuel Unit"
                        fullWidth
                        options={fuelSiteUnitInputList || [{ name: "select" }]}
                        selectedValue={formik.values.fuelUnit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.fuelUnit && formik.errors.fuelUnit
                        }
                        sortOption={true}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="amountOfElectricity"
                        name="amountOfElectricity"
                        label="Amount of Elecricity Consumed"
                        value={formik.values.amountOfElectricity}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.amountOfElectricity &&
                          formik.errors.amountOfElectricity
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="electricityUnit"
                        name="electricityUnit"
                        label="Unit"
                        fullWidth
                        options={electricityUnitList || [{ name: "select" }]}
                        selectedValue={formik.values.electricityUnit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.electricityUnit &&
                          formik.errors.electricityUnit
                        }
                        sortOption={true}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="gasType"
                        name="gasType"
                        label="Gas type"
                        fullWidth
                        options={gasTypeList}
                        selectedValue={formik.values.gasType || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.gasType && formik.errors.gasType}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="refrigerant"
                        name="refrigerant"
                        label="Refrigerant"
                        fullWidth
                        options={refrigerantList()}
                        selectedValue={formik.values.refrigerant || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.refrigerant &&
                          formik.errors.refrigerant
                        }
                        classes={{
                          input: classes.input,
                          container: classes.selectContainer,
                          paperRoot: classes.paperRoot,
                        }}
                      />
                    </Grid>

                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="quantity"
                        name="quantity"
                        label="Amount of refrigerant"
                        value={formik.values.quantity}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.quantity && formik.errors.quantity
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="refrigerantUnit"
                        name="refrigerantUnit"
                        label="Unit"
                        fullWidth
                        options={refrigerantsUnitList}
                        selectedValue={formik.values.refrigerantUnit || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.refrigerantUnit &&
                          formik.errors.refrigerantUnit
                        }
                      />
                    </Grid>
                  </>
                )}

              {formik.values.calculationMethod &&
                calculationNamesMap[
                  formik.values.calculationMethod
                ]?.toLowerCase() === "average-data method" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="volumeOfStoredGoods"
                        name="volumeOfStoredGoods"
                        label="volume of stored goods"
                        value={formik.values.volumeOfStoredGoods}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.volumeOfStoredGoods &&
                          formik.errors.volumeOfStoredGoods
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="fuelUnit"
                        name="fuelUnit"
                        label="Unit"
                        fullWidth
                        options={averageUnitInputList || [{ name: "select" }]}
                        selectedValue={formik.values.fuelUnit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.fuelUnit && formik.errors.fuelUnit
                        }
                        sortOption={true}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="emissionFactorValue"
                        name="emissionFactorValue"
                        label="Emission factor for storage facility"
                        value={formik.values.emissionFactorValue}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.emissionFactorValue &&
                          formik.errors.emissionFactorValue
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="averageNumberOfDaysStored"
                        name="averageNumberOfDaysStored"
                        label="Average number of days stored"
                        value={formik.values.averageNumberOfDaysStored}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.averageNumberOfDaysStored &&
                          formik.errors.averageNumberOfDaysStored
                        }
                        type="number"
                      />
                    </Grid>
                  </>
                )}
            </Grid>
            <CeroButton
              buttonText="Calculate"
              className={classes.buttonPrimary}
              onClick={onCalculate}
              disabled={!formik.dirty || !formik.isValid}
            />
            <CeroButton
              buttonText="Clear"
              variant="outlined"
              className={classes.buttonSecondary}
              onClick={formik.resetForm}
            />
            {isCalculateDone && (
              <Box className={classes.bottomContainer}>
                <Typography
                  variant="h6"
                  component="h6"
                  className={classes.previewTitle}
                >
                  Emission Preview
                </Typography>
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="space-between"
                  spacing={8}
                >
                  <Grid item container direction="column" xs={12} md={6}>
                    <CeroItemPair
                      title={
                        <>
                          CO<sub>2</sub>
                        </>
                      }
                      value={`${addEmissionData.data.co2} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          CH<sub>4</sub>
                        </>
                      }
                      value={`${addEmissionData.data.ch4} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          BioFuel CO<sub>2</sub>
                        </>
                      }
                      value={`${addEmissionData.data.biofuel_co2} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                  </Grid>
                  <Grid
                    className={classes.secondResultContainer}
                    item
                    container
                    direction="column"
                    xs={6}
                  >
                    <CeroItemPair
                      title={
                        <>
                          CO<sub>2</sub>e
                        </>
                      }
                      value={`${addEmissionData.data.co2e} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          N<sub>2</sub>O
                        </>
                      }
                      value={`${addEmissionData.data.n2o} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={<>EF</>}
                      value={
                        <>
                          {addEmissionData.data.ef} kgCO<sub>2</sub>e/unit
                        </>
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
          <FileUpload
            onFileUpload={handleFileUpload}
            onFileRemove={handleFileRemove}
          />
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Add Data"
            disabled={
              !isCalculateDone || addEmissionData.status === STATUS.RUNNING
            }
            className={classes.buttonPrimary}
            onClick={() => onAddUpstreamData(formik.values)}
          />

          {/* <div className={classes.buttonContainer}>
                <CeroButton
                   buttonText="Bulk Data Upload"
                   className={classes.bulkButton}
                   startIcon={<FileUploadIcon />}
                   onClick={() => setIsOpenEmissionTypeUpload(true)}
                />
                <CeroButton
                    buttonText="Download Template"
                    className={classes.bulkButton}
                    startIcon={<DownloadIcon />}
                    onClick={onDownload}
                  />
                </div> */}
        </Box>
        <EmissionUploadDrawer
          isOpen={isOpenEmissionTypeUpload}
          onClose={onCloseDrawerUpload}
          onUpload={onUpload}
          onValidate={onValidate}
          uploadDisabled={uploadDisabled}
        />
      </Container>
    </>
  );
};

export default AddDownstreamTD;
