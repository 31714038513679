import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Box, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { STATUS } from "../../../redux/constants";
import {
  addReviewComments,
  submitMonthlyApproval,
  assignMonthlyApproval,
  approveMonthlyApproval,
  resetApprovalData,
} from "../../../redux/actions";
import CeroButton from "../../../components/CeroButton";
import useStyles from "./styles";
import { getCookie } from "../../../services/cookie";
import CeroPopUp from "../../../components/CeroPopUp";

const EmissionDetailsApprovalHeader = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const readOnlyMode = getCookie("read_only_mode") === "disable_edit";
  const isApprovalPage = window.location.pathname.includes(
    "pending-approval/emissions/"
  );
  console.log(isApprovalPage, "nik");

  const role = useSelector((state) => state.auth.role);
  const { emissionId, can_review_action_do, subType, approvalId } = props;

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [reviewConfirm, setReviewConfirm] = useState(false);
  const [reviewPopup, setReviewPopup] = useState(false);
  const [comment, setComment] = useState("");
  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleReviewPopup = () => {
    setReviewConfirm(true);
  };
  const onSubmitReview = () => {
    setReviewPopup(false);
    dispatch(addReviewComments(approvalId, [emissionId], comment));
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const getReviewComments = () => {
    return (
      <>
        <TextField
          id="outlined-multiline-static"
          multiline
          rows={5}
          placeholder="Comment"
          style={{ width: "100%" }}
          value={comment}
          onChange={handleCommentChange}
          className={classes.textField}
        />
        <div className={classes.textLimit}>
          <div className={classes.noComments}>
            {comment.trim().length === 0 ? "No comments" : ""}
          </div>
          <div className={classes.maxCharacters}>
            {"Maximum 200 characters"}
          </div>
        </div>
        <div className={classes.reviewButtons}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={onSubmitReview}
            buttonText="Save"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() => setReviewPopup(false)}
            buttonText="Cancel"
          />
        </div>
      </>
    );
  };
  const handleAction = () => {
    if (role === "business_user") {
      dispatch(submitMonthlyApproval(approvalId, subType, [emissionId]));
    } else if (role === "facility_manager") {
      dispatch(assignMonthlyApproval(approvalId, [emissionId]));
    } else {
      dispatch(approveMonthlyApproval(approvalId, subType, [emissionId]));
    }
    handleClosePopup();
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setReviewPopup(false);
  };

  const getConfirmation = () => {
    return (
      <Box className={classes.popUp}>
        {role === "business_user" ? (
          <Typography variant="body1" style={{ fontWeight: 500 }}>
            Are you sure you want to submit?
          </Typography>
        ) : (
          <Typography variant="body1" style={{ fontWeight: 500 }}>
            Are you sure you want to approve ?
          </Typography>
        )}
        <div className={classes.buttonContainerPopup}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={handleAction}
            buttonText="Yes"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={handleClosePopup}
            buttonText="No"
          />
        </div>
      </Box>
    );
  };

  const getReviewConfirm = () => {
    return (
      <Box className={classes.popUp}>
        <Typography variant="body1" style={{ fontWeight: 500 }}>
          Are you sure you want to sent this records to review?
        </Typography>
        <div className={classes.buttonContainerPopup}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={() => {
              setReviewPopup(true);
              setReviewConfirm(false);
            }}
            buttonText="Yes"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() => setReviewConfirm(false)}
            buttonText="No"
          />
        </div>
      </Box>
    );
  };
  const approvalStatus = useSelector(
    (state) => state.approval.approveMonthlyApproval
  );

  const reviewStatus = useSelector((state) => state.approval.reviewComments);
  useEffect(() => {
    if (approvalStatus.status === STATUS.SUCCESS) {
      dispatch(resetApprovalData());
      navigate(-1);
      // dispatch(roleBasedApproval(id, type, subType));
      enqueueSnackbar(approvalStatus?.data?.message, {
        variant: "success",
        key: "approve",
      });
    } else if (approvalStatus.status === STATUS.ERROR) {
      enqueueSnackbar(
        approvalStatus.data.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetApprovalData());
    }
  }, [enqueueSnackbar, approvalStatus, dispatch]);

  useEffect(() => {
    if (reviewStatus.status === STATUS.SUCCESS) {
      dispatch(resetApprovalData());
      // dispatch(roleBasedApproval(id, type, subType));
      enqueueSnackbar(reviewStatus?.data?.message, {
        variant: "success",
        key: "review",
      });
    } else if (reviewStatus.status === STATUS.ERROR) {
      enqueueSnackbar(
        reviewStatus.data.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetApprovalData());
    }
  }, [enqueueSnackbar, reviewStatus, dispatch]);

  return (
    <>
      <Box>
        {isApprovalPage ? (
          role === "business_user" ? (
            <CeroButton
              buttonText="Submit"
              className={classes.buttonPrimary}
              onClick={handleOpenPopup}
              disabled={readOnlyMode}
            />
          ) : (
            <>
              <CeroButton
                buttonText="Approve"
                onClick={handleOpenPopup}
                className={classes.buttonPrimary}
              />
              {can_review_action_do && (
                <CeroButton
                  buttonText="Review"
                  onClick={handleReviewPopup}
                  className={classes.buttonPrimary}
                />
              )}
            </>
          )
        ) : (
          <></>
        )}
      </Box>
      <CeroPopUp
        primaryPopUp={{
          open: isPopupOpen,
          onClose: () => setIsPopupOpen(false),
          content: getConfirmation(),
          header: { title: "" },
        }}
        showCloseButton={true}
        classes={{ dialog: classes.dialog }}
      />
      <CeroPopUp
        primaryPopUp={{
          open: reviewPopup,
          onClose: () => setReviewPopup(false),
          content: getReviewComments(),
          header: { title: "Review Comments" },
        }}
        showCloseButton={true}
      />
      <CeroPopUp
        primaryPopUp={{
          open: reviewConfirm,
          onClose: () => setReviewConfirm(false),
          content: getReviewConfirm(),
          header: { title: "" },
        }}
        showCloseButton={true}
        classes={{ dialog: classes.dialog }}
      />
    </>
  );
};

export default EmissionDetailsApprovalHeader;
