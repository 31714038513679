import React from "react";

const CorruptionTable = ({ corruptionTable, setCorruptionTable }) => {
  const handleInputChange = (rowIndex, field, value) => {
    const updatedData = [...corruptionTable];
    updatedData[rowIndex][field] = value;
    setCorruptionTable(updatedData);
  };

  const addRow = () => {
    setCorruptionTable([
      ...corruptionTable,
      {
        countryName: "",
        corruptionRiskLevel: "",
        revenue: "",
        businessEthicsRisk: "",
        mitigationMeasures: "",
      },
    ]);
  };

  const removeRow = () => {
    if (corruptionTable.length > 1) {
      setCorruptionTable(corruptionTable.slice(0, -1));
    }
  };

  return (
    <div>
      {/* Table Container */}
      <div style={{ marginTop: "20px", border: "1px solid #000", borderRadius: "8px", overflow: "hidden", width: "100%" }}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr style={{ backgroundColor: "#D3C9CE", color: "#000", fontWeight: "bold", textAlign: "left" }}>
              <th style={tableHeaderStyle}>Country Name</th>
              <th style={tableHeaderStyle}>Corruption Risk Level ('E' or 'F')</th>
              <th style={tableHeaderStyle}>Revenue ($)</th>
              <th style={tableHeaderStyle}>Business Ethics Risks Present (Yes/No)</th>
              <th style={tableHeaderStyle}>Mitigation Measures Taken</th>
            </tr>
          </thead>
          <tbody>
            {corruptionTable.map((row, index) => (
              <tr key={index}>
                {["countryName", "corruptionRiskLevel", "revenue", "businessEthicsRisk", "mitigationMeasures"].map((field) => (
                  <td key={field} style={tableCellStyle}>
                    <input
                      type="text"
                      value={row[field]}
                      onChange={(e) => handleInputChange(index, field, e.target.value)}
                      style={inputFieldStyle}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Buttons (Now Outside the Table) */}
      <div style={{ display: "flex", justifyContent: "center", marginTop: "10px", gap: "10px" }}>
        <button onClick={removeRow} style={removeButtonStyle}>- Remove</button>
        <button onClick={addRow} style={addButtonStyle}>+ Add More</button>
      </div>
    </div>
  );
};

const tableHeaderStyle = {
  padding: "10px",
  border: "1px solid #000",
  textAlign: "center",
};

const tableCellStyle = {
  padding: "10px",
  border: "1px solid #000",
  textAlign: "center",
};

const inputFieldStyle = {
  width: "100%",
  padding: "6px",
  borderRadius: "4px",
  border: "1px solid grey",
  textAlign: "center",
  fontSize: "14px",
  backgroundColor: "white",
  outline: "none",
};

const addButtonStyle = {
    backgroundColor: "white",
    border: "1px solid lightgrey",  // Thin light grey border
    color: "grey",  // Grey text color
    borderRadius: "4px",
    padding: "8px 12px",
    cursor: "pointer",
    // fontWeight: "bold",
};

const removeButtonStyle = {
  backgroundColor: "white",
    border: "1px solid lightgrey",  // Thin light grey border
    color: "grey",  // Grey text color
    borderRadius: "4px",
    padding: "8px 12px",
    cursor: "pointer",
    // fontWeight: "bold",
};


export default CorruptionTable;
