import React, { useState } from "react";

const WorkforceTable = () => {
  const [workforceTable, setWorkforceTable] = useState([
    { metric: "Total recordable incident rate (TRIR)", directEmployees: "", contactEmployees: "" },
    { metric: "Fatality rate", directEmployees: "", contactEmployees: "" },
    { metric: "Near miss frequency rate (NMFR)", directEmployees: "", contactEmployees: "" },
  ]);

  const handleInputChange = (rowIndex, field, value) => {
    const updatedData = [...workforceTable];
    updatedData[rowIndex][field] = value;
    setWorkforceTable(updatedData);
  };

  return (
    <div>
      {/* Table Container */}
      <div style={{ marginTop: "20px", border: "1px solid #000", borderRadius: "8px", overflow: "hidden", width: "100%" }}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr style={{ backgroundColor: "#D3C9CE", color: "#000", fontWeight: "bold", textAlign: "left" }}>
              <th style={tableHeaderStyle}>Metric</th>
              <th style={tableHeaderStyle}>Direct Employees</th>
              <th style={tableHeaderStyle}>Contact Employees</th>
            </tr>
          </thead>
          <tbody>
            {workforceTable.map((row, index) => (
              <tr key={index}>
                <td style={{ ...tableCellStyle, backgroundColor: "#f5f5f5", fontWeight: "bold" }}>{row.metric}</td>
                <td style={tableCellStyle}>
                  <input
                    type="text"
                    value={row.directEmployees}
                    onChange={(e) => handleInputChange(index, "directEmployees", e.target.value)}
                    style={inputFieldStyle}
                  />
                </td>
                <td style={tableCellStyle}>
                  <input
                    type="text"
                    value={row.contactEmployees}
                    onChange={(e) => handleInputChange(index, "contactEmployees", e.target.value)}
                    style={inputFieldStyle}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const tableHeaderStyle = {
  padding: "10px",
  border: "1px solid #000",
  textAlign: "center",
};

const tableCellStyle = {
  padding: "10px",
  border: "1px solid #000",
  textAlign: "center",
};

const inputFieldStyle = {
  width: "100%",
  padding: "6px",
  borderRadius: "4px",
  border: "1px solid grey",
  textAlign: "center",
  fontSize: "14px",
  backgroundColor: "white",
  outline: "none",
};

export default WorkforceTable;
