import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { months, sampleYear } from "../../../constants";
import { STATUS } from "../../../redux/constants";
import { addMobileCombustionValidation } from "./schema";
import {
  addMobileCombustion,
  getEmissionFuelList,
  resetAddCombustionStatus,
  updateEndOfLifeTreatmentOfSoldProducts,
} from "../../../redux/actions";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroButton from "../../../components/CeroButton";
import CeroSelect from "../../../components/CeroSelect";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import DownloadIcon from "@mui/icons-material/Download";
import EmissionUploadDrawer from "../../EmissionList/EmissionUploadDrawer";
import { useNavigate } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CeroItemPair from "../../../components/CeroItemPair";
import FileUpload from "../../AddEmissions/FileUpload";

const EditTreatmentOfSoldProducts = (props) => {
  const { onCancel, onDownload, onUpload, onValidate, uploadDisabled,emissionData,
    emissionId, } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const isCalculateDone = useSelector(
    (state) => state.emission.updateEndOfLifeTreatmentOfSoldProducts.isCalculateDone
  );
  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const editEmissionData = useSelector(
    (state) => state.emission.updateEndOfLifeTreatmentOfSoldProducts
  );
  const emissionPerformData = useSelector((state) => state.emission.fuelList);
  const currentYear = new Date().getFullYear().toString();
  const [selectedFile, setSelectedFile] = useState(null);
  const unitMap={};

  const formik = useFormik({
    initialValues: {
      facility: emissionData.facility_id || "",
      year: emissionData.year || currentYear,
      month: emissionData.month || "",
      massUnit:emissionData.unit || "",
      wasteDisposalOption:emissionData.waste_treatment_method_id || "",
      product: emissionData.product_name || "",
      totalMass: emissionData.total_mass || "",
      percentageOfWasteTreatment:emissionData.percentage_treated || "",
      material:emissionData.material_id || "",
      
    },
    validationSchema: addMobileCombustionValidation,
    onSubmit: () => {},
    enableReinitialize:true,
  });

  
  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));
  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  const materialList = emissionPerformData?.data?.materials?.map((item) => ({
    key: item.id,
    value: item.name,
  }));
  const massUnitList = emissionPerformData?.data?.units?.map((item) => ({
    key: item.name,
    value: item.name,
  }));
  const wasteDisposalOption =
    emissionPerformData?.data &&
    (emissionPerformData?.data?.waste_treatment_methods || []).map((item) => ({
      key: item.id,
      value: item.name,
    }));

  useEffect(() => {
    dispatch(getEmissionFuelList("end_of_life_treatment"));
    return () => {
      dispatch(resetAddCombustionStatus());
    };
  }, [dispatch]);

  useEffect(() => {
    if (editEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("End-of-life treatment of sold products data added successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (editEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        editEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
    }
  }, [editEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onCalculate = () => {
    const requestData = {
      id:emissionId,
      facility_id: formik.values.facility,
      year: formik.values.year,
      month: formik.values.month,
      product_name: formik.values.product,
      total_mass: formik.values.totalMass,
      unit: formik.values.massUnit,
      material_id: formik.values.material,
      waste_treatment_method_id: formik.values.wasteDisposalOption,
      percentage_treated: formik.values.percentageOfWasteTreatment,
      save: false,
    };
    dispatch(updateEndOfLifeTreatmentOfSoldProducts(requestData));
  };

  const onUpdateEndOfLifeTreatmentData = () => {
    const requestData = {
      id:emissionId,
      facility_id: formik.values.facility,
      year: formik.values.year,
      month: formik.values.month,
      product_name: formik.values.product,
      total_mass: formik.values.totalMass,
      unit:formik.values.massUnit,
      material_id: formik.values.material,
      waste_treatment_method_id: formik.values.wasteDisposalOption,
      percentage_treated: formik.values.percentageOfWasteTreatment,
      save: true,
      file: selectedFile,
    };
    sessionStorage.setItem("year", requestData.year);
     dispatch(updateEndOfLifeTreatmentOfSoldProducts(requestData));
  };
  
  const [isOpenEmissionTypeUpload, setIsOpenEmissionTypeUpload] =
    useState(false);
  const onCloseDrawerUpload = () => {
    setIsOpenEmissionTypeUpload(false);
  };
  const handleFileUpload = (file) => {
    setSelectedFile(file);
    // dispatch(uploadEmissionAttachement("emissionId", file));
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
  };
  const info = () => {
    return (
      <div>
        <Typography variant="body2">
          Fuel use: Mobile combustion by the usage of fuel for mobile operations
          such as transport.
          <br />
          Distance activity: Distance travelled by the usage of fuel
        </Typography>
      </div>
    );
  };
  return (
    <>
      <div className={classes.backContainer}>
        <div
          className={classes.backButtonDiv}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon />
          Back
        </div>
        <div className={classes.templateButtonDiv}>
          {/* <CeroButton
            buttonText="Bulk Data Upload"
            className={classes.bulkButton}
            startIcon={<FileUploadIcon />}
            onClick={() => setIsOpenEmissionTypeUpload(true)}
          />
          <CeroButton
            buttonText="Download Template"
            className={classes.bulkButton}
            startIcon={<DownloadIcon />}
            onClick={onDownload}
          /> */}
        </div>
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Edit End-of-life treatment of sold products
          </Typography>
          <Box className={classes.topContainer}>
            <Grid className={classes.dynamicBoard}>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroAutoComplete
                  id="year"
                  name="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.touched.year && formik.errors.year}
                  options={yearList}
                  value={formik.values.year}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="month"
                  name="month"
                  label="Month"
                  fullWidth
                  options={months}
                  selectedValue={formik.values.month}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.month && formik.errors.month}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroInput
                  required
                  id="product"
                  name="product"
                  label="Product"
                  value={formik.values.product}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.product && formik.errors.product}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroInput
                  required
                  id="totalMass"
                  name="totalMass"
                  label="Total mass of sold products and packaging from point of sale to end of life after consumer use"
                  value={formik.values.totalMass}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.totalMass && formik.errors.totalMass}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="massUnit"
                  name="massUnit"
                  label="Unit"
                  fullWidth
                  options={massUnitList}
                  selectedValue={formik.values.massUnit}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.massUnit && formik.errors.massUnit}
                  sortOption={true}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroInput
                  required
                  id="percentageOfWasteTreatment"
                  name="percentageOfWasteTreatment"
                  label="% of total waste being treated by waste treatment method (this data company can collect"
                  value={formik.values.percentageOfWasteTreatment}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.percentageOfWasteTreatment &&
                    formik.errors.percentageOfWasteTreatment
                  }
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="material"
                  name="material"
                  label="Material"
                  fullWidth
                  options={materialList || [{ name: "select" }]}
                  selectedValue={formik.values.material}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.material && formik.errors.material}
                  sortOption={true}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="wasteDisposalOption"
                  name="wasteDisposalOption"
                  label="Waste treatment method "
                  fullWidth
                  options={wasteDisposalOption}
                  selectedValue={formik.values.wasteDisposalOption}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.wasteDisposalOption &&
                    formik.errors.wasteDisposalOption
                  }
                />
              </Grid>
            </Grid>
            <CeroButton
              buttonText="Calculate"
              className={classes.buttonPrimary}
              onClick={onCalculate}
              disabled={!formik.dirty || !formik.isValid}
            />
            <CeroButton
              buttonText="Clear"
              variant="outlined"
              className={classes.buttonSecondary}
              onClick={formik.resetForm}
            />
            {isCalculateDone && (
              <Box className={classes.bottomContainer}>
                <Typography
                  variant="h6"
                  component="h6"
                  className={classes.previewTitle}
                >
                  Emission Preview
                </Typography>
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="space-between"
                  spacing={8}
                >
                  <Grid item container direction="column" xs={12} md={6}>
                    <CeroItemPair
                      title={
                        <>
                          CO<sub>2</sub>
                        </>
                      }
                      value={`${editEmissionData.data.co2} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          CH<sub>4</sub>
                        </>
                      }
                      value={`${editEmissionData.data.ch4} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          BioFuel CO<sub>2</sub>
                        </>
                      }
                      value={`${editEmissionData.data.biofuel_co2} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                  </Grid>
                  <Grid
                    className={classes.secondResultContainer}
                    item
                    container
                    direction="column"
                    xs={6}
                  >
                    <CeroItemPair
                      title={
                        <>
                          CO<sub>2</sub>e
                        </>
                      }
                      value={`${editEmissionData.data.co2e} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          N<sub>2</sub>O
                        </>
                      }
                      value={`${editEmissionData.data.n2o} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={<>EF</>}
                      value={
                        <>
                          {editEmissionData.data.ef} kgCO<sub>2</sub>e/unit
                        </>
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
          <FileUpload
            onFileUpload={handleFileUpload}
            onFileRemove={handleFileRemove}
          />
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Update"
            disabled={
              !isCalculateDone || editEmissionData.status === STATUS.RUNNING
            }
            className={classes.buttonPrimary}
            onClick={() => onUpdateEndOfLifeTreatmentData(formik.values)}
          />

          {/* <div className={classes.buttonContainer}>
                <CeroButton
                   buttonText="Bulk Data Upload"
                   className={classes.bulkButton}
                   startIcon={<FileUploadIcon />}
                   onClick={() => setIsOpenEmissionTypeUpload(true)}
                />
                <CeroButton
                    buttonText="Download Template"
                    className={classes.bulkButton}
                    startIcon={<DownloadIcon />}
                    onClick={onDownload}
                  />
                </div> */}
        </Box>
        <EmissionUploadDrawer
          isOpen={isOpenEmissionTypeUpload}
          onClose={onCloseDrawerUpload}
          onUpload={onUpload}
          onValidate={onValidate}
          uploadDisabled={uploadDisabled}
        />
      </Container>
    </>
  );
};

export default EditTreatmentOfSoldProducts;
