import React, { useRef, useEffect, useMemo } from "react";
import { Chart, ScatterController, LinearScale, PointElement, Tooltip, Legend, Title } from "chart.js";

Chart.register(ScatterController, LinearScale, PointElement, Tooltip, Legend, Title);

const DoubleMaterialityScatterPlot = ({ financialMateriality, impactMateriality }) => {
  const chartRef = useRef(null);
  
  const drawCell = (ctx, xScale, yScale, col, row, cellSize, fillStyle) => {
    const xStart = col * cellSize;
    const xEnd = (col + 1) * cellSize;
    const yStart = row * cellSize;
    const yEnd = (row + 1) * cellSize;
  
    const xStartPixel = xScale.getPixelForValue(xStart);
    const xEndPixel = xScale.getPixelForValue(xEnd);
    const yStartPixel = yScale.getPixelForValue(yStart);
    const yEndPixel = yScale.getPixelForValue(yEnd);
  
    const width = xEndPixel - xStartPixel;
    const height = yStartPixel - yEndPixel;
  
    ctx.fillStyle = fillStyle;
    ctx.fillRect(xStartPixel, yEndPixel, width, height);
  };

  const data = useMemo(() => {
    const result = [];
    Object.keys(financialMateriality).forEach(category => {
      Object.keys(financialMateriality[category]).forEach(key => {
        result.push({
          x: financialMateriality[category][key],
          y: impactMateriality[category]?.[key] || 0,
          name: key,
          category,
        });
      });
    });
    return result;
  }, [financialMateriality, impactMateriality]);

  const colors = {
    Environment: "green",
    "Social Capital": "purple",
    "Human Capital": "blue",
    "Business Model and Innovation": "orange",
    "Leadership and Governance": "red",
  };

  const builtInShapes = {
    Environment: "rectRot", 
    "Social Capital": "triangle",
    "Human Capital": "rect", 
    "Business Model and Innovation": "circle",
    "Leadership and Governance": "crossRot",
  };

  const filteredCategories = Object.keys(colors).filter(category => 
    Object.keys(financialMateriality).includes(category)
  );

  const datasets = useMemo(() => {
    return filteredCategories.map(category => ({
      label: category,
      data: data
        .filter(d => d.category === category)
        .map(d => ({ x: d.x, y: d.y, name: d.name })),
      backgroundColor: colors[category],
      pointBackgroundColor: colors[category],
      pointBorderColor: colors[category],
      pointHoverBackgroundColor: colors[category],
      pointRadius: 12,
      pointStyle: builtInShapes[category],
    }));
  }, [colors, data, builtInShapes, filteredCategories]);
  
  const boxColors = useMemo(
    () => [
      ["#d3e4dd", "#d3e4dd", "#d3e4dd", "#d3e4dd", "#f8ebd0", "#f8ebd0", "#f8ebd0", "#f3dbda", "#f3dbda", "#f3dbda"],
      ["#d3e4dd", "#d3e4dd", "#d3e4dd", "#d3e4dd", "#f8ebd0", "#f8ebd0", "#f8ebd0", "#f3dbda", "#f3dbda", "#f3dbda"],
      ["#d3e4dd", "#d3e4dd", "#d3e4dd", "#d3e4dd", "#f8ebd0", "#f8ebd0", "#f8ebd0", "#f3dbda", "#f3dbda", "#f3dbda"],
      ["#d3e4dd", "#d3e4dd", "#d3e4dd", "#d3e4dd", "#f8ebd0", "#f8ebd0", "#f8ebd0", "#f3dbda", "#f3dbda", "#f3dbda"],
      ["#f8ebd0", "#f8ebd0", "#f8ebd0", "#f8ebd0", "#f8ebd0", "#f8ebd0", "#f8ebd0", "#f3dbda", "#f3dbda", "#f3dbda"],
      ["#f8ebd0", "#f8ebd0", "#f8ebd0", "#f8ebd0", "#f8ebd0", "#f8ebd0", "#f8ebd0", "#f3dbda", "#f3dbda", "#f3dbda"],
      ["#f8ebd0", "#f8ebd0", "#f8ebd0", "#f8ebd0", "#f8ebd0", "#f8ebd0", "#f8ebd0", "#f3dbda", "#f3dbda", "#f3dbda"],
      ["#f3dbda", "#f3dbda", "#f3dbda", "#f3dbda", "#f3dbda", "#f3dbda", "#f3dbda", "#f3dbda", "#f3dbda", "#f3dbda"],
      ["#f3dbda", "#f3dbda", "#f3dbda", "#f3dbda", "#f3dbda", "#f3dbda", "#f3dbda", "#f3dbda", "#f3dbda", "#f3dbda"],
      ["#f3dbda", "#f3dbda", "#f3dbda", "#f3dbda", "#f3dbda", "#f3dbda", "#f3dbda", "#f3dbda", "#f3dbda", "#f3dbda"],
    ],
    []
  );

  const commonScaleConfig = {
    type: "linear",
    min: 0,
    max: 5,
    ticks: { stepSize: 0.5 },
    grid: {
      color: context => (context.tick.value % 0.5 === 0 ? "#D3D3D3" : "transparent"),
    },
  };

  const backgroundPlugin = {
    id: "customBackground",
    beforeDraw: (chart) => {
      const { ctx, scales } = chart;
      const { x: xScale, y: yScale } = scales;
      const cellSize = 0.5;
      boxColors.forEach((rowColors, row) => {
        rowColors.forEach((fillStyle, col) => {
          drawCell(ctx, xScale, yScale, col, row, cellSize, fillStyle);
        });
      });
    },
  };

  const cartesianLinePlugin = {
    id: "cartesianLine",
    afterDraw: (chart) => {
      const { ctx, scales } = chart;
      const { x: xScale, y: yScale } = scales;
      const xPos = xScale.getPixelForValue(2.5);
      const yPos = yScale.getPixelForValue(2.5);
      const leftX = xScale.getPixelForValue(0);
      const rightX = xScale.getPixelForValue(5);
      const topY = yScale.getPixelForValue(5);
      const bottomY = yScale.getPixelForValue(0);

      ctx.save();
      ctx.strokeStyle = "black";
      ctx.lineWidth = 1;

      ctx.beginPath();
      ctx.moveTo(xPos, topY);
      ctx.lineTo(xPos, bottomY);
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(leftX, yPos);
      ctx.lineTo(rightX, yPos);
      ctx.stroke();
      ctx.restore();
    },
  };

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    const chartInstance = new Chart(ctx, {
      type: "scatter",
      data: { datasets },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: { 
            ...commonScaleConfig, 
            title: { display: true, text: "Financial Materiality" } 
          },
          y: { 
            ...commonScaleConfig, 
            title: { display: true, text: "Impact Materiality" } 
          },
        },
        plugins: {
          tooltip: {
            usePointStyle: true, // Enable point style rendering in tooltip
            callbacks: {
              label: context => {
                const { x, y, name } = context.raw;
                return `${name}: (${x}, ${y})`;
              },
              labelColor: context => {
                const dataset = context.dataset;
                return {
                  borderColor: dataset.pointBorderColor,
                  backgroundColor: dataset.pointBackgroundColor,
                  borderWidth: 2,
                  borderRadius: 0,
                  borderDash: [],
                };
              },
              labelPointStyle: context => {
                return {
                  pointStyle: context.dataset.pointStyle,
                  rotation: 0, // adjust rotation if needed
                };
              },
            },
          },
          legend: {
            position: "right",
            labels: {
              usePointStyle: true,
            },
          },
        },
      },
      plugins: [backgroundPlugin, cartesianLinePlugin],
    });
    return () => chartInstance.destroy();
  }, [datasets, commonScaleConfig]);
  

  return (
    <div style={{ width: "820px", height: "500px", margin: "0 auto" }}>
      <canvas ref={chartRef} />
    </div>
  );
};
export default React.memo(DoubleMaterialityScatterPlot);