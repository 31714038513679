import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { months, sampleYear } from "../../../constants";
import { STATUS } from "../../../redux/constants";
import { addMobileCombustionValidation } from "./schema";
import {
  getEmissionFuelList,
  resetAddCombustionStatus,
  updateEmployeeCommute,
} from "../../../redux/actions";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroButton from "../../../components/CeroButton";
import CeroSelect from "../../../components/CeroSelect";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import EmissionUploadDrawer from "../../EmissionList/EmissionUploadDrawer";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroItemPair from "../../../components/CeroItemPair";
import FileUpload from "../../AddEmissions/FileUpload";

const EditEmployeeCommute = (props) => {
  const {
    onCancel,
    onUpload,
    onValidate,
    uploadDisabled,
    emissionData,
    emissionId,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const isCalculateDone = useSelector(
    (state) => state.emission.updateEmployeeCommute.isCalculateDone
  );
  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const editEmissionData = useSelector(
    (state) => state.emission.updateEmployeeCommute
  );
  const emissionPerformData = useSelector((state) => state.emission.fuelList);
  const currentYear = new Date().getFullYear().toString();
  const [selectedFile, setSelectedFile] = useState(null);
  const formik = useFormik({
    initialValues: {
      facility: emissionData.facility_id || "",
      year: emissionData.year || currentYear,
      month: emissionData.month || "",
      calculationMethod: emissionData.calculation_method || "",
      fuelSource: emissionData.fuel_source_id || "",
      fuelUnit: emissionData.fuel_unit || "",
      workingDaysPerMonth: emissionData.working_days_per_month || 0,
      employeesUsingMode: emissionData.number_of_employees_using_mode || 0,
      totalEmployees: emissionData.total_employees || 0,
      oneWayDistance: emissionData.one_way_distance || 0,
      DailyoneWayDistance: emissionData.daily_one_way_distance || 0,
      commutingDaysPerMonth: emissionData.commuting_days_per_month || 0,
      amountOfFuel: emissionData.fuel_amount || 0,
      emissionFactor: emissionData.ef_dataset_id || "",
      modeOfTransport: emissionData.mode_of_transport_id || "",
      vehicleType: emissionData.vehicle_type_id || "",
      emissionFactorDataset: emissionData.ef_dataset_id || "",
      distanceTravelled: emissionData.distance_travelled || 0,
      distanceUnit: emissionData.distance_unit
        ? emissionPerformData?.data?.unit?.find(
            (item) => item.name === emissionData.distance_unit
          )?.id || ""
        : "",
      currency: emissionData.currency
        ? emissionPerformData?.data?.currency?.find(
            (item) => item.name === emissionData.currency
          )?.id || ""
        : "",
      totalSpent: emissionData.total_spent || 0,
      numberOfEmployees: emissionData.number_of_employees || 0,
      activityType: emissionData.activity_type_id || "",
    },
    validationSchema: addMobileCombustionValidation,
    onSubmit: () => {},
    enableReinitialize: true,
  });

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  const calculationNamesMap = {};
  const calculationInputList =
    emissionPerformData?.data?.calculation_method?.map((item) => {
      calculationNamesMap[item.id] = item.name; // Populate namesMap
      return {
        key: item.id,
        value: item.name,
      };
    }) || [];

  const fuelSourceInputList = emissionPerformData?.data?.fuel_sources?.map(
    (item) => {
      return {
        key: item.id,
        value: item.name,
      };
    }
  );
  const fuelUnitInputList = emissionPerformData?.data?.fuel_unit?.map(
    (item) => {
      return {
        key: item.name,
        value: item.name,
      };
    }
  );
  const transportModeList = emissionPerformData?.data?.transport_modes?.map(
    (item) => ({
      key: item.id,
      value: item.name,
    })
  );

  const transportAverageModeList =
    emissionPerformData?.data?.transport_modes_average_data_based?.map(
      (item) => ({
        key: item.id,
        value: item.name,
      })
    );
  const vehicleTypeList = emissionPerformData?.data?.vehicle_types?.map(
    (item) => ({
      key: item.id,
      value: item.name,
    })
  );
  const activityTypeList = emissionPerformData?.data?.activity_types?.map(
    (item) => ({
      key: item.id,
      value: item.name,
    })
  );

  const emissionFactorDataset = (
    emissionPerformData?.data?.ef_dataset || []
  ).map((item) => ({ key: item?.id, value: item?.name }));

  useEffect(() => {
    dispatch(getEmissionFuelList("employee_commute"));
    return () => {
      dispatch(resetAddCombustionStatus());
    };
  }, [dispatch]);

  useEffect(() => {
    if (editEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Employee Commute data updated successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (editEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        editEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
    }
  }, [editEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onCalculate = () => {
    const requestData = {
      id: emissionId,
      calculation_method: formik.values.calculationMethod,
      facility_id: formik.values.facility,
      commuting_days_per_month: formik.values.commutingDaysPerMonth,
      fuel_source_id: formik.values.fuelSource,
      fuel_unit: formik.values.fuelUnit,
      fuel_amount: formik.values.amountOfFuel,
      mode_of_transport_id: formik.values.modeOfTransport,
      vehicle_type_id: formik.values.vehicleType,
      ef_dataset_id: formik.values.emissionFactorDataset,
      activity_type_id: formik.values.activityType,
      total_employees: formik.values.totalEmployees,
      employees_using_mode: formik.values.employeesUsingMode,
      one_way_distance: formik.values.oneWayDistance,
      daily_one_way_distance: formik.values.DailyoneWayDistance,
      working_days_per_month: formik.values.workingDaysPerMonth,
      save: false,
      year: formik.values.year,
      month: formik.values.month,
    };
    // dispatch(addMobileCombustion(requestData));
    dispatch(updateEmployeeCommute(requestData));
  };

  const onAddMobileCombustionData = () => {
    const requestData = {
      id: emissionId,
      calculation_method: formik.values.calculationMethod,
      facility_id: formik.values.facility,
      commuting_days_per_month: formik.values.commutingDaysPerMonth,
      fuel_source_id: formik.values.fuelSource,
      fuel_unit: formik.values.fuelUnit,
      fuel_amount: formik.values.amountOfFuel,
      mode_of_transport_id: formik.values.modeOfTransport,
      vehicle_type_id: formik.values.vehicleType,
      ef_dataset_id: formik.values.emissionFactorDataset,
      activity_type_id: formik.values.activityType,
      total_employees: formik.values.totalEmployees,
      employees_using_mode: formik.values.employeesUsingMode,
      one_way_distance: formik.values.oneWayDistance,
      daily_one_way_distance: formik.values.DailyoneWayDistance,
      working_days_per_month: formik.values.workingDaysPerMonth,
      year: formik.values.year,
      month: formik.values.month,
      save: true,
      file: selectedFile,
    };
    sessionStorage.setItem("year", requestData.year);
    dispatch(updateEmployeeCommute(requestData));
    // dispatch(addMobileCombustion(requestData));
  };

  const [isOpenEmissionTypeUpload, setIsOpenEmissionTypeUpload] =
    useState(false);
  const onCloseDrawerUpload = () => {
    setIsOpenEmissionTypeUpload(false);
  };
  const handleFileUpload = (file) => {
    setSelectedFile(file);
    // dispatch(uploadEmissionAttachement("emissionId", file));
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
  };

  return (
    <>
      <div className={classes.backContainer}>
        <div
          className={classes.backButtonDiv}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon />
          Back
        </div>
        <div className={classes.templateButtonDiv}>
          {/* <CeroButton
            buttonText="Bulk Data Upload"
            className={classes.bulkButton}
            startIcon={<FileUploadIcon />}
            onClick={() => setIsOpenEmissionTypeUpload(true)}
          />
          <CeroButton
            buttonText="Download Template"
            className={classes.bulkButton}
            startIcon={<DownloadIcon />}
            onClick={onDownload}
          /> */}
        </div>
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Edit Employee Commuting
          </Typography>
          <Box className={classes.topContainer}>
            <Grid className={classes.dynamicBoard}>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesData.map((item) => ({
                    key: item.id,
                    value: item.name,
                  }))}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
              </Grid>

              <Grid item className={classes.individualInput}>
                <CeroAutoComplete
                  id="year"
                  name="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.touched.year && formik.errors.year}
                  options={yearList}
                  value={formik.values.year}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="month"
                  name="month"
                  label="Month"
                  fullWidth
                  options={months}
                  selectedValue={formik.values.month}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.month && formik.errors.month}
                />
              </Grid>
              <Grid item className={classes.individualInput}>
                <CeroSelect
                  required
                  id="calculationMethod"
                  name="calculationMethod"
                  label="Calculation Method"
                  fullWidth
                  options={calculationInputList}
                  selectedValue={formik.values.calculationMethod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.month && formik.errors.month}
                />
              </Grid>

              {formik.values.calculationMethod &&
                calculationNamesMap[
                  formik.values.calculationMethod
                ]?.toLowerCase() === "fuel-based" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="fuel_source_id"
                        name="fuelSource"
                        label="Fuel Source"
                        fullWidth
                        options={fuelSourceInputList}
                        selectedValue={formik.values.fuelSource}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.fuelSource && formik.errors.fuelSource
                        }
                        classes={{
                          input: classes.input,
                          container: classes.selectContainer,
                        }}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="fuel_amount"
                        name="amountOfFuel"
                        label="Amount of Fuel"
                        value={formik.values.amountOfFuel}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.amountOfFuel &&
                          formik.errors.amountOfFuel
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="fuel_unit"
                        name="fuelUnit"
                        label="Fuel Unit"
                        fullWidth
                        options={fuelUnitInputList || [{ name: "select" }]}
                        selectedValue={formik.values.fuelUnit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.fuelUnit && formik.errors.fuelUnit
                        }
                        sortOption={true}
                      />
                    </Grid>
                  </>
                )}
              {formik.values.calculationMethod &&
                calculationNamesMap[
                  formik.values.calculationMethod
                ]?.toLowerCase() === "distance-based" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="mode_of_transport_id"
                        name="modeOfTransport"
                        label="Mode of Transport"
                        fullWidth
                        options={transportModeList}
                        selectedValue={formik.values.modeOfTransport || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.modeOfTransport &&
                          formik.errors.modeOfTransport
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="vehicle_type_id"
                        name="vehicleType"
                        label="Vehicle Type"
                        fullWidth
                        options={vehicleTypeList}
                        selectedValue={formik.values.vehicleType || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.vehicleType &&
                          formik.errors.vehicleType
                        }
                        classes={{
                          input: classes.input,
                          container: classes.selectContainer,
                          paperRoot: classes.paperRoot,
                        }}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="ef_dataset_id"
                        name="emissionFactorDataset"
                        label="Emission Factor Dataset"
                        fullWidth
                        options={emissionFactorDataset}
                        selectedValue={
                          formik.values.emissionFactorDataset || ""
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.emissionFactorDataset &&
                          formik.errors.emissionFactorDataset
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="activity_type_id"
                        name="activityType"
                        label="Activity Type"
                        fullWidth
                        options={activityTypeList}
                        selectedValue={formik.values.activityType || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.activityType &&
                          formik.errors.activityType
                        }
                        classes={{
                          input: classes.input,
                          container: classes.selectContainer,
                          paperRoot: classes.paperRoot,
                        }}
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="daily_one_way_distance"
                        name="DailyoneWayDistance"
                        label="Daily one way distance between home and work in km"
                        value={formik.values.DailyoneWayDistance}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.DailyoneWayDistance &&
                          formik.errors.DailyoneWayDistance
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="commuting_days_per_month"
                        name="commutingDaysPerMonth"
                        label="Number of Commuting days per month"
                        value={formik.values.commutingDaysPerMonth}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.commutingDaysPerMonth &&
                          formik.errors.commutingDaysPerMonth
                        }
                        type="number"
                      />
                    </Grid>
                  </>
                )}
              {formik.values.calculationMethod &&
                calculationNamesMap[
                  formik.values.calculationMethod
                ]?.toLowerCase() === "average-data method" && (
                  <>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="total_employees"
                        name="totalEmployees"
                        label="Total number of employees"
                        value={formik.values.totalEmployees}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.totalEmployees &&
                          formik.errors.totalEmployees
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroSelect
                        required
                        id="mode_of_transport_id"
                        name="modeOfTransport"
                        label="Mode of Transport"
                        fullWidth
                        options={transportAverageModeList}
                        selectedValue={formik.values.modeOfTransport || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.modeOfTransport &&
                          formik.errors.modeOfTransport
                        }
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="number_of_employees_using_mode"
                        name="employeesUsingMode"
                        label="% of employees using mode of transport"
                        value={formik.values.employeesUsingMode}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.emissionFactor &&
                          formik.errors.emissionFactor
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="one_way_distance"
                        name="oneWayDistance"
                        label="One way commuting distance in km"
                        value={formik.values.oneWayDistance}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.emissionFactor &&
                          formik.errors.emissionFactor
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item className={classes.individualInput}>
                      <CeroInput
                        required
                        id="working_days_per_month"
                        name="workingDaysPerMonth"
                        label="Total working days per month"
                        value={formik.values.workingDaysPerMonth}
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.workingDaysPerMonth &&
                          formik.errors.workingDaysPerMonth
                        }
                        type="number"
                      />
                    </Grid>
                  </>
                )}
            </Grid>
            <CeroButton
              buttonText="Calculate"
              className={classes.buttonPrimary}
              onClick={onCalculate}
              disabled={!formik.dirty || !formik.isValid}
            />
            <CeroButton
              buttonText="Clear"
              variant="outlined"
              className={classes.buttonSecondary}
              onClick={formik.resetForm}
            />
            {isCalculateDone && (
              <Box className={classes.bottomContainer}>
                <Typography
                  variant="h6"
                  component="h6"
                  className={classes.previewTitle}
                >
                  Emission Preview
                </Typography>
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="space-between"
                  spacing={8}
                >
                  <Grid item container direction="column" xs={12} md={6}>
                    <CeroItemPair
                      title={
                        <>
                          CO<sub>2</sub>
                        </>
                      }
                      value={`${editEmissionData.data.co2} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          CH<sub>4</sub>
                        </>
                      }
                      value={`${editEmissionData.data.ch4} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          BioFuel CO<sub>2</sub>
                        </>
                      }
                      value={`${editEmissionData.data.biofuel_co2} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                  </Grid>
                  <Grid
                    className={classes.secondResultContainer}
                    item
                    container
                    direction="column"
                    xs={6}
                  >
                    <CeroItemPair
                      title={
                        <>
                          CO<sub>2</sub>e
                        </>
                      }
                      value={`${editEmissionData.data.co2e} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={
                        <>
                          N<sub>2</sub>O
                        </>
                      }
                      value={`${editEmissionData.data.n2o} tonnes`}
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                    <CeroItemPair
                      title={<>EF</>}
                      value={
                        <>
                          {editEmissionData.data.ef} kgCO<sub>2</sub>e/unit
                        </>
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.emissionTitle,
                        value: classes.value,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
          <FileUpload
            onFileUpload={handleFileUpload}
            onFileRemove={handleFileRemove}
          />
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Update"
            disabled={
              !isCalculateDone || editEmissionData.status === STATUS.RUNNING
            }
            className={classes.buttonPrimary}
            onClick={() => onAddMobileCombustionData(formik.values)}
          />

          {/* <div className={classes.buttonContainer}>
                <CeroButton
                   buttonText="Bulk Data Upload"
                   className={classes.bulkButton}
                   startIcon={<FileUploadIcon />}
                   onClick={() => setIsOpenEmissionTypeUpload(true)}
                />
                <CeroButton
                    buttonText="Download Template"
                    className={classes.bulkButton}
                    startIcon={<DownloadIcon />}
                    onClick={onDownload}
                  />
                </div> */}
        </Box>
        <EmissionUploadDrawer
          isOpen={isOpenEmissionTypeUpload}
          onClose={onCloseDrawerUpload}
          onUpload={onUpload}
          onValidate={onValidate}
          uploadDisabled={uploadDisabled}
        />
      </Container>
    </>
  );
};

export default EditEmployeeCommute;
