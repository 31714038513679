import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: "calc(100vh - 208px)",
  },
  container: {
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  actionContainer: {
    display: "flex",
  },
  button: {
    backgroundColor: theme.palette.Secondary.main,
  },
  editIcon: {
    marginLeft: theme.spacing(3),
    color: theme.palette.Primary.background,
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  audited: {
    color: theme.palette.status.audited,
    fontWeight: 500,
    fontSize: 14,
  },
  assigned: {
    color: theme.palette.status.pendingApproval,
    fontWeight: 500,
    fontSize: 14,
  },
  approved: {
    color: theme.palette.status.approved,
    fontWeight: 500,
    fontSize: 14,
  },
  verified: {
    color: theme.palette.status.verified,
    fontWeight: 500,
    fontSize: 14,
  },
  pending: {
    color: theme.palette.status.pendingApproval,
    fontWeight: 500,
    fontSize: 14,
  },
  submitted: {
    color: theme.palette.status.pendingApproval,
    fontWeight: 500,
    fontSize: 14,
  },
  added: {
    color: theme.palette.status.added,
    fontWeight: 500,
    fontSize: 14,
  },
  awaiting: {
    color: theme.palette.status.pendingApproval,
    fontWeight: 500,
    fontSize: 14,
    maxWidth: 55,
  },
  review: {
    color: theme.palette.status.review,
    fontWeight: 500,
    fontSize: 14,
    maxWidth: 55,
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    backgroundColor: theme.palette.background.white,
    textTransform: "capitalize",
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.contrastText,
    },
    marginRight: theme.spacing(5),
    marginLeft: theme.spacing(5),
  },
  tableTopic: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 20,
    alignItems: "center",
  },
  tableTitle: {
    fontWeight: 700,
    fontSize: 18,
    textTransform: "capitalize",
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  signalAdded: {
    backgroundColor: theme.palette.status.added,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1),
  },
  signalVerified: {
    backgroundColor: theme.palette.status.verified,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1),
  },
  signalApproved: {
    backgroundColor: theme.palette.status.approved,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1),
  },
  signalReview: {
    backgroundColor: theme.palette.status.review,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1),
  },
  signalPending: {
    backgroundColor: theme.palette.status.pendingApproval,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1),
  },
  signalAudited: {
    backgroundColor: theme.palette.status.audited,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid black",
    marginRight: theme.spacing(1),
  },
  subHeads: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  tableSubTitle: {
    fontWeight: 500,
    fontSize: 16,
    textTransform: "capitalize",
  },
}));

export default useStyles;
